import SignUp from "../pages/anonymous/SignUp";
import ViewPublishedAudit from "../pages/anonymous/ViewPublishedAudit";
import ViewPublishedAuditFixes from "../pages/anonymous/ViewPublishedAuditFixes";
import ViewPublishedDecarbonation from "../pages/anonymous/ViewPublishedDecarbonation";

const ROUTES_ANONYMOUS = {
    SIGN_UP: {
        path: '/sign-up',
        component: SignUp,
    },
    PUBLISHED_AUDIT_VIEW: {
        path: '/published/audit/:token',
        component: ViewPublishedAudit,
        withParams: function (token: string) {
            return '/published/audit/' + token
        }
    },
    PUBLISHED_AUDIT_FIXES_VIEW: {
        path: '/published/audit/:token/fixes',
        component: ViewPublishedAuditFixes,
        withParams: function (token: string) {
            return '/published/audit/' + token + '/fixes'
        }
    },
    PUBLISHED_DECARBONATION_VIEW: {
        path: '/published/decarbonation/:token',
        component: ViewPublishedDecarbonation,
        withParams: function (token: string) {
            return '/published/decarbonation/' + token
        }
    }
}

export default ROUTES_ANONYMOUS
