import React, {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useListDecarbonations} from "../../../api/decarbonationHooks";
import ROUTES from "../../../constants/routes";
import DateFormat, {Format} from "../../../components/common/DateFormat";
import {useTranslation} from "react-i18next";
import NumberFormat from "../../../components/common/NumberFormat";

export default function ListDecarbonations() {
    const {t} = useTranslation('decarbonation')
    const [search, setSearch] = useState('')
    const {data: decarbonations} = useListDecarbonations(search)

    return <div className="py-4 px-8">
        <h2>{t('list.title')}</h2>

        <input
            type="search"
            value={search}
            placeholder={t('list.search.placeHolder')}
            onChange={(event) => setSearch(event.target.value)}
            className="input input-bordered w-full max-w-x"
        />

        <div className="overflow-x-auto md:overflow-x-scroll">
            <table className="table-container w-full">
                <thead>
                <tr>
                    <th>{t('data.site.label')}</th>
                    <th>{t('data.creationDate.label')}</th>
                    <th>{t('data.scheduleDate.label')}</th>
                    <th>{t('data.startDate.label')}</th>
                    <th>{t('data.doneDate.label')}</th>
                    <th>{t('data.eqCO2Gain.label')}</th>
                    <th>{t('list.actions.label')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    decarbonations && decarbonations.map(decarbonation =>
                        <tr key={decarbonation.id}>
                            <td>
                                <NavLink className="link block" to={ROUTES.DECARBONATION_VIEW.withParams(decarbonation.id)}>{decarbonation.site.name}</NavLink>
                                {decarbonation.site.url}
                            </td>
                            <td>
                                <DateFormat date={decarbonation.creation_date} format={Format.NumericDateTime}/>
                            </td>
                            <td>
                                <DateFormat date={decarbonation.schedule.date} format={Format.NumericDay}/>
                            </td>
                            <td>
                                <DateFormat date={decarbonation.start_date} format={Format.NumericDateTime}/>
                            </td>
                            <td>
                                <DateFormat date={decarbonation.end_date} format={Format.NumericDateTime}/>
                            </td>
                            <td className="text-right font-light">
                                <NumberFormat
                                    value={decarbonation.eq_kg_co2_gain}
                                    options={{
                                        style: "unit",
                                        unit: "kilogram",
                                        unitDisplay: "short",
                                        minimumFractionDigits: 1,
                                        maximumFractionDigits: 1
                                    }}
                                />
                            </td>
                            <td>

                                <NavLink
                                    className="btn btn-xs btn-neutral"
                                    to={ROUTES.DECARBONATION_VIEW.withParams(decarbonation.id)}
                                >
                                    {
                                        decarbonation.end_date
                                            ? t('list.actions.view')
                                            : decarbonation.quote_site_audit || decarbonation.ignore_quote_site_audit
                                                ? t('list.actions.continue')
                                                : t('list.actions.schedule')
                                    }
                                </NavLink>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    </div>
}
