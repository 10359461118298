import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ViewSite from "../../../components/site/ViewSite";
import AuditSiteForm from "../../../components/audit/AuditSiteForm";
import ROUTES from "../../../constants/routes";
import {useTranslation} from "react-i18next";

export default function SiteOverviewPage() {
    let params = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    return (
        <div className="py-4 px-8">
            <ViewSite siteId={params.id}/>
            <h3 className="mb-2">{t('audit:new.title')}</h3>
            <AuditSiteForm siteId={params.id} onSuccess={() => {navigate(ROUTES.AUDITS.path)}}/>
        </div>
    )

}