import {NavLink, useNavigate, useParams} from "react-router-dom";
import SelectStartAudit from "../../../components/decarbonation/SelectStartAudit";
import CompareAudits from "../../../components/decarbonation/CompareAudits";
import DateFormat, {Format} from "../../../components/common/DateFormat";
import SiteOverView from "../../../components/site/SiteOverView";
import Modal from "../../../components/common/Modal";
import React, {useRef, useState} from "react";
import Editable from "../../../components/common/Editable";
import AuditStatus from "../../../components/audit/AuditStatus";
import {useGetDecarbonation, useUpdateDecarbonation} from "../../../api/decarbonationHooks";
import ViewLabel from "../../../components/label/ViewLabel";
import {useCreateLabel} from "../../../api/labelHooks";
import CrudFixes from "../../../components/decarbonation/CrudFixes";
import ROUTES_CLIENT from "../../../constants/routesClient";
import ROUTES from "../../../constants/routes";
import Schedule from "../../../components/decarbonation/Schedule";
import {Trans, useTranslation} from "react-i18next";

export default function ViewDecarbonation() {
    const {t} = useTranslation('decarbonation')
    const navigate = useNavigate()
    const decarbonationId = useParams().id!
    const operatorInputRef = useRef<HTMLInputElement>(null)

    const {isLoading, error, data: decarbonation} = useGetDecarbonation(decarbonationId)
    const [finishInProgress, setFinishInProgress] = useState(false)
    const [reopenInProgress, setReopenInProgress] = useState(false)
    const createLabel = useCreateLabel(_ => {
        updateDecarbonation.mutate({
            decarbonationId: decarbonationId,
            decarbonationUpdate: {finish: true}
        })
    })
    const updateDecarbonation = useUpdateDecarbonation(_ => {
        setFinishInProgress(false)
        setReopenInProgress(false)
    })

    const finishHandler = (siteId: string, endSiteAuditId: string) => {
        setFinishInProgress(true);
        createLabel.mutate({site_id: siteId, audit_id: endSiteAuditId})
    }

    const reopenHandler = () => {
        setReopenInProgress(true);
        updateDecarbonation.mutate({
            decarbonationId: decarbonationId,
            decarbonationUpdate: {reopen: true}
        })
    }

    if (isLoading) return <>Loading...</>
    if (error || decarbonation === undefined) return <>An error has occurred: {error}</>

    const stepComponent = () => {
        if (!decarbonation.quote_site_audit && !decarbonation.ignore_quote_site_audit) {
            return <Schedule
                decarbonationId={decarbonation.id}
                buttons={
                    [
                        {
                            text: t('schedule.actions.saveAndQuit'),
                            className: "btn btn-secondary",
                            afterUpdate: () => {
                                navigate(ROUTES.DECARBONATIONS_LIST.path)
                            }
                        },
                        {
                            text: t('schedule.actions.saveAndStart'),
                            className: "btn btn-primary ml-5"
                        }
                    ]
                }
            />
        } else if (!decarbonation.start_site_audit) {
            return <div className="py-4">
                <SelectStartAudit decarbonation={decarbonation} className="my-4"/>
            </div>
        } else if (decarbonation.start_site_audit
            && (decarbonation.start_site_audit.error_date || decarbonation.start_site_audit.cancel_date)
        ) {
            return <div>
                <h2>{t('startAudit.unusableAudit')}</h2>
                <strong>{t('data.startAudit.label')}:</strong>
                <AuditStatus audit={decarbonation.start_site_audit} cancelButton={true}/>
                <SelectStartAudit decarbonation={decarbonation} className="my-4"/>
            </div>
        } else if (!decarbonation.end_date) {
            return <div>
                <CompareAudits decarbonation={decarbonation} className="my-4"/>
                <h2>{t('fixes.title')}</h2>
                <CrudFixes DecarbonationId={decarbonation.id} readOnly={false}/>
            </div>
        } else {
            return <div>
                <CompareAudits decarbonation={decarbonation} className="my-4"/>
            </div>
        }
    }

    return (
        <div className="py-4 px-8">
            <div className="flex flex-row gap-6 w-full">
                <div className="flex-grow">
                    <h2>{t('data.site.label')}</h2>
                    <SiteOverView siteId={decarbonation.site.id}/>
                </div>
                <div className="w-1/2">
                    <h2>{t('data.status.label')}</h2>
                    <table className="table-data my-4">
                        <tbody>
                        <tr>
                            <th>{t('data.operator.label')}</th>
                            <td>
                                {!decarbonation.end_date &&
                                    <Editable
                                        text={decarbonation.operator}
                                        type="input"
                                        childRef={operatorInputRef}
                                        placeholder={t('data.operator.required')}
                                        className={decarbonation.operator ? "" : "text-red-500"}
                                    >
                                        <input type="email"
                                               ref={operatorInputRef}
                                               onBlur={e => updateDecarbonation.mutate({
                                                   decarbonationId: decarbonation.id,
                                                   decarbonationUpdate: {operator: e.target.value}
                                               })}
                                        />
                                    </Editable>
                                }
                                {decarbonation.end_date && decarbonation.operator}
                            </td>
                        </tr>
                        <tr>
                            <th>{t('data.startDate.label')}</th>
                            <td><DateFormat date={decarbonation.start_date} format={Format.NumericDateTime}/></td>
                        </tr>
                        <tr>
                            <th>{t('data.quoteAuditDate.label')}</th>
                            <td>
                                {
                                    decarbonation.quote_site_audit &&
                                    <DateFormat date={decarbonation.quote_site_audit.done_date} format={Format.NumericDateTime}/>
                                }
                                {
                                    !decarbonation.end_date &&
                                    <>
                                        &nbsp;
                                        <label className="btn btn-outline btn-xs" htmlFor={decarbonation.id + "-select-quote-audit"}>
                                            {t('data.quoteAuditDate.edit')}
                                        </label>
                                        <Modal id={decarbonation.id + "-select-quote-audit"}>
                                            <Schedule
                                                decarbonationId={decarbonation.id}
                                                buttons={[{text: t('schedule.actions.save'), className: "btn btn-primary"}]}
                                            />
                                        </Modal>
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>{t('data.scheduleDate.label')}</th>
                            <td>
                                <DateFormat date={decarbonation.schedule.date} format={Format.NumericDay}/>
                                {
                                    !decarbonation.end_date &&
                                    <>
                                        &nbsp;
                                        <label className="btn btn-outline btn-xs" htmlFor={decarbonation.id + "-schedule"}>
                                            {t('data.scheduleDate.edit')}
                                        </label>
                                        <Modal id={decarbonation.id + "-schedule"}>
                                            <Schedule
                                                decarbonationId={decarbonation.id}
                                                buttons={[{text: t('schedule.actions.save'), className: "btn btn-primary"}]}
                                            />
                                        </Modal>
                                    </>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>{t('data.startAuditDate.label')}</th>
                            <td>{
                                decarbonation.start_site_audit &&
                                <>
                                    <DateFormat date={decarbonation.start_site_audit.done_date} format={Format.NumericDateTime}/>
                                    &nbsp;
                                    {!decarbonation.end_date &&
                                        <>
                                            <label className="btn btn-xs btn-outline" htmlFor={decarbonation.id + "-select-start-audit"}>
                                                {t('data.startAuditDate.edit')}
                                            </label>
                                            <Modal id={decarbonation.id + "-select-start-audit"}>
                                                <SelectStartAudit decarbonation={decarbonation}/>
                                            </Modal>
                                        </>
                                    }
                                </>

                            }</td>
                        </tr>
                        <tr>
                            <th>{t('data.endAuditDate.label')}</th>
                            <td>{
                                decarbonation.end_site_audit &&
                                <DateFormat date={decarbonation.end_site_audit.done_date} format={Format.NumericDateTime}/>
                            }</td>
                        </tr>
                        <tr>
                            <th>{t('data.endDate.label')}</th>
                            <td><DateFormat date={decarbonation.end_date} format={Format.NumericDateTime}/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <ul className="steps steps-horizontal w-full">
                <li className={`step ${!(decarbonation.quote_site_audit && decarbonation.ignore_quote_site_audit) ? "step-primary" : ""} `}>
                    {t('schedule.title')}
                </li>
                <li className={`step ${(decarbonation.quote_site_audit || decarbonation.ignore_quote_site_audit) ? "step-primary" : ""} `}>
                    {t('startAudit.title')}
                </li>
                <li className={`step ${decarbonation.start_site_audit ? "step-primary" : ""} `}>
                    {t('pending.title')}
                </li>
                <li className={`step ${decarbonation.end_site_audit ? "step-primary" : ""} `}>
                    {t('endAudit.title')}
                </li>
                <li className={`step ${decarbonation.end_date ? "step-primary" : ""} `}>
                    {t('done.title')}
                </li>
            </ul>

            {stepComponent()}

            {
                decarbonation.end_site_audit && decarbonation.end_site_audit.done_date && !decarbonation.end_date &&
                <div className="text-right mt-12">
                    <NavLink to={ROUTES_CLIENT.DECARBONATION_VIEW.withParams(decarbonation.id)} className="btn btn-secondary">
                        {t('actions.preview')}
                    </NavLink>

                    {
                        decarbonation.operator
                            ?
                            <label className="btn ml-5 btn-primary" htmlFor={finishInProgress ? "" : decarbonation.id + "-finish"}>
                                {finishInProgress ? t('finish.loading') : t('finish.label')}
                            </label>
                            :
                            <div className="tooltip" data-tip="Operator is empty">
                                <label className="btn ml-5 btn-disabled" htmlFor={finishInProgress ? "" : decarbonation.id + "-finish"}>
                                    {t('finish.label')}
                                </label>
                            </div>
                    }
                    <Modal
                        id={decarbonation.id + "-finish"}
                        buttons={
                            [
                                {
                                    onClick: () => finishHandler(decarbonation.site.id, decarbonation.end_site_audit!.id),
                                    text: finishInProgress ? t('finish.loading') : t('finish.confirm.accept')
                                }
                            ]
                        }
                    >
                        <p>
                            <Trans t={t} i18nKey="finish.confirm.message" values={{url: decarbonation.site.url}}/>
                        </p>
                    </Modal>
                </div>
            }
            {
                decarbonation.end_date &&
                <>
                    {
                        decarbonation.site.label_id &&
                        <div>
                            <h2>Label</h2>
                            <ViewLabel labelId={decarbonation.site.label_id}/>
                        </div>
                    }
                    <div>
                        <h2>Fixes</h2>
                        <CrudFixes DecarbonationId={decarbonation.id} readOnly={true}/>
                    </div>

                    <div className="text-right mt-12">
                        <NavLink to={ROUTES.SITE_TEAM.withParams(decarbonation.site.id)} className="btn btn-primary btn-xs">
                            {t('actions.manageCustomer')}
                        </NavLink>

                        <NavLink to={ROUTES_CLIENT.DECARBONATION_VIEW.withParams(decarbonation.id)} className="btn btn-secondary btn-xs ml-3">
                            {t('actions.preview')}
                        </NavLink>

                        <label className="btn btn-outline btn-xs ml-3" htmlFor={decarbonation.id + "-reopen"}>
                            {t('reopen.label')}
                        </label>
                        <Modal
                            id={decarbonation.id + "-reopen"}
                            buttons={
                                [
                                    {
                                        onClick: () => reopenHandler(),
                                        text: reopenInProgress ? t('reopen.loading') : t('reopen.confirm.accept')
                                    }
                                ]
                            }
                        >
                            <p>
                                <Trans t={t} i18nKey="reopen.confirm.message" values={{url: decarbonation.site.url}}/>
                            </p>
                        </Modal>
                    </div>
                </>
            }
        </div>
    )
}
