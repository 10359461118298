import {CREDIT_VALUE} from "../../constants/audit";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    nb:number
    handleBuy: (nb:number) => void
    primary: boolean
}
export default function BuyOption(props: Props) {

    const {t} = useTranslation('credits')

return <div className="py-4 px-4 flex items-center justify-between bg-base-100 rounded-lg border border-gray-200">
            <div>
                <span className="font-bold">{props.nb}</span> <span className="text-xs text-neutral-focus">{t('items')}</span>
            </div>
            <div>
                <span className="font-bold">{props.nb * CREDIT_VALUE}</span> <span
                className="text-xs text-neutral-focus"> € {t('before_tax')}</span>
                <button type="button" className={"ml-2 btn btn-sm " + (props.primary?"btn-primary":"btn-outline")} onClick={() =>props.handleBuy(props.nb)}>{t('buy.btn')}</button>
            </div>
        </div>

}