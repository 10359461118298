import {Link} from "react-router-dom";
import ROUTES from "../../constants/routes";
import DateFormat, {Format} from "../common/DateFormat";
import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDeleteTeamSite, useGetTeam} from "../../api/teamHook";
import ModalV2 from "../common/ModalV2";
import {Site} from "../../api/dto/Site";
import {TeamMember} from "../../api/dto/Team";
import {useGetMe} from "../../api/userHook";

interface Props {
    teamId: string
}

export default function ListSitesOfTeam(props: Props) {
    const teamId = props.teamId
    const {t} = useTranslation('team')

    const userQuery = useGetMe()
    const user = userQuery.data

    const teamQuery = useGetTeam(teamId)
    const team = teamQuery.data

    const [membership, setMembership] = useState<TeamMember>()
    useEffect(() => {
        if (team && user) {
            const memberships = team.members.filter(e => e.login === user.login)
            if (memberships.length > 0) {
                setMembership(memberships[0])
            }
        }
    }, [team, user]);

    const [deletingSite, setDeletingSite] = useState<Site | undefined>()
    const [deleteModalIsOpened, setDeleteModalIsOpened] = useState(false)
    const deleteTeamSiteMutation = useDeleteTeamSite(() => setDeleteModalIsOpened(false))
    const handleOpenDeleteSiteModal = (site: Site) => {
        setDeletingSite(site)
        setDeleteModalIsOpened(true)
    }
    const handleDeleteSiteSubmit = (siteId: string) => {
        deleteTeamSiteMutation.mutate({teamId: teamId, siteId: siteId})
    }

    if (teamQuery.error || userQuery.error) {
        return <></>
    }
    if (teamQuery.isLoading || !team || userQuery.isLoading || !user) {
        return <></>
    }
    return <div>
        <table className="table-container w-full mt-4">
            <thead>
            <tr>
                <th className="text-left">{t('sites.data.name.label')}</th>
                <th className="text-left">{t('sites.data.url.label')}</th>
                <th className="text-left">{t('sites.data.creationDate.label')}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                team.sites.map(site =>
                    <tr key={site.id}>
                        <td>
                            {
                                user.is_admin ?
                                    <Link to={ROUTES.SITE.withParams(site.id)}>{site.name}</Link> :
                                    <>{site.name}</>
                            }
                        </td>
                        <td>{site.url}</td>
                        <td><DateFormat date={site.creation_date} format={Format.NumericDateTime}/></td>
                        <td>
                            {
                                (user.is_admin || team.admin_login === user.login || membership?.site_permission) &&
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => handleOpenDeleteSiteModal(site)}
                                    disabled={deleteTeamSiteMutation.isLoading}
                                >
                                    {t('sites.actions.delete.submit')}
                                </button>
                            }
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
        <ModalV2 id="delete-site" visible={deleteModalIsOpened} onClose={() => setDeleteModalIsOpened(false)}>
            <>
                {
                    deletingSite &&
                    <div>
                        <p>
                            <Trans t={t} i18nKey="sites.actions.delete.confirm.message" values={{name: deletingSite.name}}/>
                        </p>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleDeleteSiteSubmit(deletingSite.id)}
                            disabled={deleteTeamSiteMutation.isLoading}
                        >
                            {t('sites.actions.delete.confirm.accept')}
                        </button>
                    </div>
                }
            </>
        </ModalV2>
    </div>
}