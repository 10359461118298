import {useEffect} from "react"
import * as d3 from "d3"
import fileSize from "filesize";
import {useTranslation} from "react-i18next";
import {SiteAudit} from "../../api/dto/SiteAudit";

type AuditChartProps = {
    type: 'ECO' | 'ECO_IMAGES' | 'SPEED',
    audit: SiteAudit,
    className: string
}

type Bar = {
    code: string,
    name: string,
    value: number,
    displayedValue: string;
}

export default function AuditChart(props: AuditChartProps) {
    const {t} = useTranslation('audit')

    let svgRef: SVGSVGElement
    let parentRef: HTMLDivElement

    useEffect(() => {
        const svg = d3.select(svgRef)
        svg.selectAll("*").remove()
        drawChart();
    }, [props.audit]);

    const drawChart = () => {
        // prepare data
        let bars: Bar[] = [];
        if (props.type === 'ECO' || props.type === 'SPEED') {
            bars = props.audit.details_by_service.filter((detail) => {
                return detail.service_code !== 'OPTIMIZE_EXTRA'
            }).map((detail) => {
                let bar: Bar = {
                    code: detail.service_code,
                    name: detail.service_code,
                    value: 0,
                    displayedValue: ''
                };
                switch (detail.service_code) {
                    case 'OPTIMIZE_GZIP': {
                        bar.name = t('report.distributionChart.compression');
                        break;
                    }
                    case 'OPTIMIZE_CACHE': {
                        bar.name = t('report.distributionChart.cache');
                        break;
                    }
                    case 'OPTIMIZE_CSS': {
                        bar.name = t('report.distributionChart.css');
                        break;
                    }
                    case 'OPTIMIZE_JS': {
                        bar.name = t('report.distributionChart.js');
                        break;
                    }
                    case 'OPTIMIZE_IMAGES': {
                        bar.name = t('report.distributionChart.images');
                        break;
                    }
                }
                if (props.type === 'ECO') {
                    bar.value = detail.bandwidth_saving_deduplicated;
                    bar.displayedValue = fileSize(detail.bandwidth_saving_deduplicated, {base: 2, exponent: 2});
                }
                if (props.type === 'SPEED') {
                    bar.value = detail.loading_time_saving;
                    bar.displayedValue = (detail.loading_time_saving / 1000).toFixed(0) + 's';
                }
                return bar;
            });
        }
        if (props.type === 'ECO_IMAGES') {
            const services = props.audit.details_by_service.find(v => {
                return v.service_code === 'OPTIMIZE_IMAGES'
            });
            if (services) {
                bars = services.details_by_sub_category.map((detail) => {
                    let bar: Bar = {
                        code: detail.name,
                        name: detail.name,
                        value: detail.bandwidth_saving,
                        displayedValue: fileSize(detail.bandwidth_saving, {base: 2, exponent: 2})
                    };
                    switch (detail.name) {
                        case 'uses-responsive-images': {
                            bar.name = t('report.distributionChart.ecologicalGainsImages.resize');
                            break;
                        }
                        case 'bad-image-format': {
                            bar.name = t('report.distributionChart.ecologicalGainsImages.format');
                            break;
                        }
                        case 'uses-optimized-images': {
                            bar.name = t('report.distributionChart.ecologicalGainsImages.optim');
                            break;
                        }
                        case 'efficient-animated-content': {
                            bar.name = t('report.distributionChart.ecologicalGainsImages.animation');
                            break;
                        }
                    }
                    return bar;
                });
            }
        }

        bars.sort((a, b) => (a.value > b.value ? -1 : 1));

        // render
        if (bars.length > 0) {
            const maxWidth = bars[0].value;
            const heightBlockBar = 60;
            const margin = {top: 34, right: 30, bottom: 30, left: 30};
            const width = parentRef.offsetWidth - margin.left - margin.right;
            const height = heightBlockBar * bars.length;
            const legendMargin = 100;
            const valueMargin = 60;
            const spaceMargin = 20;

            const svg = d3.select(svgRef)

            svg
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")

            var defs = svg.append("defs");

            var gradient = defs.append("linearGradient")
                .attr("id", "barGradient")
                .attr("x1", "0%")
                .attr("x2", "100%")
                .attr("y1", "0%")
                .attr("y2", "0%");
        
                    gradient.append("stop")
                .attr("class", "start")
                .attr("offset", "0%")
                .attr("stop-color", "#FBBF24")
                .attr("stop-opacity", 1);
        
                    gradient.append("stop")
                .attr("class", "end")
                .attr("offset", "100%")
                .attr("stop-color", "#E11D48")
                .attr("stop-opacity", 1);

            svg.append('line')
                .style("stroke", "black")
                .style("stroke-width", 1)
                .attr("x1", margin.left + legendMargin + spaceMargin-2)
                .attr("y1", margin.top)
                .attr("x2", margin.left + legendMargin + spaceMargin-2)
                .attr("y2", margin.top+height);

            let increment = 0;
            bars.forEach((bar) => {

                svg.append("text")
                    .text(bar.name)
                    .attr("x", margin.left + legendMargin)
                    .attr("y", margin.top + increment * heightBlockBar + heightBlockBar / 2)
                    .style("text-anchor", "end")
                    .style("dominant-baseline", "middle")
                    .attr("class", () => "chart-legend-neutral-bar");

                svg.append("rect")
                    .attr("fill", "url(#barGradient)")
                    .attr("stroke-width", 2)
                    .attr("stroke", "url(#barGradient)")
                    .attr("x", margin.left + legendMargin + spaceMargin)
                    .attr("y", margin.top + increment * heightBlockBar + heightBlockBar / 6)
                    .attr("width", (width - legendMargin - spaceMargin - valueMargin) * (bar.value / maxWidth))
                    .attr("height", 3 * heightBlockBar / 5)
                    .attr("rx", 2);


                const check=bar.displayedValue.startsWith("0")
                var space= 0;
                if (check) { space = 16 }
                svg.append("text")
                    .text(bar.displayedValue)
                    .attr("x", margin.left + legendMargin + spaceMargin + (width - legendMargin - spaceMargin - valueMargin) * (bar.value / maxWidth) + 10 + space)
                    .attr("y", margin.top + increment * heightBlockBar + heightBlockBar / 2)
                    .style("text-anchor", "start")
                    .style("dominant-baseline", "middle")
                    .attr("class", () => "chart-legend-neutral-bar-value");

                if (check) {
                    svg.append("svg:image")
                        .attr("x", margin.left + legendMargin + spaceMargin + (width - legendMargin - spaceMargin - valueMargin) * (bar.value / maxWidth) + 4)
                        .attr("y", margin.top + increment * heightBlockBar + heightBlockBar / 3)
                        .attr("width", 16)
                        .attr("height", 16)
                        .attr("xlink:href", "/images/icons/check.png")
                }

                increment++;

            });
        }
    }

    return <div ref={(r: HTMLDivElement) => parentRef = r} className={"bg-neutral bg-gradient-to-tr from-secondary to-accent " + props.className}>
        <svg ref={(r: SVGSVGElement) => svgRef = r}></svg>
    </div>
}
