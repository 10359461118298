import logo from "../../assets/images/logo.svg";
import btn from "../../assets/images/signup_btn_pic.svg";
import SiteNameAndOptions from "../../components/site/SiteNameAndOptions";
import {NotificationManager} from "react-notifications";
import React, {ChangeEvent, FormEvent, useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSignUp} from "../../api/userHook";
import {ERROR_TIMEOUT} from "../../constants/notifications";
import {isValidUrl} from "../../utils/utils";
import {SignUpRequest} from "../../api/dto/SignUpRequest";
import FillBottom from "../../components/common/FillBottom";


export default function SignUp() {
    const {t} = useTranslation('user')
    const [step, setStep] = useState(0)

    const [form, setForm] = useReducer(
        (prevState: SignUpRequest, updatedValues: Partial<SignUpRequest>) => {
            return {...prevState, ...updatedValues}
        },
        {
            email: '',
            name: '',
            favicon_url: '',
            site_url: '',
            url_filter: '',
            user: '',
            password: '',
        }
    )

    const signUpMutation = useSignUp(
        () => {
            window.location.replace("/?co=true")
        },
        (error: Error) => {
            NotificationManager.error(t('signup.feedbacks.error.message'), t('signup.feedbacks.error.title'), ERROR_TIMEOUT)
        }
    )

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        signUpMutation.mutate(form)
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }

    const next = () => {
        if (isValidUrl(form.site_url)) {
            setStep(step + 1)
        }
    }

    return <div className="w-full h-screen bg-base-200 py-6 px-4">
        <div className="max-w-6xl mx-auto">
            <header className="flex justify-between items-center mb-8">
                <img src={logo} alt="Webvert" height="20" width="166"/>
                <a href="/" className="inline-block flex items-center text-neutral-focus">
                    <svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.6 9a3 3 0 0 1 2-.8h4.7a3 3 0 0 1 3 2.9v1.2a.6.6 0 0 1-1.2 0V11a1.7 1.7 0 0 0-1.8-1.8H4.7a1.8 1.8 0 0 0-1.8 1.8v1.2a.6.6 0 0 1-1.1 0V11c0-.8.3-1.5.8-2ZM7 2.3a1.8 1.8 0 1 0 0 3.5 1.8 1.8 0 0 0 0-3.5ZM4 4.1a3 3 0 1 1 6 0 3 3 0 0 1-6 0Z"
                              fill="#475569"/>
                    </svg>
                    {t('signup.login')}
                </a>
            </header>
            <div className="bg-base-100 p-4 rounded rounded-lg">
                {
                    step === 0 && <>
                        <h1 className="text-xl mb-2">{t('signup.title')}</h1>
                        <p className="text-sm  text-neutral-focus">{t('signup.subtitle')}</p>
                    </>
                }
                {
                    step === 2 && <>
                        <h1 className="text-xl mb-2">{t('signup.title2')}</h1>
                    </>}
                <form onSubmit={handleSubmit}>
                    {
                        step === 0 &&
                        <div className="form-control">
                            <label htmlFor="site_url" className="label">{t('signup.data.siteUrl.label')}</label>
                            <input
                                id="site_url"
                                name="site_url"
                                className="input input-bordered w-full max-w-x"
                                type="url"
                                placeholder={t('signup.data.siteUrl.placeHolder')}
                                value={form.site_url}
                                onChange={handleChange}
                                required
                            />
                            <p className="text-sm text-center my-4 text-neutral-focus">{t('signup.info')}</p>
                            <div className="relative inline-block">
                                <button className="btn btn-primary my-4 w-full" onClick={next}>
                                    <img src={btn} alt="" className="absolute -left-2 top-1/3 transform -translate-y-1/3" aria-hidden="true"/>
                                    {t('signup.next')}
                                </button>
                            </div>
                        </div>
                    }
                    {
                        step === 1 &&
                        <>
                            <SiteNameAndOptions handleChange={handleChange} name={form.name} basicAuthLogin={form.user}
                                                basicAuthPassword={form.password} urlFilter={form.url_filter}/>
                            <button
                                type="button"
                                className="btn btn-primary btn-wide w-full my-4"
                                onClick={next}
                            >{t('site:create.submit')}</button>
                        </>
                    }
                    {
                        step === 2 &&
                        <>
                            <div className="form-control">
                                <label htmlFor="email" className="label">{t('signup.data.email.label')}</label>
                                <input
                                    id="email"
                                    name="email"
                                    className="input input-bordered w-full max-w-x"
                                    type="email"
                                    placeholder={t('signup.data.email.placeHolder')}
                                    value={form.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="text-center bg-primary-200 rounded rounded-xl my-4 pb-1">
                                <input
                                    id="submit"
                                    type="submit"
                                    className="btn btn-primary btn-wide w-full"
                                    value={t('signup.submit')}
                                    disabled={signUpMutation.isLoading}
                                />
                                <div className="mt-2">
                                    <p className="text-sm inline-flex">
                                        <span><svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd"
                                                                                                                               d="M13.8 3.5c.3.3.3.7 0 1l-7.3 7.3c-.3.3-.7.3-1 0L2.2 8.5a.7.7 0 1 1 1-1L6 10.4l6.9-6.9c.2-.2.6-.2.9 0Z"
                                                                                                                               fill="#71B029"/></svg></span>
                                        {t('signup.freecredit-part1')}&nbsp;<strong>{t('signup.freecredit-part2')}</strong>
                                    </p>
                                </div>
                            </div>
                            <p className="text-sm text-neutral-focus">{t('signup.submitDetails')}</p>
                        </>
                    }
                </form>
            </div>
            <FillBottom/>
        </div>
    </div>
}