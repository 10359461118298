import {useTranslation} from "react-i18next";
import React from "react";
import { PropsWithChildren } from 'react'

export default function Info(props: PropsWithChildren) {

return <div className="bg-primary-100 border border-primary-200 mt-4 rounded rounded-lg p-2 w-full text-primary-800">
    <p className="inline-flex gap-2">
                <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                           className="w-5 h-5 stroke-current">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                </span>
        <span>{props.children}</span>
    </p>
</div>
}