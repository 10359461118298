import DateFormat, {Format} from "../common/DateFormat";
import React from "react";
import TechnologiesBadges from "../audit/TechnologiesBadges";
import {useListSiteAudits} from "../../api/siteAuditHooks";
import {useGetSite} from "../../api/siteHook";
import {useTranslation} from "react-i18next";

interface Props {
    siteId: string
}

export default function SiteOverView(props: Props) {
    const {t} = useTranslation('site')
    const {isLoading: siteIsLoading, error: siteError, data: site} = useGetSite(props.siteId)
    const {isLoading: lastAuditIsLoading, error: lastAuditError, data: lastAudits} = useListSiteAudits(
        1,
        0,
        {siteId: props.siteId}
    )

    if (siteIsLoading || lastAuditIsLoading) return <>Loading...</>
    if (siteError || site === undefined) return <>An error has occurred: {siteError}</>
    if (lastAuditError || lastAudits === undefined) return <>An error has occurred: {lastAuditError}</>
    return <div className="border border-dashed border-base-300 my-4 p-4 md:h-56">
        <div>
            <h3 className="font-light text-3xl ">{site.name}</h3>
            <div><strong>{t('data.url.label')}:</strong> {site.url}</div>
            <div><strong>{t('data.urlFilter.label')}:</strong> {site.url_filter}</div>
            <div><strong>{t('data.creationDate.label')}:</strong> <DateFormat date={site.creation_date} format={Format.NumericDateTime}/></div>
            {
                lastAudits.results.length > 0 &&
                <div>
                    <strong>{t('data.technologies.label')}: </strong>
                    <TechnologiesBadges technologies={lastAudits.results[0].technologies}/>
                </div>
            }
        </div>
    </div>
}
