import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useCreateM2mUser} from "../../api/userHook";
import {M2mUserCreation} from "../../api/dto/User";
import {NotificationManager} from "react-notifications";
import {SUCCESS_TIMEOUT} from "../../constants/notifications";

export default function AddM2mUserForm() {
    const {t} = useTranslation('user')

    const [name, setName] = useState('')

    const createM2mUserMutation = useCreateM2mUser(
        () => {
            NotificationManager.success(
                t('actions.create_m2m.feedbacks.success.message'),
                t('actions.create_m2m.feedbacks.success.title'),
                SUCCESS_TIMEOUT
            )
            setName('')
        },
        () => {
            NotificationManager.error(
                t('actions.create_m2m.feedbacks.error.message'),
                t('actions.create_m2m.feedbacks.error.title'),
                SUCCESS_TIMEOUT
            )
        },
    )
    const createM2mUser = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        createM2mUserMutation.mutate({name: name} as M2mUserCreation)
    }

    return <form className="flex flex-row gap-4 items-center mt-4" onSubmit={createM2mUser}>
        <div className="form-control">
            <label className="input input-xs input-bordered flex items-center gap-2">
                {t('data.m2m_name.label')}
                <input
                    name="name"
                    type="text"
                    placeholder={t('data.m2m_name.placeHolder')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </label>
        </div>
        <input
            id="submit"
            type="submit"
            className="btn btn-primary btn-xs"
            value={createM2mUserMutation.isLoading ? "..." : t('actions.create_m2m.label')}
            disabled={createM2mUserMutation.isLoading}
        />
    </form>
}