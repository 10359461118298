import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {Site, SiteCreation, SiteUpdate} from "./dto/Site";
import {ExternalSiteInfo} from "./dto/ExternalSiteInfo";

const siteKeys = {
    all: ['sites'] as const,
    lists: () => [...siteKeys.all, 'list'] as const,
    list: (keyword: string) => [...siteKeys.lists(), {keyword: keyword}] as const,
    details: () => [...siteKeys.all, 'detail'] as const,
    detail: (id: string) => [...siteKeys.details(), id] as const,
}

export function useListSites(keyword: string, enabled: boolean, onSuccess?: (data: Site[]) => void, onError?: (error: string) => void) {
    return useQuery<Site[], string>({
        queryKey: siteKeys.list(keyword),
        queryFn: () => auditApiClient.listSites(keyword),
        onSuccess: async (sites: Site[]) => {
            if (onSuccess) {
                await onSuccess(sites)
            }
        },
        onError: async (error: string) => {
            if (onError) {
                await onError(error)
            }
        },
        enabled: enabled
    })
}

export function useGetSite(id: string, enabled?: boolean, onSuccess?: (data: Site) => void) {
    return useQuery<Site>({
        queryKey: siteKeys.detail(id),
        queryFn: () => auditApiClient.getSite(id),
        onSuccess: (data: Site) => {
            if (onSuccess) {
                onSuccess(data)
            }
        },
        enabled: enabled
    })
}

export function useCreateSite(onSuccess?: (site: Site) => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: SiteCreation) => auditApiClient.createSite(data),
        onSuccess: async (site: Site) => {
            await queryClient.invalidateQueries({queryKey: siteKeys.lists()})
            if (onSuccess) {
                await onSuccess(site)
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useUpdateSiteEmail(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { siteId: string, email: string }) => auditApiClient.updateSiteEmail(data.siteId, data.email),
        onSuccess: async (_, params: { siteId: string, email: string }) => {
            await queryClient.invalidateQueries({queryKey: siteKeys.lists()})
            await queryClient.invalidateQueries({queryKey: siteKeys.detail(params.siteId)})
            if (onSuccess) {
                await onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useUpdateSite(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (params: { siteId: string, data: SiteUpdate }) => auditApiClient.updateSite(params.siteId, params.data),
        onSuccess: async (_, params: { siteId: string, data: SiteUpdate }) => {
            await queryClient.invalidateQueries({queryKey: siteKeys.lists()})
            await queryClient.invalidateQueries({queryKey: siteKeys.detail(params.siteId)})
            if (onSuccess) {
                await onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useGetExternalSiteInformation(url: string, enabled: boolean, onSuccess?: (data: ExternalSiteInfo) => void) {
    return useQuery<ExternalSiteInfo>({
        queryKey: ['external-site-info', url],
        queryFn: () => auditApiClient.getExternalSiteInfo(url),
        onSuccess: (data: ExternalSiteInfo) => {
            if (onSuccess) {
                onSuccess(data)
            }
        },
        enabled: enabled
    })
}