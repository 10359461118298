import fileSize from "filesize"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useGetWorstAssets} from "../../api/siteAuditHooks"
import {SiteAudit, SiteAuditDetailsByService} from "../../api/dto/SiteAudit"
import {getLightHouseCategory, getServiceCodeFromCategory} from "../../utils/utils"
import {Asset} from "../../api/dto/Asset"
import chevron from '../../assets/images/chevron.svg'
import list from '../../assets/images/list.svg'
import clipboard from '../../assets/images/clipboard.svg'
import ModalV2 from "../common/ModalV2";
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications";


interface Props {
    category: string
    savable: number
    audit: SiteAudit
}

const pageSize = 10

export default function WorstAssetsCategory(props: Props) {

    const {t} = useTranslation('assets')

    const [isOpened, setIsOpened] = useState(false);
    const [currentAsset, setCurrentAsset] = useState<Asset>();

    const [page, setPage] = useState<number>(1)

    const {isLoading, error, data: assets} = useGetWorstAssets(
        pageSize * page,
        0,
        props.audit ? props.audit.id : "none",
        getLightHouseCategory(props.category)
    )
    const [nbImprovement, setNbImprovement] = useState<number>(0)

    const [collapse, setCollapse] = useState<boolean>(true)

    useEffect(() => {

        if (assets) {
            setNbImprovement(assets.total_count)
        } else {
            setNbImprovement(0)
        }

        if (props.category === "extraFiles" && assets) {
            assets.results = assets.results.sort((a, b) => (b.size_in_bytes - a.size_in_bytes))
        }

    }, [assets]);

    const getSize = (audit: SiteAudit, category: string): number => {
        return audit.details_by_service.filter((detail: SiteAuditDetailsByService) => {
            return (getServiceCodeFromCategory(category).indexOf(detail.service_code) > -1)
        }).reduce((a: number, b: SiteAuditDetailsByService) => {
            if (b.service_code === "OPTIMIZE_EXTRA") {
                if (assets && assets.results) {
                    return assets.results.reduce((sumE, asset: Asset) => sumE + asset.size_in_bytes, 0)
                } else {
                    return 0
                }
            } else {
                return b.bandwidth_saving_deduplicated
            }
        }, 0)
    }

    const handleMore = () => {
        setPage(page + 1)
    }

    const handleCopyToClipboard = (src: string, value: string) => {
        navigator.clipboard.writeText(value)
        NotificationManager.info(`The ${src} has been copied to clipboard.`, 'Copied', INFO_TIMEOUT)
    }


    return <div className="border-t rounded-none last:border-b bg-base-100 px-0">
        <div tabIndex={0} className="text-xl font-medium flex flex-row justify-between items-center p-4 pl-4"
             onClick={() => {
                 if (getSize(props.audit, props.category) > 1) {
                     setCollapse(!collapse)
                 } else {
                     setCollapse(true)
                 }
             }} aria-expanded={!collapse}>
            <div className="flex justify-start items-center gap-4">
                <img src={chevron} aria-hidden="true" className={(collapse ? "rotate-180" : "")}/>
                <div>
                    <h3 className="text-lg font-bold my-0 py-0">{t('fixes.' + props.category)}</h3>
                    {props.category != "extraFiles" &&
                        <p className={"text-sm " + ((props.savable > 1) ? "text-error-content" : "text-success-content")}>{t('fixes.savable', {volume: fileSize(props.savable)})}</p>
                    }
                    {props.category === "extraFiles" &&
                        <p className="text-sm text-neutral-focus">{t('fixes.potential', {volume: fileSize(getSize(props.audit, props.category))})}</p>
                    }
                </div>
            </div>
            <div className="badge badge-secondary badge-outline">{nbImprovement}</div>
        </div>
        <div className={"flex flex-col px-0 my-0 py-0 " + (collapse ? "hidden" : "")}>
            {assets && assets.results.map((asset) =>
                <div key={asset.id} className="py-2 bg-base-200 border-t border-t-gray-200 px-4">
                    <div className="flex justify-between text-sm mb-2">
                        <p>{t('fixes.currentSize')} {fileSize(asset.size_in_bytes)}</p>
                        <div className="flex gap-2 justify-end">
                            <p className="font-bold">{asset.max_saving_size_in_bytes && t('fixes.savable', {volume: fileSize(asset.max_saving_size_in_bytes)})}</p>
                            <img src={list} tabIndex={0} alt={t('fixes.listPage')} onClick={() => {
                                setCurrentAsset(asset)
                                setIsOpened(true)
                            }}/>
                        </div>
                    </div>
                    <p className="text-xs text-gray-700 longlink">{asset.url}</p>
                </div>
            )}
            {assets &&
                <div className="py-2 bg-base-200 border-t border-t-gray-200 px-4">
                    <div className={"flex py-2 items-center " + (((assets.total_count - (page) * pageSize) > 0) ? "justify-between" : "justify-end")}>
                        {(assets.total_count - (page) * pageSize) > 0 &&
                            <button type="button" onClick={handleMore} className="btn btn-xs btn-outline btn-neutral">{t('fixes.showMore')}</button>
                        }
                        <p className="text-xs text-gray-700 text-right">{t('fixes.displayed', {
                            total: assets.total_count,
                            displayed: Math.min((page) * pageSize, assets.total_count)
                        })}</p>
                    </div>
                </div>
            }
        </div>
        <ModalV2
            id="list-pages" visible={isOpened} onClose={() => setIsOpened(false)}>
            <>
                {currentAsset &&
                    <>
                        <h2>{t('fixes.' + props.category)}</h2>
                        {props.category != "extraFiles" &&
                            <p className="font-bold text-error-content text-sm">{currentAsset.max_saving_size_in_bytes && t('fixes.savable', {volume: fileSize(currentAsset.max_saving_size_in_bytes)})}</p>
                        }
                        <p className="text-sm">{t('fixes.currentSize')} {fileSize(currentAsset.size_in_bytes)}</p>

                        <h3 className="mt-4 border-y border-y-gray-200 py-2 mb-0">{t('fixes.subtitleAsset')}</h3>
                        <div className="flex justify-between gap-2 items-center py-2 bg-base-300 px-2">
                            <a href={currentAsset.url} target="_blank" className="text-sm underline longlink ">{currentAsset.url}</a>
                            <button
                                type="button"
                                onClick={() => handleCopyToClipboard(t('common:clipboard_ressource'), currentAsset.url)}
                                className="btn btn-sm btn-outline btn-neutral px-2"
                            >
                                <img src={clipboard} alt={t('common:clipboard')} width="20"/>
                            </button>
                        </div>
                        <h3 className="py-2 border-y border-y-gray-200 mb-0">{t('fixes.subtitlePages', {nb: currentAsset.page_urls.length, s: currentAsset.page_urls.length > 1 ? "s" : ""})}</h3>
                        <div className="flex flex-col">
                            {currentAsset.page_urls.map(item => {
                                return <div className="flex justify-between gap-2 items-center py-2 bg-base-300 px-2  border-b border-b-gray-200">
                                    <a href={item} target="_blank"
                                       className="text-sm underline longlink ">{item}</a>
                                    <button
                                        type="button"
                                        onClick={() => handleCopyToClipboard(t('common:clipboard_page'), item)}
                                        className="btn btn-sm btn-outline btn-neutral px-2"
                                    >
                                        <img src={clipboard} alt={t('common:clipboard')} width="20"/>
                                    </button>
                                </div>
                            })
                            }
                        </div>


                    </>
                }
                <button onClick={() => setIsOpened(false)} className="w-full btn btn-outline btn-neutral mt-6">{t('common:close')}</button>
            </>
        </ModalV2>
    </div>


}