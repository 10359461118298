import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import {useTranslation} from "react-i18next";

export const AuthenticationGuard = ({ component }) => {
    const {t} = useTranslation('auth')
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div className="page-layout">
                <div>{t('authenticating')}...</div>
            </div>
        ),
    });

    return <Component />;
};
