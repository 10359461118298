import {useListSites} from "../../api/siteHook";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAddTeamSite, useGetTeam} from "../../api/teamHook";
import {Site} from "../../api/dto/Site";

interface Props {
    teamId: string
    onSuccess?: () => void
}

export default function AddSiteToTeam(props: Props) {
    const {teamId, onSuccess} = props

    const {t} = useTranslation('team')

    const teamQuery = useGetTeam(teamId)
    const team = teamQuery.data

    const [search, setSearch] = useState('')
    const sitesQuery = useListSites(search, search.length >= 3)
    const sites = sitesQuery.data

    const [addableSites, setAddableSites] = useState<Site[]>([])
    useEffect(() => {
        if (team && sites) {
            setAddableSites(sites.filter(site => !team.sites.map(s => s.id).includes(site.id)))
        }
    }, [team, sites]);

    const addSiteMutation = useAddTeamSite(onSuccess)
    const addSiteHandler = (siteId: string) => {
        addSiteMutation.mutate({teamId: teamId, siteId: siteId})
    }

    return <div>
        <input
            type="search"
            value={search}
            placeholder={t('site:list.searchPlaceHolder')}
            onChange={(event) => setSearch(event.target.value)}
            className="input input-bordered w-full max-w-x"
        />

        {
            addableSites.length > 0 &&
            <table className="table-container w-full mt-4 mb-8" id="sites-list">
                <thead>
                <tr>
                    <th className="text-left">{t('site:data.name.label')}</th>
                    <th className="text-left">{t('site:data.url.label')}</th>
                    <th className="text-center">{t('sites.actions.add.submit')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    addableSites.map(site =>
                        <tr key={site.id}>
                            <td>{site.name}</td>
                            <td>{site.url}</td>
                            <td className="text-center">
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => addSiteHandler(site.id)}
                                >
                                    {t('sites.actions.add.submit')}
                                </button>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        }
    </div>
}
