import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useCreateAdminUser} from "../../api/userHook";
import {AdminUserCreation} from "../../api/dto/User";
import {NotificationManager} from "react-notifications";
import {SUCCESS_TIMEOUT} from "../../constants/notifications";

export default function AddAdminUserForm() {
    const {t} = useTranslation('user')

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const createAdminUserMutation = useCreateAdminUser(
        () => {
            NotificationManager.success(
                t('actions.create_admin.feedbacks.success.message'),
                t('actions.create_admin.feedbacks.success.title'),
                SUCCESS_TIMEOUT
            )
            setLogin('')
            setPassword('')
        },
        () => {
            NotificationManager.error(
                t('actions.create_admin.feedbacks.error.message'),
                t('actions.create_admin.feedbacks.error.title'),
                SUCCESS_TIMEOUT
            )
        },
    )
    const createAdminUser = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        createAdminUserMutation.mutate({login: login, password: password} as AdminUserCreation)
    }

    return <form className="flex flex-row gap-4 items-center mt-4" onSubmit={createAdminUser}>
        <div className="form-control">
            <label className="input input-xs input-bordered flex items-center gap-2">
                {t('data.login.label')}
                <input
                    name="name"
                    type="text"
                    placeholder={t('data.login.placeHolder')}
                    value={login}
                    onChange={e => setLogin(e.target.value)}
                />
            </label>
        </div>
        <div className="form-control">
            <label className="input input-xs input-bordered flex items-center gap-2">
                {t('data.password.label')}
                <input
                    name="password"
                    type="text"
                    placeholder={t('data.password.placeHolder')}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </label>
        </div>
        <input
            id="submit"
            type="submit"
            className="btn btn-primary btn-xs"
            value={createAdminUserMutation.isLoading ? "..." : t('actions.create_admin.label')}
            disabled={createAdminUserMutation.isLoading}
        />
    </form>
}