import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useCreateTeam} from "../../api/teamHook";
import {NotificationManager} from "react-notifications";
import {SUCCESS_TIMEOUT} from "../../constants/notifications";

export default function CreateTeam() {
    const {t} = useTranslation('team')

    const [name, setName] = useState('')

    const createTeamMutation = useCreateTeam(
        () => {
            NotificationManager.success(
                t('actions.create.feedbacks.success.message', {name: name}),
                t('actions.create.feedbacks.success.title'),
                SUCCESS_TIMEOUT
            )
            setName('')
        },
        () => {
            NotificationManager.error(
                t('actions.create.feedbacks.error.message', {name: name}),
                t('actions.create.feedbacks.error.title'),
                SUCCESS_TIMEOUT
            )
        }
    )
    const handleAddMemberSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (name) {
            createTeamMutation.mutate({name: name})
        }
    }

    return <form onSubmit={handleAddMemberSubmit} className="flex flex-row my-4">
        <div className="form-control">
            <label className="input input-bordered flex items-center gap-2">
                {t('data.name.label')}
                <input
                    name="member_email"
                    type="text"
                    placeholder={t('data.name.placeHolder')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </label>
        </div>
        <input
            id="submit"
            type="submit"
            className="btn btn-primary btn-wide ml-4"
            value={createTeamMutation.isLoading ? "..." : t('actions.create.submit')}
            disabled={createTeamMutation.isLoading || !name}
        />
    </form>
}