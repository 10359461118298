import logo from '../../assets/images/logo.svg'
import {Link, useNavigate} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {useTranslation} from "react-i18next";
import {useGetMe} from "../../api/userHook";
import ClientMenu from "./ClientMenu";

export default function ClientHeader() {
    const {t} = useTranslation('layout')
    const {isLoading, data: user} = useGetMe()

    if (isLoading || !user) return <>Loading...</>

    return (
            <header className="flex justify-between items-center mb-8">
                <img src={logo} alt="Webvert" height="20" width="166" />
                <div className="lg:block justify-self-end items-center">
                    <div className="flex-none">
                        <Link to={ROUTES_CLIENT.CREDITS.path} className="text-xs text-neutral-focus hover:text-neutral-focus underline focus:text-neutral-focus  inline-flex">
                            <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-4 h-4"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>
                            &nbsp;{ user?.credits } {user.credits>1? t('credits:items') : t('credits:item')}
                        <div className="html space"></div>
                        </Link> 
                        <ClientMenu />
                    </div>
                </div>
            </header>
    )
}