import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {CreditsTransaction} from "./dto/Credits";
import Pagination from "./dto/Pagination";
import {siteAuditKeys} from "./siteAuditHooks";
import {userKeys} from "./userHook";
import {PaymentIntent, PaymentIntentResponse} from "./dto/PaymentIntent";
import {BillingDetails} from "./dto/BillingDetails";
import {PaymentConfig} from "./dto/PaymentConfig";
import {Invoice} from "./dto/Invoice";
import {BuyAssets} from "./dto/Asset";

const creditsKeys = {
    all: ['credits'] as const,
    list: () => [...creditsKeys.all, 'list'] as const,
    listOneUser: (userId: string, limit: number, offset: number) => [...creditsKeys.list(), userId, {limit: limit, offset: offset}] as const,
}

const invoiceKeys = {
    all: ['invoice'] as const,
    list: (limit: number, offset: number) => [...creditsKeys.all, 'list', {limit: limit, offset: offset}] as const,
}

export function useGetPaymentConfig() {
    return useQuery<PaymentConfig>({
        queryKey: ['payment-config'],
        queryFn: () => auditApiClient.getPaymentConfig()
    })
}

export function useListTransactions(userId: string, limit: number, offset: number) {
    return useQuery<Pagination<CreditsTransaction>, string>({
        queryKey: creditsKeys.listOneUser(userId, limit, offset),
        queryFn: () => auditApiClient.getCreditsTransactions(userId, limit, offset)
    })
}

export function useBuyWorstAsset(onSuccess?: () => void, onError?: (error: Error) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { id: string, buyAssets: BuyAssets }) => auditApiClient.buyWorstAsset(data.id, data.buyAssets),
        onSuccess: async (_, data: { id: string, buyAssets: BuyAssets }) => {
            await queryClient.invalidateQueries({queryKey: [userKeys.me]})
            await queryClient.invalidateQueries({queryKey: [siteAuditKeys.detail(data.id)]})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error: Error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useCreatePaymentIntent(onSuccess?: (data: PaymentIntentResponse) => void, onError?: (error: Error) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: PaymentIntent) => auditApiClient.createPaymentIntent(data),
        onSuccess: async (out: PaymentIntentResponse) => {
            if (onSuccess) {
                await queryClient.invalidateQueries({queryKey: ['credits']})
                await queryClient.invalidateQueries({queryKey: ['invoice']})
                onSuccess(out)
            }
        },
        onError: (error: Error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useGetBillingDetails() {
    return useQuery<BillingDetails, string>({
        queryKey: ['billing'],
        queryFn: () => auditApiClient.getBillingDetails()
    })
}

export function useUpdateBillingDetails(onSuccess?: () => void, onError?: (error: Error) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (details: BillingDetails) => auditApiClient.updateBillingDetails(details),
        onSuccess: async (_) => {
            await queryClient.invalidateQueries({queryKey: ['billing']})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error: Error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useMyInvoices(limit: number, offset: number) {
    return useQuery<Pagination<Invoice>, string>({
        queryKey: invoiceKeys.list(limit, offset),
        queryFn: () => auditApiClient.getInvoices(limit, offset)
    })
}

export function useAddCredits() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (params: { userId: string, amount: number }) => auditApiClient.addCredits(params.userId, params.amount),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: creditsKeys.list()})
        }
    })
}

