import {NavLink, Outlet} from "react-router-dom";
import logo from '@webvert/design-system-lib/src/assets/images/webvert-logo-darktheme.svg'
import ROUTES from "./constants/routes";
import {NotificationContainer} from "react-notifications";
import Profile from "./components/auth/Profile";
import {useAuth0} from "@auth0/auth0-react";
import {useMemo} from "react";
import auditApiClient from "./api/auditApiClient";
import {useTranslation} from "react-i18next";
import {useGetMe} from "./api/userHook";

export default function App() {
    const {getAccessTokenSilently} = useAuth0();
    useMemo(() => {
        auditApiClient.tokenGenerator = getAccessTokenSilently;
    }, [getAccessTokenSilently]);

    const {t} = useTranslation('layout')

    const userQuery = useGetMe()

    if (userQuery.data && !userQuery.data.is_admin) {
        return <>Forbidden</>
    }

    return (
        <div>
            <input type="checkbox" id="side" name="" value=""/>
            <div className="page-wrap">

                <div className="sidebar">
                    <div className="flex items-start content-start">
                        <div className="flex-auto sidebar-menu pr-2 overflow-x-hidden">
                            <img src={logo} className="pb-4"/>
                            <div><Profile/></div>
                            <ul>
                                <li>
                                    <div className="text-primary uppercase">
                                        <span>{t('menu.sites.label')}</span>
                                    </div>
                                    <ul>
                                        <li>
                                            <NavLink to={ROUTES.SITES_LIST.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.sites.list')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={ROUTES.SITES_CREATE.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.sites.create')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={ROUTES.SITES_CREATE_AND_RUN.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.sites.createAndAudit')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="text-primary uppercase">
                                        <span>{t('menu.audits.label')}</span>
                                    </div>
                                    <ul>
                                        <li>
                                            <NavLink to={ROUTES.AUDITS.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.audits.list')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="text-primary uppercase">
                                        <span>{t('menu.decarbonations.label')}</span>
                                    </div>
                                    <ul>
                                        <li>
                                            <NavLink to={ROUTES.DECARBONATIONS_LIST.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.decarbonations.list')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <NavLink to={ROUTES.DECARBONATION_CREATE.path} className={({isActive}) => isActive ? "text-primary  font-semibold" : ""}>
                                                {t('menu.decarbonations.create')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="text-primary uppercase">
                                        <span>{t('menu.users.label')}</span>
                                    </div>
                                    <ul>
                                        <li>
                                            <NavLink to={ROUTES.USER_LIST.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.users.users')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to={ROUTES.TEAM_LIST.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.users.teams')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <div className="text-primary uppercase">
                                        <span>{t('menu.monitor.label')}</span>
                                    </div>
                                    <ul>
                                        <li>
                                        <NavLink to={ROUTES.MONITOR_AUDITS.path} className={({isActive}) => isActive ? "text-primary font-semibold" : ""}>
                                                {t('menu.monitor.audits')}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="flex-none justify-end">
                            <label htmlFor="side" className="toggle-side">
                                <svg className="w-6 h-7" viewBox="0 0 492 492" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m198.608 246.104 184.056-184.064c5.068-5.056 7.856-11.816 7.856-19.024 0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12c-5.06-5.072-11.824-7.864-19.032-7.864s-13.964 2.792-19.028 7.864l-219.148 219.144c-5.084 5.08-7.868 11.868-7.848 19.084-.02 7.248 2.76 14.028 7.848 19.112l218.944 218.932c5.064 5.072 11.82 7.864 19.032 7.864 7.208 0 13.964-2.792 19.032-7.864l16.124-16.12c10.492-10.492 10.492-27.572 0-38.06z"/>
                                </svg>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="page-content">
                    <Outlet/>
                </div>
            </div>
            <NotificationContainer/>
        </div>
    )
}
