import React from "react";
import {useTranslation} from "react-i18next";
import ListUsers from "../../../components/user/ListUsers";
import AddM2mUserForm from "../../../components/user/AddM2mUserForm";
import AddAdminUserForm from "../../../components/user/AddAdminUserForm";

export default function ListUsersPage() {
    const {t} = useTranslation('user')

    return <div className="py-4 px-8">
        <h2>{t('actions.create_m2m.title')}</h2>
        <AddM2mUserForm/>
        <h2 className="mt-6">{t('actions.create_admin.title')}</h2>
        <AddAdminUserForm/>
        <h2 className="mt-6">{t('list.title')}</h2>
        <ListUsers/>
    </div>
}
