import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useMyInvoices} from "../../api/creditsHook";
import DateFormat, {Format} from "../../components/common/DateFormat";
import Pagination from "../../components/pagination";
import {Invoice} from "../../api/dto/Invoice";
import ClientModalHeader from "../../components/common/ClientModalHeader";

const pageSize = 10

export default function CheckCredits() {
    const {t} = useTranslation('invoice')
    const [page, setPage] = useState<number>(1)

    const {isLoading, data: invoices} = useMyInvoices(
        pageSize,
        (page - 1) * pageSize
    )

    if (isLoading || !invoices) {
        return <div>{t('loading')}</div>
    }

    const renderType = (param: string) => {
        switch (param) {
            case 'credit_note':
                return <div className="badge badge-neutral">{t('type.credit_note')}</div>;
            case 'invoice':
                return <div className="badge badge-neutral">{t('type.invoice')}</div>;
            default:
                return <div className="badge">{param}</div>;
        }
    }

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-3xl">{t('list.title')}</h1>
                <div className="bg-base-100 mt-6 pt-4 pb-16 px-2 rounded rounded-lg">
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            <thead>
                            <tr>
                                <th>{t('list.date')}</th>
                                <th>{t('list.type')}</th>
                                <th>{t('list.credit_volume')}</th>
                                <th>{t('list.link')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                invoices.results &&
                                invoices.results.map((invoice: Invoice) => (
                                    <tr key={invoice.id}>
                                        <td><DateFormat date={invoice.date} format={Format.NumericDateTime}/></td>
                                        <td>{renderType(invoice.type)}</td>
                                        <td>{invoice.credit}</td>
                                        <td><a href={invoice.link} target="_blank" rel="noreferrer">
                                            <span className="hidden lg:block">{t('actions.download')}</span>
                                            <span className="block lg:hidden">{t('actions.download_mobile')}</span>
                                        </a></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {invoices &&
                            <div className="flex flex-col items-center mt-2">
                                <Pagination currentPage={page} pageSize={pageSize} totalCount={invoices.total_count} onClick={(i) => setPage(i)}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}
