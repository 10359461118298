import DateFormat, {Format} from "../common/DateFormat";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGetTeams} from "../../api/teamHook";
import {Link} from "react-router-dom";
import ROUTES from "../../constants/routes";
import {useGetMe} from "../../api/userHook";
import ROUTES_CLIENT from "../../constants/routesClient";

interface Props {
    siteId?: string
}

export default function ListTeams(props: Props) {
    const {t} = useTranslation('team')

    const userQuery = useGetMe()
    const user = userQuery.data

    const teamsQuery = useGetTeams(props.siteId ? props.siteId : '')
    const teams = teamsQuery.data

    if (userQuery.error || teamsQuery.error) {
        return <>An error has occurred</>
    }
    if (userQuery.isLoading || !user || teamsQuery.isLoading || !teams) {
        return <div>Loading...</div>
    }

    return <table className="table-container w-full mt-4">
        <thead>
        <tr>
            <th>{t('data.name.label')}</th>
            <th>{t('data.sitesCount.label')}</th>
            <th>{t('data.membersCount.label')}</th>
            <th>{t('data.creationDate.label')}</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {
            teams.sort((a, b) =>
                a.name.toLowerCase().normalize('NFD') < b.name.toLowerCase().normalize('NFD') ? -1 : 1
            )
                .map(team =>
                    <tr key={team.id}>
                        <td>
                            {team.name}
                            {
                                user.login === team.admin_login &&
                                <div className="ml-2 badge badge-secondary badge-outline">{t('data.admin.label')}</div>
                            }
                        </td>
                        <td className="text-center">{team.sites.length}</td>
                        <td className="text-center">{team.members.length}</td>
                        <td className="text-center"><DateFormat date={team.creation_date} format={Format.NumericDateTime}/></td>
                        <td>
                            {
                                user.is_admin ?
                                    <Link to={ROUTES.TEAM_VIEW.withParams(team.id)} className="btn btn-xs btn-neutral">
                                        {t('actions.see.label')}
                                    </Link> :
                                    <Link to={ROUTES_CLIENT.TEAM_VIEW.withParams(team.id)} className="btn btn-xs btn-neutral">
                                        {t('actions.see.label')}
                                    </Link>
                            }

                        </td>
                    </tr>
                )
        }
        </tbody>
    </table>
}