import React from "react";
import {useTranslation} from "react-i18next";
import {useGetMe} from "../../api/userHook";
import {Link} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import ClientModalHeader from "../../components/common/ClientModalHeader";
import CreditsLeft from "../../components/credit/CreditsLeft";
import ListCredits from "../../components/credit/ListCredits";

export default function CheckCredits() {
    const {t} = useTranslation('credits')

    const {data: user} = useGetMe()
    if (!user) {
        return <div>{t('management.loading')}</div>
    }

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <CreditsLeft user={user}/>
                <div className="flex flex-col lg:flex-row gap-2 justify-stretch lg:justify-between items-center mb-4 ">
                    <h1 className="text-3xl mx-0 w-full  text-left">{t('management.title')}</h1>
                    <Link to={ROUTES_CLIENT.CREDITS_BUY.path} className="btn btn-primary btn-sm my-4 lg:my-0 font-normal">{t('action.add')}</Link>
                </div>
                <ListCredits userId={user.id}></ListCredits>
            </div>
        </div>
    </div>
}
