import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {SiteAudit} from "../../api/dto/SiteAudit";
import AuditChart from "./AuditChart";
import WCOIRating from "../common/WCOIRating";

type AuditEsgEvalProps = {
    audit: SiteAudit,
}

export default function AuditEsgEval(props: AuditEsgEvalProps) {
    const {t} = useTranslation('audit')

    return <>
        <h3 className="mt-6 mb-2">{t('report.subtitle_optim_esg')}</h3>
        <p className="text-neutral-focus">{t('report.desc_optim_esg')}</p>

        { props.audit.wcoi_score >= 0 &&
            <>
            <h4>{t('report.subtitle_optim_hw_obso')} - <span className="italic text-warning">{t('beta')} </span></h4>
            <p className="text-neutral-focus">{t('report.desc_optim_hw_obso')}</p>

            <WCOIRating score={props.audit.wcoi_score * 100} className="my-6"/>

            <p className="text-neutral-focus text-center text-sm mt-2">
                {t('report.src_hw_obso1')}
                <a href={t('report.src_hw_obso_link')} target="_blank"
                   rel="noreferrer">{t('report.src_hw_obso2')}</a>
            </p>

            <p className="mt-4">{t('report.desc_optim_hw_obso2')}</p>
            </>
        }

        <h4>{t('report.subtitle_optim_energy')}</h4>

        <div
            className="bg-primary-100 border border-primary-200 mt-4 rounded rounded-lg p-2 text-primary-800">
            <div className="text-base">
                <p>
                    <strong>{Math.round(props.audit.bandwidth_savings_percent)}%</strong> {t("site:auditsDashboard.cta.co2Title")}
                </p>
            </div>
            <div className="text-sm pl-10">
                <p>
                    {Math.round(props.audit.eq_co2_savings)} {t("site:auditsDashboard.cta.co2Description1")} {Math.round(props.audit.savings_car_km)} {t("site:auditsDashboard.cta.co2Description2")}
                </p>
            </div>
        </div>
        <p className="text-neutral-focus text-center text-sm mt-2">
            {t('report.src_optim_esg1')}
            <a href={t('report.src_optim_esg_link')} target="_blank"
               rel="noreferrer">{t('report.src_optim_esg2')}</a>
        </p>

        <h4 className="mt-6 mb-2">{t("report.distributionChart.ecologicalGains.title")}</h4>
        <AuditChart type="ECO" audit={props.audit} className="rounded-2xl w-full"/>

        <h4 className="mt-6 mb-2">{t("report.distributionChart.ecologicalGainsImages.title")}</h4>
        <AuditChart type="ECO_IMAGES" audit={props.audit} className="rounded-2xl w-full"/>
    </>

}
