import {useGetLabel} from "../../api/labelHooks";
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications";
import React from "react";
import {useTranslation} from "react-i18next";
import CodeExtract from "../common/CodeExtract";

interface Props {
    labelId: string
}

export default function ViewLabel(props: Props) {
    const {error, data: label} = useGetLabel(props.labelId)
    const {t} = useTranslation('label')

    if (error || !label) {
        return <></>
    }

    return <div>
        <p>{t('copy')}</p>
        <div className="mt-5">
            <label className="font-bold">{t('lightBackground')}</label>
            <div className="flex flex-col lg:flex-row lg:items-center gap-2">
                <div className="text-center flex flex-row justify-start">
                    <div className="p-3 rounded-lg">
                        <div dangerouslySetInnerHTML={{__html: label.light_snippet}}/>
                    </div>
                </div>
                <CodeExtract copy={true} >
                    {label.light_snippet}
                </CodeExtract>
            </div>
        </div>
        <div className="mt-5">
            <label className="font-bold">{t('darkBackground')}</label>
            <div className="flex flex-col lg:flex-row lg:items-center gap-2">
                <div className="text-center flex flex-row justify-start">
                    <div className="p-3 bg-primary-content rounded-lg">
                        <div dangerouslySetInnerHTML={{__html: label.dark_snippet}}/>
                    </div>
                </div>
                <CodeExtract copy={true} >
                    {label.dark_snippet}
                </CodeExtract>
            </div>
        </div>
    </div>
}
