import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {SiteAudit, SiteAuditCreation, SiteAuditReproduction} from "./dto/SiteAudit";
import Pagination from "./dto/Pagination";
import {Asset} from "./dto/Asset";
import {Site} from "./dto/Site";

export const siteAuditKeys = {
    all: ['site_audits'] as const,
    lists: () => [...siteAuditKeys.all, 'list'] as const,
    list: (limit: number, offset: number, siteId?: string, status?: string, isReproductionOf?: string) =>
        [
            ...siteAuditKeys.lists(),
            {
                limit: limit,
                offset: offset,
                siteId: siteId,
                status: status,
                isReproductionOf: isReproductionOf
            }
        ] as const,
    details: () => [...siteAuditKeys.all, 'detail'] as const,
    detail: (id: string) => [...siteAuditKeys.details(), id] as const,
    worstAssets: (limit: number, offset: number, auditId: string, subCategories: string[]) =>
        [
            ...siteAuditKeys.detail(auditId),
            'worst_assets',
            {
                limit: limit,
                offset: offset,
                subCategories: (subCategories ? subCategories.sort((a,b) => 0 - (a > b ? 1 : -1)) : "all")
            }
        ] as const,
    published: () => [...siteAuditKeys.all, 'published'] as const,
    publishedDetail: (token: string) => [...siteAuditKeys.published(), token] as const,
}

interface useListSiteAuditsOptions {
    siteId?: string
    status?: string
    isReproductionOf?: string
    onSuccess?: (data: Pagination<SiteAudit>) => void
    enabled?: boolean
}

export function useListSiteAudits(limit: number, offset: number, options?: useListSiteAuditsOptions) {
    return useQuery<Pagination<SiteAudit>, string>({
        queryKey: siteAuditKeys.list(limit, offset, options?.siteId, options?.status, options?.isReproductionOf),
        queryFn: () => auditApiClient.getAudits(limit, offset, options?.siteId, options?.status, options?.isReproductionOf),
        enabled: options?.enabled,
        onSuccess: async (siteAudit: Pagination<SiteAudit>) => {
            if (options?.onSuccess) {
                options?.onSuccess(siteAudit)
            }
        },
    })
}

export function useGetSiteAudit(id: string, enabled?: boolean) {
    return useQuery<SiteAudit>({
        queryKey: siteAuditKeys.detail(id),
        queryFn: () => auditApiClient.getSiteAudit(id),
        enabled: enabled
    })
}

export function useGetPublishedSiteAudit(token: string) {
    return useQuery<SiteAudit>({
        queryKey: siteAuditKeys.publishedDetail(token),
        queryFn: () => auditApiClient.getPublishedSiteAudit(token)
    })
}

export function useGetWorstAssets(limit: number, offset: number, siteId: string, subCategories: string[], enabled?: boolean) {
    return useQuery<Pagination<Asset>, string>({
        queryKey: siteAuditKeys.worstAssets(limit, offset, siteId, subCategories),
        queryFn: () => auditApiClient.getWorstAsset(limit, offset, siteId, subCategories),
        enabled: enabled
    })
}

export function useCreateSiteAudit(onSuccess?: (data: SiteAudit) => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: SiteAuditCreation) => auditApiClient.createAudit(data),
        onSuccess: async (siteAudit: SiteAudit) => {
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.lists()})
            if (onSuccess) {
                await onSuccess(siteAudit)
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useReproduceSiteAudit(onSuccess?: (data: SiteAudit) => void, onError?: (error: string, variables: SiteAuditReproduction) => void) {
    const queryClient = useQueryClient()
    return useMutation<SiteAudit, string, SiteAuditReproduction>({
        mutationFn: (data: SiteAuditReproduction) => auditApiClient.reproduceAudit(data),
        onSuccess: async (siteAudit: SiteAudit) => {
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.lists()})
            if (onSuccess) {
                await onSuccess(siteAudit)
            }
        },
        onError: (error, variables, context) => {
            if (onError) {
                onError(error, variables)
            }
        }
    })
}

export function useCancelSiteAudit(onSuccess?: () => void, onError?: (error: string) => void) {
    const queryClient = useQueryClient()
    return useMutation<void, string, string>({
        mutationFn: (siteAuditId: string) => auditApiClient.cancelAudit(siteAuditId),
        onSuccess: async (data, siteAuditId, context) => {
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.lists()})
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.detail(siteAuditId)})
            if (onSuccess) {
                await onSuccess()
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function usePublishSiteAudit(onSuccess?: () => void, onError?: (error: string) => void) {
    const queryClient = useQueryClient()
    return useMutation<void, string, string>({
        mutationFn: (siteAuditId: string) => auditApiClient.publishSiteAudit(siteAuditId),
        onSuccess: async (data, siteAuditId, context) => {
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.lists()})
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.detail(siteAuditId)})
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.published()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useUnpublishSiteAudit(onSuccess?: () => void, onError?: (error: string) => void) {
    const queryClient = useQueryClient()
    return useMutation<void, string, string>({
        mutationFn: (siteAuditId: string) => auditApiClient.unpublishSiteAudit(siteAuditId),
        onSuccess: async (data, siteAuditId, context) => {
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.lists()})
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.detail(siteAuditId)})
            await queryClient.invalidateQueries({queryKey: siteAuditKeys.published()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

