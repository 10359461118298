import React, {FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import ClientModalHeader from "../../components/common/ClientModalHeader";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import {useCreateSupportMessage} from "../../api/supportHook";


export default function Support() {
    const {t} = useTranslation('support')

    const [message, setMessage] = useState('')
    const createSupportMessageMutation = useCreateSupportMessage(
        () => {
            NotificationManager.success(t('create.feedbacks.success.message'), t('create.feedbacks.success.title'), SUCCESS_TIMEOUT)
            setMessage('')
        },
        () => NotificationManager.error(t('create.feedbacks.error.message'), t('create.feedbacks.error.title'), ERROR_TIMEOUT)
    )
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (message.length > 0) {
            createSupportMessageMutation.mutate({message: message})
        }
    }

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-3xl">{t('create.title')}</h1>
                <p className="leading-relaxed mb-5">{t('create.subtitle')}</p>
                <div className="bg-base-100 mt-6 pt-4 pb-16 px-2 rounded rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <textarea
                            id="site_url"
                            name="url"
                            className="textarea textarea-bordered w-full max-w-x"
                            rows={5}
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            required
                        />
                        <input
                            id="submit"
                            type="submit"
                            className="btn btn-primary btn-wide w-full my-4"
                            value={t('create.submit')}
                            disabled={message.length === 0}
                        />
                    </form>
                </div>
            </div>
        </div>
    </div>
}
