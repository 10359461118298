const DEFAULT_MAX_URLS = 500
const CLIENT_CREDIT_URL_INC = 300
const CLIENT_CREDIT_FREE_URLS = 50
const CLIENT_CREDIT_FIXES = 1
const CREDIT_VALUE = 15
const NO_ENOUGH_CREDIT = 'no-enough-credit'

interface Country {
    name: string
    code: string
    vat: number
}

const COUNTRIES: Country[] = [
    {name: "France", code: "FR", vat: 0.20},
    {name: "France - DOM", code: "FR", vat: 0.085},
    {name: "France - TOM, Guyane & Mayotte", code: "FR", vat: 0},
    {name: "Andorre", code: "AD", vat: 0},
    {name: "Émirats arabes unis", code: "AE", vat: 0},
    {name: "Afghanistan", code: "AF", vat: 0},
    {name: "Antigua-et-Barbuda", code: "AG", vat: 0},
    {name: "Anguilla", code: "AI", vat: 0},
    {name: "Albanie", code: "AL", vat: 0},
    {name: "Arménie", code: "AM", vat: 0},
    {name: "Angola", code: "AO", vat: 0},
    {name: "Antarctique", code: "AQ", vat: 0},
    {name: "Argentine", code: "AR", vat: 0},
    {name: "Samoa américaines", code: "AS", vat: 0},
    {name: "Autriche", code: "AT", vat: 0},
    {name: "Australie", code: "AU", vat: 0},
    {name: "Aruba", code: "AW", vat: 0},
    {name: "Ahvenanmaa", code: "AX", vat: 0},
    {name: "Azerbaïdjan", code: "AZ", vat: 0},
    {name: "Bosnie-Herzégovine", code: "BA", vat: 0},
    {name: "Barbade", code: "BB", vat: 0},
    {name: "Bangladesh", code: "BD", vat: 0},
    {name: "Belgique", code: "BE", vat: 0},
    {name: "Burkina Faso", code: "BF", vat: 0},
    {name: "Bulgarie", code: "BG", vat: 0},
    {name: "Bahreïn", code: "BH", vat: 0},
    {name: "Burundi", code: "BI", vat: 0},
    {name: "Bénin", code: "BJ", vat: 0},
    {name: "Saint-Barthélemy", code: "BL", vat: 0},
    {name: "Bermudes", code: "BM", vat: 0},
    {name: "Brunei", code: "BN", vat: 0},
    {name: "Bolivie", code: "BO", vat: 0},
    {name: "Pays-Bas caribéens", code: "BQ", vat: 0},
    {name: "Brésil", code: "BR", vat: 0},
    {name: "Bahamas", code: "BS", vat: 0},
    {name: "Bhoutan", code: "BT", vat: 0},
    {name: "Île Bouvet", code: "BV", vat: 0},
    {name: "Botswana", code: "BW", vat: 0},
    {name: "Biélorussie", code: "BY", vat: 0},
    {name: "Belize", code: "BZ", vat: 0},
    {name: "Canada", code: "CA", vat: 0},
    {name: "Îles Cocos", code: "CC", vat: 0},
    {name: "Congo (Rép. dém.)", code: "CD", vat: 0},
    {name: "République centrafricaine", code: "CF", vat: 0},
    {name: "Congo", code: "CG", vat: 0},
    {name: "Suisse", code: "CH", vat: 0},
    {name: "Côte d'Ivoire", code: "CI", vat: 0},
    {name: "Îles Cook", code: "CK", vat: 0},
    {name: "Chili", code: "CL", vat: 0},
    {name: "Cameroun", code: "CM", vat: 0},
    {name: "Chine", code: "CN", vat: 0},
    {name: "Colombie", code: "CO", vat: 0},
    {name: "Costa Rica", code: "CR", vat: 0},
    {name: "Cuba", code: "CU", vat: 0},
    {name: "Îles du Cap-Vert", code: "CV", vat: 0},
    {name: "Curaçao", code: "CW", vat: 0},
    {name: "Île Christmas", code: "CX", vat: 0},
    {name: "Chypre", code: "CY", vat: 0},
    {name: "Tchéquie", code: "CZ", vat: 0},
    {name: "Allemagne", code: "DE", vat: 0},
    {name: "Djibouti", code: "DJ", vat: 0},
    {name: "Danemark", code: "DK", vat: 0},
    {name: "Dominique", code: "DM", vat: 0},
    {name: "République dominicaine", code: "DO", vat: 0},
    {name: "Algérie", code: "DZ", vat: 0},
    {name: "Équateur", code: "EC", vat: 0},
    {name: "Estonie", code: "EE", vat: 0},
    {name: "Égypte", code: "EG", vat: 0},
    {name: "Sahara Occidental", code: "EH", vat: 0},
    {name: "Érythrée", code: "ER", vat: 0},
    {name: "Espagne", code: "ES", vat: 0},
    {name: "Éthiopie", code: "ET", vat: 0},
    {name: "Finlande", code: "FI", vat: 0},
    {name: "Fidji", code: "FJ", vat: 0},
    {name: "Îles Malouines", code: "FK", vat: 0},
    {name: "Micronésie", code: "FM", vat: 0},
    {name: "Îles Féroé", code: "FO", vat: 0},
    {name: "Gabon", code: "GA", vat: 0},
    {name: "Royaume-Uni", code: "GB", vat: 0},
    {name: "Grenade", code: "GD", vat: 0},
    {name: "Géorgie", code: "GE", vat: 0},
    {name: "Guyane", code: "GF", vat: 0},
    {name: "Guernesey", code: "GG", vat: 0},
    {name: "Ghana", code: "GH", vat: 0},
    {name: "Gibraltar", code: "GI", vat: 0},
    {name: "Groenland", code: "GL", vat: 0},
    {name: "Gambie", code: "GM", vat: 0},
    {name: "Guinée", code: "GN", vat: 0},
    {name: "Guadeloupe", code: "GP", vat: 0},
    {name: "Guinée équatoriale", code: "GQ", vat: 0},
    {name: "Grèce", code: "GR", vat: 0},
    {name: "Géorgie du Sud-et-les Îles Sandwich du Sud", code: "GS", vat: 0},
    {name: "Guatemala", code: "GT", vat: 0},
    {name: "Guam", code: "GU", vat: 0},
    {name: "Guinée-Bissau", code: "GW", vat: 0},
    {name: "Guyana", code: "GY", vat: 0},
    {name: "Hong Kong", code: "HK", vat: 0},
    {name: "Îles Heard-et-MacDonald", code: "HM", vat: 0},
    {name: "Honduras", code: "HN", vat: 0},
    {name: "Croatie", code: "HR", vat: 0},
    {name: "Haïti", code: "HT", vat: 0},
    {name: "Hongrie", code: "HU", vat: 0},
    {name: "Indonésie", code: "ID", vat: 0},
    {name: "Irlande", code: "IE", vat: 0},
    {name: "Israël", code: "IL", vat: 0},
    {name: "Île de Man", code: "IM", vat: 0},
    {name: "Inde", code: "IN", vat: 0},
    {name: "Territoire britannique de l'océan Indien", code: "IO", vat: 0},
    {name: "Irak", code: "IQ", vat: 0},
    {name: "Iran", code: "IR", vat: 0},
    {name: "Islande", code: "IS", vat: 0},
    {name: "Italie", code: "IT", vat: 0},
    {name: "Jersey", code: "JE", vat: 0},
    {name: "Jamaïque", code: "JM", vat: 0},
    {name: "Jordanie", code: "JO", vat: 0},
    {name: "Japon", code: "JP", vat: 0},
    {name: "Kenya", code: "KE", vat: 0},
    {name: "Kirghizistan", code: "KG", vat: 0},
    {name: "Cambodge", code: "KH", vat: 0},
    {name: "Kiribati", code: "KI", vat: 0},
    {name: "Comores", code: "KM", vat: 0},
    {name: "Saint-Christophe-et-Niévès", code: "KN", vat: 0},
    {name: "Corée du Nord", code: "KP", vat: 0},
    {name: "Corée du Sud", code: "KR", vat: 0},
    {name: "Koweït", code: "KW", vat: 0},
    {name: "Îles Caïmans", code: "KY", vat: 0},
    {name: "Kazakhstan", code: "KZ", vat: 0},
    {name: "Laos", code: "LA", vat: 0},
    {name: "Liban", code: "LB", vat: 0},
    {name: "Sainte-Lucie", code: "LC", vat: 0},
    {name: "Liechtenstein", code: "LI", vat: 0},
    {name: "Sri Lanka", code: "LK", vat: 0},
    {name: "Liberia", code: "LR", vat: 0},
    {name: "Lesotho", code: "LS", vat: 0},
    {name: "Lituanie", code: "LT", vat: 0},
    {name: "Luxembourg", code: "LU", vat: 0},
    {name: "Lettonie", code: "LV", vat: 0},
    {name: "Libye", code: "LY", vat: 0},
    {name: "Maroc", code: "MA", vat: 0},
    {name: "Monaco", code: "MC", vat: 0},
    {name: "Moldavie", code: "MD", vat: 0},
    {name: "Monténégro", code: "ME", vat: 0},
    {name: "Saint-Martin", code: "MF", vat: 0},
    {name: "Madagascar", code: "MG", vat: 0},
    {name: "Îles Marshall", code: "MH", vat: 0},
    {name: "Macédoine du Nord", code: "MK", vat: 0},
    {name: "Mali", code: "ML", vat: 0},
    {name: "Birmanie", code: "MM", vat: 0},
    {name: "Mongolie", code: "MN", vat: 0},
    {name: "Macao", code: "MO", vat: 0},
    {name: "Îles Mariannes du Nord", code: "MP", vat: 0},
    {name: "Martinique", code: "MQ", vat: 0},
    {name: "Mauritanie", code: "MR", vat: 0},
    {name: "Montserrat", code: "MS", vat: 0},
    {name: "Malte", code: "MT", vat: 0},
    {name: "Île Maurice", code: "MU", vat: 0},
    {name: "Maldives", code: "MV", vat: 0},
    {name: "Malawi", code: "MW", vat: 0},
    {name: "Mexique", code: "MX", vat: 0},
    {name: "Malaisie", code: "MY", vat: 0},
    {name: "Mozambique", code: "MZ", vat: 0},
    {name: "Namibie", code: "NA", vat: 0},
    {name: "Nouvelle-Calédonie", code: "NC", vat: 0},
    {name: "Niger", code: "NE", vat: 0},
    {name: "Île Norfolk", code: "NF", vat: 0},
    {name: "Nigéria", code: "NG", vat: 0},
    {name: "Nicaragua", code: "NI", vat: 0},
    {name: "Pays-Bas", code: "NL", vat: 0},
    {name: "Norvège", code: "NO", vat: 0},
    {name: "Népal", code: "NP", vat: 0},
    {name: "Nauru", code: "NR", vat: 0},
    {name: "Niue", code: "NU", vat: 0},
    {name: "Nouvelle-Zélande", code: "NZ", vat: 0},
    {name: "Oman", code: "OM", vat: 0},
    {name: "Panama", code: "PA", vat: 0},
    {name: "Pérou", code: "PE", vat: 0},
    {name: "Polynésie française", code: "PF", vat: 0},
    {name: "Papouasie-Nouvelle-Guinée", code: "PG", vat: 0},
    {name: "Philippines", code: "PH", vat: 0},
    {name: "Pakistan", code: "PK", vat: 0},
    {name: "Pologne", code: "PL", vat: 0},
    {name: "Saint-Pierre-et-Miquelon", code: "PM", vat: 0},
    {name: "Îles Pitcairn", code: "PN", vat: 0},
    {name: "Porto Rico", code: "PR", vat: 0},
    {name: "Palestine", code: "PS", vat: 0},
    {name: "Portugal", code: "PT", vat: 0},
    {name: "Palaos (Palau)", code: "PW", vat: 0},
    {name: "Paraguay", code: "PY", vat: 0},
    {name: "Qatar", code: "QA", vat: 0},
    {name: "Réunion", code: "RE", vat: 0},
    {name: "Roumanie", code: "RO", vat: 0},
    {name: "Serbie", code: "RS", vat: 0},
    {name: "Russie", code: "RU", vat: 0},
    {name: "Rwanda", code: "RW", vat: 0},
    {name: "Arabie Saoudite", code: "SA", vat: 0},
    {name: "Îles Salomon", code: "SB", vat: 0},
    {name: "Seychelles", code: "SC", vat: 0},
    {name: "Soudan", code: "SD", vat: 0},
    {name: "Suède", code: "SE", vat: 0},
    {name: "Singapour", code: "SG", vat: 0},
    {name: "Sainte-Hélène, Ascension et Tristan da Cunha", code: "SH", vat: 0},
    {name: "Slovénie", code: "SI", vat: 0},
    {name: "Svalbard et Jan Mayen", code: "SJ", vat: 0},
    {name: "Slovaquie", code: "SK", vat: 0},
    {name: "Sierra Leone", code: "SL", vat: 0},
    {name: "Saint-Marin", code: "SM", vat: 0},
    {name: "Sénégal", code: "SN", vat: 0},
    {name: "Somalie", code: "SO", vat: 0},
    {name: "Surinam", code: "SR", vat: 0},
    {name: "Soudan du Sud", code: "SS", vat: 0},
    {name: "São Tomé et Príncipe", code: "ST", vat: 0},
    {name: "Salvador", code: "SV", vat: 0},
    {name: "Saint-Martin", code: "SX", vat: 0},
    {name: "Syrie", code: "SY", vat: 0},
    {name: "Swaziland", code: "SZ", vat: 0},
    {name: "Îles Turques-et-Caïques", code: "TC", vat: 0},
    {name: "Tchad", code: "TD", vat: 0},
    {name: "Terres australes et antarctiques françaises", code: "TF", vat: 0},
    {name: "Togo", code: "TG", vat: 0},
    {name: "Thaïlande", code: "TH", vat: 0},
    {name: "Tadjikistan", code: "TJ", vat: 0},
    {name: "Tokelau", code: "TK", vat: 0},
    {name: "Timor oriental", code: "TL", vat: 0},
    {name: "Turkménistan", code: "TM", vat: 0},
    {name: "Tunisie", code: "TN", vat: 0},
    {name: "Tonga", code: "TO", vat: 0},
    {name: "Turquie", code: "TR", vat: 0},
    {name: "Trinité-et-Tobago", code: "TT", vat: 0},
    {name: "Tuvalu", code: "TV", vat: 0},
    {name: "Taïwan", code: "TW", vat: 0},
    {name: "Tanzanie", code: "TZ", vat: 0},
    {name: "Ukraine", code: "UA", vat: 0},
    {name: "Ouganda", code: "UG", vat: 0},
    {name: "Îles mineures éloignées des États-Unis", code: "UM", vat: 0},
    {name: "États-Unis", code: "US", vat: 0},
    {name: "Uruguay", code: "UY", vat: 0},
    {name: "Ouzbékistan", code: "UZ", vat: 0},
    {name: "Cité du Vatican", code: "VA", vat: 0},
    {name: "Saint-Vincent-et-les-Grenadines", code: "VC", vat: 0},
    {name: "Venezuela", code: "VE", vat: 0},
    {name: "Îles Vierges britanniques", code: "VG", vat: 0},
    {name: "Îles Vierges des États-Unis", code: "VI", vat: 0},
    {name: "Viêt Nam", code: "VN", vat: 0},
    {name: "Vanuatu", code: "VU", vat: 0},
    {name: "Wallis-et-Futuna", code: "WF", vat: 0},
    {name: "Samoa", code: "WS", vat: 0},
    {name: "Kosovo", code: "XK", vat: 0},
    {name: "Yémen", code: "YE", vat: 0},
    {name: "Mayotte", code: "YT", vat: 0},
    {name: "Afrique du Sud", code: "ZA", vat: 0},
    {name: "Zambie", code: "ZM", vat: 0},
    {name: "Zimbabwe", code: "ZW", vat: 0},
];

const USE_USER_CREDITS = "user-credit"

export {DEFAULT_MAX_URLS, CLIENT_CREDIT_URL_INC, CLIENT_CREDIT_FREE_URLS, CLIENT_CREDIT_FIXES, NO_ENOUGH_CREDIT, CREDIT_VALUE, COUNTRIES, USE_USER_CREDITS}