import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGetPublishedSiteAudit} from "../../api/siteAuditHooks";
import React from "react";
import RequestDecarbonationButton from "../../components/RequestDecarbonationButton";
import AuditWorstAssets from "../../components/audit/AuditWorstAssets";

export default function ViewPublishedAuditFixes() {
    const {t} = useTranslation('assets')
    let params = useParams()
    const auditToken = params.token!
    const {data: siteAudit} = useGetPublishedSiteAudit(auditToken)

    if (!siteAudit) return <>An error has occurred.</>

    return <div>
        <div className="w-full min-h-screen bg-base-200 pt-6">
            <div className="max-w-6xl mx-auto mx-4">
                <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-center">{t('fixes.title')}</h2>
                    <p className="text-sm text-center">{t('service:cta_text_1')} </p>
                    <p className="text-sm text-center">{t('service:cta_text_2')} </p>
                    <RequestDecarbonationButton siteAudit={siteAudit} anonymous={true} className="px-4"/>
                </div>
            </div>
            <div>
                <AuditWorstAssets siteAudit={siteAudit} />
            </div>
        </div>
    </div>
}
