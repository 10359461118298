import {Trans, useTranslation} from "react-i18next"
import {SiteAudit} from "../api/dto/SiteAudit"
import React, {FormEvent, useState} from "react"
import {useGetMe} from "../api/userHook"
import {NotificationManager} from "react-notifications"
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../constants/notifications"
import marketingApiClient from "../api/marketingApiClient"
import btn from '../assets/images/optimize_btn_pic.svg'
import ModalV2 from "./common/ModalV2";
import QuoteDetails from "./common/QuoteDetails";

interface Props {
    siteAudit: SiteAudit,
    anonymous?: boolean,
    className?: string
}

const ONE_SHOT = "oneshot"
const SUBS = "subs"

export default function RequestDecarbonationButton(props: Props) {
    const {t} = useTranslation('service')

    const siteAudit=props.siteAudit

    const [choice, setChoice] = useState<string>(ONE_SHOT);
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [comment, setComment] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);
    const [isOpened, setIsOpened] = useState(false);
    const {data: user} = useGetMe(!props.anonymous)

    const handleRequest = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true)
        marketingApiClient.servicing(
            user ? user.login : email,
            props.siteAudit.site ? props.siteAudit.site.url : '',
            phone,
            comment,
            choice
        )
            .then(_ => {
                NotificationManager.success(t('success.message'), t('success.title'), SUCCESS_TIMEOUT)
                setIsOpened(false)
                setLoading(false)
            })
            .catch(_ => {
                NotificationManager.error(t('error.message'), t('error.title'), ERROR_TIMEOUT)
                setLoading(false)
            })
    }

    return <div className={props.className}>
        <div className="relative inline-block w-full">
            <button
                tabIndex={0}
                role="button"
                aria-label="open"
                className="btn btn-primary my-4 w-full"
                onClick={() => setIsOpened(true)}>
                <img src={btn} alt="" className="absolute -left-3 top-1/2 transform -translate-y-1/2" aria-hidden="true"/>
                {t('cta_btn')}
            </button>
        </div>
        <ModalV2
            id="request-optimize" visible={isOpened} onClose={() => setIsOpened(false)}
        >
            <div>
                <h2 className="pb-6">{t('title')}</h2>

                <img src={t('marketing.how_url')} className="w-full bg-accent rounded py-4"/>

                <div role="alert" className="alert alert-info mt-4 rounded">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="h-6 w-6 shrink-0 stroke-current">
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <span>{t('marketing.how_txt_1')}</span>
                </div>

                <p className="text-center italic mb-4 mt-2">{t('marketing.how_txt_2')}</p>

                <p className="text-sm text-neutral-focus"><Trans
                    i18nKey='service:message.1' /></p>

                <p className="pt-4 text-sm text-neutral-focus"><Trans
                    i18nKey='service:message.2' /></p>

                <form className="form-control" onSubmit={(e) => handleRequest(e)}>
                    <div className="my-4">
                        <label htmlFor="phone" className="mr-4">{t('form.phone')}</label>
                        <input type="tel" id="phone" name="phone" placeholder={t('form.phone_placeholder')}
                               className="input input-bordered w-full" value={phone}
                               onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    {
                        props.anonymous &&
                        <div className="mb-4">
                            <label htmlFor="email" className="mr-4">{t('form.email')}</label>
                            <input type="tel" id="email" name="email" placeholder={t('form.email_placeholder')}
                                   className="input input-bordered w-full" value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                    }
                    <label htmlFor="comment" className="mr-4">{t('form.comment')}</label>
                    <textarea id="comment" name="comment" className="textarea textarea-bordered" value={comment}
                              placeholder={t('form.comment_placeholder')}
                              onChange={(e) => setComment(e.target.value)}>
                    </textarea>
                    <h3 className="my-4">{t('title_choice')}</h3>
                    <label className="label justify-start gap-4 cursor-pointer">
                        <input type="radio" name="service" className="radio radio-primary" checked={choice === ONE_SHOT}
                               onChange={() => setChoice(ONE_SHOT)}/>
                        <span className="label-text text-lg">{t('oneshot')} <span
                            className="font-bold">{props.siteAudit.one_shot_price} €</span> {t('credits:before_tax')}</span>
                    </label>

                    {props.siteAudit.subscription_price > 0 &&
                        <>
                            <label className="label justify-start gap-4 cursor-pointer">
                                <input type="radio" name="service" className="radio radio-secondary"
                                       checked={choice === SUBS} onChange={() => setChoice(SUBS)}/>
                                <span className="label-text text-lg">{t('subscription')} <span
                                    className="font-bold">{props.siteAudit.subscription_price} €</span> {t('credits:before_tax')} {t('common:byMonth')}</span>
                            </label>
                            <p className="text-sm text-neutral-focus">{t('subscription_info')}</p>
                        </>
                    }
                    <input type="submit" value={t('request_btn')} className="mt-4 btn btn-primary btn-lg w-full"
                           disabled={loading}/>
                    <p className="text-sm text-neutral-focus text-center mt-1">{t('request_desc')}</p>

                    <div className="rounded-lg bg-primary-200 border-primary border p-2 mt-4 text-primary-800">
                        <p className="font-bold text-sm">"{t('testimony')}"</p>
                        <div className="flex flex-row justify-start items-center text-primary-800 mt-2">
                            <img src={t('testimony_icon_url')} alt={t('testimony_icon_url_alt')}
                                 className="rounded-full"/>
                            <div className="flex flex-col justify-start ml-4">
                                <p className="font-bold">{t('testimony_name')}</p>
                                <p className="text-sm font-light">{t('testimony_role')}</p>
                            </div>
                        </div>
                    </div>
                    { siteAudit.details_by_service &&
                    <div className="mt-6">
                        <h3>{t("pricing_detail_subtitle")}</h3>
                        <QuoteDetails siteAudit={siteAudit}/>
                    </div>
                    }
                </form>
            </div>
        </ModalV2>
    </div>
}
