import {useGetSite} from "../../../api/siteHook";
import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import ROUTES from "../../../constants/routes";
import {Trans, useTranslation} from "react-i18next";
import {useAddTeamSite, useGetTeams} from "../../../api/teamHook";
import ListTeams from "../../../components/team/ListTeams";

export default function SiteTeams() {
    let params = useParams()
    const siteId = params.id!
    const {t} = useTranslation('team')

    const siteQuery = useGetSite(siteId)
    const site = siteQuery.data

    const siteTeamsQuery = useGetTeams(siteId)
    const siteTeams = siteTeamsQuery.data

    const allTeamsQuery = useGetTeams('')
    const allTeams = allTeamsQuery.data

    const addTeamSiteMutation = useAddTeamSite()
    const [teamIdToAdd, setTeamIdToAdd] = useState<string>('')
    const handleAddTeam = () => {
        if (teamIdToAdd) {
            addTeamSiteMutation.mutate(
                {
                    teamId: teamIdToAdd,
                    siteId: siteId
                }
            )
        }
    }

    if (siteQuery.error || siteTeamsQuery.error || allTeamsQuery.error) {
        return <>An error has occurred</>
    }
    if (siteQuery.isLoading || !site
        || siteTeamsQuery.isLoading || !siteTeams
        || allTeamsQuery.isLoading || !allTeams
    ) {
        return <div>Loading...</div>
    }
    return <div className="py-4 px-8">
        <h2>
            <Trans
                t={t} i18nKey='siteView.title'
                values={{url: site.url}}
                components={{l: <Link to={ROUTES.SITE.withParams(site.id)} className="link"/>}}
            />
        </h2>

        <ListTeams siteId={siteId}/>

        <div className="flex flex-row gap-4 items-center mt-4">
            <label>{t('siteView.addTeam.label')}</label>
            <select
                onChange={(event) => setTeamIdToAdd(event.target.value)}
                className="select select-bordered select-primary w-full max-w-xs"
            >
                <option value=''>-</option>
                {
                    allTeams
                        .filter(team => !siteTeams.map(t => t.id).includes(team.id))
                        .sort((a, b) => a.name.toLowerCase().normalize('NFD') > b.name.toLowerCase().normalize('NFD') ? 1 : -1)
                        .map(team =>
                            <option key={team.id} value={team.id}>
                                {team.name}
                            </option>
                        )
                }
            </select>
            <button className="btn btn-primary" onClick={() => handleAddTeam()} disabled={!teamIdToAdd}>
                {t('siteView.addTeam.submit')}
            </button>
        </div>
    </div>
}
