import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE_KEY, DEFAULT_NAMESPACE,} from './constants';
import {isBrowser} from '../ssr';
import locales from '../../locales';
import customNavigator from './custom_detector';
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(customNavigator);

i18n.use(initReactI18next)
    .use(languageDetector)
    .init({
        defaultNS: DEFAULT_NAMESPACE,
        ns: Object.keys(locales[DEFAULT_LANGUAGE_KEY]),
        resources: locales,
        fallbackLng: DEFAULT_LANGUAGE_KEY,
        detection:{
            order: ['localStorage', 'customNavigator']
        },

        // Fix issue with i18next types
        // https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
        returnNull: false,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

i18n.on('languageChanged', (langKey) => {
    const language = AVAILABLE_LANGUAGES.find(({key}) => key === langKey);
    if (isBrowser) {
        document.documentElement.lang = langKey;
        document.documentElement.dir = language?.dir ?? 'ltr';
        document.documentElement.style.fontSize = `${
            (language?.fontScale ?? 1) * 100
        }%`;
    }
});

export default i18n;
