import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {Decarbonation, DecarbonationUpdate} from "./dto/Decarbonation";

export const decarbonationKeys = {
    all: ['decarbonations'] as const,
    lists: () => [...decarbonationKeys.all, 'list'] as const,
    list: (keyword: string) => [...decarbonationKeys.lists(), {keyword: keyword}] as const,
    details: () => [...decarbonationKeys.all, 'detail'] as const,
    detail: (id: string) => [...decarbonationKeys.details(), id] as const,
    dashboards: () => [...decarbonationKeys.all, 'dashboard'] as const,
    dashboard: (id: string) => [...decarbonationKeys.dashboards(), id] as const,
    published: () => [...decarbonationKeys.all, 'published'] as const,
    publishedDetail: (token: string) => [...decarbonationKeys.published(), token] as const,
}

export function useListDecarbonations(search: string) {
    return useQuery({
        queryKey: decarbonationKeys.list(search),
        queryFn: () => auditApiClient.getDecarbonations(search)
    })
}

export function useGetDecarbonation(id: string, onSuccess?: (data: Decarbonation) => void) {
    return useQuery({
        queryKey: decarbonationKeys.detail(id),
        queryFn: () => auditApiClient.getDecarbonation(id),
        onSuccess: async (decarbonation: Decarbonation) => {
            if (onSuccess) {
                await onSuccess(decarbonation)
            }
        }
    })
}

export function useGetPublishedDecarbonation(token: string) {
    return useQuery({
        queryKey: decarbonationKeys.publishedDetail(token),
        queryFn: () => auditApiClient.getPublishedDecarbonation(token),
    })
}

export function useCreateDecarbonation(onSuccess?: (data: Decarbonation) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (siteId: string) => auditApiClient.createDecarbonation({site_id: siteId}),
        onSuccess: async (decarbonation: Decarbonation) => {
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.lists()})
            if (onSuccess) {
                await onSuccess(decarbonation)
            }
        }
    })
}

export function useStartDecarbonation(onSuccess?: (data: Decarbonation) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (decarbonationId: string) => auditApiClient.startDecarbonation(decarbonationId),
        onSuccess: async (decarbonation) => {
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.lists()})
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.detail(decarbonation.id)})
            if (onSuccess) {
                await onSuccess(decarbonation)
            }
        }
    })
}

export function useUpdateDecarbonation(onSuccess?: (data: Decarbonation, vars: { decarbonationId: string, decarbonationUpdate: DecarbonationUpdate }) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (vars: { decarbonationId: string, decarbonationUpdate: DecarbonationUpdate }) =>
            auditApiClient.updateDecarbonation(vars.decarbonationId, vars.decarbonationUpdate),
        onSuccess: async (decarbonation: Decarbonation, vars: { decarbonationId: string, decarbonationUpdate: DecarbonationUpdate }) => {
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.lists()})
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.detail(decarbonation.id)})
            if (onSuccess) {
                await onSuccess(decarbonation, vars)
            }
        }
    })
}

export function usePublishDecarbonation(onSuccess?: () => void, onError?: (error: string) => void) {
    const queryClient = useQueryClient()
    return useMutation<void, string, string>({
        mutationFn: (id: string) => auditApiClient.publishDecarbonation(id),
        onSuccess: async (data, id) => {
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.lists()})
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.detail(id)})
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.published()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useUnpublishDecarbonation(onSuccess?: () => void, onError?: (error: string) => void) {
    const queryClient = useQueryClient()
    return useMutation<void, string, string>({
        mutationFn: (id: string) => auditApiClient.unpublishDecarbonation(id),
        onSuccess: async (data, id) => {
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.lists()})
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.detail(id)})
            await queryClient.invalidateQueries({queryKey: decarbonationKeys.published()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useClientDashboard(id: string, enabled?: boolean) {
    return useQuery({
        queryKey: decarbonationKeys.dashboard(id),
        queryFn: () => auditApiClient.getClientDashboard(id),
        enabled: enabled
    })
}

