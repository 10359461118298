import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    currentPage: number
    pageSize: number
    totalCount: number
    onClick: (i: number) => void
}

export default function Pagination(props: Props) {
    const {t} = useTranslation('layout')

    let pageCount = Math.floor(props.totalCount / props.pageSize)
    if (props.totalCount % props.pageSize > 0) {
        pageCount++
    }

    const pageArray = []
    for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i)
    }

    if (pageCount <= 1) {
        return <></>
    }

    return <div className="join">
        {
            props.currentPage > 1 &&
            <>
                <button className={"join-item btn"} onClick={() => props.onClick(1)}>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAo0lEQVR4nO2VQQqDMBREH7hXexa9ioLF3tCi3kGwi16otNDyIYuPpMFEAi4y2wkz8PPnD5wQJZD94TLDB6MDXsDdwuXAYvg6VPwDfIHRIr4aTt5UvuIt8DYCT+DiEO+TuCCNhSjbUqs9fwDFhp+V+NVXfI/BdNRA0HgE6pZMmpj3J+RP+mTSqZxIJnCMK6jRUJU5WDhdmd6NpiEJd5X+9gLExQ9LV3lEZvGxKQAAAABJRU5ErkJggg=="
                         alt={t('pagination.firstPage')}
                    />
                </button>
                <button className={"join-item btn"} onClick={() => props.onClick(props.currentPage-1)}>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAbUlEQVR4nO2VSQrAIBAEa/KF/DPBfCXBQ3ysYUDBYw62IFjgtVp6XGACdpXYgAfIwKmQxyL3FVQ7z8ALbEtuo2uxJXeuppbYs5ZKUAe48FadnBXSZSZpxEzS1CGxCfFL2R1rHsFDEVCRffq/+AD1ZzzR2xVN3QAAAABJRU5ErkJggg=="
                         alt={t('pagination.prevPage')}
                    />
                </button>
            </>
        }
        <button className={"join-item btn btn-active"}>{props.currentPage}</button>
        {
            props.currentPage < pageCount &&
            <>
                <button className={"join-item btn"}
                        onClick={() => props.onClick(props.currentPage+1)}
                        disabled={props.currentPage >= pageCount}
                >
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaElEQVR4nO2VwQmAMBAE52zBPpXYiiEPLTYSOMFPfllUuIV8d46BsPByZmX5ClRgB0wBSA5orygg5tffkBOYAtKLha5P6ioPyKYGJKWiY6Qii/Kelqx0nn9Zjn+eqhycRT2ZLdLRH5ILkEo80v91Ya4AAAAASUVORK5CYII="
                         alt={t('pagination.nextPage')}
                    />
                </button>
                <button className={"join-item btn"}
                        onClick={() => props.onClick(pageCount)}
                        disabled={props.currentPage >= pageCount}
                >
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnUlEQVR4nO2VXQqCUBCFP/D9mmvRrSgkbTF/9hDUQxuKBGNggvugXmpSJO55PTAfzMzhwMY6AMmMl6j/tQrgAVwAN+E36h8tgAEYgesEpFNvsEBK4KmD7kDmeU7Bb8gpQsot1lVFyCeqvZxIJnylwM27iWTKBGgDgPyXx06tH1X95XAXh49rrKUIFE5vLZxQZZ6tlSmS11sqffH3pRd1IXlKshOj5gAAAABJRU5ErkJggg=="
                         alt={t('pagination.lastPage')}
                    />
                </button>
            </>
        }
    </div>
}
