import {useMutation} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {SupportMessageCreation} from "./dto/Support";


export function useCreateSupportMessage(onSuccess?: () => void, onError?: () => void) {
    return useMutation({
        mutationFn: (supportMessageCreation: SupportMessageCreation) => auditApiClient.createSupportMessage(supportMessageCreation),
        onSuccess: async () => {
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}