import React, {ChangeEvent, FormEvent, useReducer} from "react";
import {useNavigate} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import ROUTES from "../../constants/routes";
import {SiteUpdate} from "../../api/dto/Site";
import {useGetSite, useUpdateSite} from "../../api/siteHook";
import {useTranslation} from "react-i18next";

interface Props {
    siteId: string
}

export default function UpdateSiteForm(props: Props) {
    const {t} = useTranslation('site')
    const [form, setForm] = useReducer(
        (prevState: SiteUpdate, updatedValues: Partial<SiteUpdate>) => {
            return {...prevState, ...updatedValues}
        },
        {
            name: '',
            url: '',
            url_filter: '',
            update_credentials: false,
            user: '',
            password: ''
        }
    )
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }
    const getSiteQuery = useGetSite(
        props.siteId,
        form.name.length === 0, // Load data only once
        site => setForm(
            {
                name: site.name,
                url: site.url,
                url_filter: site.url_filter
            }
        )
    )
    const navigate = useNavigate()
    const updateSiteMutation = useUpdateSite(
        () => {
            NotificationManager.success(`The site "${form.name}" is updated.`, 'Success !', SUCCESS_TIMEOUT)
            navigate(ROUTES.SITE.withParams(props.siteId))
        },
        () => NotificationManager.error(`Failed to update the site "${form.name}".`, 'Error !', ERROR_TIMEOUT)
    )


    const handleUpdateSite = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        let updateSiteRequest = {
            name: form.name,
            url: form.url,
            url_filter: form.url_filter,
            update_credentials: form.update_credentials,
            user: form.user,
            password: form.password
        }
        updateSiteMutation.mutate({siteId: props.siteId, data: updateSiteRequest})
    }
    if (getSiteQuery.isLoading || !getSiteQuery.data) {
        return (
            <div>Loading...</div>
        )
    }
    return (
        <div>
            <h2>{t('update.title')}</h2>
            <form onSubmit={handleUpdateSite}>
                <div className="form-control">
                    <label htmlFor="name" className="label">{t('data.name.label')}</label>
                    <input
                        id="name"
                        name="name"
                        className="input input-bordered w-full max-w-x"
                        type="text"
                        placeholder={t('data.name.placeHolder')}
                        value={form.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="_url" className="label">{t('data.url.label')}</label>
                    <input
                        id="url"
                        name="url"
                        className="input input-bordered w-full max-w-x"
                        type="url"
                        placeholder={t('data.url.placeHolder')}
                        value={form.url}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="url_filter" className="label">{t('data.urlFilter.label')}</label>
                    <input
                        id="url_filter"
                        name="url_filter"
                        className="input input-bordered w-full max-w-x"
                        type="url"
                        placeholder={t('data.urlFilter.placeHolder')}
                        value={form.url_filter}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="update_credentials" className="label cursor-pointer justify-start">
                        <span className="label-text mr-2">{t('update.updateAuth')}</span>
                        <input
                            id="update_credentials"
                            name="update_credentials"
                            className="checkbox"
                            type="checkbox"
                            onChange={handleChange}
                        />
                    </label>

                    <fieldset className={`border p-3 ml-8 ${form.update_credentials ? "block" : "hidden"}`}>
                        <legend>{t('data.auth.title')}</legend>
                        <div className="form-control">
                            <label htmlFor="user" className="label">{t('data.username.label')}</label>
                            <input
                                id="user"
                                name="user"
                                className="input input-bordered w-full max-w-x"
                                type="text"
                                placeholder={t('data.username.placeHolder')}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-control">
                            <label htmlFor="password" className="label">{t('data.password.label')}</label>
                            <input
                                id="password"
                                name="password"
                                className="input input-bordered w-full max-w-x"
                                type="password"
                                placeholder={t('data.password.placeHolder')}
                                onChange={handleChange}
                            />
                        </div>
                    </fieldset>
                </div>

                <input
                    id="submit"
                    type="submit"
                    className="btn btn-primary btn-wide mt-6"
                    value={t('update.submit')}
                    disabled={updateSiteMutation.isLoading}
                />
            </form>
        </div>
    )
}
