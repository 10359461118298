
import {Link, useNavigate} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {useTranslation} from "react-i18next";

import {useAuth0} from "@auth0/auth0-react";
import { useGetSite } from '../../api/siteHook';
import {Site} from "../../api/dto/Site";
import React from "react";

interface Props {
    score: number
}


export default function LightHouseRating(props: Props) {


    const lhBorder = (score: number) => {
        var color = "#E11D48"
        if (score>=90) {
            color= "#16A34A"
        } else if (score>=50) {
            color= "#D97706"
        }
        var degree = 360 * score / 100
        return {
            background: `conic-gradient(${color} ${degree}deg, transparent calc(${degree}deg + 0.5deg) 100%)`
        }
    }

    const lhText = (score: number) => {
        if (score>=90) {
            return "text-success"
        } else if (score>=50) {
            return "text-warning"
        } else {
            return "text-error"
        }
    }

    const lhBg = (score: number) => {
        if (score>=90) {
            return "bg-success-200"
        } else if (score>=50) {
            return "bg-warning-content"
        } else {
            return "bg-error-200"
        }
    }


    return <div className="mx-auto h-20 w-20 rounded-full" style={lhBorder(props.score)}>
        <div
            className={"text-3xl text-error relative top-[5%] left-[5%] w-[90%] h-[90%] rounded-full " + lhBg(props.score) + " flex items-center justify-center"}>
            <span className={lhText(props.score)}>{Math.round(props.score)}</span>
        </div>
    </div>
}