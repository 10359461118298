import React, {useState} from "react";
import {useTranslation} from "react-i18next";


interface Props {
    handleChange: React.ChangeEventHandler<HTMLInputElement>
    name: string
    urlFilter: string
    basicAuthLogin: string
    basicAuthPassword: string
}

export default function SiteNameAndOptions(props: Props) {
    const {t} = useTranslation('site')
    const [advanced, setAdvanced] = useState(false)
    const [useWebvertEnableAuth, setUseWebvertEnableAuth] = useState(false)

    return <>
        <label htmlFor="name" className=" label justify-start">{t('data.name.label')} <span
            className="text-error">&nbsp;*</span></label>
        <input
            id="name"
            name="name"
            className="input input-bordered w-full max-w-x"
            type="text"
            placeholder={t('data.name.placeHolder')}
            onChange={props.handleChange}
            value={props.name}
            required
        />
        <div className="bg-white rounded rounded-lg  w-full mt-4 p-4">
            <button id="toggleBtn" type="button" aria-expanded={advanced} onClick={(e) => setAdvanced(!advanced)}
                className="gap-2 flex justify-center items-center w-full ">
                <span className="rotate-180"><svg width="9" height="6" fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"><path
                    fill-rule="evenodd" clip-rule="evenodd"
                    d="M4 .8c.3-.2.7-.2 1 0l3.4 3.5a.6.6 0 1 1-.8.9L4.5 2l-3 3a.6.6 0 1 1-1-.8L4.2.8Z"
                    fill="#475569"/></svg></span>
                {t('create.advanced')}
            </button>
            <div className={!advanced ? "hidden" : ""}>
                <label htmlFor="name"
                       className="label justify-start">{t('data.urlFilter.label')}</label>
                <input
                    id="url_filter"
                    name="url_filter"
                    className="input input-bordered w-full max-w-x"
                    type="text"
                    placeholder={t('data.urlFilter.placeHolder')}
                    onChange={props.handleChange}
                    value={props.urlFilter}
                />
                <div className="form-control">
                    <label htmlFor="use_webvert_enable_auth"
                           className="label cursor-pointer justify-start">
                        <input
                            id="use_webvert_enable_auth"
                            name="use_webvert_enable_auth"
                            className="checkbox"
                            type="checkbox"
                            onChange={(e) => setUseWebvertEnableAuth(e.target.checked)}
                        />
                        <span className="label-text ml-2">{t('data.auth.title')}</span>
                    </label>
                </div>
                <div
                    className={`${useWebvertEnableAuth ? "block" : "hidden"}`}>
                    <div className="form-control">
                        <label htmlFor="site_user"
                               className="label font-bold">{t('data.username.label')}</label>
                        <input
                            id="site_user"
                            name="user"
                            className="input input-bordered w-full max-w-x"
                            type="text"
                            placeholder={t('data.username.placeHolder')}
                            onChange={props.handleChange}
                            value={props.basicAuthLogin}
                        />
                    </div>
                    <div className="form-control">
                        <label htmlFor="site_password"
                               className="label font-bold">{t('data.password.label')}</label>
                        <input
                            id="site_password"
                            name="password"
                            className="input input-bordered w-full max-w-x"
                            type="password"
                            placeholder={t('data.password.placeHolder')}
                            onChange={props.handleChange}
                            value={props.basicAuthPassword}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}
