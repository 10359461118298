import {User} from "../api/dto/User";
import {Team} from "../api/dto/Team";

export function contains(value: string, keyword: string) {
    if (value) {
        return value.toLowerCase().includes(keyword.toLowerCase())
    } else {
        return false
    }
}

export function toCamelCase(str: string) {
    return str.split(' ').map(function (word, index) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
}


export function toInitial(str: string) {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...str.split("@")[0].matchAll(rgx)] || [];
    return (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
}

export function isValidUrl(url: string) {
    try {
        new URL(url);
        return true;
    } catch (_) {
        return false;
    }
}


export function getCategoryFromLightHouse(category: string): string {
    switch (category) {
        case "uses-responsive-images":
            return "imgResize"
        case "bad-image-format":
            return "imgFormat"
        case "efficient-animated-content":
            return "vidFormat"
        case "uses-optimized-images":
            return "imgOptimize"
        case "uses-long-cache-ttl":
            return "serverCache"
        case "uses-text-compression":
            return "serverCompression"
        case "total-byte-weight":
            return "extraFiles"
        case "unminified-javascript":
            return "unminifiedJavascript"
        case "unminified-css":
            return "unminifiedCSS"
        default:
            return "none"
    }
}

export function getLightHouseCategory(category: string): string[] {
    switch (category) {
        case "imgResize":
            return ["uses-responsive-images"]
        case "imgFormat":
            return ["bad-image-format"]
        case "vidFormat":
            return ["efficient-animated-content"]
        case "imgOptimize":
            return ["uses-optimized-images"]
        case "serverCache":
            return ["uses-long-cache-ttl"]
        case "serverCompression":
            return ["uses-text-compression"]
        case "extraFiles":
            return ["total-byte-weight"]
        case "unminifiedJavascript":
            return ["unminified-javascript"]
        case "unminifiedCSS":
            return ["unminified-css"]
        default:
            return ["none"]
    }
}

export function getServiceCodeFromCategory(category: string): string[] {
    switch (category) {
        case "imgResize":
            return ["OPTIMIZE_IMAGES"]
        case "imgFormat":
            return ["OPTIMIZE_IMAGES"]
        case "imgOptimize":
            return ["OPTIMIZE_IMAGES"]
        case "serverCache":
            return ["OPTIMIZE_CACHE"]
        case "serverCompression":
            return ["OPTIMIZE_GZIP"]
        case "extraFiles":
            return ["OPTIMIZE_EXTRA"]
        case "unminifiedJavascript":
            return ["OPTIMIZE_JS"]
        case "unminifiedCSS":
            return ["OPTIMIZE_CSS"]
        default:
            return [category]
    }
}

export function getCategoryFromServiceCode(service: string): string {
    switch (service) {
        case "OPTIMIZE_IMAGES":
            return "imgResize"
        case "OPTIMIZE_IMAGES":
            return "imgFormat"
        case "OPTIMIZE_IMAGES":
            return "imgOptimize"
        case "OPTIMIZE_CACHE":
            return "serverCache"
        case "OPTIMIZE_GZIP":
            return "serverCompression"
        case "OPTIMIZE_EXTRA":
            return "extraFiles"
        case "OPTIMIZE_JS":
            return "unminifiedJavascript"
        case "OPTIMIZE_CSS":
            return "unminifiedCSS"
        default:
            return service
    }
}

export function canRunDiagnostic(site_id: string, user: User, teams: Team[]): boolean {
    // 1. check if site in direct sites then return true
    let out = directOwnerOfSite(site_id, user)
    if (!out) {
        // 2. else check if site in teams and check permission
        // team_as_admin level
        out = adminOfSite(site_id, user, teams)

        // member level
        if (!out) {
            teams.forEach(t => {
                t.members
                    .filter(m => m.login === user.login && m.audit_permission === true)
                    .forEach(m => {
                        // double check to manager not filtered teams by sites
                        const member_site = t.sites.filter(e => e.id === site_id)
                        if (member_site.length > 0) {
                            out = true
                        }
                    })
            })
        }
    }
    return out
}

export function canBuyAccessToAssets(site_id: string, user: User, teams: Team[]): boolean {
    // 1. check if site in direct sites then return true
    let out = directOwnerOfSite(site_id, user)
    if (!out) {
        // 2. else check if site in teams and check permission
        // team_as_admin level
        out = adminOfSite(site_id, user, teams)
    }
    if (!out) {
        teams.forEach(t => {
            t.members
                .filter(m => m.login === user.login && m.audit_permission === true)
                .forEach(m => {
                    // double check to manager not filtered teams by sites
                    const member_site = t.sites.filter(e => e.id === site_id)
                    if (member_site.length > 0) {
                        out = true
                    }
                })
        })
    }
    return out;
}

export function usableTeamCredits(site_id: string, user: User, teams: Team[]): Team[] {
    let out: Team[] = []
    teams.forEach(t => {
        t.members
            .filter(m => m.login === user.login && m.use_credits_permission === true)
            .forEach(m => {
                // double check to manager not filtered teams by sites
                const member_site = t.sites.filter(e => e.id === site_id)
                if (member_site.length > 0) {
                    out.push(t)
                }
            })
    })
    return out;
}

function directOwnerOfSite(site_id: string, user: User): boolean {
    return user.sites.filter(e => e.id === site_id).length > 0
}

function adminOfSite(site_id: string, user: User, teams: Team[]): boolean {
    let out = false
    const team_as_admin = teams.filter(e => e.admin_login === user.login)
    // double check to manager not filtered teams by sites
    team_as_admin.forEach(a => {
        const admin_site = a.sites.filter(e => e.id === site_id)
        if (admin_site.length > 0) {
            out = true
        }
    })
    return out
}