import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAddCredits} from "../../api/creditsHook";

interface Props {
    userId: string
}

export default function AddCreditsForm(props: Props) {
    const userId = props.userId
    const {t} = useTranslation('credits')

    const [amount, setAmount] = useState(0)

    const addCreditsMutation = useAddCredits()
    const addCredits = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        addCreditsMutation.mutate({userId: userId, amount: amount})
    }

    return <form className="flex flex-row gap-4 items-center mt-4" onSubmit={addCredits}>
        <div className="form-control">
            <label className="input input-xs input-bordered flex items-center gap-2">
                {t('amount')}
                <input
                    name="amount"
                    type="number"
                    value={amount}
                    onChange={e => setAmount(Number(e.target.value))}
                />
            </label>
        </div>
        <input
            id="submit"
            type="submit"
            className="btn btn-primary btn-xs"
            value={addCreditsMutation.isLoading ? "..." : t('actions.add.submit')}
            disabled={addCreditsMutation.isLoading}
        />
    </form>
}