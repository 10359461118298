import {useGetDecarbonation, useUpdateDecarbonation} from "../../api/decarbonationHooks";
import React, {useState} from "react";
import DateFormat, {Format} from "../common/DateFormat";
import {useListSiteAudits} from "../../api/siteAuditHooks";
import {DecarbonationSchedule} from "../../api/dto/Decarbonation";
import {useTranslation} from "react-i18next";
import {SiteAuditStatus} from "../../api/dto/SiteAudit";

interface Props {
    decarbonationId: string
    buttons: {
        text: string
        className: string
        afterUpdate?: () => void
    }[]
}

interface ScheduleForm {
    date: string,
    all: boolean,
    images: boolean,
    cache: boolean,
    compression: boolean,
    css: boolean,
    js: boolean
}

export default function Schedule(props: Props) {
    const {t} = useTranslation('decarbonation')
    const [quoteAuditId, setQuoteAuditId] = useState('')
    const [ignoreQuoteAudit, setIgnoreQuoteAudit] = useState(false)
    const [scheduleForm, setScheduleForm] = useState<ScheduleForm>({
        date: '',
        all: false,
        images: false,
        cache: false,
        compression: false,
        css: false,
        js: false
    })
    const [formIsFilled, setFormIsFilled] = useState(false)

    const decarbonationRequest = useGetDecarbonation(
        props.decarbonationId,
        (data) => {
            if (formIsFilled) {
                return
            }
            if (data.quote_site_audit) {
                setQuoteAuditId(data.quote_site_audit?.id)
            }
            setIgnoreQuoteAudit(data.ignore_quote_site_audit)
            setScheduleForm({
                date: data.schedule.date? data.schedule.date.toString(): '',
                all: data.schedule.all,
                images: data.schedule.images,
                cache: data.schedule.cache,
                compression: data.schedule.compression,
                css: data.schedule.css,
                js: data.schedule.js
            })
            setFormIsFilled(true)
        }
    )
    const decarbonation = decarbonationRequest.data
    const auditsRequest = useListSiteAudits(
        10,
        0,
        {
            siteId: decarbonation?.site.id,
            status: SiteAuditStatus.Done,
            enabled: !!decarbonation?.site.id
        }
    )
    const audits = auditsRequest.data
    const ignoreQuoteAuditHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setQuoteAuditId('')
        }
        setIgnoreQuoteAudit(event.target.checked)
    }
    const onChangeSchedule = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let value
        switch (event.target.type) {
            case "checkbox":
                value = event.target.checked
                break;
            default:
                value = event.target.value
        }
        setScheduleForm({...scheduleForm, [event.target.name]: value});
    }
    const updateDecarbonation = useUpdateDecarbonation()
    const updateHandle = async (afterUpdate?: () => void) => {
        updateDecarbonation.mutate(
            {
                decarbonationId: decarbonation!.id,
                decarbonationUpdate: {
                    quote_site_audit_id: quoteAuditId,
                    ignore_quote_site_audit: ignoreQuoteAudit,
                    schedule: {
                        date: scheduleForm.date? new Date(scheduleForm.date): undefined,
                        all: scheduleForm.all,
                        images: scheduleForm.images,
                        cache: scheduleForm.cache,
                        compression: scheduleForm.compression,
                        css: scheduleForm.css,
                        js: scheduleForm.js
                    },
                }
            },
            {onSuccess: afterUpdate}
        )
    }

    if (decarbonationRequest.isLoading || auditsRequest.isLoading || !formIsFilled) return <>Loading...</>
    if (decarbonationRequest.isError || !decarbonation || auditsRequest.isError || !audits) return <>An error has occurred.</>
    return <div className="py-4">
        <h2 className="mb-4">{t('schedule.title')}</h2>

        <div className="flex flex-row gap-4 justify-start items-center">
            <label>{t('schedule.selectQuoteAudit')}</label>
            <select
                onChange={(event) => setQuoteAuditId(event.target.value)}
                className="select select-bordered select-primary w-full max-w-xs"
                disabled={ignoreQuoteAudit}
                value={quoteAuditId}
            >
                <option value=''>-</option>
                {
                    audits.results.map(audit =>
                        <option key={audit.id} value={audit.id}>
                            <DateFormat date={audit.creation_date} format={Format.NumericDateTime}/>
                            {" - " + audit.audited_pages_count + " pages - " + audit.webvert_score.toFixed(2) + " (" + audit.webvert_rating + ")"}
                        </option>
                    )
                }
            </select>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={ignoreQuoteAudit}
                    onChange={ignoreQuoteAuditHandle}
                />
                &nbsp;{t('schedule.ignore')}
            </label>
        </div>

        <div>
            <label>{t('schedule.selectServices')}</label>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={scheduleForm.all}
                    name="all"
                    onChange={onChangeSchedule}
                />
                &nbsp;{t('schedule.services.all')}
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={scheduleForm.images}
                    name="images"
                    onChange={onChangeSchedule}
                />
                &nbsp;{t('schedule.services.images')}
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={scheduleForm.cache}
                    name="cache"
                    onChange={onChangeSchedule}
                />
                &nbsp;{t('schedule.services.cache')}
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={scheduleForm.compression}
                    name="compression"
                    onChange={onChangeSchedule}
                />
                &nbsp;{t('schedule.services.compression')}
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={scheduleForm.css}
                    name="css"
                    onChange={onChangeSchedule}
                />
                &nbsp;{t('schedule.services.css')}
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    type="checkbox"
                    className="checkbox"
                    checked={scheduleForm.js}
                    name="js"
                    onChange={onChangeSchedule}
                />
                &nbsp;{t('schedule.services.js')}
            </label>
        </div>

        <div>
            <label>{t('schedule.selectDate')}</label>
            <input type="date" value={scheduleForm.date} name="date" className="input input-bordered input-sm ml-2" onChange={onChangeSchedule}/>
        </div>

        <div className="mt-5">
            {
                props.buttons?.map((b) =>
                    <button className={b.className} onClick={() => updateHandle(b.afterUpdate)}>{b.text}</button>)
            }
        </div>
    </div>
}
