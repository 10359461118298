
import {useTranslation} from "react-i18next";
import {useGetWorstAssets} from "../../api/siteAuditHooks";
import React, {useEffect, useState} from "react";
import fileSize from "filesize";
import WorstAssetsCategory from "../../components/audit/WorstAssetsCategory";
import {getCategoryFromLightHouse, getCategoryFromServiceCode} from "../../utils/utils";
import {SiteAudit} from "../../api/dto/SiteAudit";

interface Props {
    siteAudit: SiteAudit
}

export default function AuditWorstAssets(props: Props) {

    const {t} = useTranslation('assets')

    const siteAudit=props.siteAudit

    const {isLoading, error, data: assets} = useGetWorstAssets(
        1,
        0,
        siteAudit.id,
        []
    )
    const [nbImprovement, setNbImprovement] = useState<number>(0)

    useEffect(() => {
        if (assets) {
            setNbImprovement(assets.total_count)
        } else {
            setNbImprovement(0)
        }

    }, [assets]);

    const [improvements, setImprovements] = useState<[string, number][]>()

    useEffect(() => {

        let sources = new Map<string, number>()

        if (siteAudit) {
            siteAudit.details_by_service.forEach((service) => {
                if (service.service_code === "OPTIMIZE_EXTRA") {
                    sources.set(getCategoryFromServiceCode(service.service_code), 1) // To put it in front of 0 line in the sorting.
                } else if (service.service_code === "OPTIMIZE_IMAGES") {
                    service.details_by_sub_category.forEach((sub) => {
                        sources.set(getCategoryFromLightHouse(sub.name), sub.bandwidth_saving)
                    })
                } else {
                    sources.set(getCategoryFromServiceCode(service.service_code), service.bandwidth_saving_deduplicated)
                }
            })
        }
        const sortedArray = Array.from(sources).sort((a, b) => b[1] - a[1])
        setImprovements(sortedArray)

    }, [siteAudit])


    if (isLoading) return <>Loading...</>
    if (error || !siteAudit) return <>An error has occurred.</>
    return <div>
            <div className="flex flex-col justify-center">
                <p className="text-2xl text-center">{t('fixes.subtitle', {nb: nbImprovement})}</p>
                <p className="text-sm text-center text-error-content">{t('fixes.savable', {volume: fileSize(props.siteAudit.deduplicated_bandwidth_savings)})}</p>
                <p className="text-xs text-center text-neutral-focus">{t('fixes.savableDesc')}</p>
            </div>
            <div className="flex flex-col mt-6">
                {improvements && improvements.map((a) =>
                    <><WorstAssetsCategory category={a[0]} savable={a[1]} audit={props.siteAudit}/></>
                )}
            </div>
    </div>
}
