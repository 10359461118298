import React from 'react';
import {NotificationContainer} from "react-notifications";
import {Outlet} from "react-router-dom";


export default function AppAnonymous() {
    return <div className="text-neutral-content">
        <div>
            <Outlet/>
        </div>
        <NotificationContainer/>
    </div>
}
