import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import LightHouseRating from "../common/LightHouseRating";
import {SiteAudit} from "../../api/dto/SiteAudit";
import AuditChart from "./AuditChart";
import DateFormat, {Format} from "../common/DateFormat";

type AuditHeaderProps = {
    audit: SiteAudit,
}

export default function AuditHeader(props: AuditHeaderProps) {
    const {t} = useTranslation('audit')

    return <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
        <h2 className="text-2xl ">{t('report.title')}</h2>
        <p className="flex gap-2 justify-start items-center">
            {props.audit.site?.favicon_url ?
                <img src={props.audit.site?.favicon_url} width='24' height='24' className="rounded-full"
                     alt="Favicon"/> : ''}
            {props.audit.site?.url}
        </p>
        <p className="mt-4">
            <span className="text-neutral-focus">{t('report.started')}</span> <DateFormat
            date={props.audit.creation_date}
            format={Format.TextualDay}/> {t('report.at')} <DateFormat date={props.audit.creation_date}
                                                                      format={Format.Time}/>
        </p>
        <p>
            <span className="text-neutral-focus">{t('report.ended')}</span> <DateFormat
            date={props.audit.done_date}
            format={Format.TextualDay}/> {t('report.at')} <DateFormat date={props.audit.done_date}
                                                                      format={Format.Time}/>
        </p>
        <hr className="my-4"/>
        <h3>{t('report.subtitle_pages_stats')}</h3>
        <div className="flex w-full gap-2">
            <div className="w-1/3 text-center">
                <div
                    className={"stat-value " + (props.audit.total_pages_count > 999 && "text-2xl lg:text-4xl")}>{props.audit.settings.max_urls_to_audit}</div>
                <div className="stat-desc">{t('report.page_requested')}</div>
            </div>
            <div className="w-1/3 text-center px-2 border-x-gray-200 border-x">
                <div
                    className={"stat-value " + (props.audit.total_pages_count > 999 && "text-2xl lg:text-4xl")}>{props.audit.total_pages_count}</div>
                <div className="stat-desc">{t('report.page_crawled')}</div>
            </div>
            <div className="w-1/3 text-center">
                <div
                    className={"stat-value " + (props.audit.total_pages_count > 999 && "text-2xl lg:text-4xl")}>{props.audit.audited_pages_count}</div>
                <div className="stat-desc">{t('report.page_analyzed')}</div>
            </div>
        </div>
    </div>

}
