import locales from '../../locales';

export type Language = {
  key: keyof typeof locales;
  intlLocale: string;
  dir?: 'ltr' | 'rtl';
  fontScale?: number;
};

export const DEFAULT_NAMESPACE = 'common';

export const DEFAULT_LANGUAGE_KEY: Language['key'] = 'en';

export const AVAILABLE_LANGUAGES: Language[] = [
  {
    key: 'en',
    intlLocale: 'en-US'
  },
  {
    key: 'fr',
    intlLocale: 'fr-FR'
  },
];
