import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGetSiteAudit, usePublishSiteAudit, useUnpublishSiteAudit} from "../../api/siteAuditHooks";
import auditApiClient from "../../api/auditApiClient";
import ROUTES_CLIENT from "../../constants/routesClient";
import BuyAssetFixesButton from "../../components/BuyAssetFixesButton";
import ClientSiteDetailHeader from "../../components/common/ClientSiteDetailHeader";
import RequestDecarbonationButton from "../../components/RequestDecarbonationButton";
import ROUTES_ANONYMOUS from "../../constants/routesAnonymous";
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications";
import AuditSpeedEval from "../../components/audit/AuditSpeedEval";
import AuditEsgEval from "../../components/audit/AuditEsgEval";
import AuditHeader from "../../components/audit/AuditHeader";
import AuditScore from "../../components/audit/AuditScore";


export default function ViewAudit() {
    const {t} = useTranslation('audit')
    let params = useParams()

    const auditId = params.id!
    const {isLoading, error, data: siteAudit} = useGetSiteAudit(auditId)

    const fileSaver = require('file-saver');
    const [loadingPdf, setLoadingPdf] = useState(false)
    const downloadDiagnostic = (lang: "fr" | "en") => {
        setLoadingPdf(true)
        auditApiClient.downloadDiagnostic(auditId, lang)
            .then((blob) => {
                fileSaver.saveAs(blob, "report.pdf")
                setLoadingPdf(false)
            })
    }

    const [publishDisable, setPublishDisable] = useState(false)
    const [published, setPublished] = useState(false)
    useEffect(() => {
        if (siteAudit && !publishDisable) {
            setPublished(siteAudit.is_published)
        }
    }, [siteAudit]);

    const publishMutation = usePublishSiteAudit(
        () => {
            setPublishDisable(false)
        }
    )
    const unpublishMutation = useUnpublishSiteAudit(
        () => {
            setPublishDisable(false)
        }
    )
    const onPublishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPublished(e.target.checked)
        setPublishDisable(true)

        if (e.target.checked) {
            publishMutation.mutate(siteAudit!.id)
        } else {
            unpublishMutation.mutate(siteAudit!.id)
        }
    }

    const handleCopyToClipboard = (token: string) => {
        let url = `${window.location.protocol}//${window.location.host}` + ROUTES_ANONYMOUS.PUBLISHED_AUDIT_VIEW.withParams(token)
        navigator.clipboard.writeText(url)
        NotificationManager.info(`Copied to clipboard.`, 'Copied', INFO_TIMEOUT)
    }

    if (isLoading) return <>Loading...</>
    if (error || !siteAudit) return <>An error has occurred.</>

    return <div>
        <ClientSiteDetailHeader siteName={siteAudit.site!.name} backUrl={ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(siteAudit!.site!.id)} date={siteAudit.creation_date!} webvertRating={siteAudit.webvert_rating}/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">
                <AuditHeader audit={siteAudit}/>
                <AuditScore audit={siteAudit}/>
                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <h2 className="text-2xl ">{t('report.title_optimize')}</h2>
                    <p className="mt-4 text-center">{t('report.we_take_care')}</p>
                    <RequestDecarbonationButton siteAudit={siteAudit}/>
                    <div className="divider uppercase text-neutral-focus">{t('report.or')}</div>
                    <p className="mt-4 text-center">{t('report.you_do_it')}</p>
                    <BuyAssetFixesButton siteAudit={siteAudit}/>

                    <hr className="my-6"/>

                    <AuditSpeedEval audit={siteAudit}/>
                    <AuditEsgEval audit={siteAudit}/>

                    <RequestDecarbonationButton siteAudit={siteAudit}/>
                </div>
                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                        <h2>{t('report.subtitle_optim_share')}</h2>
                        <div className="mt-2">
                            {
                                loadingPdf && <span className="loading loading-spinner loading-xs"></span>
                            }
                            {
                                !loadingPdf &&
                                <div className="flex flex-col lg:flex-row justify-between gap-2">
                                    <button className="btn btn-outline btn-sm" onClick={() => downloadDiagnostic("fr")}>
                                        {t('report.download.label')}
                                        <img
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAARUlEQVR4nGNgGAWDAtgHbm3AhV+//4gX/7dlwImRLfiPC795/xEv/m/LgBOPWvB/NIj+j6ai0Xzwf2TlgwaaFtejYEABAKhYx1pYDcxLAAAAAElFTkSuQmCC"
                                            className="cursor-pointer"
                                            data-testid="diagnostic-pdf-fr"
                                            alt="FR"
                                        />
                                    </button>
                                    <button className="btn btn-outline btn-sm"
                                            onClick={() => downloadDiagnostic("en")}>
                                        {t('report.download.label')}
                                        <img
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACKUlEQVR4nO2U70sTcRzH90/1oEfDkmkjo82paCxSKkjTMIxYP6gFrQeVGBYtIcLsh2slwXQ/1GpirDTKOSdoLFRy3u52+zbdps7b3TvuS9sVF7WkgsgXfB7d5/t93d3nh0azxf/B6qAXMlEmgxMXxqE1DKhCJrp9Gw2Z7+Xo9/nw1DkFcsmGWIUeBQFnrgPf0gRhYYEeDrxhUXXo2S8JTl0cR6y3D8zuciTbL0NMpRRBhz0E4nCCKS9Fsv0KpNUM0hkBnbenscPk/qHAfHQEk0NB8M2N4A6YkZ0O0+dj7zhFICdWmAfheRIEsZ5DrHIv1gIvaWJkbhlNloBKoKvx4m7PFJJdt8DodUg7HgKiCJ6sw3YtSOXfCPLRcvoV5j2jYGurkGhrRY5ZgiRBJVhyDSFm3ENfSCQEuZyEx/1ztA75uwoCFEn0K0ExFAT5g787NH9NgD/9i7RfimKoH4bPv0iLKix+BH/8GNgaEz64/CrBnd73yG6IEFMrtLWZXTpwDx7B1jGhLnKJ0U1ba3klC0kQkOrpBlO2E4mbdtzomkRJpVslkC+oa3xB+11mY3YG8YP1iB85jJDvLfY3jyiC2UiSJq2PvQZbbUTCchJ+VwjGhuGiJtl6dQLxxBqdg7TTQb+GXO9UBDmOAzljAWsyINr/HG1W9T762aooq/Xifl+EzoPI8yDnzyoC2fjJbkf3vTBKqz2bXnZawwAaWkcRniE0Z1Obd4t/j8+V+ZDFCdko+wAAAABJRU5ErkJggg=="
                                            className="cursor-pointer"
                                            data-testid="diagnostic-pdf-en"
                                            alt="EN"
                                        />
                                    </button>
                                    <div className="form-control flex flex-row items-center">
                                        <label className="cursor-pointer label">
                                            <span className="label-text">{t('report.publish.label')}</span>
                                            <input
                                                type="checkbox"
                                                className="toggle toggle-secondary mx-2"
                                                checked={published}
                                                disabled={publishDisable}
                                                onChange={onPublishChange}
                                            />
                                        </label>
                                        {
                                            published &&
                                            <>
                                                <div>
                                                    <svg
                                                        version="1.1"
                                                        width="24"
                                                        viewBox="0 0 36 36"
                                                        preserveAspectRatio="xMidYMid meet"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                                        onClick={() => handleCopyToClipboard(siteAudit.published_token)}
                                                        className="cursor-pointer"
                                                    >
                                                        <title>copy-to-clipboard-line</title>
                                                        <path
                                                            d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z"
                                                            className="clr-i-outline clr-i-outline-path-1"></path>
                                                        <path
                                                            d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z"
                                                            className="clr-i-outline clr-i-outline-path-2"></path>
                                                        <path d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z"
                                                              className="clr-i-outline clr-i-outline-path-3"></path>
                                                        <path
                                                            d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z"
                                                            className="clr-i-outline clr-i-outline-path-4"></path>
                                                        <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                                                    </svg>
                                                </div>
                                                <div className="font-light ml-2">
                                                    <Link
                                                        to={ROUTES_ANONYMOUS.PUBLISHED_AUDIT_VIEW.withParams(siteAudit.published_token)}
                                                        className="link link-secondary"
                                                        target="_blank"
                                                        referrerPolicy="no-referrer"
                                                    >
                                                        {t('report.publish.link')}
                                                    </Link>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
        }
