import {AVAILABLE_LANGUAGES} from "../../lib/i18n/constants";
import i18next from "i18next";

interface Props {
    value: number
    options?: Intl.NumberFormatOptions
}

export default function NumberFormat(props: Props) {
    const language = AVAILABLE_LANGUAGES.find(({key}) => key === i18next.language);
    return <>
        {
            Intl.NumberFormat(language?.intlLocale, props.options).format(props.value)
        }
    </>
}