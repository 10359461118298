import audit from './audit.json';
import assets from './assets.json';
import auth from './auth.json';
import common from './common.json';
import credits from './credits.json'
import decarbonation from './decarbonation.json';
import imageAltIssue from './image_alt_issue.json';
import invoice from './invoice.json';
import label from './label.json';
import layout from './layout.json';
import site from './site.json';
import service from './service.json';
import support from './support.json';
import team from './team.json';
import user from './user.json';

export default {
    audit,
    assets,
    auth,
    common,
    credits,
    decarbonation,
    imageAltIssue,
    invoice,
    label,
    layout,
    site,
    service,
    support,
    team,
    user
} as const;
