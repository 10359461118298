import React from "react";
import {useQueryClient} from "@tanstack/react-query";
import ClientHeader from "../../components/common/ClientHeader";
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import { userKeys } from "../../api/userHook";


export default function PaymentSuccessful() {
    const {t} = useTranslation('credits')

    const queryClient = useQueryClient()

    //force to refresh credits
    queryClient.invalidateQueries({queryKey: [userKeys.me]})

    return <div>
        <ClientHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-3xl">{t('txu.title')}</h1>
                <div className="bg-base-100 my-6 py-4 px-2 rounded rounded-lg">
                    <p>{t('txu.text')}</p>
                    <Link to={ROUTES_CLIENT.HOME.path}
                              className="btn btn-primary w-full mt-6">{t('txu.action.back_to_dashboard')}</Link>
                </div>
            </div>
        </div>
    </div>
}
