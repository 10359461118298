import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {useTranslation} from 'react-i18next';
import ROUTES_CLIENT from '../constants/routesClient';
import {ERROR_TIMEOUT} from '../constants/notifications';
import {NotificationManager} from "react-notifications"

interface Props {
    clientSecret: string
}

export default function CheckoutForm(props: Props) {

    const {t} = useTranslation('credits')

    const stripe = useStripe()
    const elements = useElements()
    const [paymentLoading, setPaymentLoading] = useState<boolean>(false)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setPaymentLoading(true)

        const {error: submitError} = await elements.submit();
        if (submitError) {
            console.log(submitError)
            return
        }

        const clientSecret = props.clientSecret

        const result = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: window.location.origin + ROUTES_CLIENT.PAYMENT_SUCCESS.path,
            },
            redirect: 'always'
        })


        if (result.error) {
            NotificationManager.error(t('payment.error.message'), t('payment.error.title'), ERROR_TIMEOUT)
        }

        setPaymentLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement className="mt-6"/>
            <button id="submit" className="mt-6 btn btn-primary w-full" disabled={!stripe || paymentLoading}>{t('action.pay')}</button>
        </form>
    )
}   