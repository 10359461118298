import React, {useReducer} from "react";
import {useTranslation} from "react-i18next";
import {useAddTeamMember} from "../../api/teamHook";

interface Props {
    teamId: string
    onSuccess?: () => void
}

export default function AddMemberToTeam(props: Props) {
    const {teamId, onSuccess} = props
    const {t} = useTranslation('team')

    const [form, setForm] = useReducer(
        (prevState: any, updatedValues: any) => {
            return {...prevState, ...updatedValues}
        },
        {
            member_email: "",
            site_permission: false,
            audit_permission: false,
            use_credits_permission: false,
        }
    )
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }

    const addTeamMemberMutation = useAddTeamMember(
        () => {
            setForm({
                member_email: "",
                site_permission: false,
                audit_permission: false,
                use_credits_permission: false,
            })
            if (onSuccess) {
                onSuccess()
            }
        }
    )
    const handleAddMemberSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        addTeamMemberMutation.mutate(
            {
                teamId: teamId,
                addTeamMember: {
                    member_login: form.member_email,
                    site_permission: form.site_permission,
                    audit_permission: form.audit_permission,
                    use_credits_permission: form.use_credits_permission
                }
            }
        )
    }

    return <div className="flex flex-row gap-4 items-center mt-4">
        <form onSubmit={handleAddMemberSubmit}>
            <div className="form-control">
                <label className="input input-bordered flex items-center gap-2">
                    {t('members.data.login.label')}
                    <input
                        name="member_email"
                        type="text"
                        placeholder={t('members.data.login.placeHolder')}
                        value={form.member_email}
                        onChange={handleChange}
                    />
                </label>
            </div>
            <div className="form-control">
                {t('members.data.permissions.label')}
                <label className="label cursor-pointer justify-start">
                    <input
                        name="site_permission"
                        className="checkbox"
                        type="checkbox"
                        checked={form.site_permission}
                        onChange={handleChange}
                    />
                    <span className="label-text ml-2">{t('members.data.permissions.site')}</span>
                </label>
                <label className="label cursor-pointer justify-start">
                    <input
                        name="audit_permission"
                        className="checkbox"
                        type="checkbox"
                        checked={form.audit_permission}
                        onChange={handleChange}
                    />
                    <span className="label-text ml-2">{t('members.data.permissions.audit')}</span>
                </label>
                <label className="label cursor-pointer justify-start">
                    <input
                        name="use_credits_permission"
                        className="checkbox"
                        type="checkbox"
                        checked={form.use_credits_permission}
                        onChange={handleChange}
                    />
                    <span className="label-text ml-2">{t('members.data.permissions.useCredit')}</span>
                </label>
            </div>
            <input
                id="submit"
                type="submit"
                className="btn btn-primary btn-wide"
                value={addTeamMemberMutation.isLoading ? "..." : t('members.actions.add.submit')}
                disabled={addTeamMemberMutation.isLoading}
            />
        </form>
    </div>
}