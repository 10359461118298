import ClientHeader from "../../components/common/ClientHeader"
import {useTranslation} from "react-i18next"
import ROUTES_CLIENT from "../../constants/routesClient"
import {Link, useNavigate} from "react-router-dom"
import {useGetMe} from "../../api/userHook"
import FillBottom from "../../components/common/FillBottom"
import auditApiClient from "../../api/auditApiClient";
import {useGetTeams} from "../../api/teamHook";
import {useEffect, useState} from "react";
import {Site} from "../../api/dto/Site";


export default function Home() {
    const {t} = useTranslation('site')

    const navigate = useNavigate();

    const userQuery = useGetMe()
    const user = userQuery.data

    const teamsQuery = useGetTeams('')
    const teams = teamsQuery.data

    const [sites, setSites] = useState<Site[]>([])
    useEffect(() => {
        if (user && teams) {
            let sitesBuffer = user.sites
            setSites(
                sitesBuffer
                    .concat(teams.map(t => t.sites).flat())
                    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)

                    // Remove duplicate
                    .filter((site, index, self) =>
                        index === self.findIndex((t) => t.id === site.id)
                    )
            )
        }
    }, [user, teams])

    if (userQuery.isLoading || !user) return <>Loading...</>

    const handleSiteLink = (site_id: string) => {
        auditApiClient.getClientDashboard(site_id).then(
            (data) => {
                if (data && data.decarbonations && data.decarbonations.length > 0) {
                    navigate(ROUTES_CLIENT.DECARBONATION_DASHBOARD.withParams(site_id))
                } else {
                    navigate(ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(site_id))
                }
            }
        ).catch((e) => {
                navigate(ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(site_id))
            }
        )
    }

    return <div className="w-full min-h-screen bg-base-200 py-6 px-4">
        <div className="max-w-6xl mx-auto">
            <ClientHeader/>
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-lg ml-0 pl-0">{t('home.websites')}</h1>
                <Link to={ROUTES_CLIENT.CREATE_SITE.path} className="btn btn-sm btn-primary font-normal">{t('home.addsite')}</Link>
            </div>
            <div className="bg-base-100 rounded rounded-lg flex flex-col">
                {sites.map(site =>
                    <button key={site.id} onClick={() => handleSiteLink(site.id)} className="text-left p-4 flex items-center justify-between border-b border-gray-200 last:border-b-0">
                        <div className="flex gap-2 items-center">
                            <div>
                                {site.favicon_url ? <img src={site.favicon_url} width='32' height='32' className="rounded-full" alt="Favicon"/> : ''}
                            </div>
                            <div>
                                <h2 className="text-lg text-neutral-content ">{site.name}</h2>
                                <p className="text-sm text-neutral-focus">{site.url}</p>
                            </div>
                        </div>
                        <span className="text-2xl text-neutral-focus font-thin">→</span>
                    </button>
                )}
            </div>
            <FillBottom/>
        </div>
    </div>

}
