import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import Pagination from "./dto/Pagination";
import auditApiClient from "./auditApiClient";
import {ImageAltIssue, UpdateImageAltIssueRequest} from "./dto/ImageAltIssue";

const imageAltIssueKeys = {
    all: ['image_alt_issues'] as const,
    lists: () => [...imageAltIssueKeys.all, 'list'] as const,
    list: (siteAuditId: string, limit: number, offset: number) => [...imageAltIssueKeys.lists(), {siteAuditId: siteAuditId, limit: limit, offset: offset}] as const,
    details: () => [...imageAltIssueKeys.all, 'detail'] as const,
    detail: (id: string) => [...imageAltIssueKeys.details(), id] as const,
}

export function useListImageAltIssues(siteAuditId: string, limit: number, offset: number) {
    return useQuery<Pagination<ImageAltIssue>, string>({
        queryKey: imageAltIssueKeys.list(siteAuditId, limit, offset),
        queryFn: () => auditApiClient.getImageAltIssues(siteAuditId, limit, offset),
    })
}

export function useUpdateImageAltIssue(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { id: string, request: UpdateImageAltIssueRequest }) => auditApiClient.updateImageAltIssue(data.id, data.request),
        onSuccess: async (_, params: { id: string, request: UpdateImageAltIssueRequest }) => {
            await queryClient.invalidateQueries({queryKey: imageAltIssueKeys.lists()})
            await queryClient.invalidateQueries({queryKey: imageAltIssueKeys.detail(params.id)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}
