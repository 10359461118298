import React, {ChangeEvent, FormEvent, useReducer} from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../constants/routes";
import {DEFAULT_MAX_URLS} from "../../constants/audit";
import {NotificationManager} from 'react-notifications';
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications"
import {useCreateSiteAudit} from "../../api/siteAuditHooks";
import {useCreateSite} from "../../api/siteHook";
import {Site} from "../../api/dto/Site";
import {useTranslation} from "react-i18next";

export default function CreateAndAuditSiteForm() {
    const {t} = useTranslation('site')
    const [form, setForm] = useReducer(
        (prevState: any, updatedValues: any) => {
            return {...prevState, ...updatedValues}
        },
        {
            site_name: "",
            site_url: "",
            site_url_filter: "",
            use_webvert_enable_auth: false,
            site_user: "",
            site_password: "",
            audit_tag: "",
            max_urls: DEFAULT_MAX_URLS,
            use_webvert_user_agent: false,
        }
    )
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }
    const createSiteMutation = useCreateSite(
        (site: Site) => {
            NotificationManager.success(`The site "${site.name}" is created.`, 'Success !', SUCCESS_TIMEOUT)

            let parsedMaxUrls = parseInt(form.max_urls)
            let auditRequest = {
                site_id: site.id,
                audit_tag: form.audit_tag,
                max_urls: isNaN(parsedMaxUrls) ? DEFAULT_MAX_URLS : parsedMaxUrls,
                use_webvert_user_agent: form.use_webvert_user_agent,
            }
            createSiteAuditMutation.mutate(auditRequest)
        },
        () => NotificationManager.error(`Failed to create the site ${form.site_name}. Maybe the site already exists?"`, 'Error !', ERROR_TIMEOUT)
    )
    const navigate = useNavigate()
    const createSiteAuditMutation = useCreateSiteAudit(
        () => {
            NotificationManager.success(`The audit is created.`, 'Success !', SUCCESS_TIMEOUT)
            navigate(ROUTES.AUDITS.path)
        },
        () => {
            NotificationManager.error(`Failed to audit the site ${form.site_name}."`, 'Error !', ERROR_TIMEOUT)
            navigate(ROUTES.AUDITS.path)
        }
    )

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        let createSiteRequest = {
            name: form.site_name,
            favicon_url: '',
            url: form.site_url,
            url_filter: form.site_url_filter,
            user: '',
            password: ''
        }
        if (form.use_webvert_enable_auth) {
            createSiteRequest.user = form.site_user;
            createSiteRequest.password = form.site_password;
        }
        createSiteMutation.mutate(createSiteRequest)
    }

    return (
        <div>
            <h2>{t('createAndAudit.title')}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label htmlFor="site_name" className="label">{t('data.name.label')}</label>
                    <input
                        id="site_name"
                        name="site_name"
                        className="input input-bordered w-full max-w-x"
                        type="text"
                        placeholder={t('data.name.placeHolder')}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="site_url" className="label">{t('data.url.label')}</label>
                    <input
                        id="site_url"
                        name="site_url"
                        className="input input-bordered w-full max-w-x"
                        type="url"
                        placeholder={t('data.url.placeHolder')}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="site_url_filter" className="label">{t('data.urlFilter.label')}</label>
                    <input
                        id="site_url_filter"
                        name="site_url_filter"
                        className="input input-bordered w-full max-w-x"
                        type="url"
                        placeholder={t('data.urlFilter.placeHolder')}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="use_webvert_enable_auth" className="label cursor-pointer justify-start">
                        <input
                            id="use_webvert_enable_auth"
                            name="use_webvert_enable_auth"
                            className="checkbox"
                            type="checkbox"
                            onChange={handleChange}
                        />
                        <span className="label-text ml-2">{t('data.auth.enable')}</span>
                    </label>
                </div>
                <fieldset className={`border p-3 ml-8 ${form.use_webvert_enable_auth ? "block" : "hidden"}`}>
                    <legend>{t('data.auth.title')}</legend>
                    <div className="form-control">
                        <label htmlFor="site_user" className="label">{t('data.username.label')}</label>
                        <input
                            id="site_user"
                            name="site_user"
                            className="input input-bordered w-full max-w-x"
                            type="text"
                            placeholder={t('data.username.placeHolder')}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-control">
                        <label htmlFor="site_password" className="label">{t('data.password.label')}</label>
                        <input
                            id="site_password"
                            name="site_password"
                            className="input input-bordered w-full max-w-x"
                            type="password"
                            placeholder={t('data.password.placeHolder')}
                            onChange={handleChange}
                        />
                    </div>
                </fieldset>
                <div className="form-control">
                    <label htmlFor="audit_tag" className="label">{t('audit:data.tag.label')}</label>
                    <input
                        id="audit_tag"
                        name="audit_tag"
                        className="input input-bordered w-full max-w-x"
                        type="text"
                        placeholder={t('audit:data.tag.placeHolder')}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="max_urls" className="label">{t('audit:data.maxUrl.label')}</label>
                    <input
                        id="max_urls"
                        name="max_urls"
                        className="input input-bordered w-full max-w-x"
                        type="number"
                        value={form.max_urls}
                        min="1"
                        max="5000"
                        placeholder={t('audit:data.maxUrl.placeHolder')}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="use_webvert_user_agent" className="label cursor-pointer justify-start">
                        <input
                            id="use_webvert_user_agent"
                            name="use_webvert_user_agent"
                            className="checkbox"
                            type="checkbox"
                            onChange={handleChange}
                        />
                        <span className="label-text ml-2">{t('audit:data.userAgent.label')}</span>
                    </label>
                </div>
                <input
                    id="submit"
                    type="submit"
                    className="btn btn-primary btn-wide mt-6"
                    value={t('createAndAudit.submit')}
                    disabled={createSiteMutation.isLoading || createSiteAuditMutation.isLoading}
                />
            </form>
        </div>
    )
}