import React from "react";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import {SiteAudit, SiteAuditStatus} from "../../api/dto/SiteAudit";
import Modal from "../common/Modal";
import {useCancelSiteAudit} from "../../api/siteAuditHooks";
import {Trans, useTranslation} from "react-i18next";

interface Props {
    audit: SiteAudit
    cancelButton?: boolean
    afterCancelFunction?: () => void
}

export default function AuditStatus(props: Props) {
    const audit = props.audit
    const cancelButton = props.cancelButton === true
    const afterCancelFunction = props.afterCancelFunction
    const cancelMutation = useCancelSiteAudit(
        async () => {
            NotificationManager.success(
                t('cancel.feedbacks.success.message', {id: audit.id}),
                t('cancel.feedbacks.success.title'),
                SUCCESS_TIMEOUT
            )
            if (afterCancelFunction) {
                await afterCancelFunction()
            }
        },
        (error) => {
            console.log(error)
            NotificationManager.error(
                t('cancel.feedbacks.error.message', {id: audit.id}),
                t('cancel.feedbacks.error.title'),
                ERROR_TIMEOUT
            )
        }
    )
    const handleCancel = () => {
        if (cancelMutation.isLoading) {
            return
        }
        cancelMutation.mutate(audit.id)
    }

    const {t} = useTranslation('audit');
    let badgeTag;
    let isTerminatedStatus = true;

    if (audit.status === SiteAuditStatus.Canceled) {
        badgeTag = <div className="badge badge-ghost">{t('data.status.canceled')}</div>
    } else if (audit.status === SiteAuditStatus.Error) {
        badgeTag = <div className="badge badge-error tooltip tooltip-error" data-tip={audit.error_message}>{t('data.status.error')}</div>
    } else if (audit.status === SiteAuditStatus.Done) {
        badgeTag = <div className="badge badge-success">{t('data.status.done')}</div>
    } else if (audit.status === SiteAuditStatus.Aggregate) {
        badgeTag = <div className="badge badge-warning">{t('data.status.aggregate')}</div>
        isTerminatedStatus = false;
    } else if (audit.status === SiteAuditStatus.Auditing) {
        badgeTag = <div className="badge badge-warning">{t('data.status.auditing')}</div>
        isTerminatedStatus = false;
    } else if (audit.status === SiteAuditStatus.Crawling) {
        badgeTag = <div className="badge badge-warning">{t('data.status.crawling')}</div>
        isTerminatedStatus = false;
    } else {
        badgeTag = <div className="badge badge-info">{t('data.status.pending')}</div>
        isTerminatedStatus = false;
    }

    return <div className="flex flex-row inline-flex">
        {badgeTag}
        {
            !isTerminatedStatus && cancelButton &&
            <>
                <label htmlFor={audit.id + "-cancel"}>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABdUlEQVRIid2VTU4CQRCFP4cwG3HpRK8A3EG8gEFiuAKBqICHMMRjKOh5BKOIegpcyIZx0dWZctI9MxBXvKQS8urnFdXVPbDrCIE28AjMgW+xOTARX7ht8RbwBcQ59gmcb1I4AO5UgWfgGqgC+2I1oA9MVdxIcnNhi/8AnZykAOhKrBXJREsVPynSjaChRJq+oJBk5h3Fd4HIER+Jz6InuR9A2SXQJpl5kEqapUQi4WKJASgp7sIl8CTOK8UdkhzkG3Ds4I5U/ED4sUvgXZzVFK+7naV+p0dXE9/cJbAUZ8Xh0127Orc4EP/SEoX21oO9DG7tSviPEdXJGNFEnH3FuQ7UdfAWN8LfuwTsmk7Zfk1fyFjTEPNwxfy9QEUv2qXkLvBcNDCvon0qGr4gB06BFeZwz/KCR0qkh/nrPpQwna8k57ZIN4ESsfMfYC5RRawODElmvpbiG619E/Nw5X1wFhQYiw9lzEaMMSu5FHsFHsTnPdDdwC+pYpIKqrOn8wAAAABJRU5ErkJggg=="
                    />
                </label>
                <Modal
                    id={audit.id + "-cancel"}
                    buttons={
                        [
                            {
                                onClick: () => handleCancel(),
                                text: cancelMutation.isLoading ? t('cancel.confirm.loading') : t('cancel.confirm.accept')
                            }
                        ]
                    }
                >
                    <p>
                        <Trans t={t} i18nKey="cancel.confirm.message" values={{id: audit.id, url: audit.site?.url}}/>
                    </p>
                </Modal>
            </>
        }
    </div>
}
