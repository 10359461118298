import React from "react";
import {Technologies} from "../../api/dto/Technologies";

interface Props {
    technologies: Technologies
}

export default function TechnologiesBadges(props: Props) {
    const technologies = props.technologies
    return <>
        {technologies.cms && technologies.cms.map(cms =>
            <span key={cms.name} className="badge badge-primary ml-1">
                        {cms.name}{cms.version && ": " + cms.version}
                    </span>
        )}
        {technologies.web_server && technologies.web_server.map(web_server =>
            <span key={web_server.name} className="badge badge-secondary ml-1">
                        {web_server.name}{web_server.version && ": " + web_server.version}
                    </span>
        )}
        {technologies.other && technologies.other.map(other =>
            <span key={other.name} className="badge badge-neutral ml-1">
                        {other.name}{other.version && ": " + other.version}
                    </span>
        )}
    </>
}
