import {useTranslation} from "react-i18next";
import {SiteAudit} from "../api/dto/SiteAudit";
import ROUTES_CLIENT from "../constants/routesClient";
import {Link} from "react-router-dom";
import React from "react";

interface Props {
    siteAudit: SiteAudit
}

export default function GoAssetFixesButton(props: Props) {
    const {t} = useTranslation('credits')

    return <>
        {
            props.siteAudit.access_to_fixes &&
            <div className="flex flex-col items-center w-full">
                <Link
                    to={ROUTES_CLIENT.AUDIT_FIXES_VIEW.withParams(props.siteAudit.id)}
                    className="btn btn-outline btn-lg w-full"
                >
                    {t('audit:actions.see.fixes')}
                </Link>
                <span className="text-xs">{t('unlock')}</span>
            </div>
        }
    </>


}
