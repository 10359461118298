import {useGetDecarbonation, useUpdateDecarbonation} from "../../api/decarbonationHooks";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    DecarbonationId: string
    readOnly: boolean
}

interface FormState {
    is_filled: boolean
    cache_httpd: boolean
    cache_nginx: boolean
    cache_cloudflare: boolean
    cache_platform_sh: boolean
    cache_custom: string
    compression_httpd: boolean
    compression_nginx: boolean
    compression_custom: string
    images_compressed: number
    images_resized: number
    images_converted_png_to_jpg: number
    images_converted_gif_to_mp4: number
    images_internalized: number
    images_custom: string
    custom: string
    hide_partner: boolean
}

export default function CrudFixes(props: Props) {
    const decarbonationId = props.DecarbonationId
    const readOnly = props.readOnly
    const {t} = useTranslation('decarbonation')
    const [activeTab, setActiveTab] = useState('fixes-cache')
    const [form, setForm] = useState<FormState>({
        is_filled: false,
        cache_httpd: false,
        cache_nginx: false,
        cache_cloudflare: false,
        cache_platform_sh: false,
        cache_custom: "",
        compression_httpd: false,
        compression_nginx: false,
        compression_custom: "",
        images_compressed: 0,
        images_resized: 0,
        images_converted_png_to_jpg: 0,
        images_converted_gif_to_mp4: 0,
        images_internalized: 0,
        images_custom: "",
        custom: "",
        hide_partner: false,
    })
    const {isLoading, error, data: decarbonation} = useGetDecarbonation(
        decarbonationId,
        data => {
            if (form.is_filled) {
                return
            }
            setForm({
                is_filled: true,
                cache_httpd: data.fixes.cache.httpd,
                cache_nginx: data.fixes.cache.nginx,
                cache_cloudflare: data.fixes.cache.cloudflare,
                cache_platform_sh: data.fixes.cache.platform_sh,
                cache_custom: data.fixes.cache.custom,
                compression_httpd: data.fixes.compression.httpd,
                compression_nginx: data.fixes.compression.nginx,
                compression_custom: data.fixes.compression.custom,
                images_compressed: data.fixes.images.compressed,
                images_resized: data.fixes.images.resized,
                images_converted_png_to_jpg: data.fixes.images.converted_png_to_jpg,
                images_converted_gif_to_mp4: data.fixes.images.converted_gif_to_mp4,
                images_internalized: data.fixes.images.internalized,
                images_custom: data.fixes.images.custom,
                custom: data.fixes.custom,
                hide_partner: data.report_options.hide_partner,
            })
        }
    )

    const onChangeFormHandler = async (event: React.ChangeEvent<HTMLFormElement>) => {
        let value
        switch (event.target.type) {
            case "checkbox":
                value = event.target.checked
                break;
            case "number":
                value = parseInt(event.target.value)
                break;
            default:
                value = event.target.value
        }
        setForm({...form, [event.target.name]: value});
    }


    const updateDecarbonationMutation = useUpdateDecarbonation()
    useEffect(() => {
        if (!form.is_filled) {
            return
        }
        updateDecarbonationMutation.mutate({
            decarbonationId: decarbonationId,
            decarbonationUpdate: {
                fixes: {
                    cache: {
                        httpd: form.cache_httpd,
                        nginx: form.cache_nginx,
                        cloudflare: form.cache_cloudflare,
                        platform_sh: form.cache_platform_sh,
                        custom: form.cache_custom,
                    },
                    compression: {
                        httpd: form.compression_httpd,
                        nginx: form.compression_nginx,
                        custom: form.compression_custom,
                    },
                    images: {
                        compressed: form.images_compressed,
                        resized: form.images_resized,
                        converted_png_to_jpg: form.images_converted_png_to_jpg,
                        converted_gif_to_mp4: form.images_converted_gif_to_mp4,
                        internalized: form.images_internalized,
                        custom: form.images_custom,
                    },
                    custom: form.custom
                },
                report_options: {
                    hide_partner: form.hide_partner,
                }
            }
        })
    }, [form])

    if (isLoading || !form.is_filled) return <>Loading...</>
    if (error || decarbonation === undefined) return <>An error has occurred: {error}</>
    return <div>
        <div className="tabs">
            <div className={"tab tab-lifted" + (activeTab === "fixes-cache" ? " tab-active" : "")}
                 onClick={() => setActiveTab('fixes-cache')}>
                {t('fixes.cache.label')}
            </div>
            <div className={"tab tab-lifted" + (activeTab === "fixes-compression" ? " tab-active" : "")}
                 onClick={() => setActiveTab('fixes-compression')}>
                {t('fixes.compression.label')}
            </div>
            <div className={"tab tab-lifted" + (activeTab === "fixes-images" ? " tab-active" : "")}
                 onClick={() => setActiveTab('fixes-images')}>
                {t('fixes.images.label')}
            </div>
            <div className={"tab tab-lifted" + (activeTab === "fixes-others" ? " tab-active" : "")}
                 onClick={() => setActiveTab('fixes-others')}>
                {t('fixes.others.label')}
            </div>
            <div className="flex-1 cursor-default tab tab-lifted"></div>
        </div>

        <form className="p-3" onChange={onChangeFormHandler}>
            {
                activeTab === "fixes-cache" &&
                <div>
                    <div className="form-control flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.cache_httpd} name="cache_httpd" disabled={readOnly}/>
                            &nbsp;{t('fixes.apache')}
                        </label>
                        -&nbsp;
                        <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/perform-service.html#_apache_httpd" target="_blank" rel="noreferrer">
                            {t('fixes.help')}
                        </a>
                    </div>
                    <div className="form-control flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.cache_nginx} name="cache_nginx" disabled={readOnly}/>
                            &nbsp;{t('fixes.nginx')}
                        </label>
                        -&nbsp;
                        <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/perform-service.html#_nginx" target="_blank" rel="noreferrer">
                            {t('fixes.help')}
                        </a>
                    </div>
                    <div className="form-control flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.cache_cloudflare} name="cache_cloudflare" disabled={readOnly}/>
                            &nbsp;{t('fixes.cloudflare')}
                        </label>
                        -&nbsp;
                        <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/perform-service.html#_cloudflare" target="_blank" rel="noreferrer">
                            {t('fixes.help')}
                        </a>
                    </div>
                    <div className="form-control flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.cache_platform_sh} name="cache_platform_sh" disabled={readOnly}/>
                            &nbsp;{t('fixes.platformSh')}
                        </label>
                        -&nbsp;
                        <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/perform-service.html#_platform_sh" target="_blank" rel="noreferrer">
                            {t('fixes.help')}
                        </a>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.custom')} -&nbsp;
                            <a className="link link-primary" href="https://docs.asciidoctor.org/asciidoc/latest/syntax-quick-reference/" target="_blank" rel="noreferrer">
                                {t('fixes.asciidocHelp')}
                            </a>
                        </label>
                        <textarea className="textarea textarea-bordered" name="cache_custom" disabled={readOnly}>{form.cache_custom}</textarea>
                    </div>
                </div>
            }
            {
                activeTab === "fixes-compression" &&
                <div>
                    <div className="form-control flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.compression_httpd} name="compression_httpd" disabled={readOnly}/>
                            &nbsp;{t('fixes.apache')}
                        </label>
                        -&nbsp;
                        <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/perform-service.html#_apache_httpd_2" target="_blank" rel="noreferrer">
                            {t('fixes.help')}
                        </a>
                    </div>
                    <div className="form-control flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.compression_nginx} name="compression_nginx" disabled={readOnly}/>
                            &nbsp;{t('fixes.nginx')}
                        </label>
                        -&nbsp;
                        <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/perform-service.html#_nginx_2" target="_blank" rel="noreferrer">
                            {t('fixes.help')}
                        </a>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.custom')} -&nbsp;
                            <a className="link link-primary" href="https://docs.asciidoctor.org/asciidoc/latest/syntax-quick-reference/" target="_blank" rel="noreferrer">
                                {t('fixes.asciidocHelp')}
                            </a>
                        </label>
                        <textarea className="textarea textarea-bordered" name="compression_custom" disabled={readOnly}>{form.compression_custom}</textarea>
                    </div>
                </div>
            }
            {
                activeTab === "fixes-images" &&
                <div className="flex flex-row flex-wrap">
                    <div className="form-control w-1/2 flex flex-row items-center">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.compressed')}&nbsp;-&nbsp;
                            <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/optimizer-tool.html#optimize_images" target="_blank" rel="noreferrer">
                                {t('fixes.help')}
                            </a>
                            &nbsp;<input type="number" className="input input-bordered" value={form.images_compressed} name="images_compressed" disabled={readOnly}/>
                        </label>
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.resized')}&nbsp;-&nbsp;
                            <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/optimizer-tool.html#resize_image" target="_blank" rel="noreferrer">
                                {t('fixes.help')}
                            </a>
                            &nbsp;<input type="number" className="input input-bordered" value={form.images_resized} name="images_resized" disabled={readOnly}/>
                        </label>
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.pngToJpg')}&nbsp;-&nbsp;
                            <a className="link link-primary" href="https://internal-doc.lewebvert.fr/webvert/offer-decarbonize/optimizer-tool.html#_convertir_les_png" target="_blank" rel="noreferrer">
                                {t('fixes.help')}
                            </a>
                            &nbsp;<input type="number" className="input input-bordered" value={form.images_converted_png_to_jpg} name="images_converted_png_to_jpg" disabled={readOnly}/>
                        </label>
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.gifToMp4')}&nbsp;
                            <input type="number" className="input input-bordered" value={form.images_converted_gif_to_mp4} name="images_converted_gif_to_mp4" disabled={readOnly}/>
                        </label>
                    </div>
                    <div className="form-control w-1/2">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.internalized')}&nbsp;
                            <input type="number" className="input input-bordered" value={form.images_internalized} name="images_internalized" disabled={readOnly}/>
                        </label>
                    </div>
                    <div className="form-control w-full">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.custom')} -&nbsp;
                            <a className="link link-primary" href="https://docs.asciidoctor.org/asciidoc/latest/syntax-quick-reference/" target="_blank" rel="noreferrer">
                                {t('fixes.asciidocHelp')}
                            </a>
                        </label>
                        <textarea className="textarea textarea-bordered" name="images_custom" disabled={readOnly}>{form.images_custom}</textarea>
                    </div>
                </div>
            }
            {
                activeTab === "fixes-others" &&
                <div>
                    <div className="form-control">
                        <label className="label cursor-pointer justify-start">
                            <input type="checkbox" className="checkbox" checked={form.hide_partner} name="hide_partner" disabled={readOnly}/>
                            &nbsp;{t('fixes.hidePartner')}
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer justify-start">
                            {t('fixes.custom')} -&nbsp;
                            <a className="link link-primary" href="https://docs.asciidoctor.org/asciidoc/latest/syntax-quick-reference/" target="_blank" rel="noreferrer">
                                {t('fixes.asciidocHelp')}
                            </a>
                        </label>
                        <textarea className="textarea textarea-bordered" name="custom" disabled={readOnly}>{form.custom}</textarea>
                    </div>
                </div>
            }
        </form>
    </div>
}
