import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


export default function ClientModalHeader() {
    const {t} = useTranslation('layout')
    const navigate = useNavigate();

    return (
            <header className="flex justify-end items-center p-2 bg-base-100">
                <div className="flex gap-2 items-center">
                    <button onClick={() => navigate(-1)}
                            className="btn btn-outline  border-gray-200 btn-sm md:hidden">x
                    </button>
                    <button onClick={() => navigate(-1)}
                            className="btn btn-outline  border-gray-200 btn-sm hidden md:block">
                        {t('common:close')}
                    </button>
                </div>
            </header>
    )
}