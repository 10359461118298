
import {Link, useNavigate} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {useTranslation} from "react-i18next";

import {useAuth0} from "@auth0/auth0-react";
import { useGetSite } from '../../api/siteHook';
import {Site} from "../../api/dto/Site";
import ClientMenu from "./ClientMenu";

interface Props {
    title?: string
    siteName: string
    siteUrl: string
    siteFavicon: string
    backUrl: string
}


export default function ClientSiteHeader(props: Props) {
    const {t} = useTranslation('layout')
    const navigate = useNavigate();
    const {logout} = useAuth0();
    const site = {
        name: props.siteName,
        url: props.siteUrl,
        favicon_url: props.siteFavicon,
    }

    return (
            <header className="flex justify-between items-center p-2 bg-base-100">
                <div className="flex gap-2 items-center">
                    <button  onClick={() => navigate(props.backUrl)} className="btn btn-outline  border-gray-200 btn-sm">←</button>
                    { site &&
                    <div>
                        {props.title ? <h1 className=" text-lg">{props.title}</h1> :
                            <h1 className=" text-lg">{site.name}</h1>}
                        <p className="text-neutral-focus text-xs flex items-center gap-2">{site.favicon_url?<img src={site.favicon_url} width='16' height='16'  className="rounded-full" alt="Favicon"/>:''}{site.url}</p>
                    </div>
                    }
                </div>
                <div className="lg:block justify-self-end items-center">
                    <div className="flex-none">
                        <ClientMenu />
                    </div>
                </div>
            </header>
    )
}