import {useEffect} from "react"
import * as d3 from "d3"
import {useTranslation} from "react-i18next";

type WebvertScoreProps = {
    score: number,
    rating: string,
    className: string
}

export default function WebvertScore(props: WebvertScoreProps) {
    const {t} = useTranslation('audit')
    let svgRef: SVGSVGElement
    let parentRef: HTMLDivElement

    useEffect(() => {
        const svg = d3.select(svgRef)
        svg.selectAll("*").remove()
        drawChart();
    }, [props.rating, props.score]);

    const labelRect = (x: number, y: number, width: number, height: number, radius: number) => {
        return "M" + x + radius + "," + y +
            "h" + (width - radius) +
            "v" + (height - 2 * radius) +
            "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius +
            "h" + (radius - width) +
            "v" + (2 * radius - height) +
            "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius +
            "z";
    }
    const label = (svg: d3.Selection<SVGSVGElement, any, any, any>, y: number, width: number, color: string, rating: string, score: string) => {
        svg.append("path").attr("d", labelRect(0, y, width, 40, 5)).style("fill", () => color);
        svg.append("text")
            .text(rating)
            .attr("x", width - 22)
            .attr("y", 26 + y)
            .attr("class", () => "chart-letter");
        svg.append("text")
            .text(score)
            .attr("x", 5)
            .attr("y", 22 + y)
            .attr("class", () => "chart-score");
    }

    const arrow = (svg: d3.Selection<SVGSVGElement, any, any, any>, rating: string, x1: number, y1: number, width: number) => {
        var x = width*320/390;
        var y = y1 - 11;

        if (rating !== 'G') {
            svg.append("line")
                .attr("x1", x1)
                .attr("y1", y1 - 3)
                .attr("x2", x)
                .attr("y2", y1 - 3)
                .attr("stroke", "#21335E")
                .attr("stroke-dasharray", "4");
        }
        svg.append('polygon')
            .attr('points', x + "," + (y + 9) + " " + (x + 10) + "," + y + " " + (x + 40) + "," + y + "  " + (x + 40) + "," + (20 + y) + "  " + (x + 10) + "," + (20 + y))
            .attr('stroke-linejoin', 'round')
            .attr('stroke-width', '15')
            .attr('stroke', '#21335E')
            .attr('fill', '#21335E')
            .attr('class', 'arrow2');

        svg.append("text")
            .text(props.score?.toFixed(2))
            .attr("x", x + 10)
            .attr("y", y1 + 4)
            .attr("class", () => "chart-score-arrow");

        var yg = y1;

        if (rating === 'G') {
            yg = yg - 70
        }

        svg.append("text")
            .text(t('data.webvertScore.kwhPage'))
            .attr("x", x)
            .attr("y", yg + 30)
            .attr("class", () => "chart-score-arrow-legend");

        svg.append("text")
            .text("(" + t('data.webvertScore.10kVisits') + ")")
            .attr("x", x)
            .attr("y", yg + 42)
            .attr("class", () => "chart-score-arrow-legend");
    }
    const drawChart = () => {
        var labelColors: string[] = ["#4c771a", "#479A19", "#68CE24", "#FAAD15", "#F18404", "#CB5602", "#BE123C"]
        var labelRating: string[] = ["A", "B", "C", "D", "E", "F", "G"]
        var labelScore: string[] = ["< 0.1", "< 0.2", "< 0.4", "< 0.8", "< 1.5", "< 3", ">= 3"]

        const svg = d3.select(svgRef)

        
        const width = parentRef.offsetWidth
        const height = 310 //width * 310/390 // width='390' height='310'
        svg
            .attr("width", width)
            .attr("height", height)
            .append("g")



        var yScore: number = 19;
        var xScore: number = 70;

        const width_inc=width*40/(390+40)
        for (let i = 0; i < 7; i++) {
            label(svg, i * 44, 70 + i * width_inc, labelColors[i], labelRating[i], labelScore[i])
        }

        switch (props.rating) {
            case 'B': {
                yScore = yScore + 1 * 44;
                xScore = xScore + 1 * width_inc;
                break;
            }
            case 'C': {
                yScore = yScore + 2 * 44;
                xScore = xScore + 2 * width_inc;
                break;
            }
            case 'D': {
                yScore = yScore + 3 * 44;
                xScore = xScore + 3 * width_inc;
                break;
            }
            case 'E': {
                yScore = yScore + 4 * 44;
                xScore = xScore + 4 * width_inc;
                break;
            }
            case 'F': {
                yScore = yScore + 5 * 44;
                xScore = xScore + 5 * width_inc;
                break;
            }
            case 'G': {
                yScore = yScore + 6 * 44;
                xScore = xScore + 6 * 40;
                break;
            }
        }

        arrow(svg, props.rating, xScore, yScore, width);
    }

    return <div ref={(r: HTMLDivElement) => parentRef = r} className={props.className}>
        <svg ref={(r: SVGSVGElement) => svgRef = r}></svg>
    </div>
    
}