import SiteDecarbonationDashboard from "../../components/site/SiteDecarbonationDashboard";
import ROUTES_CLIENT from "../../constants/routesClient";
import ClientSiteHeader from "../../components/common/ClientSiteHeader";
import React, {useEffect} from "react";
import {useGetSite} from "../../api/siteHook";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function DecarbonationDashboard() {
    const {t} = useTranslation('site')
    const currentSiteId = useParams().site_id!
    const {data: site}  = useGetSite(currentSiteId!, !!currentSiteId)

    return (
        <>
            <ClientSiteHeader backUrl={ROUTES_CLIENT.HOME.path} siteName={site?.name!} siteUrl={site?.url!} siteFavicon={site?.favicon_url!}  />
            {
                currentSiteId && <SiteDecarbonationDashboard siteId={currentSiteId} showSiteName={false}/>
            }
        </>
    )
}