export default {
    name: 'customNavigator',

    // Adapted from: https://github.com/i18next/i18next-browser-languageDetector/blob/v7.1.0/src/browserLookups/navigator.js
    lookup(options) {
        const found = [];

        if (typeof navigator !== 'undefined') {
            if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
                for (let i = 0; i < navigator.languages.length; i++) {
                    found.push(navigator.languages[i]);
                }
            }
            if (navigator.userLanguage) {
                found.push(navigator.userLanguage);
            }
            if (navigator.language) {
                found.push(navigator.language);
            }
        }

        if (found.length === 0) {
            return undefined
        }

        if (found[0].toLowerCase().startsWith('fr')) {
            return 'fr'
        }

        return 'en'
    }
};