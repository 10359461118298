import React, {ChangeEvent, FormEvent, useReducer, useState} from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../constants/routes";
import {NotificationManager} from 'react-notifications';
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications"
import {useCreateSite} from "../../api/siteHook";
import {Site, SiteCreation} from "../../api/dto/Site";
import {useTranslation} from "react-i18next";

export default function CreateSiteForm() {
    const {t} = useTranslation('site')
    const [form, setForm] = useReducer(
        (prevState: SiteCreation, updatedValues: Partial<SiteCreation>) => {
            return {...prevState, ...updatedValues}
        },
        {
            name: '',
            favicon_url: '',
            url: '',
            url_filter: '',
            user: '',
            password: '',
        }
    )
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }
    const [useWebvertEnableAuth, setUseWebvertEnableAuth] = useState(false)

    const navigate = useNavigate()
    const createSiteMutation = useCreateSite(
        (site: Site) => {
            NotificationManager.success(`The site "${site.name}" is created.`, 'Success !', SUCCESS_TIMEOUT)
            navigate(ROUTES.SITE.withParams(site.id))
        },
        () => NotificationManager.error(`Failed to create the site "${form.name}".`, 'Error !', ERROR_TIMEOUT)
    )

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        let createSiteRequest = {
            name: form.name,
            favicon_url: '',
            url: form.url,
            url_filter: form.url_filter,
            user: '',
            password: ''
        }
        if (useWebvertEnableAuth) {
            createSiteRequest.user = form.user;
            createSiteRequest.password = form.password;
        }
        createSiteMutation.mutate(createSiteRequest)
    }

    return (
        <div>
            <h2>{t('create.title')}</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label htmlFor="site_name" className="label">{t('data.name.label')}</label>
                    <input
                        id="site_name"
                        name="name"
                        className="input input-bordered w-full max-w-x"
                        type="text"
                        placeholder={t('data.name.placeHolder')}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="site_url" className="label">{t('data.url.label')}</label>
                    <input
                        id="site_url"
                        name="url"
                        className="input input-bordered w-full max-w-x"
                        type="url"
                        placeholder={t('data.url.placeHolder')}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="site_url_filter" className="label">{t('data.urlFilter.label')}</label>
                    <input
                        id="site_url_filter"
                        name="url_filter"
                        className="input input-bordered w-full max-w-x"
                        type="url"
                        placeholder={t('data.urlFilter.placeHolder')}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control">
                    <label htmlFor="use_webvert_enable_auth" className="label cursor-pointer justify-start">
                        <input
                            id="use_webvert_enable_auth"
                            name="use_webvert_enable_auth"
                            className="checkbox"
                            type="checkbox"
                            onChange={(e) => setUseWebvertEnableAuth(e.target.checked)}
                        />
                        <span className="label-text ml-2">{t('data.auth.enable')}</span>
                    </label>
                </div>
                <fieldset className={`border p-3 ml-8 ${useWebvertEnableAuth ? "block" : "hidden"}`}>
                    <legend>{t('data.auth.title')}</legend>
                    <div className="form-control">
                        <label htmlFor="site_user" className="label">{t('data.username.label')}</label>
                        <input
                            id="site_user"
                            name="user"
                            className="input input-bordered w-full max-w-x"
                            type="text"
                            placeholder={t('data.username.placeHolder')}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-control">
                        <label htmlFor="site_password" className="label">{t('data.password.label')}</label>
                        <input
                            id="site_password"
                            name="password"
                            className="input input-bordered w-full max-w-x"
                            type="password"
                            placeholder={t('data.password.placeHolder')}
                            onChange={handleChange}
                        />
                    </div>
                </fieldset>
                <input
                    id="submit"
                    type="submit"
                    className="btn btn-primary btn-wide mt-6"
                    value={t('create.submit')}
                    disabled={createSiteMutation.isLoading}
                />
            </form>
        </div>
    )
}