import {useTranslation} from "react-i18next";
import React from "react";

interface Props {
    label: string
    before: string
    after: string
    deviation?: string
}

export default function MeasureLine(props: Props) {
    const {t} = useTranslation('decarbonation')

    return <div className="flex flex-col w-full py-4 border-b border-gray-200 gap-y-3">
        <p className="font-extrabold">{props.label}</p>
        <div className="flex flex-row justify-between gap-4 sm:gap-14">
            <div className="w-1/2 flex justify-between">
                <span className="text-sm text-neutral-focus">{t('report.comparisonChart.before')}</span>
                <span className="font-bold">{props.before}</span>
            </div>
            <div className="w-1/2 flex justify-between">
                <span className="text-sm text-neutral-focus">{t('report.comparisonChart.after')}</span>
                <span className="font-bold">{props.after}</span>
            </div>
        </div>
        { props.deviation && <p>
            <span className="text-xs text-neutral-focus">{t('measurementDetails.measurementDeviation')} :</span> <span className="text-sm ">{props.deviation}</span>
        </p>}
    </div>

}