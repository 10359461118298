import Sites from "../pages/admin/site/Sites";
import AllAudits from "../pages/admin/audit/AllAudits";
import CreateSite from "../pages/admin/site/CreateSite";
import CreateAndAuditSite from "../pages/admin/site/CreateAndAuditSite";
import SiteOverviewPage from "../pages/admin/site/SiteOverviewPage";
import AuditsDetails from "../pages/admin/audit/AuditsDetails";
import UpdateSitePage from "../pages/admin/site/UpdateSite";
import SiteDashboardPage from "../pages/admin/site/SiteDashboardPage";
import ListDecarbonations from "../pages/admin/decarbonation/ListDecarbonations";
import CreateDecarbonation from "../pages/admin/decarbonation/CreateDecarbonation";
import ViewDecarbonation from "../pages/admin/decarbonation/ViewDecarbonation";
import SiteTeams from "../pages/admin/site/SiteTeams";
import ListTeamsPage from "../pages/admin/team/ListTeamsPage";
import ViewTeamPage from "../pages/admin/team/ViewTeamPage";
import AuditImageAltPage from "../pages/admin/audit/auditImageAltPage";
import {MonitorAudits} from "../pages/admin/monitoring/MonitorAudits";
import ViewAudit from "../pages/admin/audit/ViewAudit";
import ListUsersPage from "../pages/admin/user/ListUsersPage";
import ListTransactionsPage from "../pages/admin/user/ListTransactionsPage";

const ROUTES = {
    SITES_LIST: {
        path: '/admin/site',
        component: Sites,
    },
    SITE_UPDATE: {
        path: '/admin/site/:id/edit',
        component: UpdateSitePage,
        withParams: function (siteId: string) {
            return '/admin/site/' + siteId + '/edit'
        }
    },
    SITE: {
        path: '/admin/site/:id',
        component: SiteOverviewPage,
        withParams: function (siteId: string) {
            return '/admin/site/' + siteId
        }
    },
    SITE_DASHBOARD: {
        path: '/admin/site/:id/dashboard',
        component: SiteDashboardPage,
        withParams: function (siteId: string) {
            return '/admin/site/' + siteId + '/dashboard'
        }
    },
    SITE_TEAM: {
        path: '/admin/site/:id/teams',
        component: SiteTeams,
        withParams: function (siteId: string) {
            return '/admin/site/' + siteId + '/teams'
        }
    },
    SITES_CREATE: {
        path: '/admin/sites/create',
        component: CreateSite,
    },
    SITES_CREATE_AND_RUN: {
        path: '/admin/sites/create-and-audit',
        component: CreateAndAuditSite,
    },
    AUDITS: {
        path: '/admin/audits',
        component: AllAudits,
    },
    AUDIT: {
        path: '/admin/audit/:id',
        component: ViewAudit,
        withParams: function (siteAuditId: string) {
            return '/admin/audit/' + siteAuditId
        }
    },
    AUDIT_DETAILS: {
        path: '/admin/audit/:id/fixes',
        component: AuditsDetails,
        withParams: function (siteAuditId: string) {
            return '/admin/audit/' + siteAuditId+'/fixes'
        }
    },
    AUDIT_IMAGE_ALT: {
        path: '/admin/audit/:id/images-alt',
        component: AuditImageAltPage,
        withParams: function (siteAuditId: string) {
            return '/admin/audit/' + siteAuditId + '/images-alt'
        }
    },
    DECARBONATIONS_LIST: {
        path: '/admin/decarbonations',
        component: ListDecarbonations,
    },
    DECARBONATION_VIEW: {
        path: '/admin/decarbonation/:id',
        component: ViewDecarbonation,
        withParams: function (siteId: string) {
            return '/admin/decarbonation/' + siteId
        }
    },
    DECARBONATION_CREATE: {
        path: '/admin/decarbonations-create',
        component: CreateDecarbonation,
    },
    TEAM_LIST: {
        path: '/admin/teams',
        component: ListTeamsPage
    },
    TEAM_VIEW: {
        path: '/admin/teams/:id',
        component: ViewTeamPage,
        withParams: function (id: string) {
            return '/admin/teams/' + id
        }
    },
    MONITOR_AUDITS: {
        path: '/admin/monitor/audits',
        component: MonitorAudits,
    },
    USER_LIST: {
        path: '/admin/users',
        component: ListUsersPage
    },
    USER_TRANSACTIONS: {
        path: '/admin/users/:id/transactions',
        component: ListTransactionsPage,
        withParams: function (id: string) {
            return `/admin/users/${id}/transactions`
        }
    }
}

export default ROUTES
