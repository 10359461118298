import React, {useMemo} from 'react';
import {Outlet} from "react-router-dom";
import {NotificationContainer} from "react-notifications";
import {useAuth0} from "@auth0/auth0-react";
import auditApiClient from "./api/auditApiClient";

export default function AppClient() {
    const {getAccessTokenSilently} = useAuth0();

    useMemo(() => {
        auditApiClient.tokenGenerator = getAccessTokenSilently;
    }, [getAccessTokenSilently]);


    return (
        <div className="text-neutral-content">
            <div>
                <Outlet/>
            </div>
            <NotificationContainer/>
        </div>
    )
}
