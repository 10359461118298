import {useParams} from "react-router-dom";
import SiteDecarbonationDashboard from "../../../components/site/SiteDecarbonationDashboard";

export default function SiteDashboardPage() {
    let params = useParams()

    return (
        <div>
            <SiteDecarbonationDashboard siteId={params.id!} showSiteName={true} />
        </div>
    )

}