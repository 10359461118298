import i18next from "i18next";
import {AVAILABLE_LANGUAGES} from "../../lib/i18n/constants";
import {toCamelCase} from "../../utils/utils";

interface Props {
    date?: Date
    format: Format
}

export enum Format {NumericDay, NumericDateTime, TextualDay, Time}

export default function DateFormat(props: Props) {
    if (!props.date) {
        return <></>
    }
    const language = AVAILABLE_LANGUAGES.find(({key}) => key === i18next.language);
    switch (props.format) {
        case Format.NumericDay:
            return <>
                {
                    new Intl.DateTimeFormat(language?.intlLocale, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }).format(new Date(props.date))
                }
            </>
        case Format.NumericDateTime:
            return <>
                {
                    new Intl.DateTimeFormat(language?.intlLocale, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit"
                    }).format(new Date(props.date))
                }
            </>
        case Format.TextualDay:
            return <>
                {
                    toCamelCase(new Intl.DateTimeFormat(language?.intlLocale, {dateStyle: 'full'})
                        .format(new Date(props.date)))
                }
            </>
        case Format.Time:
            return <>
                {
                    toCamelCase(new Intl.DateTimeFormat(language?.intlLocale, {timeStyle: 'medium'})
                        .format(new Date(props.date)))
                }
            </>
    }
}
