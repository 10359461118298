import {CreditsTransaction} from "../../api/dto/Credits";
import DateFormat, {Format} from "../common/DateFormat";
import Pagination from "../pagination";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useListTransactions} from "../../api/creditsHook";

interface Props {
    userId: string
}

export default function ListCredits(props: Props) {
    const {t} = useTranslation('credits')

    const userId = props.userId
    const [page, setPage] = useState<number>(1)
    const pageSize = 10

    const transactionsQuery = useListTransactions(userId, pageSize, (page - 1) * pageSize)
    const transactions = transactionsQuery.data

    const renderReason = (param: string) => {
        switch (param) {
            case 'signup':
                return <div className="badge badge-primary">{t('reason.signup')}</div>;
            case 'refund':
                return <div className="badge badge-neutral">{t('reason.refund')}</div>;
            case 'manual':
                return <div className="badge badge-neutral">{t('reason.manual')}</div>;
            case 'by_customer':
                return <div className="badge badge-primary">{t('reason.by_customer')}</div>;
            case 'audit':
                return <div className="badge badge-accent">{t('reason.audit')}</div>;
            case 'fixes':
                return <div className="badge badge-secondary">{t('reason.fixes')}</div>;
            default:
                return <div className="badge">{param}</div>;
        }
    }

    return <div className="bg-base-100 pt-4 pb-16 px-2 rounded rounded-lg">
        <div className="overflow-x-auto">
            <table className="table table-zebra">
                <thead>
                <tr>
                    <th>{t('details.date')}</th>
                    <th>{t('details.type')}</th>
                    <th>{t('details.amount')}</th>
                    <th>{t('details.reason')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    transactions && transactions.results && transactions.results.length > 0 &&
                    transactions.results.map((transaction: CreditsTransaction) => (
                        <tr key={transaction.id}>
                            <td><DateFormat date={transaction.date} format={Format.NumericDateTime}/></td>
                            <td>{transaction.amount > 0 ? t('credit') : t('debit')}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.amount > 0 ? renderReason(transaction.credit_type) : renderReason(transaction.debit_type)}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            {transactions &&
                <div className="flex flex-col items-center mt-2">
                    <Pagination
                        currentPage={page}
                        pageSize={pageSize}
                        totalCount={transactions.total_count}
                        onClick={(i) => setPage(i)}
                    />
                </div>
            }
        </div>
    </div>
}