import React from "react";

interface Props {
    id: string
    children: JSX.Element
    buttons?: {
        text: string
        onClick: () => void
        className?: string
    }[]
}

export default function Modal(props: Props) {
    const id = props.id
    const children = props.children
    const buttons = props.buttons

    return <div>
        <input type="checkbox" id={id} className="modal-toggle"/>
        <div className="modal w-screen">
            <div className="modal-box px-4 pt-12 pb-4 md:max-w-6xl">
                <label htmlFor={id} className="btn btn-neutral btn-sm btn-circle absolute right-2 top-2">✕</label>
                <div className="text-left">{children}</div>
                {
                    buttons?.map((button, index) =>
                        <div key={id + '-' + index} className={id + " modal-action"}>
                            <label htmlFor={id} className={"btn btn-primary "+button.className!} onClick={button.onClick}>{button.text}</label>
                        </div>
                    )
                }
            </div>
        </div>
    </div>
}