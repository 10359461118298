import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import LightHouseRating from "../common/LightHouseRating";
import {SiteAudit} from "../../api/dto/SiteAudit";
import AuditChart from "./AuditChart";

type AuditSpeedEvalProps = {
    audit: SiteAudit,
}

export default function AuditSpeedEval(props: AuditSpeedEvalProps) {
    const {t} = useTranslation('audit')

    const [pageLoadingTime, setPageLoadingTime] = useState<Number>(0)
    const [pageLoadingTimeAfter, setPageLoadingTimeAfter] = useState<Number>(0)

    useEffect(() => {
        if (props.audit) {
            setPageLoadingTime((props.audit.total_pages_time / props.audit.audited_pages_count) / 1000)
            setPageLoadingTimeAfter(((props.audit.total_pages_time - props.audit.total_ms_savings) / props.audit.audited_pages_count) / 1000)
            if ((props.audit.total_pages_time - props.audit.total_ms_savings) < 0) {
                setPageLoadingTimeAfter(0.5 * ((props.audit.total_pages_time / props.audit.audited_pages_count) / 1000))
            }
        }
    }, [props]);

    return <>
        <h3 className="mt-2">{t('report.subtitle_optim_biz')}</h3>
        <p className="text-neutral-focus">{t('report.desc_optim_biz')}</p>
        <div
            className="bg-primary-100 border border-primary-200 mt-4 rounded rounded-lg p-2 text-primary-800">
            <div className="text-base">
                <p>
                    <strong>{props.audit.ms_savings_percent < 80 ? Math.round(props.audit.ms_savings_percent) : 50}%</strong> {t("site:auditsDashboard.cta.speedTitle")}
                </p>
            </div>
            <div className="text-sm text-primary-800 pl-10">
                <p>{t("site:auditsDashboard.cta.speedDescription")}</p>
            </div>
        </div>

        <h4 className="mt-4">{t('report.subtitlePageLoadingTime')}</h4>
        <div className="flex justify-around mt-4">
            <div className="flex justify-center text-center flex-col border-gray-200 grow">
                <p className="text-neutral-focus text-sm text-center">{t('report.current')}</p>
                <p className={"text-3xl font-bold " + ((pageLoadingTime <= 1) ? " text-success-content " : ((pageLoadingTime > 1 && pageLoadingTime < 5) ? " text-warning " : " text-error-content "))}>
                    {pageLoadingTime.toFixed(2)}s
                </p>
            </div>
            <div className="flex justify-center text-center flex-col" aria-hidden="true">
                <p className="text-gray-300 text-8xl text-center font-thin">&gt;</p>
            </div>
            <div className="flex justify-center text-center flex-col grow">
                <p className="text-neutral-focus text-sm text-center">{t('report.afterOptim')}</p>
                <p className={"text-3xl font-bold " + ((pageLoadingTimeAfter <= 1) ? " text-success-content " : ((pageLoadingTimeAfter > 1 && pageLoadingTimeAfter < 5) ? " text-warning " : " text-error-content "))}>
                    {pageLoadingTimeAfter.toFixed(2)}s
                </p>
            </div>
        </div>
        <p className="text-sm mt-4">{t('report.loadingTimeArguments')}</p>
        <ul className="text-sm pl-4 ml-2 list-disc">
            <li>{t('report.loadingTimeArguments1')}</li>
            <li>{t('report.loadingTimeArguments2')}</li>
            <li>{t('report.loadingTimeArguments3')}</li>
        </ul>
        <p className="text-sm text-neutral-focus mt-2">
            {t('report.loadingTimeArgumentsSource')} <a href={t('report.loadingTimeArgumentsSource1Url')}
                                                        target="_blank">
            {t('report.loadingTimeArgumentsSource1Name')}
        </a>
        </p>
        <h4 className="mt-4">{t("report.subtitle_page_speed")}</h4>
        <div className="flex justify-around mt-4">
            <div className="flex justify-center text-center flex-col border-gray-200 border-r grow">
                <p className="text-neutral-focus text-sm text-center">{t('report.current')}</p>
                <LightHouseRating score={props.audit.mobile_score_google}/>
                <p className="text-neutral-focus text-sm text-center">{t('data.googleScore.mobile')}</p>
            </div>
            <div className="flex justify-center flex-col grow">
                <p className="text-neutral-focus text-sm text-center">{t('report.current')}</p>
                <LightHouseRating score={props.audit.desktop_score_google}/>
                <p className="text-neutral-focus text-sm text-center">{t('data.googleScore.desktop')}</p>
            </div>
        </div>
        <p className="text-sm mt-4">{t('report.pageSpeedArguments')}</p>
        <ul className="text-sm pl-4 ml-2 list-disc">
            <li>{t('report.pageSpeedArguments1')}</li>
            <li>{t('report.pageSpeedArguments2')}</li>
            <li>{t('report.pageSpeedArguments3')}</li>
        </ul>
        <p className="text-sm text-neutral-focus mt-2">
            {t('report.pageSpeedArgumentsSource')} <a href={t('report.pageSpeedArgumentsSource1Url')}
                                                        target="_blank">
            {t('report.pageSpeedArgumentsSource1Name')}
        </a>
        </p>
        <h4 className="mt-6 mb-2">{t("report.distributionChart.speedGain.title")}</h4>
        <AuditChart type="SPEED" audit={props.audit} className="rounded-2xl w-full"/>
    </>

}
