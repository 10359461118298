import React from "react";
import {useTranslation} from "react-i18next";
import ListTeams from "../../../components/team/ListTeams";
import CreateTeam from "../../../components/team/CreateTeam";


export default function ListTeamsPage() {
    const {t} = useTranslation('team')

    return <div className="py-4 px-8">
        <h2>{t('actions.create.title')}</h2>
        <CreateTeam/>

        <h2>{t('list.title')}</h2>
        <ListTeams/>
    </div>
}