import {useMutation, useQuery} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {Label, LabelCreation} from "./dto/Label";

export const labelKeys = {
    all: ['labels'] as const,
    list: () => [...labelKeys.all, 'list'] as const,
    details: () => [...labelKeys.all, 'detail'] as const,
    detail: (id: string) => [...labelKeys.details(), id] as const,
}

export function useGetLabel(id: string, enabled?: boolean) {
    return useQuery({
        queryKey: labelKeys.detail(id),
        queryFn: () => auditApiClient.getLabel(id),
        enabled: enabled
    })
}

export function useCreateLabel(onSuccess?: (label: Label) => void, onError?: (variables: LabelCreation) => void) {
    return useMutation({
        mutationFn: (data: LabelCreation) => auditApiClient.createLabel(data),
        onSuccess: async (label: Label) => {
            if (onSuccess) {
                await onSuccess(label)
            }
        },
        onError: (_, variables: LabelCreation) => {
            if (onError) {
                onError(variables)
            }
        }
    })
}
