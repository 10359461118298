import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import DateFormat, {Format} from "../common/DateFormat";
import WebvertRating from "../common/WebvertRating";
import {Decarbonation} from "../../api/dto/Decarbonation";

type DecarbonationSpeedEvolutionProps = {
    decarbonation: Decarbonation,
}

export default function DecarbonationSpeedEvolution(props: DecarbonationSpeedEvolutionProps) {
    const {t} = useTranslation('decarbonation')

    const [pageLoadingTime, setPageLoadingTime] = useState<Number>(0)
    const [pageLoadingTimeAfter, setPageLoadingTimeAfter] = useState<Number>(0)

    useEffect(() => {
        if (props.decarbonation&&props.decarbonation.start_site_audit&&props.decarbonation.end_site_audit) {
            setPageLoadingTime((props.decarbonation.start_site_audit.total_pages_time / props.decarbonation.start_site_audit.audited_pages_count) / 1000)
            setPageLoadingTimeAfter((props.decarbonation.end_site_audit.total_pages_time / props.decarbonation.end_site_audit.audited_pages_count) / 1000)
        }
    }, [props]);

    return <>
        <div className="flex justify-around mt-4">
            <div className="flex justify-center text-center flex-col border-gray-200 grow">
                <p className="text-neutral-focus text-sm text-center">{t('report.comparisonChart.before')}</p>
                <p className={"text-3xl font-bold " + ((pageLoadingTime <= 1) ? " text-success-content " : ((pageLoadingTime > 1 && pageLoadingTime < 5) ? " text-warning " : " text-error-content "))}>
                    {pageLoadingTime.toFixed(2)}s
                </p>
            </div>
            <div className="flex justify-center text-center flex-col" aria-hidden="true">
                <p className="text-gray-300 text-8xl text-center font-thin">&gt;</p>
            </div>
            <div className="flex justify-center text-center flex-col grow">
                <p className="text-neutral-focus text-sm text-center">{t('report.comparisonChart.after')}</p>
                <p className={"text-3xl font-bold " + ((pageLoadingTimeAfter <= 1) ? " text-success-content " : ((pageLoadingTimeAfter > 1 && pageLoadingTimeAfter < 5) ? " text-warning " : " text-error-content "))}>
                    {pageLoadingTimeAfter.toFixed(2)}s
                </p>
            </div>
        </div>
        <p className="text-sm mt-4">{t('audit:report.loadingTimeArguments')}</p>
        <ul className="text-sm pl-4 ml-2 list-disc">
            <li>{t('audit:report.loadingTimeArguments1')}</li>
            <li>{t('audit:report.loadingTimeArguments2')}</li>
            <li>{t('audit:report.loadingTimeArguments3')}</li>
        </ul>
        <p className="text-sm text-neutral-focus mt-2">
            {t('audit:report.loadingTimeArgumentsSource')} <a
            href={t('audit:report.loadingTimeArgumentsSource1Url')}
            target="_blank">
            {t('audit:report.loadingTimeArgumentsSource1Name')}
        </a>
        </p>
    </>

}
