
import {Link, useNavigate} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {useTranslation} from "react-i18next";

import {useAuth0} from "@auth0/auth0-react";
import { useGetSite } from '../../api/siteHook';
import {Site} from "../../api/dto/Site";
import WebvertRating from "./WebvertRating";
import DateFormat, {Format} from "./DateFormat";
import React from "react";
import ClientMenu from "./ClientMenu";

interface Props {
    title?: string
    siteName: string
    date: Date
    webvertRating: string
    backUrl: string
}


export default function ClientSiteDetailHeader(props: Props) {
    const {t} = useTranslation('layout')
    const navigate = useNavigate();
    const {logout} = useAuth0();
    const site = {
        name: props.siteName,
    }

    return (
        <header className="flex justify-between items-center p-2 bg-base-100">
            <div className="flex gap-2 items-center">
                <button onClick={() => navigate(props.backUrl)}
                        className="btn btn-outline  border-gray-200 btn-sm">←
                </button>
                {site &&
                    <div>
                        {props.title ? <h1 className="text-neutral-focus text-md mb-0 pb-0">{props.title}</h1> :
                            <h1 className="text-neutral-focus text-base">{site.name}</h1>}
                        <p className=" text-lg flex items-center gap-2">
                            <WebvertRating rating={props.webvertRating} sizeCss={"h-6 p-2"}/>
                            <div className="flex justify-between items-center">
                                            <span className="font-bold  text-xs lg:text-">
                                                <DateFormat date={props.date} format={Format.TextualDay}/>
                                            </span>
                                <span className="text-neutral-focus ml-4 text-xs  lg:text-sm font-light">
                                                <DateFormat date={props.date} format={Format.Time}/>
                                            </span>
                            </div>
                        </p>


                    </div>
                }
            </div>
            <div className="lg:block justify-self-end items-center">
                <div className="flex-none">
                    <ClientMenu />
                </div>
            </div>
        </header>
    )
}