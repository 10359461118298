import React, {useState} from "react";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import {Decarbonation} from "../../api/dto/Decarbonation";
import DateFormat, {Format} from "../common/DateFormat";
import {useUpdateDecarbonation} from "../../api/decarbonationHooks";
import {useListSiteAudits, useReproduceSiteAudit} from "../../api/siteAuditHooks";
import {useTranslation} from "react-i18next";
import {SiteAuditStatus} from "../../api/dto/SiteAudit";

interface Props {
    decarbonation: Decarbonation,
    className?: string
}

export default function SelectEndAudit(props: Props) {
    const startAudit = props.decarbonation.start_site_audit!
    const decarbonation = props.decarbonation
    const {t} = useTranslation('decarbonation')

    const [selectManually, setSelectManually] = useState(false)
    const [startAuditId, setStartAuditId] = useState('')

    const setEndAuditMutation = useUpdateDecarbonation(data => {
        setSelectManually(false)
    })
    const reproduceAuditMutation = useReproduceSiteAudit(
        data => {
            setEndAuditMutation.mutate({
                decarbonationId: decarbonation.id,
                decarbonationUpdate: {end_site_audit_id: data.id}
            })
            NotificationManager.success(`The audit reproduction of ${startAudit.id} is recorded.`, 'Success !', SUCCESS_TIMEOUT)
        },
        () => NotificationManager.error(`Failed to reproduce the audit ${startAudit.id}`, 'Error !', ERROR_TIMEOUT)
    )
    const selectHandle = () => {
        if (startAuditId) {
            setEndAuditMutation.mutate({
                decarbonationId: decarbonation.id,
                decarbonationUpdate: {end_site_audit_id: startAuditId}
            })
        }
    }

    const {
        isLoading,
        error,
        data: audits
    } = useListSiteAudits(10, 0, {siteId: decarbonation.site.id, status: SiteAuditStatus.Done})

    return <div className={props.className}>
        {
            selectManually ?
                <>
                    {
                        isLoading ? <span>Loading...</span> :
                            error || audits === undefined ? <span>An error has occurred: {error}</span> :
                                <select onChange={(event) => setStartAuditId(event.target.value)} className="select select-bordered select-primary w-full max-w-xs">
                                    <option value=''>-</option>
                                    {
                                        audits.results.map(audit =>
                                            <option key={audit.id} value={audit.id}>
                                                <DateFormat date={audit.creation_date} format={Format.NumericDateTime}/>
                                                {" - " + audit.audited_pages_count + " pages - " + audit.webvert_score.toFixed(2) + " (" + audit.webvert_rating + ")"}
                                            </option>
                                        )
                                    }
                                </select>
                    }
                    <button className="btn btn-primary" onClick={() => selectHandle()}>
                        {t('endAudit.select')}
                    </button>
                    <button className="btn btn-link text-secondary-focus" onClick={() => setSelectManually(false)}>
                        {t('endAudit.cancel')}
                    </button>
                </> :
                <>
                    <button className="btn btn-xs btn-primary"
                            onClick={() => reproduceAuditMutation.mutate({audit_id_to_reproduce: startAudit.id})}>
                        {t('endAudit.reproduce')}
                    </button>
                    <button className="btn btn-xs btn-link text-secondary-focus" onClick={() => setSelectManually(true)}>
                        {t('endAudit.manually')}
                    </button>
                </>
        }
    </div>
}