import React, {PropsWithChildren} from "react";
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications";
import {useTranslation} from "react-i18next";


interface Props {
    copy: boolean
}

export default function CodeExtract(props: PropsWithChildren<Props>) {

    const {t} = useTranslation('decarbonation')

    const handleCopyToClipboard = async (value: string) => {
        await navigator.clipboard.writeText(value)
        NotificationManager.info(t('label:feedbacks.copy.message'), t('label:feedbacks.copy.title'), INFO_TIMEOUT)
    }

    return <div className="mockup-code w-72 md:w-96 lg:w-full lg:max-w-5xl text-xs px-2 text-neutral bg-primary-content">
        {props.copy && <img className="inline absolute top-2 right-2"
         onClick={() => handleCopyToClipboard(props.children!.toString())}
         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAq0lEQVR4nO2UvQ7CMAyEM8BLIdipeDJ+nrLtxmJXdoZcJSOkDqgttKGFBglLXi7JfdIptnNLlpmtSXEhxbXps5mtZgOQ4sRa22OT4vi2IQsOJCjbpuzDpvJh29EFBQuyGEDRMdHa7uaVD7u+Mxbk4wHNo0/dd39AuhFx6+c80weBiwPcgJ4u4IcGTfp3Eb/qqF0kyKIggpwE+9GAqVFwbGRfB8QOYjoAl3LdAFBQYhmWT6BmAAAAAElFTkSuQmCC"/> }
    <pre className="ml-6"><code>{props.children}</code></pre>
    </div>

}