import React, {ReactEventHandler, SyntheticEvent, useEffect, useRef} from "react";
import { useOnClickOutside } from "usehooks-ts";

interface Props {
    id: string
    children: JSX.Element
    visible: boolean
    onClose: () => void
}


export default function ModalV2(props: Props) {
    const children = props.children
    const modalRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (!modalRef.current) {
            return;
        }
        props.visible ? modalRef.current.showModal() : modalRef.current.close();
    }, [props.visible]);

    useOnClickOutside(modalRef, () => {
            props.onClose()
    });

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const handleESC = (event: SyntheticEvent<HTMLDialogElement, Event>) => {
        event.preventDefault();
        handleClose();
    }

    return <div>
        <dialog className="modal w-screen" ref={modalRef}  onCancel={handleESC} >
            <div className="modal-box px-4 pt-12 pb-4 md:max-w-6xl">
                <button onClick={handleClose} className="btn btn-neutral btn-sm btn-circle absolute right-2 top-2">✕</button>
                <div className="text-left">{children}</div>
            </div>
        </dialog>
    </div>
}