import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {SiteAudit} from "../../api/dto/SiteAudit";
import WebvertScore from "./WebvertScore";

type AuditScoreProps = {
    audit: SiteAudit,
}

export default function AuditScore(props: AuditScoreProps) {
    const {t} = useTranslation('audit')

    return <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
        <h2 className="text-2xl ">{t('data.webvertScore.label')}</h2>
        <WebvertScore score={props.audit!.webvert_score} rating={props.audit!.webvert_rating}
                      className=" mt-4" aria-hidden={!props.audit?.done_date}/>
    </div>

}
