import ClientModalHeader from "../../../components/common/ClientModalHeader";
import React from "react";
import {useParams} from "react-router-dom";
import ViewTeam from "../../../components/team/ViewTeam";


export default function ViewTeamPage() {
    let params = useParams()
    const teamId = params.id!

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <ViewTeam teamId={teamId}/>
            </div>
        </div>
    </div>
}