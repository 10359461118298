import bg from '../../assets/images/signup_bg_pic.svg'


export default function FillBottom() {

return (<div className="relative mt-10 w-4/5 mx-auto max-w-2xl"  aria-hidden="true" >
<div className="absolute inset-0 w-full h-full bg-base-200 bg-opacity-30  bg-gradient-to-b from-transparent via-transparent to-base-200"></div>
<img src={bg} height="20" width="166" className="w-full" style={{background: 'linear-gradient(to bottom, rgba(248, 250, 252, 0), rgba(248, 250, 252, 1)'}}/>
</div>)

}