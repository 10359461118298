import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AuditWorstAssets from "../../../components/audit/AuditWorstAssets";
import {useGetSiteAudit} from "../../../api/siteAuditHooks";
import React from "react";
import DateFormat, {Format} from "../../../components/common/DateFormat";

export default function AuditsDetails() {
    const {t} = useTranslation('assets')
    const auditId = useParams().id

    const {isLoading, error, data: siteAudit} = useGetSiteAudit(auditId)

    if (isLoading) return <>Loading...</>
    if (error || siteAudit === undefined) return <>An error has occurred: {error}</>

    return (
        <div className="py-4 px-8">
            <h2>{t('fixes.title')}</h2>
            <p className="flex gap-2 justify-start items-center">
                {siteAudit.site?.favicon_url ?
                    <img src={siteAudit.site?.favicon_url} width='24' height='24' className="rounded-full"
                         alt="Favicon"/> : ''}
                {siteAudit.site?.url}
            </p>
            <p className="mt-4">
                <span className="text-neutral-focus">{t('audit:report.started')}</span> <DateFormat
                date={siteAudit.creation_date}
                format={Format.TextualDay}/> {t('audit:report.at')} <DateFormat date={siteAudit.creation_date}
                                                                          format={Format.Time}/>
            </p>
            <p>
                <span className="text-neutral-focus">{t('audit:report.ended')}</span> <DateFormat
                date={siteAudit.done_date}
                format={Format.TextualDay}/> {t('audit:report.at')} <DateFormat date={siteAudit.done_date}
                                                                          format={Format.Time}/>
            </p>
            <AuditWorstAssets siteAudit={siteAudit}/>
        </div>
    )
}
