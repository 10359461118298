import {Component, useEffect} from "react"
import * as d3 from "d3"
import { Measure } from "../../api/dto/Dashboard";
import {toCamelCase } from '../../utils/utils'
import {useTranslation} from "react-i18next";

type DashboardChartProps = {
    measures: Measure[],
    className: string,
}

export default function DashboardChart(props: DashboardChartProps){
    const {t} = useTranslation('site')
    let svgRef: SVGSVGElement;
    let parentRef: HTMLDivElement

    useEffect(() => {
        const svg = d3.select(svgRef)
        svg.selectAll("*").remove()
        drawChart();
    }, [props.measures]);

    const drawChart = () => {

        props.measures?.forEach(
            (m,i) => {
                if (i===0) {
                    m.chart_date="Initial"
                } else {
                    m.chart_date=toCamelCase(new Intl.DateTimeFormat("fr-FR", {month:"short",year:"numeric"}).format(new Date(m.date)));
                }
            }
        );

        const svg = d3.select(svgRef)

        const margin = {top: 34, right: 60, bottom: 90, left: 60}
        const width = parentRef.offsetWidth - margin.left - margin.right
        const mobile= (parentRef.offsetWidth < 600)
        margin.bottom=margin.bottom + (mobile?20:0)
        const height = (mobile?20:0) + 310 - margin.top - margin.bottom
        const data=props.measures
        
        if (data) {
            var graph=svg
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")")

            var max_total=Math.max(...data.map(({ total_eq_co2 }) => total_eq_co2))

            var y = d3.scaleLinear()
                .domain([0, max_total])
                .range([ height, 0]);

            var y2 = d3.scaleLinear()
                .domain([0, 100])
                .range([ height, 0]);
    
            var x = d3.scaleBand()
                .domain(data.map(({chart_date}) => chart_date))
                .range([ 0, width ])
                .padding(0.3);

            graph.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                .attr("transform", "translate(-20,10)rotate(-75)")
                .style("text-anchor", "end");
            
            graph.append("g")
                .call(d3.axisLeft(y).tickSizeOuter(0))
                .attr("color","#304988")
                .attr("opacity","1");



        
        
            // Avoided CO2
            graph.selectAll("mybar")
                .data(data)
                .enter()
                .append("rect")
                .attr("x", ({chart_date}) => x(chart_date) as number )
                .attr("y", ({avoided_eq_co2}) =>  y(avoided_eq_co2))
                .attr("width", x.bandwidth())
                .attr("height", function(d) { return height - y(d.avoided_eq_co2); })
                .attr("fill", "#607BBC")
                .attr("stroke", "#304988")
                .attr("stroke-width", 2);

            // Total CO2
            var line = d3.line<Measure>()
                .x(({chart_date}) => x(chart_date) as number + x.bandwidth() / 2)
                .y(({total_eq_co2}) => y(total_eq_co2) as number);


            // Total CO2
            graph.append("path")
                .data([data])
                .attr("fill", "none")
                .attr("stroke", "#304988")
                .attr("stroke-width", 2)
                .attr("d", line);

            // Total CO2
            graph.selectAll("myCircles")
                .data(data)
                .enter()
                .append("circle")
                .attr("fill", "#607BBC")
                .attr("stroke-width", 2)
                .attr("stroke", "#304988")
                .attr("cx", ({chart_date}) => (x(chart_date) as number + x.bandwidth()/2))
                .attr("cy", function(d) { return y(d.total_eq_co2); })
                .attr("r", 6 );

            //axis right
            graph.append("g")
                .call(d3.axisRight(y2).tickSizeOuter(0))
                .attr("transform",
                "translate("+width+",0)")
                .attr("color","#8CD935")
                .attr("opacity","1");
            // score Google
            var lineGoogleMobile = d3.line<Measure>()
                .x(({chart_date}) => x(chart_date) as number + x.bandwidth() / 2)
                .y(({mobile_google_score}) => y2(mobile_google_score) as number);

            graph.append("path")
                .data([data])
                .attr("fill", "none")
                .attr("stroke", "#8CD935")
                .attr("stroke-width", 2)
                .attr("d", lineGoogleMobile);

            graph.selectAll("myCircles")
                .data(data)
                .enter()
                .append("circle")
                .attr("fill", "#A7E950")
                .attr("stroke-width", 2)
                .attr("stroke", "#8CD935")
                .attr("cx", ({chart_date}) => (x(chart_date) as number + x.bandwidth()/2))
                .attr("cy", function(d) { return y2(d.mobile_google_score); })
                .attr("r", 6 )
            
            var lineGoogleDesktop = d3.line<Measure>()
                .x(({chart_date}) => x(chart_date) as number + x.bandwidth() / 2)
                .y(({desktop_google_score}) => y2(desktop_google_score) as number);

            graph.append("path")
                .data([data])
                .attr("fill", "none")
                .attr("stroke", "#8CD935")
                .attr("stroke-width", 2)
                .attr("d", lineGoogleDesktop);

            graph.selectAll("myRect")
                .data(data)
                .enter()
                .append("rect")
                .attr("fill", "#A7E950")
                .attr("stroke-width", 2)
                .attr("stroke", "#8CD935")
                .attr("x", ({chart_date}) => (x(chart_date) as number + x.bandwidth()/2-5))
                .attr("y", function(d) { return y2(d.desktop_google_score)-5; })
                .attr("width", 10)
                .attr("height", 10);


            svg.append("text")
                .text(t('decarbonationDashboard.trendsChart.kgEqCO2'))
                .attr("x", 20)
                .attr("y", 24)
                .attr("class",() => "chart-legend-neutral");

            svg.append("text")
                .text(t('decarbonationDashboard.trendsChart.googleScore'))
                .attr("x", width + margin.left-10)
                .attr("y", 24)
                .attr("class",() => "chart-legend-neutral");


            //legend
            var height_legend=height + margin.top+85;

            svg.append("rect")
                .attr("fill", "#607BBC")
                .attr("stroke-width", 2)
                .attr("stroke", "#304988")
                .attr("x", 16)
                .attr("y", height_legend-9)
                .attr("width", 10)
                .attr("height", 10);

            svg.append("text")
                .text(t('decarbonationDashboard.trendsChart.eqCO2Savings'))
                .attr("x", 30)
                .attr("y", height_legend)
                .attr("class",() => "chart-legend-neutral");
            
            svg.append("circle")
                .attr("fill", "#607BBC")
                .attr("stroke-width", 2)
                .attr("stroke", "#304988")
                .attr("cx", 120)
                .attr("cy", height_legend-4)
                .attr("r", 6 )

            svg.append("text")
                .text(t('decarbonationDashboard.trendsChart.eqCO2Total'))
                .attr("x", 130)
                .attr("y", height_legend)
                .attr("class",() => "chart-legend-neutral")

            if (mobile) {
                height_legend+=20
            }

            svg.append("circle")
                .attr("fill", "#A7E950")
                .attr("stroke-width", 2)
                .attr("stroke", "#8CD935")
                .attr("cx", (mobile?16:220))
                .attr("cy", height_legend-4)
                .attr("r", 6 )

            svg.append("text")
                .text(t('decarbonationDashboard.trendsChart.mobileGoogleScore'))
                .attr("x", (mobile?16:220) + 10)
                .attr("y", height_legend)
                .attr("class",() => "chart-legend-neutral")
            
            svg.append("rect")
                .attr("fill", "#A7E950")
                .attr("stroke-width", 2)
                .attr("stroke", "#8CD935")
                .attr("x", (mobile?16:220)+ 126)
                .attr("y", height_legend-9)
                .attr("width", 10)
                .attr("height", 10)

            svg.append("text")
                .text(t('decarbonationDashboard.trendsChart.desktopGoogleScore'))
                .attr("x", (mobile?16:220) + 140)
                .attr("y", height_legend)
                .attr("class",() => "chart-legend-neutral")

        }
    }
    return <div ref={(r: HTMLDivElement) => parentRef = r} className={"bg-base-100 " + props.className}>
        <svg ref={(ref: SVGSVGElement) => svgRef = ref} ></svg>
    </div>
}
