import {Site, SiteCreation, SiteUpdate} from "./dto/Site";
import {Dashboard} from "./dto/Dashboard";
import {Decarbonation, DecarbonationCreation, DecarbonationUpdate} from "./dto/Decarbonation";
import {SiteAudit, SiteAuditCreation, SiteAuditReproduction} from "./dto/SiteAudit";
import {Label, LabelCreation} from "./dto/Label";
import {AddCredits, AdminUserCreation, M2mUserCreation, SuspendUser, User} from "./dto/User";
import Pagination from "./dto/Pagination";
import {Asset, BuyAssets} from "./dto/Asset";
import {CreditsTransaction} from "./dto/Credits";
import {NO_ENOUGH_CREDIT} from "../constants/audit";
import {PaymentIntent, PaymentIntentResponse} from "./dto/PaymentIntent";
import {AuthConfig} from "./dto/AuthConfig";
import {BillingDetails} from "./dto/BillingDetails";
import {PaymentConfig} from "./dto/PaymentConfig";
import {Invoice} from "./dto/Invoice";
import {LanguageUpdate} from "./dto/Language";
import {ExternalSiteInfo} from "./dto/ExternalSiteInfo";
import {SignUpRequest} from "./dto/SignUpRequest";
import {SupportMessageCreation} from "./dto/Support";
import {AddTeamMember, CreateTeam, Team, UpdateTeamMember} from "./dto/Team";
import {ImageAltIssue, UpdateImageAltIssueRequest} from "./dto/ImageAltIssue";

class AuditApi {
    private _tokenGenerator: () => Promise<string>

    constructor() {
        this._tokenGenerator = async () => ""
    }

    set tokenGenerator(value: () => Promise<string>) {
        this._tokenGenerator = value;
    }

    async signUp(signup: SignUpRequest): Promise<void> {
        const response = await fetch(
            "/api/sign-up",
            {
                method: "POST",
                cache: 'no-cache',
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(signup)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async getUserInfo(): Promise<User> {
        const response = await fetch(
            "/api/me",
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async getUser(userId: string): Promise<User> {
        const response = await fetch(
            `/api/users/${userId}`,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async listUsers(): Promise<User[]> {
        const response = await fetch(
            "/api/users",
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as User[]
    }

    async createM2mUser(m2mUserCreation: M2mUserCreation): Promise<void> {
        const response = await fetch(
            "/api/users/create-m2m",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(m2mUserCreation)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async createAdminUser(adminUserCreation: AdminUserCreation): Promise<void> {
        const response = await fetch(
            "/api/users/create-admin",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(adminUserCreation)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async updateUserLanguage(language: LanguageUpdate): Promise<void> {
        const response = await fetch(
            "/api/me/language",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(language)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async suspendUser(userId: string, suspend: boolean): Promise<void> {
        const response = await fetch(
            `/api/users/${userId}/suspend`,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify({suspend: suspend} as SuspendUser)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async addCredits(userId: string, amount: number): Promise<void> {
        const response = await fetch(
            `/api/users/${userId}/add-credits`,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify({amount: amount} as AddCredits)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async listSites(keyword: string): Promise<Site[]> {
        const response = await fetch(
            "/api/sites?search=" + encodeURIComponent(keyword),
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Site[]
    }

    async getSite(siteId: string): Promise<Site> {
        const response = await fetch(
            "/api/sites/" + siteId,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Site
    }

    async createSite(site: SiteCreation): Promise<Site> {
        const response = await fetch(
            "/api/sites",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(site)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async updateSite(siteId: string, updateSiteRequest: SiteUpdate): Promise<void> {
        const response = await fetch(
            "/api/sites/" + siteId,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(updateSiteRequest)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async updateSiteEmail(siteId: string, email: string): Promise<void> {
        const response = await fetch(
            "/api/sites/update-main-email",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify({id: siteId, main_email: email})
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async getAudits(limit: number, offset: number, siteId?: string, status?: string, isReproductionOf?: string): Promise<Pagination<SiteAudit>> {
        let url = `/api/audits?limit=${limit}&offset=${offset}`
        if (siteId) {
            url += `&site_id=${siteId}`
        }
        if (status !== undefined) {
            url += `&status=${status}`
        }
        if (isReproductionOf) {
            url += `&is_reproduction_of=${isReproductionOf}`
        }
        const response = await fetch(
            url,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async getSiteAudit(auditId: string): Promise<SiteAudit> {
        const response = await fetch(
            "/api/audits/" + auditId,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as SiteAudit
    }

    async getPublishedSiteAudit(token: string): Promise<SiteAudit> {
        const response = await fetch(
            "/api/published/audits/" + token,
            {
                method: "GET",
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as SiteAudit
    }

    async getWorstAsset(limit: number, offset: number, siteAuditId: string, subCategories: string[]): Promise<Pagination<Asset>> {
        let url = `/api/audits/${siteAuditId}/worst-assets?limit=${limit}&offset=${offset}`
        if (subCategories.length > 0) {
            url += "&sub_categories=" + subCategories.join(',')
        }
        const response = await fetch(
            url,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async getImageAltIssues(siteAuditId: string, limit: number, offset: number): Promise<Pagination<ImageAltIssue>> {
        const response = await fetch(
            `/api/audits/${siteAuditId}/image-alt-issues?limit=${limit}&offset=${offset}`,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async updateImageAltIssue(id: string, request: UpdateImageAltIssueRequest): Promise<void> {
        const response = await fetch(
            "/api/image-alt-issues/" + id,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(request)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async generateImageAlt(imageAltIssueId: string): Promise<string> {
        const response = await fetch(
            `/api/image-alt-issues/${imageAltIssueId}/generate`,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.text()
    }

    async createAudit(siteAudit: SiteAuditCreation): Promise<SiteAudit> {
        const response = await fetch(
            "/api/audits",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(siteAudit)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async reproduceAudit(reproduceAuditRequest: SiteAuditReproduction): Promise<SiteAudit> {
        const response = await fetch(
            "/api/reproduce-audit",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(reproduceAuditRequest)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.json()
    }

    async cancelAudit(siteAuditId: string): Promise<void> {
        const response = await fetch(
            "/api/audits/" + siteAuditId + "/cancel",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async publishSiteAudit(siteAuditId: string): Promise<void> {
        const response = await fetch(
            "/api/audits/" + siteAuditId + "/publish",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async unpublishSiteAudit(siteAuditId: string): Promise<void> {
        const response = await fetch(
            "/api/audits/" + siteAuditId + "/unpublish",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async getLabel(id: string): Promise<Label> {
        const response = await fetch(
            "/api/labels/" + id,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Label
    }

    async createLabel(label: LabelCreation): Promise<Label> {
        const response = await fetch(
            "/api/labels",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(label)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Label
    }

    async getClientDashboard(siteId: string): Promise<Dashboard> {
        const response = await fetch(
            "/api/sites/" + siteId + "/dashboard",
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Dashboard
    }

    async downloadDiagnostic(auditId: string, lang: "fr" | "en"): Promise<Blob> {
        const response = await fetch(
            "/api/audit/document/diag/" + auditId + "/" + lang,
            {
                method: "GET",
                headers: await this.addAuthIfPresent({}),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.blob()
    }

    async downloadPresentation(auditId: string, lang: "fr" | "en"): Promise<Blob> {
        const response = await fetch(
            "/api/audit/document/sales_pres/" + auditId + "/" + lang,
            {
                method: "GET",
                headers: await this.addAuthIfPresent({}),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.blob()
    }

    async downloadDecarbonationSocialImage(
        decarbonationId: string,
        type: "ecological" | "speed" | "seo",
        network: "twitter" | "linkedin",
        format: "svg" | "png"
    ): Promise<Blob> {
        const response = await fetch(
            `/api/decarbonations/${decarbonationId}/image/social/${type}/${network}/${format}`,
            {
                method: "GET",
                headers: await this.addAuthIfPresent({}),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.blob()
    }

    async downloadDecarbonationGainChart(decarbonationId: string): Promise<Blob> {
        const response = await fetch(
            `/api/decarbonations/${decarbonationId}/image/gain-chart`,
            {
                method: "GET",
                headers: await this.addAuthIfPresent({}),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.blob()
    }

    async downloadDiagChart(siteAuditId: string, format: 'svg' | 'png'): Promise<Blob> {
        const response = await fetch(
            `/api/audits/${siteAuditId}/diag-chart/${format}`,
            {
                method: "GET",
                headers: await this.addAuthIfPresent({}),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return response.blob()
    }

    async getDecarbonations(search: string): Promise<Decarbonation[]> {
        const response = await fetch(
            "/api/decarbonations?search=" + encodeURIComponent(search),
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Decarbonation[]
    }

    async getDecarbonation(id: string): Promise<Decarbonation> {
        const response = await fetch(
            "/api/decarbonations/" + id,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Decarbonation
    }

    async getPublishedDecarbonation(token: string): Promise<Decarbonation> {
        const response = await fetch(
            "/api/published/decarbonations/" + token,
            {
                method: "GET",
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Decarbonation
    }

    async createDecarbonation(decarbonationCreation: DecarbonationCreation): Promise<Decarbonation> {
        const response = await fetch(
            "/api/decarbonations",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(decarbonationCreation)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Decarbonation
    }

    async startDecarbonation(id: string): Promise<Decarbonation> {
        const response = await fetch(
            "/api/decarbonations/" + id + "/start",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                })
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Decarbonation
    }

    async updateDecarbonation(id: string, decarbonationUpdate: DecarbonationUpdate): Promise<Decarbonation> {
        const response = await fetch(
            "/api/decarbonations/" + id,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(decarbonationUpdate)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Decarbonation
    }

    async publishDecarbonation(id: string): Promise<void> {
        const response = await fetch(
            "/api/decarbonations/" + id + "/publish",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async unpublishDecarbonation(id: string): Promise<void> {
        const response = await fetch(
            "/api/decarbonations/" + id + "/unpublish",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async getCreditsTransactions(userId: string, limit: number, offset: number): Promise<Pagination<CreditsTransaction>> {
        let url = `/api/users/${userId}/transactions?limit=${limit}&offset=${offset}`
        const response = await fetch(
            url,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Pagination<CreditsTransaction>
    }

    async buyWorstAsset(id: string, buyAssets: BuyAssets): Promise<void> {
        const response = await fetch(
            "/api/audits/" + id + "/worst-assets/buy",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(buyAssets)
            }
        )
        if (!response.ok) {
            if (response.status === 403) {
                throw new Error(NO_ENOUGH_CREDIT)
            } else {
                throw new Error('Network response was not ok')
            }
        }
    }

    async createPaymentIntent(paymentIntent: PaymentIntent): Promise<PaymentIntentResponse> {
        const response = await fetch(
            "/api/payment-intent",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(paymentIntent)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as PaymentIntentResponse
    }

    async getAuthConfig(): Promise<AuthConfig> {
        const response = await fetch(
            "/auth-config",
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as AuthConfig
    }

    async getPaymentConfig(): Promise<PaymentConfig> {
        const response = await fetch(
            "/payment-config",
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as PaymentConfig
    }

    async getBillingDetails(): Promise<BillingDetails> {
        const response = await fetch(
            "/api/me/billing-details",
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
        return await response.json() as BillingDetails
    }

    async updateBillingDetails(paymentIntent: BillingDetails): Promise<void> {
        const response = await fetch(
            "/api/me/billing-details",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(paymentIntent)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async getInvoices(limit: number, offset: number): Promise<Pagination<Invoice>> {
        let url = `/api/me/invoices?limit=${limit}&offset=${offset}`
        const response = await fetch(
            url,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Pagination<Invoice>
    }

    async getExternalSiteInfo(externalSiteUrl: string): Promise<ExternalSiteInfo> {
        let url = `/api/external-site-info?url=${externalSiteUrl}`
        const response = await fetch(
            url,
            {
                method: "GET",
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as ExternalSiteInfo
    }

    async createSupportMessage(supportMessageCreation: SupportMessageCreation): Promise<void> {
        const response = await fetch(
            "/api/support-message",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(supportMessageCreation)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async createTeam(createTeam: CreateTeam): Promise<Team> {
        const response = await fetch(
            "/api/teams",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(createTeam)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Team
    }

    async getTeams(siteId: string): Promise<Team[]> {
        const response = await fetch(
            "/api/teams?site_id=" + siteId,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Team[]
    }

    async getTeam(id: string): Promise<Team> {
        const response = await fetch(
            "/api/teams/" + id,
            {
                method: "GET",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        return await response.json() as Team
    }

    async addTeamSite(teamId: string, siteId: string): Promise<void> {
        const response = await fetch(
            "/api/teams/" + teamId + "/sites/" + siteId,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                })
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async deleteTeamSite(teamId: string, siteId: string): Promise<void> {
        const response = await fetch(
            "/api/teams/" + teamId + "/sites/" + siteId,
            {
                method: "DELETE",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                })
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async addTeamMember(teamId: string, addTeamMember: AddTeamMember): Promise<void> {
        const response = await fetch(
            "/api/teams/" + teamId + "/members",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(addTeamMember)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async updateTeamMember(teamId: string, memberId: string, updateTeamMember: UpdateTeamMember): Promise<void> {
        const response = await fetch(
            "/api/teams/" + teamId + "/members/" + memberId,
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(updateTeamMember)
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async deleteTeamMember(teamId: string, memberId: string): Promise<void> {
        const response = await fetch(
            "/api/teams/" + teamId + "/members/" + memberId,
            {
                method: "DELETE",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                })
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async transferTeam(teamId: string, newAdminLogin: string): Promise<void> {
        const response = await fetch(
            "/api/teams/" + teamId + "/transfer",
            {
                method: "POST",
                cache: 'no-cache',
                headers: await this.addAuthIfPresent({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify({new_admin_login: newAdminLogin})
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }

    async addAuthIfPresent(headers: Record<string, string>): Promise<Record<string, string>> {
        let token = await this._tokenGenerator()
        if (token) {
            headers.Authorization = `Bearer ${token}`
            return headers
        }
        return headers
    }
}

const auditApiClient = new AuditApi()

export default auditApiClient;