import React from "react";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGetPublishedSiteAudit} from "../../api/siteAuditHooks";
import RequestDecarbonationButton from "../../components/RequestDecarbonationButton";
import ROUTES_ANONYMOUS from "../../constants/routesAnonymous";
import ROUTES_CLIENT from "../../constants/routesClient";
import AuditHeader from "../../components/audit/AuditHeader";
import AuditScore from "../../components/audit/AuditScore";
import AuditSpeedEval from "../../components/audit/AuditSpeedEval";
import AuditEsgEval from "../../components/audit/AuditEsgEval";


export default function ViewPublishedAudit() {
    const {t} = useTranslation('audit')
    let params = useParams()
    const auditToken = params.token!
    const {isLoading, error, data: siteAudit} = useGetPublishedSiteAudit(auditToken)

    if (isLoading) return <>Loading...</>
    if (error || !siteAudit) return <>An error has occurred.</>

    return <div>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">
                <AuditHeader audit={siteAudit}/>
                <AuditScore audit={siteAudit}/>
                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <h2 className="text-2xl ">{t('report.title_optimize')}</h2>
                    <p className="mt-4 text-center">{t('report.we_take_care')}</p>
                    <RequestDecarbonationButton siteAudit={siteAudit} anonymous={true}/>
                    {
                        siteAudit.access_to_fixes &&
                        <>
                            <div className="divider uppercase text-neutral-focus">{t('report.or')}</div>
                            <p className="mt-4 text-center">{t('report.you_do_it')}</p>
                            <div className="flex flex-col items-center w-full">
                                <Link
                                    to={ROUTES_ANONYMOUS.PUBLISHED_AUDIT_FIXES_VIEW.withParams(auditToken)}
                                    className="btn btn-outline btn-lg w-full"
                                >
                                    {t('audit:actions.see.fixes')}
                                </Link>
                            </div>
                        </>
                    }

                    <hr className="my-6"/>

                    <AuditSpeedEval audit={siteAudit}/>
                    <AuditEsgEval audit={siteAudit}/>

                    <RequestDecarbonationButton siteAudit={siteAudit} anonymous={true}/>
                </div>
                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <h2 className="text-2xl ">{t('report.auto_diag.title')}</h2>
                    <div className="mt-2">
                        <Link to={ROUTES_CLIENT.HOME.path} className="btn btn-primary my-4 w-full">
                            {t('report.auto_diag.label')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
