import React, {useRef, useState} from "react"
import {Link} from "react-router-dom"
import Editable from "../common/Editable"
import validator from 'validator';
import ROUTES from "../../constants/routes"
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications"
import {useListSites, useUpdateSiteEmail} from "../../api/siteHook";
import DateFormat, {Format} from "../common/DateFormat";
import {useTranslation} from "react-i18next";

export default function ListSites() {
    const {t} = useTranslation('site')
    const [search, setSearch] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)
    const {data: sites} = useListSites(search, search.length >= 3)
    const updateSiteEmailMutation = useUpdateSiteEmail(
        () => NotificationManager.success(`The main email is updated.`, 'Success !', SUCCESS_TIMEOUT),
        () => NotificationManager.error(`Failed to update main email`, 'Error !', ERROR_TIMEOUT)
    )

    const updateMainEmail = (id: string, value: string) => {
        if (validator.isEmail(value)) {
            updateSiteEmailMutation.mutate({siteId: id, email: value})
        } else {
            console.log("Bad email.")
        }
    }

    return (
        <div>
            <h2 className="mb-4">{t('list.title')}</h2>
            <input
                type="search"
                value={search}
                placeholder={t('list.searchPlaceHolder')}
                onChange={(event) => setSearch(event.target.value)}
                className="input input-bordered w-full max-w-x"
            />

            {sites && sites.length > 0 &&
                <table className="table-container w-full" id="sites-list">
                    <thead>
                    <tr>
                        <th>{t('data.name.label')}</th>
                        <th>{t('data.url.label')}</th>
                        <th>{t('data.creationDate.label')}</th>
                        <th>{t('data.email.label')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        sites.map(site =>
                            <tr key={site.id}>
                                <td><Link to={ROUTES.SITE.withParams(site.id)}>{site.name}</Link></td>
                                <td>{site.url}</td>
                                <td><DateFormat date={site.creation_date} format={Format.NumericDateTime}/></td>
                                <td>
                                    <Editable
                                        text={site.main_email}
                                        type="input"
                                        childRef={inputRef}
                                        placeholder={t('data.email.placeHolder')}
                                    >
                                        <input type="email"
                                               ref={inputRef}
                                               onBlur={e => updateMainEmail(site.id, e.target.value)}
                                        />
                                    </Editable>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            }
        </div>
    )
}