import React from "react";
import {useParams} from "react-router-dom";
import ViewTeam from "../../../components/team/ViewTeam";

export default function ViewTeamPage() {
    let params = useParams()
    const teamId = params.id!

    return <div className="py-4 px-8">
        <ViewTeam teamId={teamId}/>
    </div>
}
