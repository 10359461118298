import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {Decarbonation} from "../../api/dto/Decarbonation";

interface Props {
    decarbonation: Decarbonation
}

export default function Recommendations(props: Props) {
    const {t} = useTranslation('decarbonation')

    const decarbonation=props.decarbonation

return <div>
{(decarbonation.fixes.cache.httpd || decarbonation.fixes.cache.nginx || decarbonation.fixes.cache.cloudflare || decarbonation.fixes.cache.custom || decarbonation.fixes.cache.platform_sh) &&
<>
    <h4>{t('report.recommendations.cache.title')}</h4>
    <p>{t('report.recommendations.cache.text1')}</p>
    <p>
        <Trans t={t} i18nKey={'report.recommendations.cache.text2'}/>
        asf, asx, avi, avif, avifs, bmp, class, ss, divx, doc, DOC, docx,
        eot,
        exe, gif,
        gz, gzip, htc, ico, jpe,
        jpeg, jpg, js, js2, js3, js4, json, less, m4a, m4v, mdb, mid, midi,
        mov,
        mp3,
        mp4, mpe, mpeg, mpg, mpp, odb, odc, odf, odg, odp, ods, odt, ogg,
        ogv,
        _otf,
        otf, pdf, png, pot, pps, ppt, pptx, qt, ra,
        ram, svg, svgz, swf, tar, tif, tiff, ttc, _ttf, ttf, wav, wax, webm,
        webp, wma,
        wmv, wmx, woff, woff2, wri, xla, xls, xlsx xlt, xlw, zip.
    </p>
</>
}
{(decarbonation.fixes.images.converted_png_to_jpg !== 0 || decarbonation.fixes.images.resized !== 0 || decarbonation.fixes.images.compressed !== 0) &&
<>
    <h4>{t('report.recommendations.rules.title')}</h4>
    <p>{t('report.recommendations.rules.intro')}</p>
    <p className="ulist">
        <ul>
            <li>{t('report.recommendations.rules.rule1')}</li>
            <li>{t('report.recommendations.rules.rule2')}</li>
            <li>{t('report.recommendations.rules.rule3')}</li>
        </ul>
    </p>
    <p className="mt-4">{t('report.recommendations.rules.resources.intro')}</p>
    <p className="ulist">
        <ul>
            <li>
                <a href="https://youtu.be/Dk8778C5Zjo" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.rules.resources.video')}
                </a>
            </li>
        </ul>
    </p>
    <h4>{t('report.recommendations.imagesFormat.title')}</h4>
    <p>{t('report.recommendations.imagesFormat.categories.intro')}</p>
    <p className="ulist">
        <ul>
            <li>{t('report.recommendations.imagesFormat.categories.1')}</li>
            <li>{t('report.recommendations.imagesFormat.categories.2')}</li>
        </ul>
    </p>
    <p>{t('report.recommendations.imagesFormat.recommendations.intro')}</p>
    <p className="ulist">
        <ul>
            <li>{t('report.recommendations.imagesFormat.recommendations.1')}</li>
            <li>{t('report.recommendations.imagesFormat.recommendations.2')}</li>
        </ul>
    </p>
    <p>{t('report.recommendations.imagesFormat.text1')}</p>
    <p>{t('report.recommendations.imagesFormat.text2')}</p>
    <p className="mt-4">{t('report.recommendations.imagesFormat.resources.intro')}</p>
    <p className="ulist">
        <ul>
            <li>
                <a href="https://www.lewebvert.fr/blog/2023-04-18-tutoriel-3-choix-des-formats/"
                   target="_blank" rel="noreferrer">
                    {t('report.recommendations.imagesFormat.resources.imageConversion')}
                </a>
            </li>
            <li>
                <a href="https://www.lewebvert.fr/blog/2023-06-16-tutoriel-5-choix-des-formats-webp/"
                   target="_blank" rel="noreferrer">
                    {t('report.recommendations.imagesFormat.resources.webp1')}
                </a>
            </li>
            <li>
                <a href="https://youtu.be/QNosUU-Rbz8" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.imagesFormat.resources.imageFormat')}
                </a>
            </li>
            <li>
                <a href="https://youtu.be/nJgBzojX2EU" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.imagesFormat.resources.changeFormat')}
                </a>
            </li>
            <li>
                <a href="https://youtu.be/FCSChnTDwuE" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.imagesFormat.resources.webp2')}
                </a>
            </li>
        </ul>
    </p>
    <h4>{t('report.recommendations.videoFormat.title')}</h4>
    <p className="ulist mt-4">
        <ul>
            <li><a href="https://youtu.be/b7IZN8zPAWY" target="_blank"
                   rel="noreferrer">{t('report.recommendations.videoFormat.video')}</a>
            </li>
        </ul>
    </p>
    <h4>{t('report.recommendations.resize.title')}</h4>
    <p>{t('report.recommendations.resize.text1')}</p>
    <p>{t('report.recommendations.resize.text2')}</p>
    <p>{t('report.recommendations.resize.impacts.intro')}</p>
    <p className="ulist">
        <ul>
            <li>{t('report.recommendations.resize.impacts.1')}</li>
            <li>{t('report.recommendations.resize.impacts.2')}</li>
            <li>{t('report.recommendations.resize.impacts.3')}</li>
        </ul>
    </p>
    <p>{t('report.recommendations.resize.text3')}</p>
    <p>{t('report.recommendations.resize.text4')}</p>
    <p className="mt-4">{t('report.recommendations.resize.resources.intro')}</p>
    <p className="ulist">
        <ul>
            <li>
                <a href="https://www.lewebvert.fr/blog/2023-04-11-tutoriel-1-redimensionnemen/"
                   target="_blank" rel="noreferrer">
                    {t('report.recommendations.resize.resources.1')}
                </a>
            </li>
            <li>
                <a href="https://youtu.be/e9qQXmBfnkM" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.resize.resources.2')}
                </a>
            </li>
        </ul>
    </p>

    <h4>{t('report.recommendations.optimization.title')}</h4>
    <p>{t('report.recommendations.optimization.intro')}</p>
    <p>{t('report.recommendations.optimization.toolsAction.intro')}</p>
    <p className="ulist">
        <ul>
            <li>{t('report.recommendations.optimization.toolsAction.1')}</li>
            <li>{t('report.recommendations.optimization.toolsAction.2')}</li>
            <li>{t('report.recommendations.optimization.toolsAction.3')}</li>
            <li>{t('report.recommendations.optimization.toolsAction.4')}</li>
        </ul>
    </p>
    <p>{t('report.recommendations.optimization.text')}</p>
    <p>{t('report.recommendations.optimization.tools')}</p>
    <p className="ulist">
        <ul>
            <li>
                MacOS:&nbsp;
                <a href="https://imageoptim.com/" target="_blank"
                   rel="noreferrer">
                    https://imageoptim.com/
                </a>
            </li>
            <li>
                Windows:&nbsp;
                <a href="https://css-ig.net/pinga" target="_blank"
                   rel="noreferrer">https://css-ig.net/pinga</a>
                &nbsp;ou&nbsp;
                <a href="https://sourceforge.net/projects/nikkhokkho/"
                   target="_blank"
                   rel="noreferrer">
                    https://sourceforge.net/projects/nikkhokkho/
                </a>
            </li>
            <li>
                Linux:&nbsp;
                <a href="https://trimage.org" target="_blank"
                   rel="noreferrer">https://trimage.org/</a>
            </li>
            <li>
                Web/API:&nbsp;
                <a href="https://imageoptim.com/api" target="_blank"
                   rel="noreferrer">https://imageoptim.com/api</a>
                &nbsp;ou&nbsp;<a href="https://imagify.io/" target="_blank"
                                 rel="noreferrer">https://imagify.io/</a>
            </li>
        </ul>
    </p>
    <p className="mt-4">{t('report.recommendations.resize.resources.intro')}</p>
    <p className="ulist">
        <ul>
            <li>
                <a href="https://www.lewebvert.fr/blog/2023-04-14-tutoriel-2-optimisation/"
                   target="_blank" rel="noreferrer">
                    {t('report.recommendations.resize.resources.1')}
                </a>
            </li>
            <li>
                <a href="https://youtu.be/GTfcdNtETQg" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.resize.resources.2')}
                </a>
            </li>
        </ul>
    </p>
    <h4>{t('report.recommendations.summary.title')}</h4>
    <p className="ulist">
        <ul>
            <li>
                <a href="https://youtu.be/NbspQWWC0zE" target="_blank"
                   rel="noreferrer">
                    {t('report.recommendations.summary.video')}
                </a>
            </li>
        </ul>
    </p>
</>
}
</div>
}