const CACHE_HTTPD=String.raw`
<IfModule mod_mime.c>
    AddType text/css .css
    AddType text/x-component .htc
    AddType application/x-javascript .js
    AddType application/javascript .js2
    AddType text/javascript .js3
    AddType text/x-js .js4
    AddType text/html .html .htm
    AddType text/richtext .rtf .rtx
    AddType text/plain .txt
    AddType text/xsd .xsd
    AddType text/xsl .xsl
    AddType text/xml .xml
    AddType video/asf .asf .asx .wax .wmv .wmx
    AddType video/avi .avi
    AddType image/avif .avif
    AddType image/avif-sequence .avifs
    AddType image/bmp .bmp
    AddType application/java .class
    AddType video/divx .divx
    AddType application/msword .doc .docx
    AddType application/vnd.ms-fontobject .eot
    AddType application/x-msdownload .exe
    AddType image/gif .gif
    AddType application/x-gzip .gz .gzip
    AddType image/x-icon .ico
    AddType image/jpeg .jpg .jpeg .jpe
    AddType image/webp .webp
    AddType application/json .json
    AddType application/vnd.ms-access .mdb
    AddType audio/midi .mid .midi
    AddType video/quicktime .mov .qt
    AddType audio/mpeg .mp3 .m4a
    AddType video/mp4 .mp4 .m4v
    AddType video/mpeg .mpeg .mpg .mpe
    AddType video/webm .webm
    AddType application/vnd.ms-project .mpp
    AddType application/x-font-otf .otf
    AddType application/vnd.ms-opentype ._otf
    AddType application/vnd.oasis.opendocument.database .odb
    AddType application/vnd.oasis.opendocument.chart .odc
    AddType application/vnd.oasis.opendocument.formula .odf
    AddType application/vnd.oasis.opendocument.graphics .odg
    AddType application/vnd.oasis.opendocument.presentation .odp
    AddType application/vnd.oasis.opendocument.spreadsheet .ods
    AddType application/vnd.oasis.opendocument.text .odt
    AddType audio/ogg .ogg
    AddType video/ogg .ogv
    AddType application/pdf .pdf
    AddType image/png .png
    AddType application/vnd.ms-powerpoint .pot .pps .ppt .pptx
    AddType audio/x-realaudio .ra .ram
    AddType image/svg+xml .svg .svgz
    AddType application/x-shockwave-flash .swf
    AddType application/x-tar .tar
    AddType image/tiff .tif .tiff
    AddType application/x-font-ttf .ttf .ttc
    AddType application/vnd.ms-opentype ._ttf
    AddType audio/wav .wav
    AddType audio/wma .wma
    AddType application/vnd.ms-write .wri
    AddType application/font-woff .woff
    AddType application/font-woff2 .woff2
    AddType application/vnd.ms-excel .xla .xls .xlsx .xlt .xlw
    AddType application/zip .zip
</IfModule>
<IfModule mod_expires.c>
    ExpiresActive On
    ExpiresByType text/css A31536000
    ExpiresByType text/x-component A31536000
    ExpiresByType application/x-javascript A31536000
    ExpiresByType application/javascript A31536000
    ExpiresByType text/javascript A31536000
    ExpiresByType text/x-js A31536000
    ExpiresByType video/asf A31536000
    ExpiresByType video/avi A31536000
    ExpiresByType image/avif A31536000
    ExpiresByType image/avif-sequence A31536000
    ExpiresByType image/bmp A31536000
    ExpiresByType application/java A31536000
    ExpiresByType video/divx A31536000
    ExpiresByType application/msword A31536000
    ExpiresByType application/vnd.ms-fontobject A31536000
    ExpiresByType application/x-msdownload A31536000
    ExpiresByType image/gif A31536000
    ExpiresByType application/x-gzip A31536000
    ExpiresByType image/x-icon A31536000
    ExpiresByType image/jpeg A31536000
    ExpiresByType image/webp A31536000
    ExpiresByType application/json A31536000
    ExpiresByType application/vnd.ms-access A31536000
    ExpiresByType audio/midi A31536000
    ExpiresByType video/quicktime A31536000
    ExpiresByType audio/mpeg A31536000
    ExpiresByType video/mp4 A31536000
    ExpiresByType video/mpeg A31536000
    ExpiresByType video/webm A31536000
    ExpiresByType application/vnd.ms-project A31536000
    ExpiresByType application/x-font-otf A31536000
    ExpiresByType application/vnd.ms-opentype A31536000
    ExpiresByType application/vnd.oasis.opendocument.database A31536000
    ExpiresByType application/vnd.oasis.opendocument.chart A31536000
    ExpiresByType application/vnd.oasis.opendocument.formula A31536000
    ExpiresByType application/vnd.oasis.opendocument.graphics A31536000
    ExpiresByType application/vnd.oasis.opendocument.presentation A31536000
    ExpiresByType application/vnd.oasis.opendocument.spreadsheet A31536000
    ExpiresByType application/vnd.oasis.opendocument.text A31536000
    ExpiresByType audio/ogg A31536000
    ExpiresByType video/ogg A31536000
    ExpiresByType application/pdf A31536000
    ExpiresByType image/png A31536000
    ExpiresByType application/vnd.ms-powerpoint A31536000
    ExpiresByType audio/x-realaudio A31536000
    ExpiresByType image/svg+xml A31536000
    ExpiresByType application/x-shockwave-flash A31536000
    ExpiresByType application/x-tar A31536000
    ExpiresByType image/tiff A31536000
    ExpiresByType application/x-font-ttf A31536000
    ExpiresByType application/vnd.ms-opentype A31536000
    ExpiresByType audio/wav A31536000
    ExpiresByType audio/wma A31536000
    ExpiresByType application/vnd.ms-write A31536000
    ExpiresByType application/font-woff A31536000
    ExpiresByType application/font-woff2 A31536000
    ExpiresByType application/vnd.ms-excel A31536000
    ExpiresByType application/zip A31536000
</IfModule>
<FilesMatch "\.(asf|ASF|asx|ASX|avi|AVI|avif|AVIF|avifs|AVIFS|bmp|BMP|class|CLASS|css|CSS|divx|DIVX|doc|DOC|docx|DOCX|eot|EOT|exe|EXE|gif|GIF|gz|GZ|gzip|GZIP|htc|HTC|ico|ICO|jpe|JPE|jpeg|JPEG|jpg|JPG|js|JS|js2|JS2|js3|JS3|js4|JS4|json|JSON|less|LESS|m4a|M4A|m4v|M4V|mdb|MDB|mid|MID|midi|MIDI|mov|MOV|mp3|MP3|mp4|MP4|mpe|MPE|mpeg|MPEG|mpg|MPG|mpp|MPP|odb|ODB|odc|ODC|odf|ODF|odg|ODG|odp|ODP|ods|ODS|odt|ODT|ogg|OGG|ogv|OGV|_otf|otf|_OTF|OTF|pdf|PDF|png|PNG|pot|POT|pps|PPS|ppt|PPT|pptx|PPTX|qt|QT|ra|RA|ram|RAM|svg|SVG|svgz|SVGZ|swf|SWF|tar|TAR|tif|TIF|tiff|TIFF|ttc|TTC|_ttf|ttf|_TTF|TTF|wav|WAV|wax|WAX|webm|WEBM|webp|WEBP|wma|WMA|wmv|WMV|wmx|WMX|woff|WOFF|woff2|WOFF2|wri|WRI|xla|XLA|xls|XLS|xlsx|XLSX|xlt|XLT|xlw|XLW|zip|ZIP)$">
    FileETag MTime Size
    <IfModule mod_headers.c>
        Header set Pragma "public"
        Header set Cache-Control "max-age=31536000, public"
        Header unset Last-Modified
    </IfModule>
</FilesMatch>
`;

const CACHE_NGINX=String.raw`
location ~ \.(asf|ASF|asx|ASX|avi|AVI|avif|AVIF|avifs|AVIFS|bmp|BMP|class|CLASS|css|CSS|divx|DIVX|doc|DOC|docx|DOCX|eot|EOT|exe|EXE|gif|GIF|gz|GZ|gzip|GZIP|htc|HTC|ico|ICO|jpe|JPE|jpeg|JPEG|jpg|JPG|js|JS|js2|JS2|js3|JS3|js4|JS4|json|JSON|less|LESS|m4a|M4A|m4v|M4V|mdb|MDB|mid|MID|midi|MIDI|mov|MOV|mp3|MP3|mp4|MP4|mpe|MPE|mpeg|MPEG|mpg|MPG|mpp|MPP|odb|ODB|odc|ODC|odf|ODF|odg|ODG|odp|ODP|ods|ODS|odt|ODT|ogg|OGG|ogv|OGV|_otf|otf|_OTF|OTF|pdf|PDF|png|PNG|pot|POT|pps|PPS|ppt|PPT|pptx|PPTX|qt|QT|ra|RA|ram|RAM|svg|SVG|svgz|SVGZ|swf|SWF|tar|TAR|tif|TIF|tiff|TIFF|ttc|TTC|_ttf|ttf|_TTF|TTF|wav|WAV|wax|WAX|webm|WEBM|webp|WEBP|wma|WMA|wmv|WMV|wmx|WMX|woff|WOFF|woff2|WOFF2|wri|WRI|xla|XLA|xls|XLS|xlsx|XLSX|xlt|XLT|xlw|XLW|zip|ZIP)$ {
    etag on;
    add_header Cache-Control "max-age=31536000, public";
    add_header Last-Modified "";
}
`;

const GZIP_HTTPD=String.raw`
<IfModule mod_deflate.c>
    <IfModule mod_filter.c>
        # Compress HTML, CSS, JavaScript, Text, XML and fonts
        AddOutputFilterByType DEFLATE application/javascript
        AddOutputFilterByType DEFLATE application/rss+xml
        AddOutputFilterByType DEFLATE application/vnd.ms-fontobject
        AddOutputFilterByType DEFLATE application/x-font
        AddOutputFilterByType DEFLATE application/x-font-opentype
        AddOutputFilterByType DEFLATE application/x-font-otf
        AddOutputFilterByType DEFLATE application/x-font-truetype
        AddOutputFilterByType DEFLATE application/x-font-ttf
        AddOutputFilterByType DEFLATE application/x-javascript
        AddOutputFilterByType DEFLATE application/xhtml+xml
        AddOutputFilterByType DEFLATE application/xml
        AddOutputFilterByType DEFLATE font/opentype
        AddOutputFilterByType DEFLATE font/otf
        AddOutputFilterByType DEFLATE font/ttf
        AddOutputFilterByType DEFLATE image/svg+xml
        AddOutputFilterByType DEFLATE image/x-icon
        AddOutputFilterByType DEFLATE text/css
        AddOutputFilterByType DEFLATE text/html
        AddOutputFilterByType DEFLATE text/javascript
        AddOutputFilterByType DEFLATE text/plain
        AddOutputFilterByType DEFLATE text/xml
        AddOutputFilterByType DEFLATE application/json
	AddOutputFilterByType DEFLATE text/json

        # Remove browser bugs (only needed for really old browsers)
        <IfModule mod_setenvif.c>
            BrowserMatch ^Mozilla/4 gzip-only-text/html
            BrowserMatch ^Mozilla/4.0[678] no-gzip
            BrowserMatch \bMSIE !no-gzip !gzip-only-text/html
        </IfModule>

        <IfModule mod_headers.c>
            Header append Vary User-Agent
        </IfModule>
    </IfModule>
</IfModule>
`;

export { CACHE_HTTPD, CACHE_NGINX, GZIP_HTTPD }