import {useTranslation} from "react-i18next";
import CreateTeam from "../../../components/team/CreateTeam";
import ListTeams from "../../../components/team/ListTeams";
import React from "react";
import ClientModalHeader from "../../../components/common/ClientModalHeader";


export default function ListTeamsPage() {
    const {t} = useTranslation('team')

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <h2>{t('actions.create.title')}</h2>
                <CreateTeam/>

                <h2>{t('list.title')}</h2>
                <ListTeams/>
            </div>
        </div>
    </div>
}