import DecarbonationDashboard from "../pages/client/DecarbonationDashboard";
import ViewDecarbonation from "../pages/client/ViewDecarbonation";
import AuditDashboard from "../pages/client/AuditDashboard";
import PostLogin from "../pages/PostLogin";
import ViewAudit from "../pages/client/ViewAudit";
import ViewAuditFixes from "../pages/client/ViewAuditFixes";
import CreateSite from "../pages/client/CreateSite";
import RunAudit from "../pages/client/RunAudit";
import CheckCredits from "../pages/client/CheckCredits";
import BuyCredits from "../pages/client/BuyCredits";
import PaymentSuccessful from "../pages/client/PaymentSuccessful";
import Invoices from "../pages/client/Invoices";
import Home from "../pages/client/Home";
import Support from "../pages/client/Support";
import ListTeamsPage from "../pages/client/team/ListTeamsPage";
import ViewTeamPage from "../pages/client/team/ViewTeamPage";

const ROUTES_CLIENT = {
    POST_LOGIN: {
        path: '/',
        component: PostLogin,
    },
    HOME: {
        path: '/home',
        component: Home,
    },
    CREDITS: {
        path: '/credits',
        component: CheckCredits,
    },
    TEAMS: {
        path: '/teams',
        component: ListTeamsPage,
    },
    CREDITS_BUY: {
        path: '/credits/buy',
        component: BuyCredits,
    },
    INVOICES: {
        path: '/invoices',
        component: Invoices,
    },
    SUPPORT: {
        path: '/support',
        component: Support,
    },
    CREATE_SITE: {
        path: '/create-site',
        component: CreateSite,
    },
    CREATE_AUDIT: {
        path: '/run-audit/:site_id',
        component: RunAudit,
        withParams: function (siteId: string) {
            return '/run-audit/' + siteId
        }
    },
    DECARBONATION_DASHBOARD: {
        path: '/site/:site_id/decarbonations',
        component: DecarbonationDashboard,
        withParams: function (siteId: string) {
            return '/site/' + siteId +'/decarbonations'
        }
    },
    AUDITS_DASHBOARD: {
        path: '/site/:id',
        component: AuditDashboard,
        withParams: function (siteId: string) {
            return '/site/' + siteId
        }
    },
    PAYMENT_SUCCESS: {
        path: '/payment-success',
        component: PaymentSuccessful,
    },
    AUDIT_VIEW: {
        path: '/audits/:id',
        component: ViewAudit,
        withParams: function (auditId: string) {
            return '/audits/' + auditId
        }
    },
    AUDIT_FIXES_VIEW: {
        path: '/audits/:id/fixes',
        component: ViewAuditFixes,
        withParams: function (auditId: string) {
            return '/audits/' + auditId + '/fixes'
        }
    },
    DECARBONATION_VIEW: {
        path: '/decarbonations/:id',
        component: ViewDecarbonation,
        withParams: function (siteId: string) {
            return '/decarbonations/' + siteId
        }
    },
    TEAM_LIST: {
        path: '/teams',
        component: ListTeamsPage
    },
    TEAM_VIEW: {
        path: '/teams/:id',
        component: ViewTeamPage,
        withParams: function (id: string) {
            return '/teams/' + id
        }
    }
}

export default ROUTES_CLIENT
