import {useParams} from "react-router-dom";
import {useListImageAltIssues, useUpdateImageAltIssue} from "../../../api/ImageAltIssueHooks";
import React, {useEffect, useState} from "react";
import {UpdateImageAltIssueRequest} from "../../../api/dto/ImageAltIssue";
import {useTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";
import {SUCCESS_TIMEOUT} from "../../../constants/notifications";
import Pagination from "../../../components/pagination";
import auditApiClient from "../../../api/auditApiClient";

const pageSize = 10

export default function AuditImageAltPage() {
    const params = useParams()
    const auditId = params.id!

    const {t} = useTranslation('imageAltIssue')

    const [page, setPage] = useState<number>(1)
    const imageAltIssuesQuery = useListImageAltIssues(auditId, pageSize, (page - 1) * 10)
    const imageAltIssues = imageAltIssuesQuery.data

    const [updateImageAltData, setUpdateImageAltData] = useState<Map<string, UpdateImageAltIssueRequest>>(new Map())
    useEffect(() => {
        if (imageAltIssues) {
            const newMap = new Map<string, UpdateImageAltIssueRequest>()
            imageAltIssues.results.forEach(image =>
                newMap.set(image.id, {new_alternative_text: image.new_alternative_text, decorative: image.decorative})
            )
            setUpdateImageAltData(newMap)
        }
    }, [imageAltIssues]);

    const updateAltHandler = (id: string, newAlt: string) => {
        const newMap = new Map<string, UpdateImageAltIssueRequest>(updateImageAltData)
        const record = newMap.get(id)!
        newMap.set(id, {new_alternative_text: newAlt, decorative: record.decorative})
        setUpdateImageAltData(newMap)
    }
    const updateDecorativeHandler = (id: string, decorative: boolean) => {
        const newMap = new Map<string, UpdateImageAltIssueRequest>(updateImageAltData)
        const record = newMap.get(id)!
        newMap.set(id, {new_alternative_text: record.new_alternative_text, decorative: decorative})
        setUpdateImageAltData(newMap)
    }
    const resetDataHandler = (id: string) => {
        const image =  imageAltIssues!.results.filter(image => image.id === id)[0]
        const newMap = new Map<string, UpdateImageAltIssueRequest>(updateImageAltData)
        newMap.set(id, {new_alternative_text: image.new_alternative_text, decorative: image.decorative})
        setUpdateImageAltData(newMap)
    }

    const updateImageAltIssueMutation = useUpdateImageAltIssue(
        () => NotificationManager.success(
            t('list.actions.saveOne.feedbacks.success.message'),
            t('list.actions.saveOne.feedbacks.success.title'),
            SUCCESS_TIMEOUT
        ),
        () => NotificationManager.error(
            t('list.actions.saveOne.feedbacks.error.message'),
            t('list.actions.saveOne.feedbacks.error.title'),
            SUCCESS_TIMEOUT
        )
    )
    const applyUpdate = (id: string) => {
        updateImageAltIssueMutation.mutate({id: id, request: updateImageAltData.get(id)!})
    }

    const urlExtensionIsCompatibleForGeneration = (url: string): boolean =>
        ['png', 'jpg', 'jpeg', 'gif', 'webp'].includes(url.split(/[#?]/)[0].split('.').pop()!.trim())

    const [generationIsLoading, setGenerationIsLoading] = useState(false)
    const generateNewAlt = (imageAltIssueId: string) => {
        if (!generationIsLoading) {
            setGenerationIsLoading(true)
            auditApiClient.generateImageAlt(imageAltIssueId)
                .then(value => {
                    updateAltHandler(imageAltIssueId, value)
                    NotificationManager.success(
                        t('list.actions.generate.feedbacks.success.message'),
                        t('list.actions.generate.feedbacks.success.title'),
                        SUCCESS_TIMEOUT
                    )
                    setGenerationIsLoading(false)
                })
                .catch(reason => {
                    NotificationManager.error(
                        t('list.actions.generate.feedbacks.error.message'),
                        t('list.actions.generate.feedbacks.error.title'),
                        SUCCESS_TIMEOUT
                    )
                    setGenerationIsLoading(false)
                })
        }
    }

    if (imageAltIssuesQuery.error) {
        return <></>
    }
    if (imageAltIssuesQuery.isLoading || !imageAltIssues) {
        return <></>
    }

    return <div className="py-4 px-8">
        <h2>{t('list.title')}</h2>

        <div className="p-4 flex items-center justify-center border-b border-gray-200 last:border-b-0">
            <div className="flex flex-col items-center mt-2">
                <Pagination currentPage={page} pageSize={pageSize} totalCount={imageAltIssues.total_count}
                            onClick={(i) => setPage(i)}/>
            </div>
        </div>
        <div className="overflow-x-auto md:overflow-x-scroll">
            <table className="table-container w-full table-audit text-left mt-4" id="audits-list">
                <thead>
                <tr>
                    <th>{t('data.image.label')}</th>
                    <th>{t('data.imageUrl.label')}</th>
                    <th>{t('data.originalAltTexts.label')}</th>
                    <th>{t('data.newAltText.label')}</th>
                    <th>{t('data.decorative.label')}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    imageAltIssues.results.map(image =>
                        <tr key={image.id}>
                            <td>
                                <img src={image.src} alt="" className="max-w-40 max-h-40"/>
                            </td>
                            <td>{image.src}</td>
                            <td>
                                <ul>
                                    {
                                        image.tags &&
                                        image.tags
                                            .map(tag => tag.original_alternative_text)
                                            .filter(text => text.length > 0)
                                            .filter((elem, index, self) => index === self.indexOf(elem))
                                            .map(text => <li key={text}>{text}</li>)
                                    }
                                </ul>
                            </td>
                            <td>
                                <textarea
                                    className="textarea textarea-bordered w-full max-w-xs"
                                    onChange={(e) => updateAltHandler(image.id, e.target.value)}
                                    value={updateImageAltData.get(image.id)?.new_alternative_text}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    className="checkbox checkbox-primary"
                                    checked={updateImageAltData.get(image.id)?.decorative}
                                    onChange={e => updateDecorativeHandler(image.id, e.target.checked)}
                                />
                            </td>
                            <td className="flex flex-col">
                                <button className="btn btn-sm btn-primary" onClick={() => applyUpdate(image.id)}>
                                    {t('list.actions.saveOne.label')}
                                </button>
                                <button className="btn btn-sm btn-primary mt-4" onClick={() => resetDataHandler(image.id)}>
                                    {t('list.actions.reset.label')}
                                </button>
                                {
                                    urlExtensionIsCompatibleForGeneration(image.src) &&
                                    <button
                                        className="btn btn-sm btn-primary mt-4"
                                        onClick={() => generateNewAlt(image.id)}
                                        disabled={generationIsLoading}
                                    >
                                        {t('list.actions.generate.label')}
                                    </button>
                                }
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
        <div className="p-4 flex items-center justify-center border-b border-gray-200 last:border-b-0">
            <div className="flex flex-col items-center mt-2">
                <Pagination currentPage={page} pageSize={pageSize} totalCount={imageAltIssues.total_count}
                            onClick={(i) => setPage(i)}/>
            </div>
        </div>
    </div>
}