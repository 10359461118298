import {useQuery,} from '@tanstack/react-query'
import {Dashboard, DashboardDecarbonation} from '../../api/dto/Dashboard'
import auditApiClient from "../../api/auditApiClient";
import {toCamelCase} from '../../utils/utils'
import WebvertScore from '../audit/WebvertScore'
import DashboardChart from './DashboardChart'
import React, {useState} from 'react'
import {Link, NavLink} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import Modal from "../common/Modal";
import ViewLabel from "../label/ViewLabel";
import DateFormat, {Format} from "../common/DateFormat";
import {useTranslation} from "react-i18next";
import {useClientDashboard} from "../../api/decarbonationHooks";
import WebvertRating from "../common/WebvertRating";
import ModalV2 from "../common/ModalV2";

type SiteDashboardProps = {
    siteId: string
    showSiteName: boolean
}

export default function SiteDecarbonationDashboard(props: SiteDashboardProps) {
    const {t} = useTranslation('site')
    const [isOpened, setIsOpened] = useState(false);
    const {isLoading, error, data} = useClientDashboard(props.siteId)

    if (isLoading) return <>Loading...</>
    if (error || data === undefined) return <>An error has occurred: {error}</>

    return <div className="w-full min-h-screen bg-base-200 py-6 px-4">
        <div className="max-w-6xl mx-auto">
            <h2 className="text-3xl">{t('decarbonationDashboard.title')}</h2>
            <Link className="mt-4 w-full btn btn-outline btn-secondary btn-sm" to={ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(props.siteId)}>{t('actions.gotoAudits')}</Link>
            <div
                className="bg-base-100 rounded-2xl w-full p-4 mt-4 flex flex-col gap-2 lg:flex-row lg:justify-between lg:items-center">
                <h3 className="text-2xl text-center">{t('decarbonationDashboard.totalCO2Savings')}</h3>
                <p className="font-light text-4xl text-center">{data?.total_avoided_eq_co2?.toFixed(0)} {t('decarbonationDashboard.kgCo2Eq')}</p>
            </div>
            <div className="flex flex-col lg:flex-row mb-12 gap-x-6">
                <div className="bg-white rounded-2xl w-full w-1 lg:w-3/5 h-auto p-8 mt-6">
                    <h3 className="text-2xl">{t('decarbonationDashboard.trendsChart.title')}</h3>
                    <DashboardChart measures={data.measures} className=""/>
                </div>
                <div className="bg-white rounded-2xl  w-full w-1 lg:w-2/5 h-auto p-8 mt-6">
                    <h3 className="text-2xl">{t('decarbonationDashboard.webvertScore')}</h3>
                    <WebvertScore score={data.current_webvert_score} rating={data.current_webvert_rating}
                                  className="mt-4"/>
                    {
                        data.label_id &&
                        <>
                            <button className="link"
                                   onClick={() => setIsOpened(true)}>{t('decarbonationDashboard.downloadLabel')}</button>
                            <ModalV2 id={"download-label-" + data.label_id} visible={isOpened} onClose={() => setIsOpened(false)}>
                                <>
                                    <h2 className="mb-6">{t('label:title')}</h2>
                                    <ViewLabel labelId={data.label_id}/>
                                    <button className="btn btn-outline btn-neutral w-full mt-4" onClick={() => setIsOpened(false)}>{t('common:close')}</button>
                                </>
                            </ModalV2>
                        </>
                    }
                </div>
            </div>

            <h3 className="mb-4 text-2xl">{t('decarbonationDashboard.decarbonationHistory')}</h3>

            <div className="bg-base-100 rounded rounded-lg flex flex-col mb-8">
                {data.decarbonations?.map((decarbonation: DashboardDecarbonation) =>
                <div className="border-b border-gray-200 w-full last:border-b-0" key={decarbonation.id}>
                    <Link to={decarbonation.end_date ? ROUTES_CLIENT.DECARBONATION_VIEW.withParams(decarbonation.id) : '#'}
                          className={"p-4 flex flex-col justify-start " + (!decarbonation.end_date && "cursor-not-allowed")}>
                        <div className="flex justify-between">
                                            <span className="font-bold text-neutral-content">
                                                <DateFormat date={decarbonation.end_date} format={Format.TextualDay}/>
                                            </span>
                            <span className="text-neutral-focus ml-4 text-sm font-light">
                                                <DateFormat date={decarbonation.end_date} format={Format.Time}/>
                                            </span>
                        </div>
                        {decarbonation.end_date ? <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-row gap-0 justify-start items-center">
                                    <span><WebvertRating rating={decarbonation.webvert_rating} className="mr-4"/></span>
                                    <div>
                                        <div className="text-neutral-focus text-xs text-center">
                                                            {decarbonation.webvert_score.toFixed(2)}
                                                        </div>
                                        <div className="text-neutral-focus text-xs  text-center">{t('audit:data.webvertScore.kwhPage')}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-end">
                                    <div
                                        className="font-bold text-neutral-content"><span className="text-xs text-neutral-focus font-normal">{t('decarbonationDashboard.Co2Eq')} {t('decarbonationDashboard.saved')} :</span> {decarbonation.avoided_eq_co2.toFixed(1)} Kg</div>
                                    <span className="text-2xl text-neutral-focus font-thin text-right">→</span>
                                </div>
                            </div> :
                            <div className="badge badge-info">{t('decarbonation:data.status.inProgress')}</div>
                        }
                    </Link>
                </div>
                )}
        </div>
        <Link className="w-full btn btn-outline btn-secondary" to={ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(props.siteId)}>{t('actions.gotoAuditsCTA')}</Link>
    </div>
</div>

}