import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {COUNTRIES, CREDIT_VALUE} from "../../constants/audit"
import {NotificationManager} from "react-notifications"
import {ERROR_TIMEOUT} from "../../constants/notifications";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {useCreatePaymentIntent, useGetBillingDetails, useGetPaymentConfig, useUpdateBillingDetails} from "../../api/creditsHook";
import {PaymentIntentResponse} from "../../api/dto/PaymentIntent";
import CheckoutForm from "../../components/CheckoutForm";
import {StripeElementsOptionsClientSecret} from "@stripe/stripe-js/types/stripe-js/elements-group";
import CreditsLeft from "../../components/credit/CreditsLeft";
import ClientModalHeader from "../../components/common/ClientModalHeader";
import {useGetMe} from "../../api/userHook";
import BuyOption from "../../components/common/BuyOption";

type Cart = {
    volume: number,
    amount: number
    vat: number,
    amountWithTax: number,
    company_name: string
    buyer_name: string
    buyer_firstname: string
    address_1: string
    address_2: string
    address_3: string
    postal_code: string
    city: string
    country: string
    country_code: string
    enterprise_legal_id: string
    vat_number: string
}

export default function BuyCredits() {
    const {t} = useTranslation('credits')
    const [step, setStep] = useState<number>(0)
    const [options, setOptions] = useState<StripeElementsOptionsClientSecret>()
    const [secret, setSecret] = useState<string>('')
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null)
    const {data: user} = useGetMe()
    const {data: paymentConfig} = useGetPaymentConfig()
    const [customVolume, setCustomVolume] = useState<number>(200)

    useEffect(() => {
            if (paymentConfig && paymentConfig.public_key) {
                setStripePromise(loadStripe(paymentConfig.public_key, {apiVersion: paymentConfig.api_version}))
            }
        }, [paymentConfig]
    );

    const [cart, setCart] = useState<Partial<Cart>>({
        volume: 3,
        amount: 3 * CREDIT_VALUE
    })
    const {isLoading, data: billingDetails} = useGetBillingDetails()

    useEffect(() => {
            if (billingDetails) {
                const countries = COUNTRIES.filter(c => c.name === billingDetails.country)
                setCart({
                    ...cart,
                    company_name: billingDetails.company_name,
                    buyer_firstname: billingDetails.buyer_firstname,
                    buyer_name: billingDetails.buyer_name,
                    address_1: billingDetails.address_1,
                    address_2: billingDetails.address_2,
                    address_3: billingDetails.address_3,
                    city: billingDetails.city,
                    postal_code: billingDetails.postal_code,
                    country: billingDetails.country,
                    country_code: countries[0] ? countries[0].code : "",
                    vat: countries[0] ? countries[0].vat : 0,
                    enterprise_legal_id: billingDetails.enterprise_legal_id,
                    vat_number: billingDetails.vat_number
                })
            }
        }, [billingDetails]
    );


    const runCreatePaymentMutation = useCreatePaymentIntent(
        (secret: PaymentIntentResponse) => {
            let secretOption: StripeElementsOptionsClientSecret = {clientSecret: secret.client_secret}
            setOptions(secretOption)
            setSecret(secret.client_secret)
            runUpdateBillingDetails.mutate({
                company_name: cart.company_name ? cart.company_name : '',
                buyer_firstname: cart.buyer_firstname ? cart.buyer_firstname : '',
                buyer_name: cart.buyer_name ? cart.buyer_name : '',
                address_1: cart.address_1 ? cart.address_1 : '',
                address_2: cart.address_2 ? cart.address_2 : '',
                address_3: cart.address_3 ? cart.address_3 : '',
                city: cart.city ? cart.city : '',
                postal_code: cart.postal_code ? cart.postal_code : '',
                country: cart.country ? cart.country : '',
                country_code: cart.country_code ? cart.country_code : '',
                enterprise_legal_id: cart.enterprise_legal_id ? cart.enterprise_legal_id : '',
                vat_number: cart.vat_number ? cart.vat_number : '',
            })
        },
        () => NotificationManager.error(t('payment.error.message'), t('payment.error.title'), ERROR_TIMEOUT)
    )

    const runUpdateBillingDetails = useUpdateBillingDetails(
        () => {
            setStep(2)
        },
        () => {
            NotificationManager.error(t('payment.error.message'), t('payment.error.title'), ERROR_TIMEOUT)
        }
    )

    const handleChoice = (nb: number) => {
        if (nb <= 0) {
            NotificationManager.error(t('payment.negative.message'), t('payment.negative.title'), ERROR_TIMEOUT)
        } else {
            setCart({...cart, volume: nb, amount: nb * CREDIT_VALUE});
            setStep(1)
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (cart.amount && cart.amount >= 0 && cart.vat !== undefined) {
            const centimeAmountWithTax = Math.round((cart.amount * 100) * (1 + cart.vat))
            setCart({...cart, amountWithTax: centimeAmountWithTax / 100})
            runCreatePaymentMutation.mutate({centime_amount: centimeAmountWithTax, currency: "eur", vat: cart.vat, credit: cart.volume ? cart.volume : 0})
        }
    }

    if (!user || isLoading) {
        return <div>{t('buy.loading')}</div>
    }

    return <div>
        <ClientModalHeader/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-2">
            <div className="max-w-6xl mx-auto">
                <CreditsLeft user={user}/>
                {
                    step === 0 &&
                    <div>
                        <h1 className="text-3xl mb-4">{t('buy.title')}</h1>
                        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-2">
                            <BuyOption nb={3} handleBuy={handleChoice} primary={false}/>
                            <BuyOption nb={10} handleBuy={handleChoice} primary={true}/>
                            <BuyOption nb={20} handleBuy={handleChoice} primary={true}/>
                            <BuyOption nb={50} handleBuy={handleChoice} primary={true}/>
                            <div
                                className="py-4 px-4 flex flex-col bg-base-100 rounded-lg border border-gray-200">
                                <label htmlFor="custom_amount"
                                       className="w-full mb-2">{t('buy.custom_volume')}</label>
                                <div className="mb-2 flex justify-between gap-2">
                                    <button type="button" className="font-bold btn btn-outline" onClick={() => {
                                        customVolume > 2 ? setCustomVolume(customVolume - 1) : setCustomVolume(1)
                                    }}>-
                                    </button>
                                    <input type="number" id="custom_amount_input" name="customAmount" size={0} min={0}
                                           className="p-2 input input-bordered rounded text-right grow w-8"
                                           value={customVolume} onChange={(e) => setCustomVolume(Math.abs(Number(e.target.value)))}/>
                                    <button type="button" className="font-bold btn btn-outline;"
                                            onClick={() => setCustomVolume(customVolume + 1)}>+
                                    </button>
                                </div>
                                <div className="flex flex-row justify-end items-center">
                                    <div className="mr-4">
                                        <span className="font-bold">{customVolume * CREDIT_VALUE}</span> <span
                                        className="text-xs text-neutral-focus"> € {t('before_tax')}</span>
                                    </div>
                                    <button type="button"
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleChoice(customVolume)}>{t('buy.btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    step === 1 &&
                    <>
                        <h1 className="text-3xl mb-4">{t('buy.billing_part')}</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4" role="form">
                                <div>
                                    <label className="block" htmlFor="companyName">{t('buy.company_name')} (*)</label>
                                    <input type="text" required id="companyName"
                                           className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, company_name: e.target.value})}
                                           value={cart.company_name}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="buyerName">{t('buy.buyer_name')}</label>
                                    <input type="text" id="buyerName"
                                           className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, buyer_name: e.target.value})}
                                           value={cart.buyer_name}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="buyerFirstName">{t('buy.buyer_firstname')}</label>
                                    <input type="text" id="buyerFirstName"
                                           className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, buyer_firstname: e.target.value})}
                                           value={cart.buyer_firstname}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="address1">{t('buy.address_1')}</label>
                                    <input type="text" id="address1" className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, address_1: e.target.value})}
                                           value={cart.address_1}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="address2">{t('buy.address_2')}</label>
                                    <input type="text" id="address2" className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, address_2: e.target.value})}
                                           value={cart.address_2}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="address3">{t('buy.address_3')}</label>
                                    <input type="text" id="address3" className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, address_3: e.target.value})}
                                           value={cart.address_3}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="postalCode">{t('buy.postal_code')}</label>
                                    <input type="text" id="postalCode"
                                           className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, postal_code: e.target.value})}
                                           value={cart.postal_code}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="city">{t('buy.city')}</label>
                                    <input type="text" id="city" className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, city: e.target.value})}
                                           value={cart.city}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="country">{t('buy.country')} (*)</label>
                                    <select
                                        id="country"
                                        className="w-full p-2 select select-bordered rounded"
                                        defaultValue=""
                                        value={cart.country}
                                        onChange={(e) => setCart({
                                            ...cart,
                                            country: e.target.value,
                                            country_code: COUNTRIES.filter(c => c.name === e.target.value)[0].code,
                                            vat: COUNTRIES.filter(c => c.name === e.target.value)[0].vat,
                                        })}
                                    >
                                        <option key="0" disabled></option>
                                        {COUNTRIES.map((country, index) => (
                                            <option key={index} value={country.name}>{country.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block"
                                           htmlFor="enterpriseLegalId">{t('buy.enterprise_legal_id')}</label>
                                    <input type="text" id="enterpriseLegalId"
                                           className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, enterprise_legal_id: e.target.value})}
                                           value={cart.enterprise_legal_id}/>
                                </div>
                                <div>
                                    <label className="block" htmlFor="vatNumber">{t('buy.vat_number')}</label>
                                    <input type="text" id="vatNumber"
                                           className="w-full p-2 input input-bordered rounded"
                                           onChange={(e) => setCart({...cart, vat_number: e.target.value})}
                                           value={cart.vat_number}/>
                                </div>
                            </div>
                            <div className="flex justify-end mt-6">
                                <input
                                    id="submit"
                                    type="submit"
                                    className="btn btn-primary w-full"
                                    value={t('action.order')}
                                    disabled={cart.company_name === undefined || cart.country === undefined || cart.country === ""}
                                />
                            </div>
                        </form>
                    </>
                }
                {
                    step === 2 &&
                    <div>
                        <div className="stats w-full lg:w-1/2 shadow mt-6 items-start">
                            <div className="stat">
                                <div className="stat-title text-right uppercase">{t('amount')}</div>
                                <div className="stat-value text-right">{cart.amountWithTax} € {t('after_tax')}</div>
                                <div className="stat-descy text-right">TVA: {cart.vat ? cart.vat * 100 : 0}%</div>
                            </div>
                            <div className="stat">
                                <div className="stat-title uppercase text-right">{t('items')}</div>
                                <div className="stat-value  text-right">{cart.volume}</div>
                            </div>
                        </div>
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm clientSecret={secret}/>
                        </Elements>
                    </div>
                }
            </div>
        </div>
    </div>

}
