import {Decarbonation} from "../../api/dto/Decarbonation";
import {useState} from "react";
import AuditSiteForm from "../audit/AuditSiteForm";
import {SiteAudit, SiteAuditStatus} from "../../api/dto/SiteAudit";
import DateFormat, {Format} from "../common/DateFormat";
import {useUpdateDecarbonation} from "../../api/decarbonationHooks";
import {useListSiteAudits} from "../../api/siteAuditHooks";
import {useTranslation} from "react-i18next";

interface Props {
    decarbonation: Decarbonation,
    className?: string
}

export default function SelectStartAudit(props: Props) {
    const decarbonation = props.decarbonation
    const {t} = useTranslation('decarbonation')
    const [startAuditId, setStartAuditId] = useState('')
    const {isLoading, error, data: audits} = useListSiteAudits(10, 0, {siteId: decarbonation.site.id, status: SiteAuditStatus.Done})

    const updateDecarbonation = useUpdateDecarbonation()
    const selectHandle = () => {
        if (startAuditId) {
            updateDecarbonation.mutate({
                decarbonationId: decarbonation.id,
                decarbonationUpdate: {start_site_audit_id: startAuditId}
            })
        }
    }

    const postRunAuditHandle = (siteAudit: SiteAudit) => {
        updateDecarbonation.mutate({
            decarbonationId: decarbonation.id,
            decarbonationUpdate: {start_site_audit_id: siteAudit.id}
        })
    }

    if (isLoading) return <>Loading...</>
    if (error || audits === undefined) return <>An error has occurred: {error}</>
    return <div className={props.className}>
        <div>
            <h2 className="mb-4">{t('startAudit.title')}</h2>
            <div className="flex flex-row gap-4 justify-start items-center mb-6">
                <select
                    onChange={(event) => setStartAuditId(event.target.value)}
                    className="select select-bordered select-primary w-full max-w-xs"
                >
                    <option value=''>-</option>
                    {
                        audits.results.map(audit =>
                            <option key={audit.id} value={audit.id}>
                                <DateFormat date={audit.creation_date} format={Format.NumericDateTime}/>
                                {" - " + audit.audited_pages_count + " pages - " + audit.webvert_score.toFixed(2) + " (" + audit.webvert_rating + ")"}
                            </option>
                        )
                    }
                </select>
                <button className="btn btn-primary" onClick={() => selectHandle()}>
                    {t('startAudit.select')}
                </button>
            </div>
        </div>
        <div>
            <h3>{t('startAudit.run')}</h3>
            <AuditSiteForm siteId={decarbonation.site.id} onSuccess={postRunAuditHandle}/>
        </div>
    </div>
}