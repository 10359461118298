import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AdminUserCreation, M2mUserCreation, User} from "./dto/User";
import auditApiClient from "./auditApiClient";
import {SignUpRequest} from "./dto/SignUpRequest";
import {LanguageUpdate} from "./dto/Language";

export const userKeys = {
    me: ['userInfo'] as const,
    all: ['users'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
    details: () => [...userKeys.all, 'detail'] as const,
    detail: (id: string) => [...userKeys.details(), id] as const,
}

export function useGetMe(enabled?: boolean) {
    return useQuery<User, Error>({
        queryKey: [userKeys.me],
        queryFn: () => auditApiClient.getUserInfo(),
        enabled: enabled
    })
}

export function useGetUser(userId: string) {
    return useQuery<User, Error>({
        queryKey: userKeys.detail(userId),
        queryFn: () => auditApiClient.getUser(userId)
    })
}

export function useGetUsers() {
    return useQuery<User[], Error>({
        queryKey: userKeys.lists(),
        queryFn: () => auditApiClient.listUsers()
    })
}

export function useSignUp(onSuccess?: () => void, onError?: (error: Error) => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (signUp: SignUpRequest) => auditApiClient.signUp(signUp),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.all})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: (error: Error) => {
            if (onError) {
                onError(error)
            }
        }
    })
}

export function useUpdateLanguage(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (language: LanguageUpdate) => auditApiClient.updateUserLanguage(language),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.lists()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useSuspendUser() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (params: { userId: string, suspend: boolean }) => auditApiClient.suspendUser(params.userId, params.suspend),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.lists()})
        }
    })
}

export function useCreateM2mUser(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (m2mUserCreation: M2mUserCreation) => auditApiClient.createM2mUser(m2mUserCreation),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.lists()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useCreateAdminUser(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (adminUserCreation: AdminUserCreation) => auditApiClient.createAdminUser(adminUserCreation),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: userKeys.lists()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}
