import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './index.css';
import 'react-notifications/lib/notifications.css';
import App from "./App";
import AppClient from "./AppClient";
import ROUTES from "./constants/routes";
import ROUTES_CLIENT from "./constants/routesClient";
import {AuthenticationGuard} from "./components/auth/AuthenticationGuard";
import {AutoAuth0Provider} from "./components/auth/AutoAuth0Provider";
import './lib/i18n/config';
import AppAnonymous from "./AppAnonymous";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import ROUTES_ANONYMOUS from "./constants/routesAnonymous";

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <AutoAuth0Provider>
            <QueryClientProvider client={queryClient}>
            <Routes>
                {Object.values(ROUTES_ANONYMOUS).map((route, index) => (
                    <Route key={index} path={route.path} element={<AppAnonymous />}>
                            <Route
                                path=""
                                element={<route.component/>}
                            />
                    </Route>
                ))}
                <Route path="/admin" element={<AuthenticationGuard component={App}/>}>
                    {Object.values(ROUTES).map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component/>}
                        />
                    ))}
                </Route>
                <Route path="/" element={<AuthenticationGuard component={AppClient}/>}>
                    {Object.values(ROUTES_CLIENT).map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={<route.component/>}
                        />
                    ))}
                </Route>
            </Routes>
            </QueryClientProvider>
        </AutoAuth0Provider>
    </BrowserRouter>
);
