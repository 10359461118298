import {Link} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";

export default function ClientMenu() {
    const {t} = useTranslation('layout')
    const {logout} = useAuth0();

    return <div className="dropdown dropdown-end">
        <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
            <div className="avatar placeholder">
                <div tabIndex={0} role="button" className="btn btn-outline  border-gray-200 btn-sm">
                    <span className="text-xs">⋮</span>
                </div>
            </div>
        </div>
        <ul tabIndex={0}
            className="menu lg:menu-sm dropdown-content z-[1] p-2 shadow bg-base-100 !fixed lg:!absolute top-18 lg:top-12 w-screen lg:w-52 gap-4 lg:gap-2 border-y-1 border-gray-500">
            <li>
                <Link to={ROUTES_CLIENT.CREDITS.path} className="text-neutral-content">
                    {t('menu.myaccount.credits')}
                </Link>
            </li>
            <li>
                <Link to={ROUTES_CLIENT.TEAMS.path} className="text-neutral-content">
                    {t('menu.myaccount.teams')}
                </Link>
            </li>
            <li>
                <Link to={ROUTES_CLIENT.INVOICES.path} className="text-neutral-content">
                    {t('menu.myaccount.invoices')}
                </Link>
            </li>
            <li>
                <Link to={ROUTES_CLIENT.SUPPORT.path} className="text-neutral-content">
                    {t('menu.myaccount.support')}
                </Link>
            </li>
            <li>
                <a onClick={() => logout({logoutParams: {returnTo: window.location.origin}})}
                   className="text-neutral-content"
                >
                    {t('auth:logout')}
                </a>
            </li>
        </ul>
    </div>

}