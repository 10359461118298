import {Link, useParams} from "react-router-dom";
import {useGetDecarbonation, usePublishDecarbonation, useUnpublishDecarbonation} from "../../api/decarbonationHooks";
import React, {useEffect, useState} from "react";
import BeforeAfterChart from "../../components/decarbonation/BeforeAfterChart";
import ViewMeasureDetails from "../../components/decarbonation/ViewMeasureDetails";
import WebvertScore from "../../components/audit/WebvertScore";
import ViewLabel from "../../components/label/ViewLabel";
import DateFormat, {Format} from "../../components/common/DateFormat";
import {Trans, useTranslation} from "react-i18next";
import ClientSiteDetailHeader from "../../components/common/ClientSiteDetailHeader";
import ROUTES_CLIENT from "../../constants/routesClient";
import WebvertRating from "../../components/common/WebvertRating";
import Recommendations from "../../components/decarbonation/Recommendations";
import ServiceDetails from "../../components/decarbonation/ServiceDetails";
import Download from "../../components/decarbonation/Download";
import ROUTES_ANONYMOUS from "../../constants/routesAnonymous";
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications";
import DecarbonationHeader from "../../components/decarbonation/DecarbonationHeader";
import DecarbonationSpeedEvolution from "../../components/decarbonation/DecarbonationSpeedEvolution";
import DecarbonationScoreEvolution from "../../components/decarbonation/DecarbonationScoreEvolution";


export default function ViewDecarbonation() {
    const {t} = useTranslation('decarbonation')
    let params = useParams()
    const decarbonationId = params.id!
    const {isLoading, error, data: decarbonation} = useGetDecarbonation(decarbonationId)

    const [publishDisable, setPublishDisable] = useState(false)
    const [published, setPublished] = useState(false)

    useEffect(() => {
        if (decarbonation && !publishDisable) {
            setPublished(decarbonation.is_published)
        }

    }, [decarbonation]);

    const publishMutation = usePublishDecarbonation(
        () => {
            setPublishDisable(false)
        }
    )
    const unpublishMutation = useUnpublishDecarbonation(
        () => {
            setPublishDisable(false)
        }
    )
    const onPublishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPublished(e.target.checked)
        setPublishDisable(true)

        if (e.target.checked) {
            publishMutation.mutate(decarbonation!.id)
        } else {
            unpublishMutation.mutate(decarbonation!.id)
        }
    }

    const handleCopyToClipboard = (token: string) => {
        let url = `${window.location.protocol}//${window.location.host}` + ROUTES_ANONYMOUS.PUBLISHED_DECARBONATION_VIEW.withParams(token)
        navigator.clipboard.writeText(url)
        NotificationManager.info(`Copied to clipboard.`, 'Copied', INFO_TIMEOUT)
    }

    if (isLoading) return <>Loading...</>
    if (error || !decarbonation) return <>An error has occurred.</>

    return <div>
        <ClientSiteDetailHeader siteName={decarbonation.site!.name}
                                backUrl={ROUTES_CLIENT.DECARBONATION_DASHBOARD.withParams(decarbonation!.site!.id)}
                                date={decarbonation.creation_date!} webvertRating={decarbonation.end_site_audit?.webvert_rating!}/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">

                <DecarbonationHeader decarbonation={decarbonation} />

                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <h3>{t('report.comparisonChart.title')}</h3>
                    <BeforeAfterChart decarbonation={decarbonation} className="bg-base-100 rounded-2xl mt-6 w-full"/>
                </div>


                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.scoreEvolution.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <DecarbonationScoreEvolution decarbonation={decarbonation} />
                    </div>
                </div>


                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.speedEvolution.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <DecarbonationSpeedEvolution decarbonation={decarbonation} />
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.serviceDetails.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <ServiceDetails decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.recommendations.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <Recommendations decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.csr.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <ViewMeasureDetails decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.label.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <ViewLabel labelId={decarbonation.site.label_id}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.download.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <Download decarbonation={decarbonation}/>
                    </div>
                </div>

                <div className="bg-white rounded rounded-lg w-full p-4 mb-8">
                    <h3>{t('report.publish.title')}</h3>
                    <div className="form-control flex flex-row items-center">
                        <label className="cursor-pointer label">
                            <span className="label-text">{t('report.publish.label')}</span>
                            <input
                                type="checkbox"
                                className="toggle toggle-secondary mx-2"
                                checked={published}
                                disabled={publishDisable}
                                onChange={onPublishChange}
                            />
                        </label>
                        {
                            published &&
                            <>
                                <div>
                                    <svg
                                        version="1.1"
                                        width="24"
                                        viewBox="0 0 36 36"
                                        preserveAspectRatio="xMidYMid meet"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        onClick={() => handleCopyToClipboard(decarbonation.published_token)}
                                        className="cursor-pointer"
                                    >
                                        <title>copy-to-clipboard-line</title>
                                        <path
                                            d="M22.6,4H21.55a3.89,3.89,0,0,0-7.31,0H13.4A2.41,2.41,0,0,0,11,6.4V10H25V6.4A2.41,2.41,0,0,0,22.6,4ZM23,8H13V6.25A.25.25,0,0,1,13.25,6h2.69l.12-1.11A1.24,1.24,0,0,1,16.61,4a2,2,0,0,1,3.15,1.18l.09.84h2.9a.25.25,0,0,1,.25.25Z"
                                            className="clr-i-outline clr-i-outline-path-1"></path>
                                        <path
                                            d="M33.25,18.06H21.33l2.84-2.83a1,1,0,1,0-1.42-1.42L17.5,19.06l5.25,5.25a1,1,0,0,0,.71.29,1,1,0,0,0,.71-1.7l-2.84-2.84H33.25a1,1,0,0,0,0-2Z"
                                            className="clr-i-outline clr-i-outline-path-2"></path>
                                        <path d="M29,16h2V6.68A1.66,1.66,0,0,0,29.35,5H27.08V7H29Z"
                                              className="clr-i-outline clr-i-outline-path-3"></path>
                                        <path
                                            d="M29,31H7V7H9V5H6.64A1.66,1.66,0,0,0,5,6.67V31.32A1.66,1.66,0,0,0,6.65,33H29.36A1.66,1.66,0,0,0,31,31.33V22.06H29Z"
                                            className="clr-i-outline clr-i-outline-path-4"></path>
                                        <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
                                    </svg>
                                </div>
                                <div className="font-light ml-2">
                                    <Link
                                        to={ROUTES_ANONYMOUS.PUBLISHED_DECARBONATION_VIEW.withParams(decarbonation.published_token)}
                                        className="link link-secondary"
                                        target="_blank"
                                        referrerPolicy="no-referrer"
                                    >
                                        {t('report.publish.link')}
                                    </Link>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}
