import React, {useEffect, useState} from 'react'
import {contains} from "../../utils/utils"
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications"
import ROUTES from "../../constants/routes";
import AuditStatus from "./AuditStatus";
import {Link, NavLink} from "react-router-dom";
import DownloadDiagnosticButton from "./DownloadDiagnosticButton";
import DownloadPresentationButton from "./DownloadPresentationButton";
import {SiteAudit} from "../../api/dto/SiteAudit";
import {useListSiteAudits} from "../../api/siteAuditHooks";
import {useTranslation} from "react-i18next";
import DateFormat, {Format} from "../common/DateFormat";
import InternalBadge from "../site/InternalBadge";

const siteAuditsLimit = 50

export default function ListAudits() {
    const {t} = useTranslation('audit')
    const [displayedAudits, setDisplayedAudits] = useState<SiteAudit[]>([])
    const [search, setSearch] = useState('')
    const {data: siteAudits, refetch: refreshLatestAudits} = useListSiteAudits(siteAuditsLimit, 0)

    useEffect(
        () => {
            if (!siteAudits) {
                return
            }
            if (search !== '') {
                const results = siteAudits.results.filter((audit) => {
                    return (contains(audit.site!.name, search) || contains(audit.site!.url, search) || (Array.isArray(audit.tags) && audit.tags.length ? audit.tags.includes(search) : false))
                })
                setDisplayedAudits(results)
            } else {
                setDisplayedAudits(siteAudits.results)
            }
        },
        [siteAudits, search]
    )

    const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }

    return (
        <div>
            <h2 className="mb-4">
                {t('list.latestTitle')}
                <button className="btn btn-ghost ml-1">
                    <img className="is-clickable"
                         onClick={() => refreshLatestAudits()}
                         alt="refresh"
                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABIElEQVR4nO3USUoDYRAF4E/BlRo33sBh6wDuRFTwVmKCXiKaIwhKtkoWuYORXMEhgsaFu5aGCrQi3b9DwEUeNHTDq/dq6Com+AMcoIU+XvGCHs6w9xvhVXSRVTzXWCnENVLEd/AUAnc4xDpmUcMGjnEfnAG2QjxLyXwkfo75Eu4i2sF9K1RVim5BfCqh2pmYSbFtpQMdtaUs8yJGbUkyaAUh7/lPxbOygH4Q1owJwzCY8w9Ri+Sex2WwGQa3ZaSvBtZINKgHv/kdg0ai+AIeImY/1aAXS1SFaVxGTKeKnH1a+3acg7LML4L7iKUUg3ocrkF856WfxBAXYsPz96NCW3Lx7YRqP/R8GVcJ57qTknkZdnGKm/jHhzGfZtVAJ5CCdywscWCgJHMnAAAAAElFTkSuQmCC"/>
                </button>
            </h2>
            <input
                type="search"
                value={search}
                onChange={handleSearchUpdate}
                className="input input-bordered w-full max-w-x"
                placeholder="Filter"
            />
            <div className="overflow-x-auto md:overflow-x-scroll">
                <table className="table-container w-full table-audit text-left mt-4" id="audits-list">
                    <thead>
                    <tr>
                        <th>{t('data.creationDate.label')}</th>
                        <th>{t('data.site.label')}</th>
                        <th>{t('data.tag.label')}</th>
                        <th>{t('data.pages.label')}</th>
                        <th>{t('data.scores.label')}</th>
                        <th>{t('data.quote.label')}</th>
                        <th>{t('data.status.label')}</th>
                        <th>{t('data.documents.label')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        displayedAudits.map(audit =>
                            <tr key={audit.id}>
                                <td>
                                    <DateFormat date={audit.creation_date} format={Format.NumericDateTime}/>
                                </td>
                                <td>
                                    <div>
                                        <Link to={ROUTES.SITE.withParams(audit.site!.id)} className="link">
                                            {audit.site?.name}
                                        </Link>
                                        <InternalBadge isInternal={audit.site!.is_internal}/>
                                    </div>
                                    {audit.site?.url}
                                </td>
                                <td>{!Array.isArray(audit.tags) ? "-" : audit.tags.join(",")}</td>

                                {audit.done_date ?
                                    <td className="text-right">
                                        <div className="flex flex-row justify-end">
                                            <div data-testid="found-pages-count">{audit.total_pages_count}</div>
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABPUlEQVR4nN3VPS8EURTG8R+hEIVkC6y3xNuG8AkUPoVK0IleCBuJciko1BK+CcpNdN4KUaHyGgq1THIkS4gZayPxTyZzc+bO89y555w7/FfaUMIxHuM6wTry1YpP4Bqr6K+I92Elnk1WI34WYk2YxQ52MYdm9OAIMz/Zliv0YhDnIT6NKWzjAsPowmXcU1OKbUkYwvgnc8YwGuMFbGQxSBI6kGF+R3xlah5Ql+UF3KIh7eSkFNXS4CSqJy3tkfTUrEedp2UeW1kM8lGmSZ1/RytuMCIjk9FE3d+IH+IAe8hlNZmJJlpEZ0W8J/rkHvsRK+MFxawmSYduRp0ngnc4xRoKsfJyGOVjnCV/72hE/YdYLlb+drK2VGvyGcUQTcRrZrLyhclyLU0KePpNg0qTQpwIyX/k11nCc63E/45X4/1DJLbRui4AAAAASUVORK5CYII="/>
                                        </div>
                                        <div className="flex flex-row justify-end">
                                            <div data-testid="audited-pages-count">{audit.audited_pages_count}</div>
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABjUlEQVR4nL2VzStEYRTGfxgjanyWhQ3ZUKzYMQtKjZWSlI2dLFhIFjQLkvKxUchXkiasrIxiMRJmoWTJzkZZ+iP01rN4Z9z3ztzMzFNP3Xvu6T73nOe+54A/7oGUB1+ALQKiDIgB0xY/HLndwENWbjSXQAJYB8YtvgNDHpwC7qy8CeASWHC9vAG49ohPApsODmTllgNpl0AbcMb/8ZiPQBXwrB7PF0MgAiSBTuBQsW3g2MF4UIEw8KQK5hRrAdodbA4qUBIP3pS8VCoPEo4TnbJyAnmQVhWzxaigJB6kS30OkmqZF48KcQ780AFsALfApz6wIB5EtS8uNGHNwJzRx+0DIZdABbCmiRnzEVgGBq1JOmI9uwHqXQIRDbsxy4O4NaZXFFtVFdVAl1raannW6BKoAb61UPYUMy/oFc02M1gEhoEe4BT4Un5Irat0CRjUqqdhnxb1A7vWr32i6yYZnoE6r2AeMFX2WffGvyuZ/gc7mu/2Is9F480PMKrWnivuhOmxvfTz5QHwquWfgV9rZneVGqZ3bAAAAABJRU5ErkJggg=="/>
                                        </div>
                                        <div className="flex flex-row justify-end">
                                            <div>{audit.error_pages_count}</div>
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABUklEQVR4nM3UsUocURjF8V80KhJW0UhIkYiIIqIgC+kMWJgmxb5A+qTQwsY8QKwELbQSzAMkVoIGbAKChWUgRSSQIkhifA2Z5RPGdXZnx8yCf/jgzrn3nsPM3Ptxz3iG0U6ZP8AJTmNcOm8i4CTGpdKP33iBKs7xqMyANeymnj/iQ1nmz3GJpyntSWhjZQTsYTVDf4/P/2s+j1/oy5jrxU8s3NW8C99QS2mfoq6pxZpkbWHe4WuDdhTVqL0tal7BH8y2ETCNCwwWCdjEVoaeFZCwjY12zSfiCD4uEDCEf5hqJ+ALlprMHTUJSFjGYZ75K/zAwzsEdOM7Xjcz78EZFnPuxXyL+cXwSLxusYJ9raliLmfNfnjdYDh+0mTO5uOoVozHIRlJiztYl8/LqDzWw7PODP5iQHlU4qLWP2fSDg6iNZRZB9etJmm7ySt1ohLvznIF0FNN/jBUHdgAAAAASUVORK5CYII="/>
                                        </div>
                                    </td> :
                                    <td className="text-right">
                                        <div className="tooltip tooltip-secondary before:max-w-none"
                                             data-tip={
                                                 audit.total_pages_count + " page(s) found - "
                                                 + audit.audited_pages_count + "/" + audit.settings.max_urls_to_audit + " page(s) audited - "
                                                 + audit.error_pages_count + " page(s) in error"
                                             }
                                        >{Math.round(100 * audit.audited_pages_count / (audit.total_pages_count < audit.settings.max_urls_to_audit && audit.total_pages_count > 0 ? audit.total_pages_count : audit.settings.max_urls_to_audit))}%
                                        </div>
                                    </td>
                                }
                                <td>
                                    {
                                        audit.done_date &&
                                        <div>
                                            <div className="flex flex-row">
                                                <img
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACMUlEQVR4nGNgGNbgfycD7/9Cxkn/Y5ke/fdm/PXfkeH/fyeG//99GX/+j2O8/7+Isf9/PQMXeYYXMPb892L8/d+W4T9e7Mv4638RYxvxBqcxsP5PZDpP0GBbJGzH8P9/NtMW4ixIZjpHkuG2DP//ezP+/l/PYkdcsKBrdmD4/z+B6cr/EtbU/5kMsv/rGcT+lzEl/k9hugiWAxlezWJD2PArDGz/K5jfoxjuxvD3fylTJE49FUyRRBkOVnyQNfz/Qfb//6ey/v/vCg3XMqYIojQTZwH7MrAFILyRDWT4AaoZDrbgAPtNuAVgzGKGTZ1k2aX/hLB90/ov2HzwGcWC/Qw85FqgXHni3wBYcIB6QWRat/MX3kg+uUf2f+sWi3PYLMAGwrtn3UK2ILhz7n0sPmAN+3OQ4/+8HXr/fdcH/Pdb7/9/2g6DKEKG182siJcpv4Dig/xJLRMwLbjCwNayxeKzz/qA/zAcvsHrLz5L+hbFRGtWHf6HbLhu7YHf3YuKubFqmLLNoBPZAhD2X+//v3Kz7bU52wyT1u5UElu6X01kxg79xJatltcCN/j9j53agub61m68Xm7aYnUG3RIfAjh5buF/uYqz/2N7p50iFKQMq/4zMDdutj5NigW+6wP+N6wIOZQ2M42VgVgwaatRR/wGj9+EDE/a6PZr2naDFgZywJT9WjyTthr2V2yyfRC/weNX0Hrf/8Hrff8lb3T7WbPZ+t6kbUa9q47JcJJl+JABAGALQHVa59MsAAAAAElFTkSuQmCC"/>
                                                <div data-testid="google-score">{audit.global_score_google}</div>
                                            </div>

                                            <div className="flex flex-row whitespace-nowrap">
                                                <div className="w-6">
                                                    <img
                                                        src="https://www.lewebvert.fr/assets/images/webvert-logo.svg"
                                                        alt="Webvert"
                                                    />
                                                </div>
                                                <span data-testid="dpe-score">{audit.webvert_score.toFixed(2)}</span>
                                                &nbsp;(<span data-testid="dpe-label">{audit.webvert_rating}</span>)
                                            </div>
                                        </div>
                                    }
                                </td>
                                <td>
                                    {audit.one_shot_price &&
                                        <div><span data-testid="price">{audit.one_shot_price}</span>€&nbsp;HT</div>}
                                </td>
                                <td data-testid="status">
                                    <AuditStatus
                                        audit={audit}
                                        cancelButton={true}
                                        afterCancelFunction={refreshLatestAudits}
                                    />
                                </td>
                                <td>
                                    {audit.done_date &&
                                        <div>
                                            <p>
                                                <NavLink to={ROUTES.AUDIT.withParams(audit.id)}
                                                         className="link"
                                                         data-testid="details"
                                                >
                                                    {t('list.actions.view')}
                                                </NavLink>
                                            </p>
                                            <p>
                                                <NavLink to={ROUTES.AUDIT_DETAILS.withParams(audit.id)}
                                                         className="link"
                                                         data-testid="fixes"
                                                >
                                                    {t('list.actions.fixes')}
                                                </NavLink>
                                            </p>
                                        </div>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
