import {useParams} from "react-router-dom";
import {useGetDecarbonation, useGetPublishedDecarbonation} from "../../api/decarbonationHooks";
import React from "react";
import BeforeAfterChart from "../../components/decarbonation/BeforeAfterChart";
import ViewMeasureDetails from "../../components/decarbonation/ViewMeasureDetails";
import WebvertScore from "../../components/audit/WebvertScore";
import DateFormat, {Format} from "../../components/common/DateFormat";
import {Trans, useTranslation} from "react-i18next";
import WebvertRating from "../../components/common/WebvertRating";
import Recommendations from "../../components/decarbonation/Recommendations";
import ServiceDetails from "../../components/decarbonation/ServiceDetails";
import DecarbonationScoreEvolution from "../../components/decarbonation/DecarbonationScoreEvolution";
import DecarbonationSpeedEvolution from "../../components/decarbonation/DecarbonationSpeedEvolution";
import DecarbonationHeader from "../../components/decarbonation/DecarbonationHeader";


export default function ViewPublishedDecarbonation() {
    const {t} = useTranslation('decarbonation')
    let params = useParams()
    const token = params.token!
    const {isLoading, error, data: decarbonation} = useGetPublishedDecarbonation(token)

    if (isLoading) return <>Loading...</>
    if (error || !decarbonation) return <>An error has occurred.</>

    return <div>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">

                <DecarbonationHeader decarbonation={decarbonation} />

                <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <h3>{t('report.comparisonChart.title')}</h3>
                    <BeforeAfterChart decarbonation={decarbonation} className="bg-base-100 rounded-2xl mt-6 w-full"/>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.scoreEvolution.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <DecarbonationScoreEvolution decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.speedEvolution.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <DecarbonationSpeedEvolution decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.serviceDetails.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <ServiceDetails decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.recommendations.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <Recommendations decarbonation={decarbonation}/>
                    </div>
                </div>

                <div tabIndex={0} className="collapse collapse-arrow bg-white rounded rounded-lg  w-full p-4 mb-8">
                    <input type="radio" name="my-accordion-2"/>
                    <div className="collapse-title">
                        <h3>{t('report.csr.title')}</h3>
                    </div>
                    <div className="collapse-content">
                        <ViewMeasureDetails decarbonation={decarbonation}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
