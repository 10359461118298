import React, {useMemo, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {Auth0Provider} from "@auth0/auth0-react";
import ROUTES_CLIENT from "../../constants/routesClient";

export function AutoAuth0Provider({children}) {
    const [config, setConfig] = useState({loaded: false})
    useMemo(() => {
            fetch("/auth-config")
                .then(response => response.json())
                .then(data => {
                    data.loaded = true;
                    setConfig(data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        []
    );
    const navigate = useNavigate();
    const onRedirectCallback = async (appState) => {
        navigate(ROUTES_CLIENT.POST_LOGIN.path);
    };

    if (!config.loaded) {
        return <></>
    }

    return <Auth0Provider
        domain={config.domain}
        clientId={config.spa_client_id}
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: config.audience,
            col: (new URLSearchParams(window.location.search)).get("co")
        }}
        onRedirectCallback={onRedirectCallback}
    >{children}</Auth0Provider>;
}
