import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../constants/routes";
import ROUTES_CLIENT from "../constants/routesClient";
import {useGetMe, useUpdateLanguage} from "../api/userHook";
import i18next from "i18next";

export default function PostLogin() {
    const navigate = useNavigate();

    const {data: user} = useGetMe()
    const updateLanguageMutation = useUpdateLanguage()

    useEffect(() => {
            if (!user) {
                return
            }

            updateLanguageMutation.mutate({language: i18next.language})
            if (user.is_admin) {
                navigate(ROUTES.SITES_LIST.path);
            } else {
                navigate(ROUTES_CLIENT.HOME.path);
            }
        },
        [user]
    );
    return <></>
}
