import React from "react";
import {User} from "../../api/dto/User";
import {useTranslation} from "react-i18next";

interface Props {
    user: User
}

export default function CreditsLeft(props: Props) {
    const {t} = useTranslation('credits')

    return <div className="rounded-lg bg-accent text-secondary-content px-4 py-4 flex items-center mb-6">
        <span className="mr-4">
            <svg width="16" height="16" fill="none"
                 xmlns="http://www.w3.org/2000/svg"><path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8 3.5c.3.3.3.7 0 1l-7.3 7.3c-.3.3-.7.3-1 0L2.2 8.5a.7.7 0 1 1 1-1L6 10.4l6.9-6.9c.2-.2.6-.2.9 0Z"
                fill="#ffffff"/></svg>
        </span>
        <span className="font-bold mr-2">
            {props.user?.credits}
        </span>
        {t('management.available', {s: (props.user!.credits > 0 ? "s" : "")})}
    </div>
}