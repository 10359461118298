import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import auditApiClient from "./auditApiClient";
import {AddTeamMember, CreateTeam, Team} from "./dto/Team";


export const teamKeys = {
    all: ['teams'] as const,
    lists: () => [...teamKeys.all, 'list'] as const,
    list: (siteId: string) => [...teamKeys.lists(), {siteId: siteId}] as const,
    details: () => [...teamKeys.all, 'detail'] as const,
    detail: (id: string) => [...teamKeys.details(), id] as const,
}


export function useGetTeams(siteId: string, enabled?: boolean) {
    return useQuery<Team[], Error>({
        queryKey: teamKeys.list(siteId),
        queryFn: () => auditApiClient.getTeams(siteId),
        enabled: enabled
    })
}

export function useGetTeam(id: string) {
    return useQuery<Team, Error>({
        queryKey: teamKeys.detail(id),
        queryFn: () => auditApiClient.getTeam(id),
    })
}

export function useCreateTeam(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (createTeam: CreateTeam) => auditApiClient.createTeam(createTeam),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useAddTeamSite(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { teamId: string, siteId: string }) => auditApiClient.addTeamSite(data.teamId, data.siteId),
        onSuccess: async (_, params: { teamId: string, siteId: string }) => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            await queryClient.invalidateQueries({queryKey: teamKeys.detail(params.teamId)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useAddTeamMember(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { teamId: string, addTeamMember: AddTeamMember }) => auditApiClient.addTeamMember(data.teamId, data.addTeamMember),
        onSuccess: async (_, params: { teamId: string, addTeamMember: AddTeamMember }) => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            await queryClient.invalidateQueries({queryKey: teamKeys.detail(params.teamId)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useUpdateTeamMember(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { teamId: string, memberId: string, addTeamMember: AddTeamMember }) =>
            auditApiClient.updateTeamMember(data.teamId, data.memberId, data.addTeamMember),
        onSuccess: async (_, params: { teamId: string, addTeamMember: AddTeamMember }) => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            await queryClient.invalidateQueries({queryKey: teamKeys.detail(params.teamId)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useDeleteTeamMember(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { teamId: string, memberId: string }) => auditApiClient.deleteTeamMember(data.teamId, data.memberId),
        onSuccess: async (_, params: { teamId: string, memberId: string }) => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            await queryClient.invalidateQueries({queryKey: teamKeys.detail(params.teamId)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useDeleteTeamSite(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { teamId: string, siteId: string }) => auditApiClient.deleteTeamSite(data.teamId, data.siteId),
        onSuccess: async (_, params: { teamId: string, siteId: string }) => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            await queryClient.invalidateQueries({queryKey: teamKeys.detail(params.teamId)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}

export function useTransferTeam(onSuccess?: () => void, onError?: () => void) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (data: { teamId: string, newAdminLogin: string }) => auditApiClient.transferTeam(data.teamId, data.newAdminLogin),
        onSuccess: async (_, params: { teamId: string, newAdminLogin: string }) => {
            await queryClient.invalidateQueries({queryKey: teamKeys.lists()})
            await queryClient.invalidateQueries({queryKey: teamKeys.detail(params.teamId)})
            if (onSuccess) {
                onSuccess()
            }
        },
        onError: () => {
            if (onError) {
                onError()
            }
        }
    })
}
