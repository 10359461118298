import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useGetSiteAudit } from "../../api/siteAuditHooks";
import ROUTES_CLIENT from "../../constants/routesClient";
import ClientSiteDetailHeader from "../../components/common/ClientSiteDetailHeader";
import RequestDecarbonationButton from "../../components/RequestDecarbonationButton";
import AuditWorstAssets from "../../components/audit/AuditWorstAssets";
import React from "react";

export default function ViewAuditFixes() {
    const {t} = useTranslation('assets')
    let params = useParams()
    const auditId = params.id!
    const {data: siteAudit} = useGetSiteAudit(auditId)

    if (!siteAudit) return <>An error has occurred.</>

    return siteAudit && <div>
        <ClientSiteDetailHeader siteName={siteAudit.site!.name} backUrl={ROUTES_CLIENT.AUDIT_VIEW.withParams(siteAudit!.id)} date={siteAudit.creation_date!} webvertRating={siteAudit.webvert_rating}/>
        <div className="w-full min-h-screen bg-base-200 pt-6">
            <div className="max-w-6xl mx-auto mx-4">
                <div className="flex flex-col justify-center">
                    <h2 className="text-xl text-center">{t('fixes.title')}</h2>
                    <p className="text-sm text-center">{t('service:cta_text_1')} </p>
                    <p className="text-sm text-center">{t('service:cta_text_2')} </p>
                    <RequestDecarbonationButton siteAudit={siteAudit} className="px-4"/>
                </div>
            </div>
            <div>
                <AuditWorstAssets siteAudit={siteAudit} />
            </div>
        </div>
    </div>
}
