
import {Link, useNavigate} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import {useTranslation} from "react-i18next";

import {useAuth0} from "@auth0/auth0-react";
import { useGetSite } from '../../api/siteHook';
import {Site} from "../../api/dto/Site";

interface Props {
    rating: string
    sizeCss?: string
    className?: string
}


export default function WebvertRating(props: Props) {
    return <span
        className={`bg-grade-${props.rating.toLowerCase()} text-white inline-flex items-center rounded-tl-lg rounded-br-lg  ${props.sizeCss?props.sizeCss:"px-4 py-3 h-10"} ${props.className}`}>
         {props.rating}
    </span>
}