import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AVAILABLE_LANGUAGES} from "../../lib/i18n/constants";

const Profile = () => {
    const {t, i18n} = useTranslation('auth')
    const { user, isAuthenticated, isLoading, logout } = useAuth0();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    if (isLoading) {
        return <div>{t('loadingProfile')} ...</div>;
    }

    return (
        isAuthenticated && (
            <div>
                <span>{user.email}</span>
                <button className="btn btn-xs btn-secondary mb-4" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    {t('logout')}
                </button>
                <div className="dropdown uppercase">
                    <label tabIndex="0" className="btn btn-secondary btn-xs ml-2">{i18n.language}</label>
                    <ul tabIndex="0" className="dropdown-content z-[1] menu bg-base-100 rounded-box">
                        {
                            AVAILABLE_LANGUAGES.map(language => <li key={language.key} onClick={() => changeLanguage(language.key)}><a>{language.key}</a></li>)
                        }
                    </ul>
                </div>
            </div>
        )
    );
};

export default Profile;
