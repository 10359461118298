import React from "react";
import fileSize from "filesize";
import DateFormat, {Format} from "../common/DateFormat";
import {Trans, useTranslation} from "react-i18next";
import MeasureLine from "./MeasureLine";
import Info from "../common/Info";
import {Decarbonation} from "../../api/dto/Decarbonation";

interface Props {
    decarbonation: Decarbonation
}

export default function ViewMeasureDetails(props: Props) {
    const {t} = useTranslation('decarbonation')
    const decarbonation = props.decarbonation

    if (!decarbonation.start_site_audit || !decarbonation.end_site_audit) return <>No data</>
    return <div className="mt-4">
        <div className="hidden md:block">
            <table className="table-container w-full">
                <thead>
                <tr>
                    <th>{t('measurementDetails.measurementType')}</th>
                    <th className="text-right"><DateFormat date={decarbonation.start_site_audit.creation_date}
                                                           format={Format.NumericDay}/></th>
                    <th className="text-right"><DateFormat date={decarbonation.end_site_audit.creation_date}
                                                           format={Format.NumericDay}/></th>
                    <th className="text-right">{t('measurementDetails.measurementDeviation')}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{t('measurementDetails.data.googleDesktop')}</td>
                    <td className="text-right">
                        <div>{decarbonation.start_site_audit.desktop_score_google}</div>
                    </td>
                    <td className="text-right">
                        <div>{decarbonation.end_site_audit.desktop_score_google}</div>
                    </td>
                    <td className="text-right">
                        <div>+/-4 pt</div>
                    </td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.googleMobile')}</td>
                    <td className="text-right">
                        <div>
                            {decarbonation.start_site_audit.mobile_score_google}
                        </div>
                    </td>
                    <td className="text-right">
                        <div>
                            {decarbonation.end_site_audit.mobile_score_google}
                        </div>
                    </td>
                    <td className="text-right">
                        <div>+/-4 pt</div>
                    </td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.loadingTime')}</td>
                    <td className="text-right">{(decarbonation.start_site_audit.total_pages_time / 1000).toFixed(0)}</td>
                    <td className="text-right">{(decarbonation.end_site_audit.total_pages_time / 1000).toFixed(0)}</td>
                    <td className="text-right">
                        +/-10 %
                    </td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.energeticPerformance')}</td>
                    <td className="text-right">
                        {decarbonation.start_site_audit.webvert_rating}
                        &nbsp;
                        {decarbonation.start_site_audit.webvert_score.toFixed(2)}
                    </td>
                    <td className="text-right">
                        {decarbonation.end_site_audit.webvert_rating}
                        &nbsp;
                        {decarbonation.end_site_audit.webvert_score.toFixed(2)}
                    </td>
                    <td className="text-right">-</td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.bandwidth')}</td>
                    <td className="text-right">{fileSize(decarbonation.start_site_audit.total_page_size, {
                        base: 2,
                        exponent: 2
                    })}</td>
                    <td className="text-right">{fileSize(decarbonation.end_site_audit.total_page_size, {
                        base: 2,
                        exponent: 2
                    })}</td>
                    <td className="text-right">-</td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.bandwidthSavings')}</td>
                    <td className="text-right">{fileSize(decarbonation.start_site_audit.deduplicated_bandwidth_savings, {
                        base: 2,
                        exponent: 2
                    })}</td>
                    <td className="text-right">{fileSize(decarbonation.end_site_audit.deduplicated_bandwidth_savings, {
                        base: 2,
                        exponent: 2
                    })}</td>
                    <td className="text-right">-</td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.co2Eq')}</td>
                    <td className="text-right">{decarbonation.start_site_audit.total_eq_co2.toFixed(0)}</td>
                    <td className="text-right">{decarbonation.end_site_audit.total_eq_co2.toFixed(0)}</td>
                    <td className="text-right">-</td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.co2EqSavings')}</td>
                    <td className="text-right">{decarbonation.start_site_audit.eq_co2_savings.toFixed(0)}</td>
                    <td className="text-right">{decarbonation.end_site_audit.eq_co2_savings.toFixed(0)}</td>
                    <td className="text-right">-</td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.driveEq')}</td>
                    <td className="text-right">{decarbonation.start_site_audit.total_car_km.toFixed(0)}</td>
                    <td className="text-right">{decarbonation.end_site_audit.total_car_km.toFixed(0)}</td>
                    <td className="text-right">-</td>
                </tr>
                <tr>
                    <td>{t('measurementDetails.data.driveEqSavings')}</td>
                    <td className="text-right">{decarbonation.start_site_audit.savings_car_km.toFixed(0)}</td>
                    <td className="text-right">{decarbonation.end_site_audit.savings_car_km.toFixed(0)}</td>
                    <td className="text-right">-</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div className="block md:hidden">
            <MeasureLine label={t('measurementDetails.data.googleDesktop')}
                         before={decarbonation.start_site_audit.desktop_score_google.toString()}
                         after={decarbonation.end_site_audit.desktop_score_google.toString()} deviation={"+/-4 pt"}/>
            <MeasureLine label={t('measurementDetails.data.googleMobile')}
                         before={decarbonation.start_site_audit.mobile_score_google.toString()}
                         after={decarbonation.end_site_audit.mobile_score_google.toString()} deviation={"+/-4 pt"}/>
            <MeasureLine label={t('measurementDetails.data.loadingTime')}
                         before={(decarbonation.start_site_audit.total_pages_time / 1000).toFixed(0).toString() + "s"}
                         after={(decarbonation.end_site_audit.total_pages_time / 1000).toFixed(0).toString() + "s"}
                         deviation={"+/-10 %"}/>
            <MeasureLine label={t('measurementDetails.data.energeticPerformance')}
                         before={decarbonation.start_site_audit.webvert_rating
                             + " / " +
                             decarbonation.start_site_audit.webvert_score.toFixed(2)}
                         after={decarbonation.end_site_audit.webvert_rating
                             + " / " +
                             decarbonation.end_site_audit.webvert_score.toFixed(2)}/>
            <MeasureLine label={t('measurementDetails.data.bandwidth')}
                         before={fileSize(decarbonation.start_site_audit.total_page_size, {
                             base: 2,
                             exponent: 2
                         })} after={fileSize(decarbonation.end_site_audit.total_page_size, {
                base: 2,
                exponent: 2
            })}/>
            <MeasureLine label={t('measurementDetails.data.bandwidthSavings')}
                         before={fileSize(decarbonation.start_site_audit.deduplicated_bandwidth_savings, {
                             base: 2,
                             exponent: 2
                         })}
                         after={fileSize(decarbonation.end_site_audit.deduplicated_bandwidth_savings, {
                             base: 2,
                             exponent: 2
                         })}/>
            <MeasureLine label={t('measurementDetails.data.co2Eq')}
                         before={decarbonation.start_site_audit.total_eq_co2.toFixed(0).toString() + "kg"}
                         after={decarbonation.end_site_audit.total_eq_co2.toFixed(0).toString() + "kg"}/>
            <MeasureLine label={t('measurementDetails.data.co2EqSavings')}
                         before={decarbonation.start_site_audit.eq_co2_savings.toFixed(0).toString() + "kg"}
                         after={decarbonation.end_site_audit.eq_co2_savings.toFixed(0).toString() + "kg"}/>
            <MeasureLine label={t('measurementDetails.data.driveEq')}
                         before={decarbonation.start_site_audit.total_car_km.toFixed(0).toString() + "km"}
                         after={decarbonation.end_site_audit.total_car_km.toFixed(0).toString() + "km"}/>
            <MeasureLine label={t('measurementDetails.data.driveEqSavings')}
                         before={decarbonation.start_site_audit.savings_car_km.toFixed(0).toString() + "km"}
                         after={decarbonation.end_site_audit.savings_car_km.toFixed(0).toString() + "km"}/>
        </div>
        <Info><Trans t={t} i18nKey="measurementDetails.notice" components={{a: <a/>}}/></Info>
    </div>
}