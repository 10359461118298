import React, {useEffect, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {useUpdateTeamMember} from "../../api/teamHook";
import {TeamMember} from "../../api/dto/Team";

interface Props {
    teamId: string
    teamMember: TeamMember
    onSuccess?: () => void
}

export default function UpdateMember(props: Props) {
    const teamId = props.teamId
    const teamMember = props.teamMember
    const onSuccess = props.onSuccess

    const {t} = useTranslation('team')

    const [updateMemberForm, setUpdateMemberForm] = useReducer(
        (prevState: any, updatedValues: any) => {
            return {...prevState, ...updatedValues}
        },
        {
            site_permission: teamMember.site_permission,
            audit_permission: teamMember.audit_permission,
            use_credits_permission: teamMember.use_credits_permission,
        }
    )
    useEffect(() => {
        setUpdateMemberForm({
            site_permission: teamMember.site_permission,
            audit_permission: teamMember.audit_permission,
            use_credits_permission: teamMember.use_credits_permission,
        })
    }, [teamMember]);
    const handleUpdateMemberForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateMemberForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }

    const updateTeamMemberMutation = useUpdateTeamMember(
        () => {
            if (onSuccess) {
                onSuccess()
            }
        }
    )
    const handleUpdateMemberSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        updateTeamMemberMutation.mutate({teamId: teamId, memberId: teamMember.id, addTeamMember: updateMemberForm})
    }

    return <form onSubmit={handleUpdateMemberSubmit}>
        <div className="form-control">
            {t('members.data.permissions.label')}
            <label className="label cursor-pointer justify-start">
                <input
                    name="site_permission"
                    className="checkbox"
                    type="checkbox"
                    checked={updateMemberForm.site_permission}
                    onChange={handleUpdateMemberForm}
                />
                <span className="label-text ml-2">{t('members.data.permissions.site')}</span>
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    name="audit_permission"
                    className="checkbox"
                    type="checkbox"
                    checked={updateMemberForm.audit_permission}
                    onChange={handleUpdateMemberForm}
                />
                <span className="label-text ml-2">{t('members.data.permissions.audit')}</span>
            </label>
            <label className="label cursor-pointer justify-start">
                <input
                    name="use_credits_permission"
                    className="checkbox"
                    type="checkbox"
                    checked={updateMemberForm.use_credits_permission}
                    onChange={handleUpdateMemberForm}
                />
                <span className="label-text ml-2">{t('members.data.permissions.useCredit')}</span>
            </label>
        </div>
        <input
            id="submit"
            type="submit"
            className="btn btn-primary btn-wide"
            value={updateTeamMemberMutation.isLoading ? "..." : t('members.actions.edit.submit')}
            disabled={updateTeamMemberMutation.isLoading}
        />
    </form>
}