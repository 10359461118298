import {useGetSite} from "../../api/siteHook";
import DateFormat, {Format} from "../../components/common/DateFormat";
import {useListSiteAudits} from "../../api/siteAuditHooks";
import {Trans, useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Pagination from "../../components/pagination";
import {Link, useParams} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import WebvertScore from "../../components/audit/WebvertScore";
import ClientSiteHeader from "../../components/common/ClientSiteHeader";
import RequestDecarbonationButton from "../../components/RequestDecarbonationButton";
import FillBottom from "../../components/common/FillBottom";
import {SiteAudit} from "../../api/dto/SiteAudit";
import WebvertRating from "../../components/common/WebvertRating";
import {useClientDashboard} from "../../api/decarbonationHooks";
import {canRunDiagnostic} from "../../utils/utils";
import {useGetMe} from "../../api/userHook";
import {useGetTeam, useGetTeams} from "../../api/teamHook";

const pageSize = 10

export default function AuditDashboard() {

    let params = useParams()

    const {t} = useTranslation('site')
    const currentSiteId = params.id!
    const {data: site} = useGetSite(currentSiteId!, !!currentSiteId)

    const userQuery = useGetMe()
    const user = userQuery.data

    const teamsQuery = useGetTeams(currentSiteId ? currentSiteId : '')
    const teams = teamsQuery.data

    const [page, setPage] = useState<number>(1)
    const {data: audits} = useListSiteAudits(pageSize, (page - 1) * 10, {siteId: site?.id, enabled: !!site?.id})
    const {data: dashboard} = useClientDashboard(currentSiteId!, !!currentSiteId)

    const [lastAudit, setLastAudit] = useState<SiteAudit>()

    useEffect(() => {
        setPage(1)
    }, [currentSiteId]);

    useEffect(() => {
        if (audits) {
            var fallback = true
            for (var i = 0; i < audits.results.length; i++) {
                if (audits.results[i].done_date) {
                    setLastAudit(audits.results[i])
                    fallback = false
                    break
                }
            }
            if (fallback && audits?.results[0]) {
                setLastAudit(audits?.results[0])
            }
        }
    }, [audits]);


    function getStatus(audit: SiteAudit): JSX.Element {
        if (audit.cancel_date) {
            return <div
                className="bg-warning bg-opacity-20 border border-warning mt-4 rounded rounded-lg p-2">
                <p className="text-sm flex items-center text-warning">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none"
                         viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
                    </svg>
                    <strong className="mx-2  ">{t("auditsDashboard.status.canceled")}</strong>
                </p>
            </div>
        } else if (audit.error_date) {
            return <div
                className="bg-error bg-opacity-20 border border-error mt-4 rounded rounded-lg p-2">
                <p className="text-sm flex items-center text-error">
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none"
                         viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                    <strong className="mx-2 text-error-content">{t("auditsDashboard.status.error")}</strong>
                </p>
                <p className="pt-1 text-xs text-error-content">{audit.error_message}</p>
            </div>
        } else if (audit.aggregation_date) {
            return <div
                className="bg-info bg-opacity-20 border border-info mt-4 rounded rounded-lg p-2">
                <p className="text-sm flex items-center text-info">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         className="stroke-info shrink-0 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <strong className="mx-2 ">{t("auditsDashboard.status.aggregation")}</strong>
                </p>
            </div>
        } else if (audit.audit_date) {
            return <div
                className="bg-info bg-opacity-20 border border-info mt-4 rounded rounded-lg p-2">
                <p className="text-sm flex items-center text-info">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         className="stroke-info shrink-0 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <strong className="mx-2 ">{t("auditsDashboard.status.audit")} {Math.round(100 * audit.audited_pages_count/(audit.total_pages_count<audit.settings.max_urls_to_audit&&audit.total_pages_count>0?audit.total_pages_count:audit.settings.max_urls_to_audit))}%</strong>
                </p>
            </div>
        } else if (audit.crawl_date) {
            return <div
                className="bg-info bg-opacity-20 border border-info mt-4 rounded rounded-lg p-2">
                <p className="text-sm flex items-center text-info">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         className="stroke-info shrink-0 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <strong className="mx-2 ">{t("auditsDashboard.status.crawl")}</strong>
                </p>
            </div>
        } else {
            return <div
                className="bg-primary-100 border border-primary-200 mt-4 rounded rounded-lg p-2 text-primary-800">
                <p className="text-sm flex items-center">
                                                <span><svg width="16" height="16" fill="none"
                                                           xmlns="http://www.w3.org/2000/svg"><path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M13.8 3.5c.3.3.3.7 0 1l-7.3 7.3c-.3.3-.7.3-1 0L2.2 8.5a.7.7 0 1 1 1-1L6 10.4l6.9-6.9c.2-.2.6-.2.9 0Z"
                                                    fill="#71B029"/></svg></span>
                    <strong className="mx-2">{t("auditsDashboard.status.launch")}</strong>
                </p>
            </div>
        }
    }

    return <>
        <ClientSiteHeader backUrl={ROUTES_CLIENT.HOME.path} siteName={site?.name!} siteUrl={site?.url!} siteFavicon={site?.favicon_url!}/>
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <h2 className="text-3xl mb-6 max-w-6xl mx-auto">{t('auditsDashboard.title')}</h2>
            {dashboard && dashboard.decarbonations?.length > 0 &&
                <>
                    <Link className="mb-6 w-full btn btn-outline btn-secondary  btn-sm"
                          to={ROUTES_CLIENT.DECARBONATION_DASHBOARD.withParams(currentSiteId)}>{t('actions.gotoDecarbonations')}</Link>
                </>
            }
            <div className="max-w-6xl mx-auto">
                {
                    site && (lastAudit) &&
                    <div className="bg-white rounded rounded-lg  w-full p-4 mb-8">
                        <div className="flex justify-between">
                            <h2 className="text-xl">{t('decarbonationDashboard.webvertScore')}</h2>
                            {lastAudit?.done_date && <Link className="text-neutral-focus"
                                                           to={ROUTES_CLIENT.AUDIT_VIEW.withParams(lastAudit.id)}>{t("auditsDashboard.action.seeDiagnostic")}</Link>}
                        </div>
                        <div className="relative w-full my-2 h-88 py-4">
                            <WebvertScore score={lastAudit!.webvert_score} rating={lastAudit!.webvert_rating}
                                          className={"" + (!lastAudit?.done_date && "blur filter w-4/5 h-4/5 mx-auto mt-0")}
                                          aria-hidden={!lastAudit?.done_date}/>
                            {!lastAudit?.done_date &&
                                <div
                                    className="text-center absolute -top-2 left-0 w-full bg-white bg-opacity-80 py-24 h-full">
                                    <p className="font-bold text-lg ">{t("auditsDashboard.waiting.title")}</p>
                                    <p className="font-light text-neutral-focus">{t("auditsDashboard.waiting.description")}</p>
                                </div>}
                        </div>
                        {lastAudit?.done_date &&
                            <>
                                <h3 className="text-lg">{t("auditsDashboard.cta.title")}</h3>
                                <div
                                    className="collapse collapse-arrow bg-primary-100 border border-primary-200 mt-4  text-primary-800">
                                    <input type="radio" name="my-accordion-2"/>
                                    <div className="collapse-title text-md  text-primary-800">
                                        <p>
                                            <strong>{Math.round(lastAudit.ms_savings_percent)}%</strong> {t("auditsDashboard.cta.speedTitle")}
                                        </p>
                                    </div>
                                    <div className="collapse-content text-sm">
                                        <p>{t("auditsDashboard.cta.speedDescription")}</p>
                                    </div>
                                </div>
                                <div
                                    className="collapse collapse-arrow bg-primary-100 border border-primary-200 mt-4  text-primary-800">
                                    <input type="radio" name="my-accordion-2"/>
                                    <div className="collapse-title text-base text-primary-800">
                                        <p>
                                            <strong>{Math.round(lastAudit.bandwidth_savings_percent)}%</strong> {t("auditsDashboard.cta.co2Title")}
                                        </p>
                                    </div>
                                    <div className="collapse-content text-sm">
                                        <p>{Math.round(lastAudit.eq_co2_savings)} {t("auditsDashboard.cta.co2Description1")} {Math.round(lastAudit.savings_car_km)} {t("auditsDashboard.cta.co2Description2")}</p>
                                    </div>
                                </div>
                                <RequestDecarbonationButton siteAudit={lastAudit} className="w-full mt-4"/>
                            </>
                        }
                    </div>
                }

                <div className="flex justify-between items-center mb-8">
                    <h2 className="text-2xl">{t('auditsDashboard.auditHistory')}</h2>
                    {site && audits && !(audits.results.length === 0) && user && teams && canRunDiagnostic(currentSiteId, user, teams) &&
                        <div className="text-center bg-base-100 rounded rounded-xl my-4">
                            <Link to={ROUTES_CLIENT.CREATE_AUDIT.withParams(currentSiteId)}
                                  className="btn btn-sm btn-outline">{t('auditsDashboard.action.addDiagnostic')}</Link>
                            <p className="success-content text-xs my-1">{t('auditsDashboard.action.addDiagnosticDesc')}</p>
                        </div>}
                </div>

                {site && audits && audits.results.length === 0 && user && teams &&  canRunDiagnostic(currentSiteId, user, teams) &&
                    <>
                        <div className="bg-base-300 rounded rounded-lg flex flex-col p-4">
                            <p>{t('auditsDashboard.noDiagnostic.description')}</p>
                            <div className="text-center bg-primary-200 rounded rounded-xl my-4 pb-1">
                                <Link to={ROUTES_CLIENT.CREATE_AUDIT.withParams(currentSiteId)}
                                      className="btn btn-lg btn-primary w-full">{t('auditsDashboard.action.addDiagnostic')}</Link>
                                <p className="success-content text-xs my-2">{t('auditsDashboard.action.addDiagnosticDesc')}</p>
                            </div>
                            <p className="text-sm text-neutral-focus"><Trans t={t} i18nKey="auditsDashboard.noDiagnostic.warning" components={{a: <a/>}}/></p>
                        </div>
                        <FillBottom/>
                    </>
                }
                {site && audits && audits.results.length > 0 &&
                    <div className="bg-base-100 rounded rounded-lg flex flex-col mb-8">
                        {audits?.results?.map((audit) =>
                            <div className="border-b border-gray-200 w-full last:border-b-0" key={audit.id}>
                                <Link to={audit.done_date ? ROUTES_CLIENT.AUDIT_VIEW.withParams(audit.id) : '#'}
                                      className={"p-4 flex flex-col justify-start " + (!audit.done_date && "cursor-not-allowed")}>
                                    <div className="flex justify-between">
                                            <span className="font-bold text-neutral-content">
                                                <DateFormat date={audit.creation_date} format={Format.TextualDay}/>
                                            </span>
                                        <span className="text-neutral-focus ml-4 text-sm font-light">
                                                <DateFormat date={audit.creation_date} format={Format.Time}/>
                                            </span>
                                    </div>
                                    {audit.done_date ? <div className="flex flex-row justify-between items-center">
                                        <div>
                                            <WebvertRating rating={audit.webvert_rating} className="mr-4"/>
                                            <span className="text-neutral-focus">
                                                    {audit.webvert_score.toFixed(2)} {t('audit:data.webvertScore.kwhPage')}
                                                </span>
                                        </div>
                                        <span className="text-2xl text-neutral-focus font-thin">→</span>
                                    </div> : getStatus(audit)
                                    }
                                </Link>
                            </div>
                        )}
                        <div className="p-4 flex items-center justify-center border-b border-gray-200 last:border-b-0">
                            <div className="flex flex-col items-center mt-2">
                                <Pagination currentPage={page} pageSize={pageSize} totalCount={audits.total_count}
                                            onClick={(i) => setPage(i)}/>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </div>
    </>
}