import React, {useReducer} from "react";
import {DEFAULT_MAX_URLS} from "../../constants/audit";
import {NotificationManager} from 'react-notifications';
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications"
import {SiteAudit} from "../../api/dto/SiteAudit";
import {useCreateSiteAudit} from "../../api/siteAuditHooks";
import {useTranslation} from "react-i18next";

interface Props {
    siteId: string
    onSuccess?: (siteAudit: SiteAudit) => void
}

export default function AuditSiteForm(props: Props) {
    const { t } = useTranslation('audit');

    const [form, setForm] = useReducer(
        (prevState: any, updatedValues: any) => {
            return {...prevState, ...updatedValues}
        },
        {
            audit_tag: "",
            max_urls: DEFAULT_MAX_URLS,
            use_webvert_user_agent: false,
        }
    )
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }

    const createSiteAuditMutation = useCreateSiteAudit(
        data => {
            NotificationManager.success(t('new.feedbacks.success.message'), t('new.feedbacks.success.title'), SUCCESS_TIMEOUT)
            if (props.onSuccess) {
                props.onSuccess(data)
            }
        },
        () => NotificationManager.error(t('new.feedbacks.error.message'), t('new.feedbacks.error.title'), ERROR_TIMEOUT)
    )

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        let parsedMaxUrls = parseInt(form.max_urls)
        let request = {
            site_id: props.siteId,
            audit_tag: form.audit_tag,
            max_urls: isNaN(parsedMaxUrls) ? DEFAULT_MAX_URLS : parsedMaxUrls,
            use_webvert_user_agent: form.use_webvert_user_agent,
        }
        createSiteAuditMutation.mutate(request)
        event.preventDefault()
    }

    return (
        <div className="mt-4">
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label htmlFor="audit_tag" className="label">{t('data.tag.label')}</label>
                    <input
                        id="audit_tag"
                        name="audit_tag"
                        className="input input-bordered w-full max-w-x"
                        type="text"
                        placeholder={t('data.tag.placeHolder')}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control mb-6">
                    <label htmlFor="max_urls" className="label">{t('data.maxUrl.label')}</label>
                    <input
                        id="max_urls"
                        name="max_urls"
                        className="input input-bordered w-full max-w-x"
                        type="number"
                        value={form.max_urls}
                        min="1"
                        max="5000"
                        placeholder={t('data.maxUrl.placeHolder')}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-control mb-6">
                    <label htmlFor="use_webvert_user_agent" className="label cursor-pointer justify-start">
                        <input
                            id="use_webvert_user_agent"
                            name="use_webvert_user_agent"
                            className="checkbox"
                            type="checkbox"
                            onChange={handleChange}
                        />
                        <span className="label-text ml-2">{t('data.userAgent.label')}</span>
                    </label>
                </div>
                <input
                    id="submit"
                    type="submit"
                    className="btn btn-primary btn-wide"
                    value={createSiteAuditMutation.isLoading ? "..." : t('new.actions.submit')}
                    disabled={createSiteAuditMutation.isLoading}
                />
            </form>
        </div>
    )

}