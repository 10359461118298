import React, {ChangeEvent, FormEvent, useEffect, useReducer, useState} from "react";
import ClientHeader from "../../components/common/ClientHeader";
import {useTranslation} from "react-i18next";
import {Site, SiteCreation} from "../../api/dto/Site";
import {useCreateSite, useGetExternalSiteInformation} from "../../api/siteHook";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import {Link, useNavigate} from "react-router-dom";
import ROUTES_CLIENT from "../../constants/routesClient";
import ClientSiteHeader from "../../components/common/ClientSiteHeader";
import ClientModalHeader from "../../components/common/ClientModalHeader";
import btn from "../../assets/images/signup_btn_pic.svg";
import {isValidUrl} from "../../utils/utils";
import {ExternalSiteInfo} from "../../api/dto/ExternalSiteInfo";
import {COUNTRIES} from "../../constants/audit";
import SiteNameAndOptions from "../../components/site/SiteNameAndOptions";


export default function CreateSite() {
    const {t} = useTranslation('site')
    const [step, setStep] = useState(0)

    const [form , setForm] = useReducer(
        (prevState: SiteCreation, updatedValues: Partial<SiteCreation>) => {
            return {...prevState, ...updatedValues}
        },
        {
            name: '',
            favicon_url: '',
            url: '',
            url_filter: '',
            user: '',
            password: '',
        }
    )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
        });
    }

    const navigate = useNavigate()
    const createSiteMutation = useCreateSite(
        (site: Site) => {
            NotificationManager.success(t('create.feedbacks.success.message'), t('create.feedbacks.success.title'), SUCCESS_TIMEOUT)
            navigate(ROUTES_CLIENT.AUDITS_DASHBOARD.withParams(site.id))
        },
        () => NotificationManager.error(t('create.feedbacks.error.message'), t('create.feedbacks.error.title'), ERROR_TIMEOUT)
    )
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (step===1) {
            createSiteMutation.mutate(form)
        }
    }

    const {data: externalSiteInfo } = useGetExternalSiteInformation(form.url, step===1,(siteInfo:ExternalSiteInfo) => {
        setForm({...form, name: siteInfo.name, favicon_url: siteInfo.favicon_url })
    })

    const next = () => {
        if (isValidUrl(form.url)) {
            setStep(step + 1)
        }
    }

    return <div>
        { step===0?<ClientModalHeader/>:<ClientSiteHeader backUrl="-1" title={t('create.title')} siteName={form.name} siteUrl={form.url} siteFavicon={form.favicon_url} />}
        <div className="w-full min-h-screen bg-base-200 py-6 px-4">
            <div className="max-w-6xl mx-auto">
                    <form onSubmit={handleSubmit}>
                    {step === 0 &&
                        <div className="bg-white rounded rounded-lg  w-full px-4 pt-4 pb-0 mb-8">
                            <div className="form-control">
                                <h1 className="text-xl mb-2 mx-0">{t('create.title')}</h1>
                                <p className="text-sm text-neutral-focus">{t('create.description')}</p>
                                <label htmlFor="site_url" className="label justify-start">{t('data.url.label')} <span
                                    className="text-error">&nbsp;*</span></label>
                                <input
                                    id="site_url"
                                    name="url"
                                    className="input input-bordered w-full max-w-x"
                                    type="url"
                                    placeholder={t('data.url.placeHolder')}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="relative inline-block mt-4">
                                    <button type="button" className="btn btn-primary mt-4 mb-1 w-full" onClick={next}>
                                        <img src={btn} alt=""
                                             className="absolute -left-2 top-1/3 transform -translate-y-1/3"
                                             aria-hidden="true"/>
                                        {t('user:signup.next')}
                                    </button>
                                </div>
                            </div>
                        </div>
                            }
                            {
                                step === 1 &&
                                <>
                                    <SiteNameAndOptions handleChange={handleChange} name={form.name} basicAuthLogin={form.user} basicAuthPassword={form.password} urlFilter={form.url_filter} />
                                    <input
                                        id="submit"
                                        type="submit"
                                        className="btn btn-primary btn-wide w-full my-4"
                                        value={t('create.submit')}
                                        disabled={createSiteMutation.isLoading}
                                    />
                                </>
                            }
                    </form>
            </div>
        </div>
    </div>
}
