class MarketingApi {
    async servicing(email: string, siteUrl: string, phone: string, comment: string, choice: string): Promise<void> {
        const response = await fetch(
            "https://api-web.lewebvert.fr/servicing",
            {
                method: "POST",
                body: JSON.stringify(
                    {
                        email: email,
                        url: siteUrl,
                        phone: phone,
                        comment: comment,
                        newsletter: false,
                        origin: window.location.origin,
                        type: "saas-" + choice
                    }
                )
            }
        )
        if (!response.ok) {
            throw new Error('Network response was not ok')
        }
    }
}

const marketingApiClient = new MarketingApi()

export default marketingApiClient;