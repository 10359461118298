import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import ROUTES from "../../../constants/routes";
import SiteOverView from "../../../components/site/SiteOverView";
import {useCreateDecarbonation} from "../../../api/decarbonationHooks";
import {useListSites} from "../../../api/siteHook";
import {useTranslation} from "react-i18next";

export default function CreateDecarbonation() {
    const {t} = useTranslation('decarbonation')
    const navigate = useNavigate()

    const [search, setSearch] = useState('')
    const [selectedSiteId, setSelectedSiteId] = useState<string>('')
    const {data: sites} = useListSites(search, search.length >= 3)

    const createMutation = useCreateDecarbonation(data => {
        navigate(ROUTES.DECARBONATION_VIEW.withParams(data.id))
    })

    return <div className="py-4 px-8">
        <h2>{t('create.title')}</h2>

        <div>
            <label htmlFor="search" className="label">{t('create.search.label')}</label>
            <input
                type="search"
                value={search}
                placeholder={t('create.search.placeHolder')}
                onChange={(event) => setSearch(event.target.value)}
                className="input input-bordered w-full max-w-x"
            />
        </div>

        {sites && sites.length > 0 &&
            <>
                <div className="flex flex-row">
                    <table className="table-container basis-1/2 w-full" id="sites-list">
                        <thead>
                        <tr>
                            <th>{t('site:data.name.label')}</th>
                            <th>{t('site:data.url.label')}</th>
                            <th>{t('create.actions.select')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            sites.map(site =>
                                <tr key={site.id}>
                                    <td>{site.name}</td>
                                    <td>{site.url}</td>
                                    <td>
                                        <input
                                            type="radio"
                                            name="site"
                                            value={site.id}
                                            onChange={(event) => setSelectedSiteId(event.target.value)}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                    <div className="basis-1/2 w-full ml-4">
                        {
                            selectedSiteId ?
                                <>
                                    <SiteOverView siteId={selectedSiteId}/>
                                    <div className="flex flex-row gap-x-4">
                                        <button className="btn btn-primary flex-grow" onClick={() => createMutation.mutate(selectedSiteId)}>
                                            {t('create.actions.schedule')}
                                        </button>
                                    </div>
                                </> :
                                <>{t('create.selectSite')}</>
                        }
                    </div>
                </div>
            </>
        }
    </div>
}
