import DateFormat, {Format} from "../common/DateFormat";
import ModalV2 from "../common/ModalV2";
import TransferTeam from "./TransferTeam";
import ListMembersOfTeam from "./ListMembersOfTeam";
import AddMemberToTeam from "./AddMemberToTeam";
import ListSitesOfTeam from "./ListSitesOfTeam";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetMe} from "../../api/userHook";
import {useGetTeam} from "../../api/teamHook";
import AddSiteToTeam from "./AddSiteToTeam";
import {TeamMember} from "../../api/dto/Team";

interface Props {
    teamId: string
}

export default function ViewTeam(props: Props) {
    const teamId = props.teamId

    const {t} = useTranslation('team')

    const userQuery = useGetMe()
    const user = userQuery.data

    const teamQuery = useGetTeam(teamId)
    const team = teamQuery.data

    const [membership, setMembership] = useState<TeamMember>()
    useEffect(() => {
        if (team && user) {
            const memberships = team.members.filter(e => e.login === user.login)
            if (memberships.length > 0) {
                setMembership(memberships[0])
            }
        }
    }, [team, user]);

    const [transferModalIsOpened, setTransferModalIsOpened] = useState(false)
    const [addMemberModalIsOpened, setAddMemberModalIsOpened] = useState(false)
    const [addSiteModalIsOpened, setAddSiteModalIsOpened] = useState(false)

    if (teamQuery.error || userQuery.error) {
        return <></>
    }
    if (teamQuery.isLoading || !team || userQuery.isLoading || !user) {
        return <></>
    }

    return <>
        <h2>{team.name}</h2>
        <div><strong>{t('data.creationDate.label')}:</strong> <DateFormat date={team.creation_date} format={Format.NumericDateTime}/></div>
        <div>
            <strong>{t('data.admin.label')}:</strong> {team.admin_login}

            {
                team.admin_login === user.login &&
                <button
                    className="btn btn-primary btn-sm ml-4"
                    onClick={() => setTransferModalIsOpened(true)}
                >
                    {t('actions.transfer.submit')}
                </button>
            }
        </div>

        <ModalV2 id="transfer-team" visible={transferModalIsOpened} onClose={() => setTransferModalIsOpened(false)}>
            <TransferTeam teamId={teamId} onSuccess={() => setTransferModalIsOpened(false)}/>
        </ModalV2>

        <h3 className="mt-8">
            {t('members.title')}
            {
                (user.is_admin || team.admin_login === user.login) &&
                <button className="btn btn-primary btn-sm ml-2" onClick={() => setAddMemberModalIsOpened(true)}>
                    {t('members.actions.add.submit')}
                </button>
            }
        </h3>
        <ListMembersOfTeam teamId={teamId}/>

        <ModalV2 id="add-member-modal" visible={addMemberModalIsOpened} onClose={() => setAddMemberModalIsOpened(false)}>
            <>
                <h3 className="mt-4">{t('members.actions.add.title')}</h3>
                <AddMemberToTeam teamId={teamId} onSuccess={() => setAddMemberModalIsOpened(false)}/>
            </>
        </ModalV2>

        <h3 className="mt-8">
            {t('sites.title')}
            {
                (user.is_admin || team.admin_login === user.login || membership?.site_permission) &&
                <button className="btn btn-primary btn-sm ml-2" onClick={() => setAddSiteModalIsOpened(true)}>
                    {t('sites.actions.add.submit')}
                </button>
            }
        </h3>
        <ListSitesOfTeam teamId={teamId}/>

        <ModalV2 id="add-member-modal" visible={addSiteModalIsOpened} onClose={() => setAddSiteModalIsOpened(false)}>
            <>
                <h3 className="mt-4">{t('sites.actions.add.title')}</h3>
                <AddSiteToTeam teamId={teamId} onSuccess={() => setAddSiteModalIsOpened(false)}/>
            </>
        </ModalV2>
    </>
}