import AuditStatus from "../audit/AuditStatus";
import React from "react";
import {useQueryClient} from "@tanstack/react-query";
import {Decarbonation} from "../../api/dto/Decarbonation";
import fileSize from "filesize";
import SelectEndAudit from "./SelectEndAudit";
import {decarbonationKeys} from "../../api/decarbonationHooks";
import ROUTES from "../../constants/routes";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {INFO_TIMEOUT} from "../../constants/notifications";

interface Props {
    decarbonation: Decarbonation,
    className?: string
}

export default function CompareAudits(props: Props) {
    const decarbonation = props.decarbonation
    const startAudit = props.decarbonation.start_site_audit!
    const endAudit = props.decarbonation.end_site_audit!
    const queryClient = useQueryClient()

    const {t} = useTranslation('decarbonation')

    const handleCopyToClipboard = (src: string, value: string) => {
        navigator.clipboard.writeText(value)
        NotificationManager.info(`The "${src}" has been copied to clipboard.`, 'Copied', INFO_TIMEOUT)
    }

    return <div className={props.className}>
        <div className="flex flex-row gap-6">
            <div className="w-1/2">
                <h2 className="mb-4">{t('data.startAudit.label')}</h2>
                <div className="text-right mb-4">
                    <Link to={ROUTES.AUDIT.withParams(startAudit.id)} className="mr-4">{t('actions.see')}</Link>
                    <Link to={ROUTES.AUDIT_DETAILS.withParams(startAudit.id)}>{t('actions.assetsDetails')}</Link>
                </div>
                <table className="table-data mb-4">
                    <tr>
                        <th>{t('data.status.label')}</th>
                        <td>
                            <AuditStatus
                                audit={startAudit}
                                cancelButton={true}
                                afterCancelFunction={() => queryClient.invalidateQueries({queryKey: decarbonationKeys.detail(decarbonation.id)})}
                            />
                        </td>
                    </tr>
                    {
                        startAudit.done_date &&
                        <>
                            <tr>
                                <th>{t('audit:data.id.label')}</th>
                                <td className="text-right">
                                    <img className="inline"
                                         alt="Copy audit ID"
                                         onClick={() => handleCopyToClipboard("Audit id", startAudit.id)}
                                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApElEQVRIie2USw6DMAxEXxG9VAV7UE/WzymBHYeABUkXwVYSSGkqMZIVyXY8tuIM/BhX4A2Mxl5AmZLgCUyOPfYUvAODUPQGVIK/B9oYgl4oMpnitRLrYgjspWT5RQz7FpwEXoR+GvchL6HxbCawcDt3/auVzWYCrXNv3uFrOphT0hzJYNGvYLTogqcJXRNDoCFUBFd52WyRhe3O95M/+PoE/48ZQ4BEKq9FRS8AAAAASUVORK5CYII="/>
                                    <span className="uuid">{startAudit.id}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('data.auditedPageNumber.label')}</th>
                                <td>{startAudit.audited_pages_count}</td>
                            </tr>
                            <tr>
                                <th>{t('data.webvertScore.label')}</th>
                                <td>{startAudit.webvert_rating} - {startAudit.webvert_score.toFixed(2)} kWh/page</td>
                            </tr>
                            <tr>
                                <th>{t('data.siteSize.label')}</th>
                                <td>{fileSize(startAudit.total_page_size)}</td>
                            </tr>
                            <tr>
                                <th>{t('data.googleScore.desktopLabel')}</th>
                                <td>{startAudit.desktop_score_google}</td>
                            </tr>
                            <tr>
                                <th>{t('data.googleScore.mobileLabel')}</th>
                                <td>{startAudit.mobile_score_google}</td>
                            </tr>
                        </>
                    }
                </table>
            </div>
            <div className="w-1/2">
                <h2 className="mb-4">{t('data.endAudit.label')}</h2>
                {endAudit &&
                    <>
                        <div className="text-right mb-4">
                            <Link to={ROUTES.AUDIT.withParams(endAudit.id)} className="mr-4">{t('actions.see')}</Link>
                            <Link to={ROUTES.AUDIT_DETAILS.withParams(endAudit.id)}>{t('actions.assetsDetails')}</Link>
                        </div>
                        <table className="table-data mb-4">
                            <tr>
                                <th>{t('data.status.label')}</th>
                                <td>
                                    <AuditStatus
                                        audit={endAudit}
                                        cancelButton={true}
                                        afterCancelFunction={() => queryClient.invalidateQueries({queryKey: decarbonationKeys.detail(decarbonation.id)})}
                                    />
                                </td>
                            </tr>
                            {endAudit.done_date &&
                                <>
                                    <th>{t('audit:data.id.label')}</th>
                                    <td className="text-right">
                                        <img className="inline"
                                             alt="Copy audit ID"
                                             onClick={() => handleCopyToClipboard("Audit id", endAudit.id)}
                                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApElEQVRIie2USw6DMAxEXxG9VAV7UE/WzymBHYeABUkXwVYSSGkqMZIVyXY8tuIM/BhX4A2Mxl5AmZLgCUyOPfYUvAODUPQGVIK/B9oYgl4oMpnitRLrYgjspWT5RQz7FpwEXoR+GvchL6HxbCawcDt3/auVzWYCrXNv3uFrOphT0hzJYNGvYLTogqcJXRNDoCFUBFd52WyRhe3O95M/+PoE/48ZQ4BEKq9FRS8AAAAASUVORK5CYII="/>
                                        <span className="uuid">{endAudit.id}</span>
                                    </td>
                                    <tr>
                                        <th>{t('data.auditedPageNumber.label')}</th>
                                        <td>{endAudit.audited_pages_count}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('data.webvertScore.label')}</th>
                                        <td>{endAudit.webvert_rating} - {endAudit.webvert_score.toFixed(2)} {t('data.webvertScore.kwhPage')}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('data.siteSize.label')}</th>
                                        <td>{fileSize(endAudit.total_page_size)}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('data.googleScore.desktopLabel')}</th>
                                        <td>{endAudit.desktop_score_google}</td>
                                    </tr>
                                    <tr>
                                        <th>{t('data.googleScore.mobileLabel')}</th>
                                        <td>{endAudit.mobile_score_google}</td>
                                    </tr>
                                </>
                            }
                        </table>
                    </>
                }
                {
                    startAudit.done_date && !props.decarbonation.end_date &&
                    <SelectEndAudit
                        decarbonation={props.decarbonation}
                        className="flex flex-row gap-4 justify-start items-center"
                    />
                }
            </div>
        </div>
    </div>
}