import {CREDIT_VALUE} from "../../constants/audit";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SiteAudit, SiteAuditDetailsByService} from "../../api/dto/SiteAudit";
import {useGetWorstAssets} from "../../api/siteAuditHooks";

interface Props {
    siteAudit: SiteAudit
}

interface PriceDetails {
    name: string
    amount: number
    count: number
    impactSustainability: number
    impactPerformance: number

}

export default function QuoteDetails(props: Props) {

    const {t} = useTranslation('credits')

    const siteAudit=props.siteAudit
    const [quoteDetails, setQuoteDetails] = useState<PriceDetails[]>([])

    useEffect(() => {
        let details: PriceDetails[]= []

        console.log(siteAudit.details_by_service)
        if (siteAudit && siteAudit.details_by_service) {
        siteAudit.details_by_service.forEach((detail: SiteAuditDetailsByService) => {
            const impact_percent:number=detail.bandwidth_saving_deduplicated/siteAudit.deduplicated_bandwidth_savings
            let impact_eco=0
            let impact_perf=0
            if (impact_percent>0.05) {
                 if (impact_percent < 0.30) {
                    impact_eco = 2
                } else {
                    impact_eco = 3
                }
                const impact_perf_percent:number=detail.loading_time_saving/siteAudit.ms_savings_percent
                if (impact_perf_percent < 0.05) {
                    impact_perf = 1
                } else if (impact_perf_percent < 0.30) {
                    impact_perf = 2
                } else {
                    impact_perf = 3
                }
                switch (detail.service_code) {
                    case "OPTIMIZE_IMAGES":
                        details.push({
                            amount: 500 * Math.ceil(detail.count_assets_to_fix / 500),
                            impactPerformance: impact_perf,
                            impactSustainability: impact_eco,
                            count: detail.count_assets_to_fix,
                            name: t('audit:report.distributionChart.images')
                        })
                        break;
                    case "OPTIMIZE_GZIP":
                        details.push({
                            amount: 300,
                            impactPerformance: impact_perf,
                            impactSustainability: impact_eco,
                            count: detail.count_assets_to_fix,
                            name: t('audit:report.distributionChart.compression')
                        })
                        break;
                    case "OPTIMIZE_CACHE":
                        details.push({
                            amount: 300,
                            impactPerformance: impact_perf,
                            impactSustainability: impact_eco,
                            count: detail.count_assets_to_fix,
                            name: t('audit:report.distributionChart.cache')
                        })
                        break;
                    case "OPTIMIZE_CSS":
                        details.push({
                            amount: 300,
                            impactPerformance: impact_perf,
                            impactSustainability: impact_eco,
                            count: detail.count_assets_to_fix,
                            name: t('audit:report.distributionChart.css')
                        })
                        break;
                    case "OPTIMIZE_JS":
                        details.push({
                            amount: 300,
                            impactPerformance: impact_perf,
                            impactSustainability: impact_eco,
                            count: detail.count_assets_to_fix,
                            name: t('audit:report.distributionChart.js')
                        })
                        break;
                }
            }
        })

        setQuoteDetails(details)
        }
    }, [siteAudit]);

    const impact = (score:number,emoji:string) :string => {
        let out=""
        for(let i = 0; i < score; i++) {
            out+=emoji
        }
        return out
    }


return <div>
    {siteAudit.total_pages_count <= 25 ?
        <p className="mt-4">{t('pricing.small_website')}</p>
        : <div className="overflow-x-auto md:overflow-x-scroll">
            <table className="table table-zebra w-full mt-4">
                <thead>
                <tr>
                    <th>{t('pricing.service')}</th>
                    <th>{t('pricing.impact_perf')}</th>
                    <th>{t('pricing.impact_sus')}</th>
                    <th>{t('pricing.count')}</th>
                    <th>{t('pricing.amount')}</th>
                </tr>
                </thead>
                <tbody>
                {quoteDetails.map(detail =>
                    <tr>
                        <td>{detail.name}</td>
                        <td>{impact(detail.impactPerformance, "🏃‍♀️‍➡️")}</td>
                        <td>{impact(detail.impactSustainability, "🌱")}</td>
                        <td className="text-right">{detail.count}</td>
                        <td className="text-right"><span
                            className="font-bold">{detail.amount}</span> € {t('before_tax')}</td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
            }
        </div>

    }