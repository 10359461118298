import React from "react";
import {useTranslation} from "react-i18next";


interface Props {
    isInternal: boolean
    className?: string
}

export default function InternalBadge(props: Props) {
    const {t} = useTranslation('site')
    return <>
        {
            props.isInternal &&
            <span className={"badge badge-info ml-1 tooltip " + props.className} data-tip={t('data.isInternal.internal.tooltip')}>
                {t('data.isInternal.internal.label')}
            </span>
        }
        {
            !props.isInternal &&
            <span className={"badge badge-warning ml-1 tooltip " + props.className}  data-tip={t('data.isInternal.customer.tooltip')}>
                {t('data.isInternal.customer.label')}
            </span>
        }
    </>
}