import {useParams} from "react-router-dom";
import React from "react";
import ListCredits from "../../../components/credit/ListCredits";
import {useTranslation} from "react-i18next";
import {useGetUser} from "../../../api/userHook";
import AddCreditsForm from "../../../components/credit/AddCreditsForm";

export default function ListTransactionsPage() {
    const {t} = useTranslation('credits')
    let params = useParams()
    const userId = params.id!
    const userQuery = useGetUser(userId)
    const user = userQuery.data

    if (!user) {
        return <></>
    }

    return <div className="py-4 px-8">
        <h2>{t('actions.add.title')}</h2>
        <AddCreditsForm userId={userId}/>

        <h2 className="mt-6">
            {t('list.title', {login: user.login})}
            {
                user.notes &&
                <span> ({user.notes})</span>
            }
        </h2>
        <ListCredits userId={userId}></ListCredits>
    </div>
}
