import {Component, useEffect} from "react"
import * as d3 from "d3"
import fileSize from "filesize";
import {Decarbonation} from "../../api/dto/Decarbonation";
import {useTranslation} from "react-i18next";

type BeforeAfterChartProps = {
    decarbonation: Decarbonation,
    className: string
}

export default function BeforeAfterChart(props: BeforeAfterChartProps) {
    const {t} = useTranslation('decarbonation')

    let svgRef: SVGSVGElement
    let parentRef: HTMLDivElement

    useEffect(() => {
        const svg = d3.select(svgRef)
        svg.selectAll("*").remove()
        drawChart();
    }, [props.decarbonation]);


    const drawChart = () => {
        const svg = d3.select(svgRef)
        const mobile = (parentRef.offsetWidth<600)
        const margin = {top: 34, right: 60, bottom: 30, left: 60}
        if (mobile) {
            margin.left=10
            margin.right=10
        }
        const width = parentRef.offsetWidth - margin.left - margin.right
        const height = 510 - margin.top - margin.bottom


        svg
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // Titles
        svg.append("text")
            .text(t('report.comparisonChart.speedGain'))
            .attr("x", margin.left + width / 6)
            .attr("y", margin.top)
            .style("text-anchor", "middle")
            .attr("class", () => (mobile?"chart-subtitle-mobile":"chart-subtitle"));

        svg.append("text")
            .text(t('report.comparisonChart.seoGain'))
            .attr("x", margin.left + width / 2)
            .attr("y", margin.top)
            .style("text-anchor", "middle")
            .attr("class", () => (mobile?"chart-subtitle-mobile":"chart-subtitle"))

        svg.append("text")
            .text(t('report.comparisonChart.ecologicalGain'))
            .attr("x", margin.left + 5 * width / 6)
            .attr("y", margin.top)
            .style("text-anchor", "middle")
            .attr("class", () => (mobile?"chart-subtitle-mobile":"chart-subtitle"));

        const bar_size = 340;

        // Time Bars

        var time_b = props.decarbonation.start_site_audit ? props.decarbonation.start_site_audit.total_pages_time : 0
        var time_a = props.decarbonation.end_site_audit ? props.decarbonation.end_site_audit.total_pages_time : 0

        var tb_y = 0;
        var ta_y = 0;
        if (time_b > time_a) {
            tb_y = 0;
            ta_y = (1 - (time_a / time_b)) * bar_size;
        }

        if (time_a > time_b) {
            tb_y = (1 - (time_b / time_a)) * bar_size;
            ta_y = 0;
        }

        svg.append("rect")
            .attr("fill", "#607BBC")
            .attr("stroke-width", 2)
            .attr("stroke", "#304988")
            .attr("x", margin.left + width / 6 - width / 12 + width / 48)
            .attr("y", margin.top + 50 + tb_y)
            .attr("width", width / 24)
            .attr("height", bar_size - tb_y);

        svg.append("text")
            .text(Math.round(time_b / 1000) + " s")
            .attr("x", margin.left + width / 6 - width / 12 + 2 * width / 48)
            .attr("y", margin.top + 50 + tb_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-before");

        svg.append("rect")
            .attr("fill", "#A7E950")
            .attr("stroke-width", 2)
            .attr("stroke", "#8CD935")
            .attr("x", margin.left + width / 6 + width / 48)
            .attr("y", margin.top + 50 + ta_y)
            .attr("width", width / 24)
            .attr("height", bar_size - ta_y);

        svg.append("text")
            .text(Math.round(time_a / 1000) + " s")
            .attr("x", margin.left + width / 6 + 2 * width / 48)
            .attr("y", margin.top + 50 + ta_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-after");


        // Google Page Speed Bars

        var desktop_b = props.decarbonation.start_site_audit ? props.decarbonation.start_site_audit.desktop_score_google : 0
        var desktop_a = props.decarbonation.end_site_audit ? props.decarbonation.end_site_audit.desktop_score_google : 0

        var db_y = (1 - desktop_b / 100) * bar_size;
        var da_y = (1 - desktop_a / 100) * bar_size;
        ;

        svg.append("rect")
            .attr("fill", "#607BBC")
            .attr("stroke-width", 2)
            .attr("stroke", "#304988")
            .attr("x", margin.left + width / 2 - width / 12 + width / 96)
            .attr("y", margin.top + 50 + db_y)
            .attr("width", width / 48)
            .attr("height", bar_size - db_y);

        svg.append("text")
            .text(Math.round(desktop_b))
            .attr("x", margin.left + width / 2 - width / 12 + 2 * width / 96 - (mobile?4:0))
            .attr("y", margin.top + 50 + db_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-before");

        svg.append("svg:image")
            .attr("x", margin.left + width / 2 - width / 12 + 1.5 * width / 96)
            .attr("y", margin.top + 50 + bar_size - ((width / 96) * 32 / 35 + 4))
            .attr("width", width / 96)
            .attr("height", (width / 96) * 32 / 35)
            .attr("xlink:href", "/images/icons/desktop-light.png")

        svg.append("rect")
            .attr("fill", "#A7E950")
            .attr("stroke-width", 2)
            .attr("stroke", "#8CD935")
            .attr("x", margin.left + width / 2 - width / 24)
            .attr("y", margin.top + 50 + da_y)
            .attr("width", width / 48)
            .attr("height", bar_size - da_y);

        svg.append("text")
            .text(Math.round(desktop_a))
            .attr("x", margin.left + width / 2 - width / 24 + width / 96 + (mobile?4:0))
            .attr("y", margin.top + 50 + da_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-after");

        svg.append("svg:image")
            .attr("x", margin.left + width / 2 - width / 24 + 0.5 * width / 96)
            .attr("y", margin.top + 50 + bar_size - ((width / 96) * 32 / 35 + 4))
            .attr("width", width / 96)
            .attr("height", (width / 96) * 32 / 35)
            .attr("xlink:href", "/images/icons/desktop-dark.png")

        var mobile_b = props.decarbonation.start_site_audit ? props.decarbonation.start_site_audit.mobile_score_google : 0
        var mobile_a = props.decarbonation.end_site_audit ? props.decarbonation.end_site_audit.mobile_score_google : 0

        var mb_y = (1 - mobile_b / 100) * bar_size;
        var ma_y = (1 - mobile_a / 100) * bar_size;
        ;

        svg.append("rect")
            .attr("fill", "#607BBC")
            .attr("stroke-width", 2)
            .attr("stroke", "#304988")
            .attr("x", margin.left + width / 2 + width / 48)
            .attr("y", margin.top + 50 + mb_y)
            .attr("width", width / 48)
            .attr("height", bar_size - mb_y);

        svg.append("text")
            .text(Math.round(mobile_b))
            .attr("x", margin.left + width / 2 + width / 48 + width / 96 - (mobile?4:0))
            .attr("y", margin.top + 50 + mb_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-before");

        svg.append("svg:image")
            .attr("x", margin.left + width / 2 + width / 48 + 0.5 * width / 96)
            .attr("y", margin.top + 50 + bar_size - ((width / 96) + 4))
            .attr("width", width / 96)
            .attr("height", width / 96)
            .attr("xlink:href", "/images/icons/mobile-light.png")

        svg.append("rect")
            .attr("fill", "#A7E950")
            .attr("stroke-width", 2)
            .attr("stroke", "#8CD935")
            .attr("x", margin.left + width / 2 + width / 24 + width / 96)
            .attr("y", margin.top + 50 + ma_y)
            .attr("width", width / 48)
            .attr("height", bar_size - ma_y);

        svg.append("text")
            .text(Math.round(mobile_a))
            .attr("x", margin.left + width / 2 + width / 24 + 2 * width / 96 + (mobile?4:0))
            .attr("y", margin.top + 50 + ma_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-after");

        svg.append("svg:image")
            .attr("x", margin.left + width / 2 + width / 24 + 1.5 * width / 96)
            .attr("y", margin.top + 50 + bar_size - ((width / 96) + 4))
            .attr("width", width / 96)
            .attr("height", width / 96)
            .attr("xlink:href", "/images/icons/mobile-dark.png")

        // Bandwidth Bars

        var bandwidth_b = props.decarbonation.start_site_audit ? props.decarbonation.start_site_audit.total_page_size : 0
        var bandwidth_a = props.decarbonation.end_site_audit ? props.decarbonation.end_site_audit.total_page_size : 0

        var bb_y = 0;
        var ba_y = 0;
        if (bandwidth_b > bandwidth_a) {
            bb_y = 0;
            ba_y = (1 - (bandwidth_a / bandwidth_b)) * bar_size;
        }

        if (bandwidth_a > bandwidth_b) {
            bb_y = (1 - (bandwidth_b / bandwidth_a)) * bar_size;
            ba_y = 0;
        }

        svg.append("rect")
            .attr("fill", "#607BBC")
            .attr("stroke-width", 2)
            .attr("stroke", "#304988")
            .attr("x", margin.left + 5 * width / 6 - width / 12 + width / 48)
            .attr("y", margin.top + 50 + bb_y)
            .attr("width", width / 24)
            .attr("height", bar_size - bb_y);

        svg.append("text")
            .text(fileSize(bandwidth_b, {base: 2}))
            .attr("x", margin.left + 5 * width / 6 - width / 12 + 2 * width / 48)
            .attr("y", margin.top + 50 + bb_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-before");

        svg.append("rect")
            .attr("fill", "#A7E950")
            .attr("stroke-width", 2)
            .attr("stroke", "#8CD935")
            .attr("x", margin.left + 5 * width / 6 + width / 48)
            .attr("y", margin.top + 50 + ba_y)
            .attr("width", width / 24)
            .attr("height", bar_size - ba_y);

        svg.append("text")
            .text(fileSize(bandwidth_a, {base: 2}))
            .attr("x", margin.left + 5 * width / 6 + 2 * width / 48)
            .attr("y", margin.top + 50 + ba_y - 10)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral-after");

        // X axis
        svg.append("text")
            .text(t('report.comparisonChart.loadingTime'))
            .attr("x", margin.left + width / 6)
            .attr("y", margin.top + height - 30)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral");

        svg.append("line")
            .style("stroke", "#F1F5F9")
            .style("stroke-width", 2)
            .attr("x1", margin.left + width / 6 - width / 12)
            .attr("y1", margin.top + height - 46)
            .attr("x2", margin.left + width / 6 + width / 12)
            .attr("y2", margin.top + height - 46);

        svg.append("text")
            .text(t('report.comparisonChart.googlePerformanceScore'))
            .attr("x", margin.left + width / 2)
            .attr("y", margin.top + height - 30 + (mobile?14:0))
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral");

        svg.append("line")
            .style("stroke", "#F1F5F9")
            .style("stroke-width", 2)
            .attr("x1", margin.left + width / 2 - width / 12)
            .attr("y1", margin.top + height - 46)
            .attr("x2", margin.left + width / 2 + width / 12)
            .attr("y2", margin.top + height - 46);

        svg.append("text")
            .text(t('report.comparisonChart.bandwidth'))
            .attr("x", margin.left + 5 * width / 6)
            .attr("y", margin.top + height - 30)
            .style("text-anchor", "middle")
            .attr("class", () => "chart-legend-neutral");

        svg.append("line")
            .style("stroke", "#F1F5F9")
            .style("stroke-width", 2)
            .attr("x1", margin.left + 5 * width / 6 - width / 12)
            .attr("y1", margin.top + height - 46)
            .attr("x2", margin.left + 5 * width / 6 + width / 12)
            .attr("y2", margin.top + height - 46);


        // Legend
        svg.append("rect")
            .attr("fill", "#607BBC")
            .attr("stroke-width", 2)
            .attr("stroke", "#304988")
            .attr("x", margin.left)
            .attr("y", margin.top + height - 9)
            .attr("width", 10)
            .attr("height", 10);

        svg.append("text")
            .text(t('report.comparisonChart.before'))
            .attr("x", margin.left + 16)
            .attr("y", margin.top + height)
            .attr("class", () => "chart-legend-neutral");

        svg.append("rect")
            .attr("fill", "#A7E950")
            .attr("stroke-width", 2)
            .attr("stroke", "#8CD935")
            .attr("x", margin.left + 60)
            .attr("y", margin.top + height - 9)
            .attr("width", 10)
            .attr("height", 10);

        svg.append("text")
            .text(t('report.comparisonChart.after'))
            .attr("x", margin.left + 76)
            .attr("y", margin.top + height)
            .attr("class", () => "chart-legend-neutral");


    }

    return <div ref={(r: HTMLDivElement) => parentRef = r} className={props.className}>
        <svg ref={(r: SVGSVGElement) => svgRef = r}></svg>
    </div>
}
