import React, {useState} from "react";
import {NIL as NIL_UUID} from "uuid";
import {NotificationManager} from "react-notifications";
import {ERROR_TIMEOUT, INFO_TIMEOUT, SUCCESS_TIMEOUT} from "../../constants/notifications";
import AuditStatus from "../audit/AuditStatus";
import ROUTES from "../../constants/routes";
import {Link, NavLink} from "react-router-dom";
import {SiteAudit} from "../../api/dto/SiteAudit";
import TechnologiesBadges from "../audit/TechnologiesBadges";
import {useListSiteAudits, useReproduceSiteAudit} from "../../api/siteAuditHooks";
import {useGetSite} from "../../api/siteHook";
import {useCreateLabel} from "../../api/labelHooks";
import {LabelCreation} from "../../api/dto/Label";
import Modal from "../common/Modal";
import ViewLabel from "../label/ViewLabel";
import DateFormat, {Format} from "../common/DateFormat";
import {Trans, useTranslation} from "react-i18next";
import InternalBadge from "./InternalBadge";

interface Props {
    siteId: string
}

const siteAuditsLimit = 100

export default function ViewSite(props: Props) {
    const {t} = useTranslation('site')
    const siteId = props.siteId
    const {isLoading: siteIsLoading, data: site} = useGetSite(props.siteId)
    const [lastAudit, setLastAudit] = useState<SiteAudit>()
    const {data: siteAudits, refetch: refreshSiteAudits} = useListSiteAudits(
        siteAuditsLimit,
        0,
        {
            siteId: siteId,
            onSuccess: data => {
                if (data.results.length > 0) {
                    setLastAudit(data.results[0])
                }
            }
        }
    )
    const reproduceSiteAuditMutation = useReproduceSiteAudit(
        data => {
            NotificationManager.success(`The audit reproduction of ${data.id} is recorded.`, 'Success !', SUCCESS_TIMEOUT)
            refreshSiteAudits()
        },
        (_, variables) =>
            NotificationManager.error(`Failed to reproduce the audit ${variables.audit_id_to_reproduce}`, 'Error !', ERROR_TIMEOUT)
    )
    const handleReproduceAudit = (audit_id: string) => {
        reproduceSiteAuditMutation.mutate({audit_id_to_reproduce: audit_id})
    }

    const handleCopyToClipboard = (src: string, value: string) => {
        navigator.clipboard.writeText(value)
        NotificationManager.info(`The "${src}" has been copied to clipboard.`, 'Copied', INFO_TIMEOUT)
    }

    const handleCopytoClipboard = (src: string, value: string) => {
        navigator.clipboard.writeText(value)
        NotificationManager.info(`The "${src}" has been copied to clipboard.`, 'Copied', INFO_TIMEOUT)
    }

    const createLabelMutation = useCreateLabel(
        label => {
            NotificationManager.success(`Label set for ${label.site_audit.id}`, 'Success !', SUCCESS_TIMEOUT)
            refreshSiteAudits()
        },
        (variables: LabelCreation) => NotificationManager.error(`Can't set the label for ${variables.audit_id}`, 'Error !', ERROR_TIMEOUT)
    )
    const setLabel = (site_id: string, audit_id: string) => {
        if (createLabelMutation.isLoading) {
            return
        }

        let request = {
            audit_id: audit_id,
            site_id: site_id
        }
        createLabelMutation.mutate(request)
    }

    if (siteIsLoading || !site || !siteAudits) {
        return (
            <div>Loading...</div>
        )
    } else {
        return (
            <div>
                <h2 className="mb-4">
                    {site.name}
                    <InternalBadge isInternal={site.is_internal} className="align-middle"/>
                    <Link
                        className="btn btn-neutral btn-sm ml-1 align-middle"
                        to={ROUTES.SITE_UPDATE.withParams(site.id)}
                    >
                        {t('actions.edit')}
                    </Link>
                </h2>

                <Link
                    className="btn btn-sm btn-outline mb-2 align-left"
                    to={ROUTES.SITE_DASHBOARD.withParams(site.id)}
                >
                    {t('actions.decarbonationDashboard')}
                </Link>

                <p><strong>{t('data.url.label')}:</strong> <a href={site.url} target="_blank" rel="noreferrer">{site.url}</a></p>
                <p><strong>{t('data.urlFilter.label')}:</strong> <a href={site.url_filter}>{site.url_filter}</a></p>
                <p>
                    <strong>{t('data.creationDate.label')}: </strong>
                    <DateFormat date={site.creation_date} format={Format.NumericDateTime}/>
                </p>
                {
                    lastAudit &&
                    <div>
                        <h3 className="mt-4 mb-2">{t('data.lastAudit')}</h3>
                        <p>
                            <strong>{t('audit:data.creationDate.label')}: </strong>
                            <DateFormat date={lastAudit.creation_date} format={Format.NumericDateTime}/>
                        </p>
                        <p onClick={() => handleCopyToClipboard("Audit id", lastAudit.id)} className="cursor-pointer">
                            <strong>{t('audit:data.id.label')}: </strong>
                            <img className="inline"
                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApElEQVRIie2USw6DMAxEXxG9VAV7UE/WzymBHYeABUkXwVYSSGkqMZIVyXY8tuIM/BhX4A2Mxl5AmZLgCUyOPfYUvAODUPQGVIK/B9oYgl4oMpnitRLrYgjspWT5RQz7FpwEXoR+GvchL6HxbCawcDt3/auVzWYCrXNv3uFrOphT0hzJYNGvYLTogqcJXRNDoCFUBFd52WyRhe3O95M/+PoE/48ZQ4BEKq9FRS8AAAAASUVORK5CYII="/>
                            <span>{lastAudit.id}</span>
                        </p>
                        <p>
                            <strong>{t('data.technologies.label')}: </strong>
                            <TechnologiesBadges technologies={lastAudit.technologies}/>
                        </p>
                        {
                            lastAudit.done_date &&
                            <div>
                                <p>
                                    <NavLink to={ROUTES.AUDIT.withParams(lastAudit.id)}
                                             className="link"
                                             data-testid="details"
                                    >
                                        {t('decarbonation:actions.see')}
                                    </NavLink>
                                </p>
                                <p>
                                    <NavLink to={ROUTES.AUDIT_DETAILS.withParams(lastAudit.id)}
                                             className="link"
                                             data-testid="fixes"
                                    >
                                        {t('decarbonation:actions.assetsDetails')}
                                    </NavLink>
                                </p>
                            </div>
                        }
                        <NavLink to={ROUTES.SITE_TEAM.withParams(site.id)}
                                 className="btn btn-secondary btn-xs">{t('actions.manageAccess')}</NavLink>
                    </div>
                }
                {
                    site.label_id &&
                    <div>
                        <h3 className="mt-4 mb-2">{t('label:title')}</h3>
                        <ViewLabel labelId={site.label_id}/>
                    </div>

                }

                <h3 className="mt-4 mb-2">{t('data.auditHistory')}</h3>
                <div className="overflow-x-auto">
                    <table className="table-container w-full table-audit-simple text-left" id="audits-list">
                        <thead>
                        <tr>
                            <th>{t('audit:data.creationDate.label')}</th>
                            <th className="w-28">{t('audit:data.id.label')}</th>
                            <th>{t('audit:data.tag.label')}</th>
                            <th>{t('audit:data.pages.label')}</th>
                            <th>{t('audit:data.scores.label')}</th>
                            <th>{t('audit:data.co2EqSavings.label')}</th>
                            <th>{t('audit:data.quote.label')}</th>
                            <th>{t('audit:data.status.label')}</th>
                            <th>{t('audit:data.documents.label')}</th>
                            <th className="w-28"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            siteAudits.results.map(audit =>
                                <tr key={audit.id}>
                                    <td className="text-sm">
                                        <DateFormat date={audit.creation_date} format={Format.NumericDateTime}/>
                                    </td>
                                    <td data-testid="audit-id" className="cursor-pointer text-xs"
                                        onClick={() => handleCopytoClipboard("Audit id", audit.id)}>
                                        <img className="inline h-4"
                                             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApElEQVRIie2USw6DMAxEXxG9VAV7UE/WzymBHYeABUkXwVYSSGkqMZIVyXY8tuIM/BhX4A2Mxl5AmZLgCUyOPfYUvAODUPQGVIK/B9oYgl4oMpnitRLrYgjspWT5RQz7FpwEXoR+GvchL6HxbCawcDt3/auVzWYCrXNv3uFrOphT0hzJYNGvYLTogqcJXRNDoCFUBFd52WyRhe3O95M/+PoE/48ZQ4BEKq9FRS8AAAAASUVORK5CYII="/>
                                        <span className="uuid">{audit.id}</span>
                                    </td>
                                    <td>{!Array.isArray(audit.tags) ? "-" : audit.tags.join(",")}</td>
                                    <td data-testid="page-count"
                                        className="tooltip tooltip-secondary before:max-w-none"
                                        data-tip={
                                            audit.total_pages_count + " page(s) found - "
                                            + audit.audited_pages_count + "/" + audit.settings.max_urls_to_audit + " page(s) audited - "
                                            + audit.error_pages_count + " page(s) in error"
                                        }
                                    >
                                        <div className="flex flex-row justify-end">
                                            {audit.total_pages_count}
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABPUlEQVR4nN3VPS8EURTG8R+hEIVkC6y3xNuG8AkUPoVK0IleCBuJciko1BK+CcpNdN4KUaHyGgq1THIkS4gZayPxTyZzc+bO89y555w7/FfaUMIxHuM6wTry1YpP4Bqr6K+I92Elnk1WI34WYk2YxQ52MYdm9OAIMz/Zliv0YhDnIT6NKWzjAsPowmXcU1OKbUkYwvgnc8YwGuMFbGQxSBI6kGF+R3xlah5Ql+UF3KIh7eSkFNXS4CSqJy3tkfTUrEedp2UeW1kM8lGmSZ1/RytuMCIjk9FE3d+IH+IAe8hlNZmJJlpEZ0W8J/rkHvsRK+MFxawmSYduRp0ngnc4xRoKsfJyGOVjnCV/72hE/YdYLlb+drK2VGvyGcUQTcRrZrLyhclyLU0KePpNg0qTQpwIyX/k11nCc63E/45X4/1DJLbRui4AAAAASUVORK5CYII="/>
                                        </div>
                                        <div className="flex flex-row justify-end">
                                            {audit.audited_pages_count}
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABjUlEQVR4nL2VzStEYRTGfxgjanyWhQ3ZUKzYMQtKjZWSlI2dLFhIFjQLkvKxUchXkiasrIxiMRJmoWTJzkZZ+iP01rN4Z9z3ztzMzFNP3Xvu6T73nOe+54A/7oGUB1+ALQKiDIgB0xY/HLndwENWbjSXQAJYB8YtvgNDHpwC7qy8CeASWHC9vAG49ohPApsODmTllgNpl0AbcMb/8ZiPQBXwrB7PF0MgAiSBTuBQsW3g2MF4UIEw8KQK5hRrAdodbA4qUBIP3pS8VCoPEo4TnbJyAnmQVhWzxaigJB6kS30OkmqZF48KcQ780AFsALfApz6wIB5EtS8uNGHNwJzRx+0DIZdABbCmiRnzEVgGBq1JOmI9uwHqXQIRDbsxy4O4NaZXFFtVFdVAl1raannW6BKoAb61UPYUMy/oFc02M1gEhoEe4BT4Un5Irat0CRjUqqdhnxb1A7vWr32i6yYZnoE6r2AeMFX2WffGvyuZ/gc7mu/2Is9F480PMKrWnivuhOmxvfTz5QHwquWfgV9rZneVGqZ3bAAAAABJRU5ErkJggg=="/>
                                        </div>
                                        <div className="flex flex-row justify-end">
                                            <div>{audit.error_pages_count}</div>
                                            <img
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABUklEQVR4nM3UsUocURjF8V80KhJW0UhIkYiIIqIgC+kMWJgmxb5A+qTQwsY8QKwELbQSzAMkVoIGbAKChWUgRSSQIkhifA2Z5RPGdXZnx8yCf/jgzrn3nsPM3Ptxz3iG0U6ZP8AJTmNcOm8i4CTGpdKP33iBKs7xqMyANeymnj/iQ1nmz3GJpyntSWhjZQTsYTVDf4/P/2s+j1/oy5jrxU8s3NW8C99QS2mfoq6pxZpkbWHe4WuDdhTVqL0tal7BH8y2ETCNCwwWCdjEVoaeFZCwjY12zSfiCD4uEDCEf5hqJ+ALlprMHTUJSFjGYZ75K/zAwzsEdOM7Xjcz78EZFnPuxXyL+cXwSLxusYJ9raliLmfNfnjdYDh+0mTO5uOoVozHIRlJiztYl8/LqDzWw7PODP5iQHlU4qLWP2fSDg6iNZRZB9etJmm7ySt1ohLvznIF0FNN/jBUHdgAAAAASUVORK5CYII="/>
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            audit.done_date &&
                                            <div>
                                                <div className="flex flex-row">
                                                    <img
                                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACMUlEQVR4nGNgGNbgfycD7/9Cxkn/Y5ke/fdm/PXfkeH/fyeG//99GX/+j2O8/7+Isf9/PQMXeYYXMPb892L8/d+W4T9e7Mv4638RYxvxBqcxsP5PZDpP0GBbJGzH8P9/NtMW4ixIZjpHkuG2DP//ezP+/l/PYkdcsKBrdmD4/z+B6cr/EtbU/5kMsv/rGcT+lzEl/k9hugiWAxlezWJD2PArDGz/K5jfoxjuxvD3fylTJE49FUyRRBkOVnyQNfz/Qfb//6ey/v/vCg3XMqYIojQTZwH7MrAFILyRDWT4AaoZDrbgAPtNuAVgzGKGTZ1k2aX/hLB90/ov2HzwGcWC/Qw85FqgXHni3wBYcIB6QWRat/MX3kg+uUf2f+sWi3PYLMAGwrtn3UK2ILhz7n0sPmAN+3OQ4/+8HXr/fdcH/Pdb7/9/2g6DKEKG182siJcpv4Dig/xJLRMwLbjCwNayxeKzz/qA/zAcvsHrLz5L+hbFRGtWHf6HbLhu7YHf3YuKubFqmLLNoBPZAhD2X+//v3Kz7bU52wyT1u5UElu6X01kxg79xJatltcCN/j9j53agub61m68Xm7aYnUG3RIfAjh5buF/uYqz/2N7p50iFKQMq/4zMDdutj5NigW+6wP+N6wIOZQ2M42VgVgwaatRR/wGj9+EDE/a6PZr2naDFgZywJT9WjyTthr2V2yyfRC/weNX0Hrf/8Hrff8lb3T7WbPZ+t6kbUa9q47JcJJl+JABAGALQHVa59MsAAAAAElFTkSuQmCC"/>
                                                    <div data-testid="google-score">{audit.global_score_google}</div>
                                                </div>

                                                <div className="flex flex-row whitespace-nowrap">
                                                    <div className="w-6">
                                                        <img
                                                            src="https://www.lewebvert.fr/assets/images/webvert-logo.svg"
                                                            alt="Webvert"
                                                        />
                                                    </div>
                                                    <span
                                                        data-testid="dpe-score">{audit.webvert_score.toFixed(2)}</span>
                                                    &nbsp;(<span data-testid="dpe-label">{audit.webvert_rating}</span>)
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>{audit.done_date && audit.eq_co2_savings && audit.eq_co2_savings.toFixed(2)}</td>
                                    <td>
                                        {audit.one_shot_price &&
                                            <div><span data-testid="price">{audit.one_shot_price}</span>€&nbsp;HT</div>}
                                    </td>
                                    <td className="text-sm" data-testid="status">
                                        <AuditStatus audit={audit} cancelButton={true} afterCancelFunction={() => refreshSiteAudits()}/>
                                    </td>
                                    <td className="text-sm">
                                        {audit.done_date &&
                                            <div>
                                                <p>
                                                    <NavLink to={ROUTES.AUDIT.withParams(audit.id)}
                                                             className="link"
                                                             data-testid="details"
                                                    >
                                                        {t('audit:list.actions.view')}
                                                    </NavLink>
                                                </p>
                                                <p>
                                                    <NavLink to={ROUTES.AUDIT_DETAILS.withParams(audit.id)}
                                                             className="link"
                                                             data-testid="fixes"
                                                    >
                                                        {t('audit:list.actions.fixes')}
                                                    </NavLink>
                                                </p>
                                            </div>
                                        }
                                    </td>
                                    <td className="text-xs">
                                        <div className="mb-2">
                                            {audit.label_id === NIL_UUID || !audit.label_id ?
                                                <>
                                                <label
                                                        className="btn btn-secondary btn-xs"
                                                        htmlFor={"set-label-" + audit.id}
                                                    >
                                                        {
                                                            createLabelMutation.isLoading
                                                                ? t('audit:actions.setLabel.inProgress') :
                                                                t('audit:actions.setLabel.label')
                                                        }
                                                    </label>
                                                    <Modal
                                                        id={"set-label-" + audit.id}
                                                        buttons={
                                                            [
                                                                {
                                                                    onClick: () => setLabel(site.id, audit.id),
                                                                    text: createLabelMutation.isLoading
                                                                        ? t('audit:actions.setLabel.inProgress')
                                                                        : t('audit:actions.setLabel.confirm.accept')
                                                                }
                                                            ]
                                                        }
                                                    >
                                                        <p>
                                                            <Trans t={t} i18nKey='audit:actions.setLabel.confirm.message' values={{url: audit.site?.url}}/>
                                                        </p>
                                                    </Modal>
                                                </>
                                                :
                                                <div onClick={() => handleCopytoClipboard("Label id", audit.label_id)}>
                                                    <img className="inline h-4"
                                                         src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApElEQVRIie2USw6DMAxEXxG9VAV7UE/WzymBHYeABUkXwVYSSGkqMZIVyXY8tuIM/BhX4A2Mxl5AmZLgCUyOPfYUvAODUPQGVIK/B9oYgl4oMpnitRLrYgjspWT5RQz7FpwEXoR+GvchL6HxbCawcDt3/auVzWYCrXNv3uFrOphT0hzJYNGvYLTogqcJXRNDoCFUBFd52WyRhe3O95M/+PoE/48ZQ4BEKq9FRS8AAAAASUVORK5CYII="/>
                                                    {audit.label_id}
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            {
                                                audit.done_date &&
                                                <button className="btn btn-xs"
                                                        onClick={() => handleReproduceAudit(audit.id)}>
                                                    {t('audit:actions.reproduce.label')}
                                                </button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}