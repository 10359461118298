import React from "react";
import {useTranslation} from "react-i18next";
import {useGetMe, useGetUsers, useSuspendUser} from "../../api/userHook";
import {Link} from "react-router-dom";
import ROUTES from "../../constants/routes";

export default function ListUsers() {
    const {t} = useTranslation('user')

    const currentUserQuery = useGetMe()
    const currentUser = currentUserQuery.data

    const usersQuery = useGetUsers()
    const users = usersQuery.data

    const suspendUserMutation = useSuspendUser()

    const suspendUser = (userId: string, suspend: boolean) => {
        suspendUserMutation.mutate({userId, suspend})
    }

    if (currentUserQuery.error || usersQuery.error) {
        return <>An error has occurred</>
    }
    if (currentUserQuery.isLoading || !currentUser || usersQuery.isLoading || !users) {
        return <div>Loading...</div>
    }

    return <table className="table-container w-full mt-4">
        <thead>
        <tr>
            <th>{t('data.login.label')}</th>
            <th>{t('data.isAdmin.label')}</th>
            <th>{t('data.suspend.label')}</th>
            <th>{t('data.credits.label')}</th>
            <th>{t('data.notes.label')}</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {
            users.sort((a, b) =>
                a.is_m2m && !b.is_m2m ? -1
                    : !a.is_m2m && b.is_m2m ? 1
                        : a.login.toLowerCase().normalize('NFD') < b.login.toLowerCase().normalize('NFD') ? -1 : 1
            )
                .map(user =>
                    <tr key={user.id}>
                        <td>{user.login}</td>
                        <td className="text-center">
                            {
                                user.is_admin
                                    ? <div className="mb-1 badge badge-outline badge-error">
                                        {t('data.isAdmin.true')}
                                    </div>
                                    : <div className="mb-1 badge badge-outline badge-success">
                                        {t('data.isAdmin.false')}
                                    </div>
                            }
                        </td>
                        <td className="text-center">
                            {
                                user.suspend
                                    ? <div className="mb-1 badge badge-outline badge-error">
                                        {t('data.suspend.true')}
                                    </div>
                                    : <div className="mb-1 badge badge-outline badge-success">
                                        {t('data.suspend.false')}
                                    </div>
                            }
                        </td>
                        <td className="text-center">
                            <Link className="btn btn-xs btn-neutral" to={ROUTES.USER_TRANSACTIONS.withParams(user.id)}>
                                {user.credits}
                            </Link>
                        </td>
                        <td className="text-center">{user.notes}</td>
                        <td>
                            {
                                user.suspend
                                    ? <button
                                        className="btn btn-xs btn-primary"
                                        onClick={() => suspendUser(user.id, false)}
                                    >
                                        {t('actions.unsuspend.label')}
                                    </button>
                                    : <button
                                        className="btn btn-xs btn-primary"
                                        onClick={() => suspendUser(user.id, true)}
                                    >
                                        {t('actions.suspend.label')}
                                    </button>
                            }

                        </td>
                    </tr>
                )
        }
        </tbody>
    </table>
}
