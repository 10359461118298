import {Trans, useTranslation} from "react-i18next";
import {CACHE_HTTPD, CACHE_NGINX, GZIP_HTTPD} from "../../constants/content";
import cloudflare_1 from "../../assets/content/cloudflare_1.png";
import cloudflare_2 from "../../assets/content/cloudflare_2.png";
import cloudflare_3 from "../../assets/content/cloudflare_3.png";
import cloudflare_4 from "../../assets/content/cloudflare_4.png";
import cloudflare_5 from "../../assets/content/cloudflare_5.png";
import React from "react";
import {Decarbonation} from "../../api/dto/Decarbonation";
import Processor from "asciidoctor";
import Info from "../common/Info";
import CodeExtract from "../common/CodeExtract";

interface Props {
    decarbonation: Decarbonation
}

export default function ServiceDetails(props: Props) {
    const {t} = useTranslation('decarbonation')

    const decarbonation=props.decarbonation

    const processor = Processor()

    return <>
        {(decarbonation.fixes.cache.httpd || decarbonation.fixes.cache.nginx || decarbonation.fixes.cache.cloudflare || decarbonation.fixes.cache.custom || decarbonation.fixes.cache.platform_sh) &&
            <>
                <h4>{t('report.serviceDetails.cacheOptimization.title')}</h4>
                {
                    decarbonation.fixes.cache.httpd  &&
                    <>
                        <p>
                            <Trans t={t}
                                   i18nKey='report.serviceDetails.cacheOptimization.htaccess.intro'/>
                        </p>
                        <div tabIndex={0}
                             className="collapse border rounded-box border-base-300 collapse-arrow mb-6">
                            <input type="checkbox"/>
                            <div className="collapse-title text-xl font-medium">
                                {t('report.serviceDetails.cacheOptimization.htaccess.see')}
                            </div>
                            <div className="collapse-content text-wrap">
                                <CodeExtract copy={true}>
                                    {CACHE_HTTPD}
                                </CodeExtract>
                            </div>
                        </div>
                    </>
                }
                {decarbonation.fixes.cache.nginx  &&
                    <>
                        <p><Trans t={t}
                                  i18nKey='report.serviceDetails.cacheOptimization.nginx.intro'/>
                        </p>
                        <div
                            className="collapse  border rounded-box border-base-300 collapse-arrow mb-6">
                            <input type="checkbox"/>
                            <div className="collapse-title text-xl font-medium">
                                {t('report.serviceDetails.cacheOptimization.nginx.see')}
                            </div>
                            <div className="collapse-content">
                                <CodeExtract copy={true}>
                                    {CACHE_NGINX}
                                </CodeExtract>
                            </div>
                        </div>
                    </>
                }
                {decarbonation.fixes.cache.cloudflare  &&
                    <>
                        <p><Trans t={t}
                                  i18nKey='report.serviceDetails.cacheOptimization.cloudflare.intro'/>
                        </p>
                        <div
                            className="collapse  border rounded-box border-base-300 collapse-arrow mb-6">
                            <input type="checkbox"/>
                            <div className="collapse-title text-xl font-medium">
                                {t('report.serviceDetails.cacheOptimization.cloudflare.see')}
                            </div>
                            <div className="collapse-content">
                                <p>{t('report.serviceDetails.cacheOptimization.cloudflare.step1')}</p>
                                <div className="text-center  mb-6">
                                    <img
                                        src={cloudflare_1}
                                        alt={t('report.serviceDetails.cacheOptimization.cloudflare.step1Fig')}
                                        className="mx-auto"
                                    />
                                    <span>
                            <em className="text-center">
                                {t('report.serviceDetails.cacheOptimization.cloudflare.step1Fig')}
                            </em>
                        </span>
                                </div>

                                <p>{t('report.serviceDetails.cacheOptimization.cloudflare.step2')}</p>
                                <div className="text-center  mb-6">
                                    <img
                                        src={cloudflare_2}
                                        alt={t('report.serviceDetails.cacheOptimization.cloudflare.step2Fig')}
                                        className="mx-auto"
                                    />
                                    <span>
                            <em className="text-center">
                                {t('report.serviceDetails.cacheOptimization.cloudflare.step2Fig')}
                            </em>
                        </span>
                                </div>

                                <p>{t('report.serviceDetails.cacheOptimization.cloudflare.step3')}</p>
                                <div className="text-center  mb-6">
                                    <img
                                        src={cloudflare_3}
                                        alt={t('report.serviceDetails.cacheOptimization.cloudflare.step3Fig')}
                                        className="mx-auto"
                                    />
                                    <span>
                            <em className="text-center">
                                {t('report.serviceDetails.cacheOptimization.cloudflare.step3Fig')}
                            </em>
                        </span>
                                </div>

                                <p>{t('report.serviceDetails.cacheOptimization.cloudflare.step4')}</p>
                                <div className="text-center  mb-6">
                                    <img
                                        src={cloudflare_4}
                                        alt={t('report.serviceDetails.cacheOptimization.cloudflare.step4Fig')}
                                        className="mx-auto"
                                    />
                                    <span>
                            <em className="text-center">
                                {t('report.serviceDetails.cacheOptimization.cloudflare.step4Fig')}
                            </em>
                        </span>
                                </div>

                                <p>
                                    <Trans t={t}
                                           i18nKey='report.serviceDetails.cacheOptimization.cloudflare.step5'/>
                                </p>
                                <div className="text-center  mb-6">
                                    <img
                                        src={cloudflare_5}
                                        alt={t('report.serviceDetails.cacheOptimization.cloudflare.step5Fig')}
                                        className="mx-auto"
                                    />
                                    <span>
                            <em className="text-center">
                                {t('report.serviceDetails.cacheOptimization.cloudflare.step5Fig')}
                            </em>
                        </span>
                                </div>

                                <p>
                                    <Trans t={t}
                                           i18nKey='report.serviceDetails.cacheOptimization.cloudflare.step6'/>
                                </p>

                                <p>{t('report.serviceDetails.cacheOptimization.cloudflare.done')}</p>
                            </div>
                        </div>
                    </>
                }
                {decarbonation.fixes.cache.custom  &&
                    <div
                        dangerouslySetInnerHTML={{__html: processor.convert(decarbonation.fixes.cache.custom) as string}}/>
                }

            </>
        }


        {(decarbonation.fixes.compression.httpd || decarbonation.fixes.compression.nginx || decarbonation.fixes.compression.custom) &&
            <>
                <h4>{t('report.serviceDetails.textCompression.title')}</h4>
                {decarbonation.fixes.compression.httpd  &&
                    <>
                        <p><Trans t={t}
                                  i18nKey='report.serviceDetails.textCompression.htaccess.intro'/>
                        </p>
                        <div
                            className="collapse  border rounded-box border-base-300 collapse-arrow mb-6">
                            <input type="checkbox"/>
                            <div className="collapse-title text-xl font-medium">
                                {t('report.serviceDetails.textCompression.htaccess.see')}
                            </div>
                            <div className="collapse-content">
                                <CodeExtract copy={true}>
                                    {GZIP_HTTPD}
                                </CodeExtract>
                            </div>
                        </div>
                    </>
                }
                {decarbonation.fixes.compression.nginx  &&
                    <>
                        <p><Trans t={t}
                                  i18nKey='report.serviceDetails.textCompression.nginx.intro'/></p>
                        <p className="ulist">
                            <ul>
                                <li>{t('report.serviceDetails.textCompression.nginx.enableGzip')}</li>
                                <li>{t('report.serviceDetails.textCompression.nginx.addTypes')}</li>
                                <li>{t('report.serviceDetails.textCompression.nginx.excludePath')}</li>
                            </ul>
                        </p>
                    </>
                }
                <div className="my-4">
                    <p>{t('report.serviceDetails.textCompression.nginx.securityBreach.intro')}</p>
                    <Info>
                BREACH (CVE-2013-3587) potentially NOT ok, "gzip" HTTP compression detected. - only supplied "/" tested Can be ignored for static pages or if no secrets in the page
                    </Info>
                    <p>{t('report.serviceDetails.textCompression.nginx.securityBreach.text')}</p>
                    <p>{t('report.serviceDetails.textCompression.nginx.text')}</p>
                </div>
                {decarbonation.fixes.compression.custom  &&
                    <div
                        dangerouslySetInnerHTML={{__html: processor.convert(decarbonation.fixes.compression.custom) as string}}/>
                }
            </>
        }
        {
            decarbonation.fixes.images.compressed !== 0 &&
            <>
                <h4>{t('report.serviceDetails.imagesOptimization.title')}</h4>
                <p>{t('report.serviceDetails.imagesOptimization.text1')}</p>
                <p>{t('report.serviceDetails.imagesOptimization.text2')}</p>
                <Info>
                    <Trans t={t} i18nKey='report.serviceDetails.imagesOptimization.count'
                           values={{count: decarbonation.fixes.images.compressed}}/>
                </Info>
            </>
        }

        {
            decarbonation.fixes.images.resized !== 0 &&
            <>
                <h4>{t('report.serviceDetails.imagesResize.title')}</h4>
                <p>{t('report.serviceDetails.imagesResize.text1')}</p>
                <p>{t('report.serviceDetails.imagesResize.text2')}</p>
                <Info>
                    <Trans t={t} i18nKey='report.serviceDetails.imagesResize.count'
                           values={{count: decarbonation.fixes.images.resized}}/>
                </Info>
            </>
        }

        {
            decarbonation.fixes.images.converted_png_to_jpg !== 0 &&
            <>
                <h4>{t('report.serviceDetails.imagesFormat.title')}</h4>
                <p>{t('report.serviceDetails.imagesFormat.text1')}</p>
                <p>{t('report.serviceDetails.imagesFormat.text2')}</p>
                <Info>
                    <Trans t={t} i18nKey='report.serviceDetails.imagesFormat.count'
                           values={{count: decarbonation.fixes.images.converted_png_to_jpg}}/>
                </Info>
            </>
        }

        {
            decarbonation.fixes.images.converted_gif_to_mp4 !== 0 &&
            <>
                <h4>{t('report.serviceDetails.videoFormat.title')}</h4>
                <p>{t('report.serviceDetails.videoFormat.text1')}</p>
                <p>{t('report.serviceDetails.videoFormat.text2')}</p>
                <Info>
                    <Trans t={t} i18nKey='report.serviceDetails.videoFormat.count'
                           values={{count: decarbonation.fixes.images.converted_gif_to_mp4}}/>
                </Info>
            </>
        }

        {
            decarbonation.fixes.images.internalized !== 0 &&
            <>
                <h4>{t('report.serviceDetails.imagesInternalisation.title')}</h4>
                <p>{t('report.serviceDetails.imagesInternalisation.text')}</p>
                <Info>
                    <Trans t={t} i18nKey='report.serviceDetails.imagesInternalisation.count'
                           values={{count: decarbonation.fixes.images.internalized}}/>
                </Info>
            </>
        }

        {decarbonation.fixes.images.custom  &&
            <>
                <h4>{t('report.serviceDetails.imagesCustom.title')}</h4>
                <div
                    dangerouslySetInnerHTML={{__html: processor.convert(decarbonation.fixes.images.custom) as string}}/>
            </>
        }
        {decarbonation.fixes.custom  &&
            <>
                <h4>{t('report.serviceDetails.custom.title')}</h4>
                <div
                    dangerouslySetInnerHTML={{__html: processor.convert(decarbonation.fixes.custom) as string}}/>
            </>
        }
    </>
}