import React, {useEffect, useState} from "react"
import {Trans, useTranslation} from "react-i18next";
import DateFormat, {Format} from "../common/DateFormat";
import WebvertRating from "../common/WebvertRating";
import {Decarbonation} from "../../api/dto/Decarbonation";
import WebvertScore from "../audit/WebvertScore";
import WCOIRating from "../common/WCOIRating";

type DecarbonationScoreEvolutionProps = {
    decarbonation: Decarbonation,
}

export default function DecarbonationScoreEvolution(props: DecarbonationScoreEvolutionProps) {
    const {t} = useTranslation('decarbonation')

    return <>
        {
            (props.decarbonation.start_site_audit && props.decarbonation.end_site_audit) &&
            <>
                <h4>{t('audit:report.subtitle_optim_energy')}</h4>
                <p>
                    <Trans
                        t={t}
                        i18nKey='report.scoreEvolution.globalGain'
                        values={{value: Math.round((1 - props.decarbonation.end_site_audit.webvert_score / props.decarbonation.start_site_audit.webvert_score) * 100)}}
                    />
                </p>
            </>
        }
        <div className="flex flex-wrap lg:flex-nowrap flew-row gap-4 ">
            {
                props.decarbonation.start_site_audit &&
                <div className="lg:w-1/2">
                    <h4>{t('report.scoreEvolution.epdBefore.title')}</h4>
                    <p>
                        {
                            t(
                                'report.scoreEvolution.epdBefore.text',
                                {
                                    rating: props.decarbonation.start_site_audit.webvert_rating,
                                    score: props.decarbonation.start_site_audit.webvert_score
                                }
                            )
                        }
                    </p>
                    <WebvertScore score={props.decarbonation.start_site_audit.webvert_score}
                                  rating={props.decarbonation.start_site_audit.webvert_rating}
                                  className="mt-4"/>
                </div>
            }
            {
                props.decarbonation.end_site_audit &&
                <div className="lg:w-1/2">
                    <h4>{t('report.scoreEvolution.epdAfter.title')}</h4>
                    <p>
                        {
                            t(
                                'report.scoreEvolution.epdAfter.text',
                                {
                                    rating: props.decarbonation.end_site_audit.webvert_rating,
                                    score: props.decarbonation.end_site_audit.webvert_score
                                }
                            )
                        }
                    </p>
                    <WebvertScore score={props.decarbonation.end_site_audit.webvert_score}
                                  rating={props.decarbonation.end_site_audit.webvert_rating}
                                  className="mt-4"/>
                </div>
            }
        </div>
        { props.decarbonation.start_site_audit && props.decarbonation.end_site_audit && props.decarbonation.start_site_audit.wcoi_score >= 0 &&
            <>
                <h4>{t('audit:report.subtitle_optim_hw_obso')} - <span
                    className="italic text-warning">{t('audit:beta')} </span>
                </h4>
                <p className="text-neutral-focus">{t('audit:report.desc_optim_hw_obso')}</p>

                <div className="flex flex-wrap lg:flex-nowrap flew-row gap-4 ">
                    <div className="lg:w-1/2">
                        <h4 className="text-center">{t('report.scoreEvolution.wcoiBefore.title')}</h4>
                        <WCOIRating score={props.decarbonation.start_site_audit.wcoi_score * 100} className="my-6"/>
                    </div>
                    <div className="lg:w-1/2">
                        <h4 className="text-center">{t('report.scoreEvolution.wcoiAfter.title')}</h4>
                        <WCOIRating score={props.decarbonation.end_site_audit.wcoi_score * 100} className="my-6"/>
                    </div>
                </div>

                <p className="text-neutral-focus text-center text-sm mt-2">
                        {t('audit:report.src_hw_obso1')}
                        <a href={t('audit:report.src_hw_obso_link')} target="_blank"
                           rel="noreferrer">{t('audit:report.src_hw_obso2')}</a>
                    </p>

                    <p className="mt-4">{t('audit:report.desc_optim_hw_obso2')}</p>
                </>
                }
            </>

        }
