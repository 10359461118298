import React from "react";
import {useParams} from "react-router-dom";
import UpdateSiteForm from "../../../components/site/UpdateSiteForm";


export default function UpdateSitePage() {
    let params = useParams()

    return (
        <div className="py-4 px-8">
            <UpdateSiteForm siteId={params.id}/>
        </div>
    )

}
