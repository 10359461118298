import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useTransferTeam} from "../../api/teamHook";

interface Props {
    teamId: string
    onSuccess?: () => void
}

export default function TransferTeam(props: Props) {
    const teamId = props.teamId
    const onSuccess = props.onSuccess

    const {t} = useTranslation('team')

    const [newAdminLogin, setNewAdminLogin] = useState('')
    const transferTeamMutation = useTransferTeam(
        () => {
            setNewAdminLogin('')
            if (onSuccess) {
                onSuccess()
            }
        }
    )
    const handleAddMemberSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (newAdminLogin) {
            transferTeamMutation.mutate({teamId: teamId, newAdminLogin: newAdminLogin})
        }
    }

    return <form onSubmit={handleAddMemberSubmit} className="flex flex-row my-4">
        <div className="form-control">
            <label className="input input-bordered flex items-center gap-2">
                {t('data.admin.label')}
                <input
                    name="admin_login"
                    type="text"
                    placeholder={t('data.admin.placeHolder')}
                    value={newAdminLogin}
                    onChange={e => setNewAdminLogin(e.target.value)}
                />
            </label>
        </div>
        <input
            id="submit"
            type="submit"
            className="btn btn-primary btn-wide ml-4"
            value={transferTeamMutation.isLoading ? "..." : t('actions.transfer.submit')}
            disabled={transferTeamMutation.isLoading || !newAdminLogin}
        />
    </form>
}